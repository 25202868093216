import React, { useEffect, useState } from 'react';
import { LabelInput } from '../../components/label/label.input';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { Button } from '../../components/buttons/button.default';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import Loader from '../../components/Loader/loader';
import {LoadingPulse} from '../../components/Loader/loadingPulse';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai'
import { FaAngleRight, FaAngleDown, FaTrash, FaCog } from 'react-icons/fa'
import CurrencyInput from '../../components/input/inputCurrencyPtBr';
import { useMenu } from '../../hooks/useMenu';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { useModal } from '../../hooks/useModal';
import moment from 'moment';
import Modal from '../../components/Modal/modal';
import { FiSlash, FiTrash2, FiX } from 'react-icons/fi';
import { BiCog } from "react-icons/bi";
import { Input } from '../../components/input/input';
import { TableDefault } from '../../components/table/table.default';

export function Notes(){

    const { show, setShow } = useModal();
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const [employeesList, setEmployeesList] = useState([])
    const [loading, setLoading] = useState(true);
    const [loadingNotes, setLoadingNotes] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState('')
    const { company, branch } = useCompanyBranch();
    const token = localStorage.getItem('@auth:token');
    const { setMenuTitle } = useMenu()
    setMenuTitle('Anotações')
    const navigate = useNavigate()
    const [notes, setNotes] = useState([])
    const [selectedNote, setSelectedNote] = useState('')
    const [buttonRemove, setButtonRemove] = useState(false)
    const [buttonCreate, setButtonCreate] = useState(false)
    const [buttonEdit, setButtonEdit] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')
    const [selectedType, setSelectedType] = useState('')
    const [selectedText, setSelectedText] = useState('')
    const [filterType, setFilterType] = useState('')
    const [typesList, setTypesList] = useState([])
    const [employeeInput, setEmployeeInput] = useState('')

    useEffect(() => {
        if (company === 0 || branch === 0) {
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }

        async function getEmployess() {
            setLoading(true)
            try {
                const getEmployeesAPI = await api.get(`api/v1/employees/company/${company}/branch/${branch}?status=1`, {
                    headers: {
                        Authorization: token
                    }
                })
                setEmployeesList(getEmployeesAPI?.data?.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        getTypes()
        getEmployess()
    }, [])

    async function getTypes(){
        try {
            const getTypeApi = await api.get(`api/v1/typeNotes/company/${company}`, {
                headers: {
                    Authorization: token
                }
            })
            setTypesList(getTypeApi?.data?.data)
        } catch (error) {
            setLoading(false)
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function handleNotes(employee, filter){
        setLoadingNotes(true)
        try {

            const getNotesApi = await api.get(`api/v1/notes/company/${company}/person/${employee?.employeeId}${filter ? `?typeNotes=${filter?.id}` : ''}`, {
                headers: {
                    Authorization: token
                }
            })
            setNotes(getNotesApi?.data?.data?.map(item => {
                return {
                    ...item,
                    alertDate: item?.alertDate ? moment(item?.alertDate)?.utc(false)?.format('DD/MM/YYYY') : ' - ',
                    notes: item?.notes?.length > 50 ? `${item?.notes?.slice(0,50)}...` : item?.notes,
                    trash: 
                    <Tippy content={<span>Excluir</span>}
                        arrow={true}
                        animation='perspective'
                        placement='top'
                        className="ml-3"
                        delay={100}>
                        <div>
                            <FiTrash2 className='cursor-pointer ml-[50%] text-red-800' onClick={() => {setSelectedNote(item); setButtonRemove(true); setShow(true)}} />
                        </div>
                    </Tippy>
                }
            }))

            setLoadingNotes(false)
        } catch(error){
            setLoadingNotes(false)
        }
    }

    async function handleDelete(){
        setLoadingNotes(true)
        try {
            await api.delete(`api/v1/notes/${selectedNote?.id}`, {
                headers: {
                    Authorization: token
                }
            })
            setLoadingNotes(false)
            setShowAprovalModal(false)
            setShowNotificationModal(true)
            setNotificationModalText('Anotação removida com sucesso')
            handleNotes(selectedEmployee)

        } catch(error){
            setLoadingNotes(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao excluir anotação')
        }
    }

    async function handleSubmit(){
        if (!selectedType || !selectedText){
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Preencha os campos obrigatórios')
        }

        setLoadingNotes(true)
        setShow(false)

        try {
            await api.post(`api/v1/notes`, {
                company,
                typePerson: 1,
                person: selectedEmployee?.employeeId,
                notes: selectedText,
                alertDate: selectedDate ? selectedDate : undefined,
                typesNotes_id: selectedType?.id
            }, {
                headers: {
                    Authorization: token
                }
            })
            setLoadingNotes(false)
            setShowAprovalModal(false)
            setShowNotificationModal(true)
            setNotificationModalText('Anotação criada com sucesso')
            handleNotes(selectedEmployee)

        } catch(error){
            setLoadingNotes(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao criar anotação')
        }
    }

    async function handleEdit(){
        setLoadingNotes(true)
        setShow(false)
        console.log()
        try {
            await api.put(`api/v1/notes/${selectedNote?.id}`, {
                notes: selectedNote?.notes,
                alertDate: ![' - '].includes(selectedNote?.alertDate) ? selectedNote?.alertDate : undefined
            }, {
                headers: {
                    Authorization: token
                }
            })
            setLoadingNotes(false)
            setShowAprovalModal(false)
            setShowNotificationModal(true)
            setNotificationModalText('Anotação atualizada com sucesso')
            handleNotes(selectedEmployee)

        } catch(error){
            setLoadingNotes(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao criar anotação')
        }
    }

    useEffect(() => {
        if(!show){
            clearState()          
        }
    }, [show])

    function clearState(){
        setSelectedNote('')
        setSelectedDate('')
        setSelectedText('')
        setButtonRemove(false)
        setButtonCreate(false)
        setButtonEdit(false)
        setFilterType('')
        setSelectedType('')
    }

    const tableThead = [
        {
            "name": "Data",
            "original_name": "alertDate"
        },
        {
            "name": "Anotação",
            "original_name": "notes"
        },
        {
            "name": "Tipo",
            "original_name": "typesNotes_description"
        },
        {
            "name": "Remover",
            "original_name": "trash"
        }
    ]

    return (
        <>
            <Modal>
            {
                show && buttonRemove ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-semibold items-center text-sm'>
                                <p className='mr-7'>Deseja realmente excluir essa anotação?</p>
                            </p>
                            <FiX className='text-gray-500 text-lg mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                        </div>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button height={6} width={24} background={'bg-green-800'} type={'button'} onClick={() => { setShow(false); handleDelete() }}>Confirmar</Button>
                            <Button height={6} width={24} background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                        </div>
                    </div>
                </Modal>
                : show && buttonCreate ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full sm:w-[28rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-semibold items-center text-sm'>
                                <p className='mr-7'>Criação de anotação</p>
                            </p>
                            <FiX className='text-gray-500 text-lg mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                        </div>
                        <form className='flex flex-col gap-2 h-full w-full p-5 items-center justify-center mt-3 mb-5'>
                            <LabelInput text={''}>
                                <Input
                                    type={'date'}
                                    width={80}
                                    value={selectedDate ? selectedDate : ''}
                                    onChange={(e) => {
                                        setSelectedDate(e?.target?.value)
                                    }}
                                />
                            </LabelInput>
                            <LabelInput>
                                <InputAutoComplete
                                    data={typesList}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    preSelectedValue={selectedType ? selectedType?.description : selectedType?.description}
                                    value={selectedType?.description}
                                    onChange={(e) => {
                                        if (typeof (e) == 'object') {
                                            setSelectedType(e)
                                        }
                                    }}
                                    placeHolder={'Tipo de anotação *'}
                                    width={80}
                                />
                            </LabelInput>
                            <textarea
                                className='resize-none p-2 w-80 text-sm rounded-md h-32'
                                maxLength={1000}
                                onChange={(e) => {
                                    setSelectedText(e?.target?.value)
                                }}
                                value={selectedText ? selectedText : ''}
                                placeholder='Anotação *'
                            />
                        </form>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4 mt-5'>
                            <Button height={6} width={24} background={'bg-green-800'} type={'button'} onClick={() => { handleSubmit() }}>Confirmar</Button>
                            <Button height={6} width={24} background={'bg-red-800'} onClick={() => { setShow(false); setButtonCreate(false) }}>Cancelar</Button>
                        </div>
                    </div>
                </Modal>
                : show && buttonEdit ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full sm:w-[28rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-semibold items-center text-sm'>
                                <p className='mr-7'>Edição de anotação</p>
                            </p>
                            <FiX className='text-gray-500 text-lg mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                        </div>
                        <form className='flex flex-col gap-2 h-full w-full p-5 items-center justify-center mt-3 mb-5'>
                            <LabelInput text={''}>
                                <Input
                                    type={'date'}
                                    width={80}
                                    value={selectedNote?.alertDate ? moment(selectedNote?.alertDate)?.utc(false)?.format('YYYY-MM-DD') : ''}
                                    onChange={(e) => {
                                        setSelectedNote(prev => ({...prev, alertDate: e?.target?.value}))
                                    }}
                                />
                            </LabelInput>
                            <textarea
                                className='resize-none p-2 w-80 text-sm rounded-md h-32'
                                maxLength={1000}
                                onChange={(e) => {
                                    setSelectedNote(prev => ({...prev, notes: e?.target?.value}))
                                }}
                                value={selectedNote?.notes ? selectedNote?.notes : ''}
                                placeholder='Anotação'
                            />
                            <div className='flex flex-row w-80 p-1 border border-gray-100 bg-gray-50 rounded-md items-center justify-center'>
                                <p>{typesList.find(item => item?.id == selectedNote?.typesNotes_id)?.description}</p>
                            </div>
                        </form>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4 mt-5'>
                            <Button height={6} width={24} background={'bg-green-800'} type={'button'} onClick={() => { handleEdit() }}>Editar</Button>
                            <Button height={6} width={24} background={'bg-red-800'} onClick={() => { setShow(false); setButtonEdit(false) }}>Cancelar</Button>
                        </div>
                    </div>
                </Modal>
                :
                <></>
            }
            </Modal>
            <div className={`overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 h-[100vh]`}>
                {
                    loading ? 
                    <Loader/>
                    :
                    <>
                        <div className="w-full hidden md:flex flex-col justify-center items-center mt-8">
                            <div className={`flex ${selectedEmployee?.employeeId ? 'flex-col sm:flex-row' : 'flex-col'} justify-center items-center gap-5 mt-5`}>
                                {
                                    !selectedEmployee?.employeeId &&
                                    <h1 className='text-xl'>Selecione um colaborador</h1>
                                }
                                <LabelInput>
                                    <InputAutoComplete
                                        data={employeesList}
                                        id={'employee'}
                                        preSelectedValue={employeeInput ? employeeInput?.employeeName : ''}
                                        value={employeeInput ? employeeInput?.employeeName : ''}
                                        optionList={["employeeId", "employeeName"]}
                                        selectedLabel={'employeeName'}
                                        onChange={(e) => {
                                            setEmployeeInput(e)
                                            if (typeof(e) == 'object') {
                                                setSelectedEmployee(e)
                                                clearState()
                                                handleNotes(e)
                                            }
                                        }}
                                        width={80}
                                        placeHolder={'Colaborador'}
                                    />
                                </LabelInput>
                                <div className={`absolute top-16 right-5 ${selectedEmployee?.employeeId ? 'hidden' : 'flex flex-row'}`}>
                                    <Tippy
                                        content={'Configurar Tipos'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={80}>
                                        <div>
                                            <BiCog 
                                                className='text-xl cursor-pointer'
                                                onClick={() => navigate('/typesNotes')}
                                            />
                                        </div>
                                    </Tippy>
                                </div>
                            </div>
                            {
                                selectedEmployee?.employeeId &&
                                <div className='w-[92%] ml-10 justify-between items-center flex mt-5'>
                                    <div className='flex flex-row justify-center items-center gap-2'>
                                        <LabelInput>
                                            <InputAutoComplete
                                                data={typesList}
                                                id={'typeFilter'}
                                                preSelectedValue={filterType ? filterType?.description : ''}
                                                value={filterType ? filterType?.description : ''}
                                                optionList={["description"]}
                                                selectedLabel={'description'}
                                                onChange={(e) => {
                                                    setFilterType(e)
                                                    if (typeof(e) == 'object') {
                                                        handleNotes(selectedEmployee, e)
                                                    }
                                                }}
                                                width={80}
                                                placeHolder={'Filtrar por tipo'}
                                            />
                                        </LabelInput>
                                        {
                                            filterType?.id &&
                                            <Tippy
                                                content={'Limpar Filtro'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={80}>
                                                <div>
                                                    <FiSlash
                                                        className='cursor-pointer text-base'
                                                        onClick={() => {
                                                            setFilterType('')
                                                            handleNotes(selectedEmployee)
                                                        }}
                                                    />
                                                </div>
                                            </Tippy>
                                        }
                                    </div>
                                    <div className={` items-center justify-center gap-3 ${selectedEmployee ? 'flex flex-row' : 'hidden'}`}>
                                        <Button height={6} width={32} onClick={() => {setButtonCreate(true); setShow(true)}}>Criar anotação</Button>
                                        <Tippy
                                            content={'Configurar Tipos'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={80}>
                                            <div>
                                                <BiCog 
                                                    className='text-xl cursor-pointer'
                                                    onClick={() => navigate('/typesNotes')}
                                                />
                                            </div>
                                        </Tippy>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            loadingNotes ?
                            <div className='w-full justify-center items-center'>
                                <Loader/>
                            </div>
                            : selectedEmployee?.employeeId &&
                            <div className="hidden lg:flex mt-3">
                                <div className="hidden lg:flex flex-col justify-start items-center px-2 lg:ml-20 w-full mr-8 mt-3 gap-5">
                                    <TableDefault
                                        onClick={(e) => {setSelectedNote(e);setButtonEdit(true);setShow(true)}}
                                        title={tableThead}
                                        cursorPointer={true}
                                        data={notes}
                                        quantity={notes?.length}
                                        collumns={["alertDate", "notes", "typesNotes_description", "trash"]}
                                    />
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </>
    )
}