export function removerAcentos(texto) {
    var mapaAcentos = {
      'a': /[áàãâä]/g,
      'e': /[éèêë]/g,
      'i': /[íìîï]/g,
      'o': /[óòõôö]/g,
      'u': /[úùûü]/g,
      'c': /[ç]/g,
      'n': /[ñ]/g
    };
  
    for (var letra in mapaAcentos) {
      var regex = mapaAcentos[letra];
      texto = texto.replace(regex, letra);
    }
  
    return texto;
  }