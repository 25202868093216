import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import { useMenu } from '../../hooks/useMenu';
import api from "../../services/api";
import { SplineAdminAdmission } from "../../components/dashboard/splineAdminAdmission";
import { SplineAdminSendEvents } from "../../components/dashboard/splineAdminSendEvents";
import { FunnelAdminAdmission } from "../../components/dashboard/funnelAdminAdmission";
import { SimplePieAdminUserPerAccess } from "../../components/dashboard/simplePieAdminUserPerAccess";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import Loader from "../../components/Loader/loader";
import Modal from "../../components/Modal/modal";
import { useModal } from "../../hooks/useModal";
import { TableDefault } from "../../components/table/table.default";
import tableToExcel from '../../utils/tableToExcel'
import { FaCloudDownloadAlt } from "react-icons/fa"

export function DashboardAdmin() {

    const { setMenuTitle } = useMenu()
    const navigate = useNavigate()
    setMenuTitle('Dashboard')
    const token = localStorage.getItem('@auth:token')
    const [loading, setLoading] = useState(true)
    const { setShow, show, setShowNotificationModal, setNotificationModalText, setShowAprovalModal } = useModal()
    const [loadingAdmission, setLoadingAdmission] = useState(true)
    const [loadingCards, setLoadingCards] = useState(true)
    const [loadingSendEvents, setLoadingSendEvents] = useState(true)
    const [loadingCompaniesMoreAdmission, setLoadingCompaniesMoreAdmission] = useState(true)
    const [loadingUsersPerAccess, setLoadingUsersPerAccess] = useState(true)
    const [dataQuantityAdmission, setDataQuantityAdmission] = useState([])
    const [infoUsers, setInfoUsers] = useState('')
    const [docsSends, setDocsSends] = useState('')
    const [dataQuantitySendEvents, setDataQuantitySendEvents] = useState([])
    const [dataCompaniesMoreAdmission, setDataCompaniesMoreAdmission] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableThead, setTableThead] = useState([])
    const [tableColumn, setTableColumn] = useState([])
    const [tableId, setTableId] = useState('')
    const [modalTitle, setModalTitle] = useState('')

    useEffect(() => {
        if (!show){
            setTableData([])
            setTableThead([])
            setTableColumn([])
            setTableId('')
            setTableId('')
        }
    }, [show])

    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        try {

            setLoading(true)
            await getQuantityAdmission()
            setLoadingCards(true)
            await getInfoUsers()
            await quantityDocsSends()
            setLoadingCards(false)
            await getQuantitySendEvents()
            await getCompaniesWithMoreAdmission()
            setLoadingUsersPerAccess(true)
            await new Promise(resolve => setTimeout(resolve, 1000))
            setLoadingUsersPerAccess(false)
            setLoading(false)

        } catch (error) {
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function getQuantityAdmission(){
        try {
            setLoadingAdmission(true)

            const getQuantityAPI = await api.get(`api/v1/dashboardAdmin/quantityAdmissionInYear`, {
                headers: {
                    authorization: token
                }
            })
            setDataQuantityAdmission(getQuantityAPI?.data?.data)

            setLoadingAdmission(false)

        } catch(error){
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function getInfoUsers() {
        try {
            const getInfoUsersAPI = await api.get(`api/v1/dashboardAdmin/quantityInfoUsers`, {
                headers: {
                    authorization: token
                }
            })
            setInfoUsers(getInfoUsersAPI?.data?.data)
        } catch(error){
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function quantityDocsSends(){
        try {
            const getDocsSendsAPI = await api.get(`api/v1/dashboardAdmin/quantityDocsSend`, {
                headers: {
                    authorization: token
                }
            })
            setDocsSends(getDocsSendsAPI?.data?.data)
        } catch(error){
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function getQuantitySendEvents(){
        try {
            setLoadingSendEvents(true)

            const getQuantityAPI = await api.get(`api/v1/dashboardAdmin/quantitySendEventsInYear`, {
                headers: {
                    authorization: token
                }
            })
            setDataQuantitySendEvents(getQuantityAPI?.data?.data)

            setLoadingSendEvents(false)

        } catch(error){
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function getCompaniesWithMoreAdmission(){
        try {
            setLoadingCompaniesMoreAdmission(true)

            const getCompaniesMoreAdmission = await api.get(`api/v1/dashboardAdmin/companiesWithMoreAdmissions`, {
                headers: {
                    authorization: token
                }
            })
            setDataCompaniesMoreAdmission(getCompaniesMoreAdmission?.data?.data)

            setLoadingCompaniesMoreAdmission(false)

        } catch(error){
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    function clickCard(card){
        switch (card) {
            case 'usersAccessManager':
                setShow(true)
                setTableData(infoUsers?.usersAccessManager)
                setTableThead([
                    {
                        name: "Usuário",
                        original_name: "concat_name"
                    },
                    {
                        name: "Empresas",
                        original_name: "companies"
                    },
                    {
                        name: "Acesso",
                        original_name: "access"
                    }
                ])
                setTableColumn(['concat_name', 'companies', 'access'])
                setTableId('usersAccessManager')
                setModalTitle('Usuários com acesso gerencial')
            break;
            case 'usersLoggedCurrentMonth':
                setShow(true)
                setTableData(infoUsers?.usersLoggedCurrentMonth)
                setTableThead([
                    {
                        name: "Usuário",
                        original_name: "concat_name"
                    }
                ])
                setTableColumn(['concat_name'])
                setTableId('usersAccessManager')
                setModalTitle('Usuários logados no mês')
            break;
        }
    }

    return (
        <>
            <Modal>
                <div className={`h-fit max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[50rem] p-4`}>
                    <div className='flex justify-between pl-3 py-2 items-center mb-6'>
                        <p className='flex flex-row font-extrabold items-center text-base sm:text-sm xl:text-lg'>
                            <p className='mr-7 flex text-primaryDefaultLight justify-center items-center'>{modalTitle}</p>
                            <Tippy
                                content={<a>Exportar</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={80}
                            >
                                <div>
                                    <FaCloudDownloadAlt className="flex flex-col justify-center cursor-pointer duration-100 text-primaryDefaultLight text-xl h-full" onClick={() => tableToExcel(`${Number(new Date())}.xls`)}/>
                                </div>
                            </Tippy>
                            <a id="link-to-download" href="!#">{}</a>
                        </p>
                    </div>
                    <TableDefault
                        title={tableThead}
                        data={tableData}
                        quantity={tableData?.length}
                        collumns={tableColumn}
                        cursorPointer={false}
                        id={'TableToExport'}
                        onClick={() => {}}
                        isDangerously
                    />                    
                </div>
            </Modal>
            <div className={`duration-500 hidden w-full h-full sm:flex flex-col justify-start py-8 bg-gray-200 gap-4`}>
                <div className="flex flex-col overflow-scroll h-full w-full gap-4">
                    <div className="w-[96%] mt-4 ml-14 pr-1 h-full gap-3 hidden lg:flex lg:flex-col xl:flex-col 2xl:flex-col lg:overflow-x-hidden xl:overflow-auto 2xl:overflow-x-hidden overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                        {/* Admissiões */}
                        <div className="flex flex-col w-full gap-3">
                            <div className="h-full gap-3 w-full flex">
                                {
                                    loadingAdmission ?
                                    <div className="py-6 w-full xl:h-[28rem] shadow-borderShadow rounded-lg bg-white flex justify-center items-center">
                                        <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                                    </div>
                                    :
                                    <>
                                        <div className="py-6 w-full h-full shadow-borderShadow rounded-lg bg-white pr-2">
                                            <p className="flex flex-row items-center justify-center gap-2 text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">
                                                Admissões
                                            </p>
                                            <SplineAdminAdmission
                                                id={'dashboardDesktopAdmissions'}
                                                data={dataQuantityAdmission}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        {/* Cards */}
                        <div className="flex flex-row items-center w-full h-32 gap-3 p-2 justify-evenly">
                            {
                                loadingCards ?
                                <>
                                    <div className="bg-white rounded-md h-32 animate-pulse w-48 flex flex-col justify-start p-2 items-center"></div>
                                    <div className="bg-white rounded-md h-32 animate-pulse w-48 flex flex-col justify-start p-2 items-center"></div>
                                    <div className="bg-white rounded-md h-32 animate-pulse w-48 flex flex-col justify-start p-2 items-center"></div>
                                    <div className="bg-white rounded-md h-32 animate-pulse w-48 flex flex-col justify-start p-2 items-center"></div>
                                </>
                                : 
                                <>
                                    <div className="bg-white rounded-md h-full w-48 flex flex-col justify-start p-2 items-center hover:scale-105 duration-200 cursor-pointer" onClick={() => navigate('/users')}>
                                        <p className="">Usuários ativos</p>
                                        <p className="text-lg flex flex-col mt-5 text-primaryDefaultLight font-semibold">{infoUsers?.quantityUsersActive}</p>
                                    </div>
                                    <div className="bg-white rounded-md h-full w-48 flex flex-col justify-start p-2 items-center hover:scale-105 duration-200 cursor-pointer" onClick={() => clickCard('usersAccessManager')}>
                                        <p className="">Acessos gerencial</p>
                                        <p className="text-lg flex flex-col mt-5 text-primaryDefaultLight font-semibold">{infoUsers?.quantityUsersWithAccessManager}</p>
                                    </div>
                                    <div className="bg-white rounded-md h-full w-48 flex flex-col justify-start p-2 items-center hover:scale-105 duration-200 cursor-pointer" onClick={() => clickCard('usersLoggedCurrentMonth')}>
                                        <p className="">Usuários logados mês</p>
                                        <p className="text-lg flex flex-col mt-5 text-primaryDefaultLight font-semibold">{infoUsers?.usersLogged?.loggedCurrentMonth}</p>
                                        <Tippy
                                            content={'Mês anterior'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={80}>
                                            <p className="flex flex-row items-center justify-center gap-1 text-primaryDefaultLight text-opacity-60 text-sm mt-1">{infoUsers?.usersLogged?.loggedPreviousMonth}</p>
                                        </Tippy>                            
                                    </div>
                                    <div className="bg-white rounded-md h-full w-48 flex flex-col justify-start p-2 items-center">
                                        <p className="">Documentos enviados mês</p>
                                        <p className="text-lg flex flex-col mt-5 text-primaryDefaultLight font-semibold">{docsSends?.quantityCurrentMonth}</p>
                                        <Tippy
                                            content={'Mês anterior'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={80}>
                                            <p className="flex flex-row items-center justify-center gap-1 text-primaryDefaultLight text-opacity-60 text-sm mt-1">{docsSends?.quantityPreviousMonth}</p>
                                        </Tippy> 
                                    </div>
                                </>
                            }
                        </div>
                        {/* Eventos enviados */}
                        <div className="flex flex-col w-full gap-3">
                            <div className="h-full gap-3 w-full flex">
                                {
                                    loadingSendEvents ?
                                    <div className="py-6 w-full xl:h-[28rem] shadow-borderShadow rounded-lg bg-white flex justify-center items-center">
                                        <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                                    </div>
                                    :
                                    <>
                                        <div className="py-6 w-full h-full shadow-borderShadow rounded-lg bg-white pr-2">
                                            <p className="flex flex-row items-center justify-center gap-2 text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">
                                                Folhas enviadas
                                            </p>
                                            <SplineAdminSendEvents
                                                id={'dashboardDesktopSendEvents'}
                                                data={dataQuantitySendEvents}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="flex flex-row w-full gap-3">
                            {/* Empresa mais admissões */}
                            <div className="py-6 w-[70%] h-full border shadow-borderShadow rounded-lg flex flex-col bg-white">
                                {
                                    loadingCompaniesMoreAdmission ?
                                    <div className="py-6 w-full xl:h-[28rem] shadow-borderShadow rounded-lg bg-white flex justify-center items-center">
                                        <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                                    </div>
                                    :
                                    <div className="py-6 w-full h-full shadow-borderShadow rounded-lg bg-white">
                                        <FunnelAdminAdmission id={'companiesMoreAdmission'} data={dataCompaniesMoreAdmission} title={'Empresas mais admissões'}/>      
                                    </div>
                                }
                            </div>
                            {/* Usuários por acesso */}
                            <div className="py-6 w-[30%] h-full border shadow-borderShadow rounded-lg flex flex-col bg-white">
                                {
                                    loadingUsersPerAccess ?
                                    <div className="py-6 w-full xl:h-[28rem] shadow-borderShadow rounded-lg bg-white flex justify-center items-center">
                                        <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                                    </div>
                                    :
                                    <div className="py-6 w-full h-full shadow-borderShadow rounded-lg bg-white">
                                        <div className="h-full w-full justify-center items-center">
                                            <p className="text-center text-base font-semibold text-titleBlackTextLight pb-2 flex flex-row items-center justify-around">Usuários por acesso</p>
                                            <SimplePieAdminUserPerAccess id={'usersPerAccess'} data={infoUsers?.quantityUsersPerAccess}/>      
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ?
                <div className="flex sm:hidden w-full h-full justify-center items-center">
                    <Loader />
                </div>
                :
                <div className="h-full bg-gray-200 gap-3 md:hidden overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 pb-14">
                    {/* Admissiões */}
                    <div className="flex flex-col w-full gap-3 mt-5">
                        <div className="h-full gap-3 w-full flex">                            
                            <div className="py-6 w-full h-full shadow-borderShadow rounded-lg bg-white pr-2">
                                <p className="flex flex-row items-center justify-center gap-2 text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">
                                    Admissões
                                </p>
                                <SplineAdminAdmission
                                    id={'dashboardDesktopAdmissionsMobile'}
                                    data={dataQuantityAdmission}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Cards */}
                    <div className="flex flex-col items-center w-full h-full gap-3 p-2 justify-evenly">                            
                        <div className="bg-white rounded-md h-42 w-48 flex flex-col justify-start p-2 items-center">
                            <p className="">Usuários ativos</p>
                            <p className="text-lg flex flex-col mt-5 text-primaryDefaultLight font-semibold">{infoUsers?.quantityUsersActive}</p>
                        </div>
                        <div className="bg-white rounded-md h-42 w-48 flex flex-col justify-start p-2 items-center">
                            <p className="">Acessos gerencial</p>
                            <p className="text-lg flex flex-col mt-5 text-primaryDefaultLight font-semibold">{infoUsers?.quantityUsersWithAccessManager}</p>
                        </div>
                        <div className="bg-white rounded-md h-42 w-48 flex flex-col justify-start p-2 items-center">
                            <p className="">Usuários logados mês</p>
                            <p className="text-lg flex flex-col mt-5 text-primaryDefaultLight font-semibold">{infoUsers?.usersLogged?.loggedCurrentMonth}</p>
                            <Tippy
                                content={'Mês anterior'}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={80}>
                                <p className="flex flex-row items-center justify-center gap-1 text-primaryDefaultLight text-opacity-60 text-sm mt-1">{infoUsers?.usersLogged?.loggedPreviousMonth}</p>
                            </Tippy>                            
                        </div>
                        <div className="bg-white rounded-md h-42 w-48 flex flex-col justify-start p-2 items-center">
                            <p className="">Documentos enviados mês</p>
                            <p className="text-lg flex flex-col mt-5 text-primaryDefaultLight font-semibold">{docsSends?.quantityCurrentMonth}</p>
                            <Tippy
                                content={'Mês anterior'}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={80}>
                                <p className="flex flex-row items-center justify-center gap-1 text-primaryDefaultLight text-opacity-60 text-sm mt-1">{docsSends?.quantityPreviousMonth}</p>
                            </Tippy> 
                        </div>
                    </div>
                    {/* Eventos enviados */}
                    <div className="flex flex-col w-full gap-3">
                        <div className="h-full gap-3 w-full flex">                                
                            <div className="py-6 w-full h-full shadow-borderShadow rounded-lg bg-white pr-2 mt-2">
                                <p className="flex flex-row items-center justify-center gap-2 text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">
                                    Folhas enviadas
                                </p>
                                <SplineAdminSendEvents
                                    id={'dashboardDesktopSendEventsMobile'}
                                    data={dataQuantitySendEvents}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-3 mt-2">
                        {/* Empresa mais admissões */}
                        <div className="py-6 w-full h-full border shadow-borderShadow rounded-lg flex flex-col bg-white">                            
                            <div className="py-6 w-full h-full shadow-borderShadow rounded-lg bg-white">
                                <FunnelAdminAdmission id={'companiesMoreAdmissionMobile'} data={dataCompaniesMoreAdmission} title={'Empresas mais admissões'}/>      
                            </div>
                        </div>
                        {/* Usuários por acesso */}
                        <div className="border bg-white w-96 h-80 shadow-borderShadow rounded-lg flex-col items-center justify-start">
                            <div className="w-[95%] h-full justify-center items-center pb-5">
                                <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">Usuários por acesso</p>
                                <SimplePieAdminUserPerAccess id={'usersPerAccessMobile'} data={infoUsers?.quantityUsersPerAccess} mobile={true}/> 
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}