import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';

export function Spline({data, title, id}){
    
    const dataValueAdmission = data?.arrayQuantityAdmission?.map(apiData => apiData)
    const dataValueDemission = data?.arrayQuantityDemission?.map(apiData => apiData)

    useEffect(() => {

        var options = {
            series: [{
                name: 'Admissão',
                data: dataValueAdmission
            }, {
            name: 'Demissão',
            data: dataValueDemission
            }],
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            colors: ['#311962', '#ef4444'],
            xaxis: {
                type: 'category',
                categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
            }
        };
  
          var chart = new ApexCharts(document.querySelector(`#${id}`), options);
          chart.render();

    }, [])

    return (
        <div id={id}>

        </div>
    )
}