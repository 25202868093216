module.exports = [
    {
        name: "Código do colaborador",
        value: "codfun"
    },
    {
        name: "Código da Situação",
        value: "sitafa"
    },
    {
        name: "Situação",
        value: "dessit"
    },
    {
        name: "Data admissão",
        value: "datadm"
    },
    {
        name: "Posto",
        value: "despos"
    },
    {
        name: "Cargo",
        value: "titcar"
    },
    {
        name: "Escala",
        value: "nomesc"
    },
    {
        name: "Time da escala",
        value: "codtma"
    },
    {
        name: "Local",
        value: "numloc"
    },
    {
        name: "Local (descrição)",
        value: "nomloc"
    },
    {
        name: "Centro de custo",
        value: "codccu"
    },
    {
        name: "Centro de custo (descrição)",
        value: "nomccu"
    },
    {
        name: "Gênero",
        value: "tipsex"
    },
    {
        name: "Nivel de graduação",
        value: "desgra"
    },
    {
        name: "Estado civil",
        value: "estciv"
    },
    {
        name: "Data de nascimento",
        value: "datnas"
    },
    {
        name: "Nacionalidade",
        value: "desnac"
    },
    {
        name: "Cartão Trabalho",
        value: "numctp"
    },
    {
        name: "Cartão de Trabalho (série)",
        value: "serctp"
    },
    {
        name: "Cartão de Trabalho (UF)",
        value: "estctp"
    },
    {
        name: "Cartão de Trabalho (emissão)",
        value: "dexctp"
    },
    {
        name: "PIS",
        value: "numpis"
    },
    {
        name: "PIS (emissão)",
        value: "dcdpis"
    },
    {
        name: "CPF",
        value: "numcpf"
    },
    {
        name: "Experiência",
        value: "durcon"
    },
    {
        name: "Primeiro Vencimento",
        value: "usu_datini"
    },
    {
        name: "Segundo Vencimento",
        value: "usu_datfim"
    },
    {
        name: "Periodo Pagamento",
        value: "perpag"
    },
    {
        name: "Modo Pagamento",
        value: "modpag"
    },
    {
        name: "Banco",
        value: "nomban"
    },
    {
        name: "Agência",
        value: "codage"
    },
    {
        name: "Conta (Tipo)",
        value: "tpctba"
    },
    {
        name: "Conta bancaria",
        value: "conban"
    },
    {
        name: "Digito Conta",
        value: "digban"
    },
    {
        name: "Recebe Adiantamento",
        value: "recadi"
    },
    {
        name: "Porcentagem Adiantamento",
        value: "usu_peradt"
    },
    {
        name: "Deficiente",
        value: "deffis"
    },
    {
        name: "Deficiente (Tipo)",
        value: "coddef"
    },
    {
        name: "Preenche cota",
        value: "cotdef"
    },
    {
        name: "Tipo de apuração",
        value: "apupon"
    },
    {
        name: "Tipo de apuração (data)",
        value: "iniapu"
    },
    {
        name: "Tipo de vinculo",
        value: "codvin"
    },
    {
        name: "Tipo de vinculo (data)",
        value: "hvi_datalt"
    },
    {
        name: "RG",
        value: "numcid"
    },
    {
        name: "RG (data expedição)",
        value: "dexcid"
    },
    {
        name: "Titulo de eleitor",
        value: "numele"
    },
    {
        name: "Titulo de eleitor (zona)",
        value: "zonele"
    },
    {
        name: "Titulo de eleitor (seção)",
        value: "secele"
    },
    {
        name: "CNH",
        value: "numcnh"
    },
    {
        name: "CNH (categoria)",
        value: "catcnh"
    },
    {
        name: "CNH (vencimento)",
        value: "vencnh"
    },
    {
        name: "CNH (orgão emissor)",
        value: "orgcnh"
    },
    {
        name: "CNH (UF)",
        value: "estcnh"
    },
    {
        name: "CNH (primeira habilitação)",
        value: "pricnh"
    },
    {
        name: "CNH (data expedição)",
        value: "datcnh"
    },
    {
        name: "Número reservista",
        value: "numres"
    },
    {
        name: "Categoria reservista",
        value: "catres"
    },
    {
        name: "CEP",
        value: "endcep"
    },
    {
        name: "País",
        value: "nompai"
    },
    {
        name: "Estado",
        value: "codest"
    },
    {
        name: "Cidade",
        value: "nomcid"
    },
    {
        name: "Bairro",
        value: "nombai"
    },
    {
        name: "Rua",
        value: "endrua"
    },
    {
        name: "Complemento",
        value: "endcpl"
    },
    {
        name: "Número",
        value: "endnum"
    },
    {
        name: "DDD",
        value: "dddtel"
    },
    {
        name: "Telefone",
        value: "numtel"
    },
    {
        name: "Email",
        value: "emapar"
    },
    {
        name: "Último exame",
        value: "ultexm"
    },
    {
        name: "Sindicato (data alteração)",
        value: "hsi_datalt"
    },
    {
        name: "Sindicato",
        value: "nomsin"
    },
    {
        name: "Sócio sindicato",
        value: "socsin"
    },
    {
        name: "Salário (data alteração)",
        value: "hsa_datalt"
    },
    {
        name: "Salário",
        value: "valsal"
    },
    {
        name: "Salário (tipo)",
        value: "tipsal"
    },
    {
        name: "Escala (vale transporte)",
        value: "escvtr"
    },
    {
        name: "Quantidade (vale transporte)",
        value: "pasute"
    },
    {
        name: "Insalubridade",
        value: "perins"
    },
    {
        name: "Periculosidade",
        value: "perper"
    },
    {
        name: "Estado nascimento",
        value: "estnas"
    },
    {
        name: "Raça",
        value: "raccor"
    },
    {
        name: "Cidade Nascimento",
        value: "cidnas_nomcid"
    },
    {
        name: "Empresa",
        value: "nomemp"
    },
    {
        name: "Empresa (CNPJ)",
        value: "numcgc"
    },
    {
        name: "Empresa (tipo endereço)",
        value: "tiplgr"
    },
    {
        name: "Email comercial",
        value: "emacom"
    },
    {
        name: "Estrangeiro (chegada)",
        value: "esta_datche"
    },
    {
        name: "Estrangeiro (condição)",
        value: "esta_visest"
    },
    {
        name: "Estrangeiro (carteira)",
        value: "esta_regest"
    },
    {
        name: "Estrangeiro (expedição registro)",
        value: "esta_datest"
    },
    {
        name: "Estrangeiro (validade carteira)",
        value: "esta_dvlest"
    },
    {
        name: "Estrangeiro (validade cart.trabalho)",
        value: "esta_dvlctp"
    },
    {
        name: "Estrangeiro (casado brasileiro)",
        value: "esta_casbra"
    },
    {
        name: "Estrangeiro (filhos brasileiros)",
        value: "esta_filcbr"
    },
    {
        name: "Estrangeiro (tempo residência)",
        value: "esta_tmpres"
    },
    {
        name: "Estrangeiro (término residência)",
        value: "esta_datter"
    },
    {
        name: "Estágio (natureza)",
        value: "natetg"
    },
    {
        name: "Estágio (nível)",
        value: "nivetg"
    },
    {
        name: "Estágio (apólice)",
        value: "aposeg"
    },
    {
        name: "Estágio (data término)",
        value: "preter"
    },
    {
        name: "Estágio (instituição)",
        value: "nomoem"
    },
    {
        name: "Estágio (superior)",
        value: "nomcoo"
    },
    {
        name: "Filial",
        value: 'codfil'
    },
    {
        name: "Gratificação de Função",
        value: 'gratification'
    },
    {
        name: "Mátricula Esocial",
        value: 'mateso'
    },
    {
        name: "Apelido",
        value: 'apefun'
    },
    {
        name: "CBO (Código)",
        value: 'codcb2'
    },
    {
        name: "CBO (Descrição)",
        value: 'descbo'
    }
]