import React from 'react'

export function Button({children, height, width, shadow, onClick, action, approval, background}){

    const widthPixel = width > 0 ? `w-[${width}px]` : 'w-32'
    const widthDefault = width > 0 ? `w-${width}` : 'w-32'

    const heightPixel = height > 0 ? `h-[${height}px]` : 'h-12 sm:h-8'
    const heightDefault = height > 0 ? `h-${height}` : 'h-12 sm:h-8'

    return (
        <button 
        onClick={ () => onClick(action)}
        className={`${shadow ? 'shadow-lg shadow-gray-500' : ''} rounded-lg text-sm font-extrabold ${background ? `${background} hover:brightness-[0.80]` : approval === false ?'bg-gray-500 hover:bg-gray-700' : 'bg-primaryDefaultLight hover:bg-primaryDefaultLightHover'}  transition-all duration-300 text-titleGrayTextLight ${shadow ? 'shadow-md shadow-gray-500/50 dark:shadow-md dark:shadow-secondaryDefaultDark' : ''} ${width > 96 ? widthPixel : widthDefault} ${height > 96 ? heightDefault : heightDefault}`}>
            {children}        
        </button>
    )
}