import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiUserCheck, FiPlus, FiUserMinus, FiClock, FiAlertCircle, FiArrowDown, FiArrowUp, FiTrash2, FiX, FiPaperclip, FiDownload, FiEye, FiTrash } from 'react-icons/fi';
import { RiArrowUpDownLine } from 'react-icons/ri';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import Loader from '../../components/Loader/loader';
import { permittedFiles } from '../../utils/filesValidadtor/permittedFiles'

// import './employeeConsult.css'
import { useAuth } from '../../hooks/useAuth';
import { handleOrdenation } from '../../utils/tableOrdenation';
import { TableDefault } from '../../components/table/table.default';
import { Button } from '../../components/buttons/button.default';
import Modal from '../../components/Modal/modal';
import Tippy from '@tippyjs/react';
import { TableMobile } from '../../components/table/table.mobile';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';

export default function EmployeeConsult() {

    const token = localStorage.getItem('@auth:token');
    const { company, branch } = useCompanyBranch();
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()

    const { user } = useAuth()
    const { setShow, show } = useModal()
    const [loading, setLoading] = useState(true);
    const [filteredValue, setFilteredValue] = useState('')
    const [employees, setEmployees] = useState([]);
    const [filteredStatus, setFilteredStatus] = useState(1)
    const { setMenuTitle } = useMenu()
    setMenuTitle('Lista de Colaboradores')
    const navigate = useNavigate()
    const [employeeTemp, setEmployeeTemp] = useState()
    const [buttonRemove, setButtonRemove] = useState(false)
    const [updateEffect, setUpdateEffect] = useState(false)
    const [showModalFiles, setShowModalFiles] = useState(false)
    const [employeeFiles, setEmployeeFiles] = useState([])
    const [newScheduleAttacments, setNewScheduleAttachments] = useState([])
    const [attachmentToRemove, setAttachmentToRemove] = useState([])

    useEffect(() => {

        if (company === 0) {
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }
        setLoading(true)
        async function getApiDatas() {
            const sessionData = JSON.parse(localStorage.getItem('@session:data'));

            try {

                const employeesResponse = await api.get(`api/v1/employees/company/${sessionData?.company}/branch/${sessionData?.branch}?status=${filteredStatus}`, {
                    headers: {
                        authorization: token
                    }
                })
                setEmployees(employeesResponse.data.data.map((item) => {
                    return {
                        ...item,
                        employeeId: item.employeeId,
                        employeeIdDescription: item.employeeId ? item.employeeId : <span>-</span>,
                        byConnect: item.byConnect,
                        byConnectDescription: item.byConnect == null || item.byConnect == undefined ? <b>Senior</b> : item.isSave == 1 ? <b>Em andamento</b> : <b>Folpag Connect</b>,
                        status: item.admissionStatus,
                        attachments: item.admissionStatus == 1 &&
                            <Tippy
                                content={<a>{[1, 2].includes(user.type) ? 'Anexar Arquivos' : 'Visualizar Arquivos'}</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                className='ml-3'
                                delay={80}>
                                <div>
                                    <FiPaperclip className='ml-[50%] cursor-pointer'
                                        onClick={() => {
                                            localStorage.setItem('employeeData', JSON.stringify(item))
                                            navigate('/employeeDocs')
                                        }}
                                    />
                                </div>
                            </Tippy>,
                        statusIcon: item.admissionStatus == 1 && filteredStatus == 1 ?
                            <FiUserCheck className='ml-[50%] text-green-700' />
                            :
                            item.admissionStatus == 1 && filteredStatus == 0 ?
                                <FiUserMinus className='ml-[50%] text-red-800' />
                                :
                                item.isSave == 1 ?
                                    <Tippy
                                        content={<a>Excluir Admissão Salva</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        className='ml-3'
                                        delay={80}>
                                        <div>
                                            <FiTrash2 className='ml-[50%] text-red-800 cursor-pointer' onClick={() => { setShow(true); setEmployeeTemp(item); setButtonRemove(true) }} />
                                        </div>
                                    </Tippy>
                                    :
                                    item.admissionStatus == 0 ?
                                        <FiClock className='ml-[50%] text-yellow-500' />
                                        :
                                        <FiAlertCircle className='ml-[50%] text-yellow-500' />,
                        functions: item.isSave == 1 ?
                            [
                                {
                                    func: <FiEye
                                        onClick={() => {
                                            navigate(
                                                item.byConnect && user.type > 2 && !item.isSave ? `/employeeConsultDetail/originConnect/${item.byConnect}`
                                                    : item.isSave == 1 ? `/employeeAdmissionSave/${item.byConnect}`
                                                        : item.byConnect && user.type < 3 ? `/employeeAdmission/${item.byConnect}`
                                                            : `/employeeConsultDetail/${item.employeeId}`
                                            )
                                        }} />
                                },
                                {
                                    func: <FiTrash2 onClick={() => { setShow(true); setEmployeeTemp(item); setButtonRemove(true) }} />
                                }
                            ]
                            :
                            [
                                {
                                    func: <FiEye
                                        onClick={() => {
                                            navigate(
                                                item.byConnect && user.type > 2 && !item.isSave ? `/employeeConsultDetail/originConnect/${item.byConnect}`
                                                    : item.isSave == 1 ? `/employeeAdmissionSave/${item.byConnect}`
                                                        : item.byConnect && user.type < 3 ? `/employeeAdmission/${item.byConnect}`
                                                            : `/employeeConsultDetail/${item.employeeId}`
                                            )
                                        }} />
                                }
                            ]
                    }
                }))
                setLoading(false)
            } catch (error) {
                console.log(error)
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de colaboradores.')
            }
        }
        getApiDatas()

    }, [token, company, filteredStatus, updateEffect]);

    useEffect(() => {
        if (!show) {
            setButtonRemove(false)
            setEmployeeTemp()
        }
    }, [show])

    const tableThead = [
        {
            "name": "Código",
            "original_name": "employeeIdDescription",
        },
        {
            "name": "Nome",
            "original_name": "employeeName",
        },
        {
            "name": "CPF",
            "original_name": "cpf",
        },
        {
            "name": filteredStatus == 0 ? "Data Demissão" : "Data Admissão",
            "original_name": filteredStatus == 0 ? "demissionDate" : "admissionDate",
        },
        {
            "name": "Cargo",
            "original_name": "employeePosition",
        },
        {
            "name": "Origem",
            "original_name": "byConnectDescription",
        },
        {
            "name": "Arquivos",
            "original_name": "attachments"
        },
        {
            "name": "Status",
            "original_name": "statusIcon",
        }
    ]

    const customFunctions = {
        isValid: true,
        collumn: [6, 7]
    }

    async function handleDeleteSave(save_id) {
        setLoading(true)

        try {

            await api.delete(`api/v1/employeesSave/company/${company}/branch/${branch}/save_id/${save_id}`, {
                headers: {
                    Authorization: token,
                }
            });

            setShow(false)
            setShowNotificationModal(true)
            setNotificationModalText('Admissão deletada com sucesso')
            setLoading(false)
            setUpdateEffect(true)
            return navigate('/employeeConsult')


        } catch (error) {
            setShow(false)
            setLoading(false)
            console.log(error)
            return alert('Erro ao deletar admissão salva')
        }

    }

    function addNewAttachment() {
        const inputFile = document.getElementById('fileInput')
        inputFile.click()
    }

    const handleNewPhoto = (photoFile) => {
        const inputFile = document.getElementById('fileInput')

        for (let i = 0; i < photoFile?.target?.files.length; i++) {
            const checkFile = permittedFiles(photoFile.target.files[i])
            if (checkFile.error) {
                return alert('Erro ao adicionar novo arquivo.')
            }
        }

        let newFiles = []

        Array.from(photoFile.target.files).map(file => {
            newFiles.push(file)
        })

        const oldArrayFiles = newScheduleAttacments

        setNewScheduleAttachments(newScheduleAttacments.length > 0 ? oldArrayFiles.concat(newFiles) : newFiles)

    }

    async function removeThisAttachmentHistory(attachment) {
        setAttachmentToRemove([...attachmentToRemove, attachment])
        setEmployeeFiles(employeeFiles.filter(attchToRemove => attchToRemove !== attachment))
    }

    async function removeThisAttachment(attachment, indexAttchament) {
        setNewScheduleAttachments(newScheduleAttacments.filter(attchToRemove => attchToRemove !== attachment))
    }

    async function handleSaveAttachments() {
        setLoading(true)

        try {

            if (newScheduleAttacments?.length > 0) {
                await handleAttchamentFile(employeeTemp?.employeeId)
            }

            if (attachmentToRemove?.length > 0) {

                let filesRemove = []

                attachmentToRemove.map(file => {
                    filesRemove.push(file?.filename)
                })

                await handleDeleteAttachmentFiles(filesRemove, employeeTemp?.employeeId)
            }

            setLoading(false)
            setShowNotificationModal(true)
            setShowModalFiles(false)
            setEmployeeTemp()
            setNewScheduleAttachments([])
            setAttachmentToRemove([])
            setNotificationModalText('Arquivos salvos com sucesso')
            return navigate('/employeeConsult')

        } catch (error) {
            setLoading(false)
            return alert(error?.response?.data[0]?.message)
        }
    }

    async function handleAttchamentFile(person) {

        try {

            const formData = new FormData();

            newScheduleAttacments.map((attachment) => {
                formData.append('files', attachment);
            })

            const config = {
                headers: {
                    authorization: token,
                    'content-type': 'multipart/form-data',
                },
            };

            await api.post(`/api/v1/employeesDocs/${person}/${company}/${branch}`, formData, config)
            return
        } catch (error) {
            setLoading(false)
            return alert(error?.response?.data[0]?.message)
        }

    }

    async function handleDeleteAttachmentFiles(attach, person) {

        try {

            await api.put(`/api/v1/employeesDocs/${person}/${company}/${branch}`, {
                files: attach
            }, {
                headers: {
                    authorization: token,
                },
            })
            return

        } catch (error) {
            setLoading(false)
            return alert(error?.response?.data[0]?.message)
        }
    }

    async function downloadFile(person, company, filename, originalFilename, branch) {

        const employeeFile = await api.get(`/api/v1/employeesDocs/${person}/${company}/${branch}/${filename}`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: token
            }
        })

        let fileObj = URL.createObjectURL(employeeFile?.data)

        const myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute('download', originalFilename)
        myElement.click()

    }

    async function redirectMobileFunction(e) {
        navigate(
            e.byConnect && user.type > 2 && !e.isSave ? `/employeeConsultDetail/originConnect/${e.byConnect}`
                : e.isSave == 1 ? `/employeeAdmissionSave/${e.byConnect}`
                    : e.byConnect && user.type < 3 ? `/employeeAdmission/${e.byConnect}`
                        : `/employeeConsultDetail/${e.employeeId}`
        )
    }

    async function removeMobileFunction(item) {
        if (item.isSave == 1) {
            setShow(true)
            setEmployeeTemp(item)
            setButtonRemove(true)
        }
    }

    return (
        <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
            {
                loading ?
                <Loader />
                :
                <>
                    {
                        show && buttonRemove &&
                        <Modal>
                            <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                                <div className='flex justify-between pl-3 py-5 items-center'>
                                    <p className='flex flex-row font-extrabold items-center text-lg'>
                                        <p className='mr-7'>Deseja excluir essa admissão em andamento?</p>
                                    </p>
                                    <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                                </div>
                                <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                    <Button background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                                    <Button background={'bg-green-800'} type={'button'} onClick={() => { handleDeleteSave(employeeTemp.byConnect) }}>Confirmar</Button>
                                </div>
                            </div>
                        </Modal>
                    }
                    {
                        showModalFiles &&
                        <div className='animate-[wiggleLeft_1s_ease-in-out] flex flex-col z-10 fixed'>
                            <div className={`${showModalFiles ? 'flex' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] h-[100vh] top-0 z-[800] `}>
                                <div className={`transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                                    <div className='flex justify-between pl-3 py-5 items-center'>
                                        <p className='flex flex-row font-extrabold items-center text-lg'>
                                            <span className='mr-7 font-semibold'><i>Arquivos - {employeeTemp && employeeTemp?.employeeName}</i></span>
                                        </p>
                                        <Tippy
                                            content={<a>Fechar</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            className='mr-3'
                                            delay={80}>
                                            <div>
                                                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={() => { setShowModalFiles(false); setEmployeeTemp(); setNewScheduleAttachments([]); setAttachmentToRemove([]) }} />
                                            </div>
                                        </Tippy>
                                    </div>
                                    <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                                        <div className={`flex flex-col items-center justify-start h-56 ${[1, 2].includes(user.type) ? 'w-[50rem]' : 'w-full'} p-1`}>
                                            <div className="w-full flex lg:flex-row flex-col gap-2 items-start justify-start lg:h-48 rounded-sm ">
                                                {
                                                    ([1, 2].includes(user.type) || ([3, 4].includes(user.type) && user.canAttach)) &&
                                                    <Tippy
                                                        content={<a>Anexar arquivos para esse colaborador</a>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={80}>
                                                        <div onClick={() => addNewAttachment()} className="p-2 ml-3 lg:mt-0 hover:brightness-125 rounded-lg shadow-lg hover:scale-150 duration-200 cursor-pointer h-10 flex flex-col items-center justify-center w-10">
                                                            <FiPaperclip />
                                                        </div>
                                                    </Tippy>
                                                }
                                                <input className="hidden" multiple="multiple" id='fileInput' type={'file'} onChange={(e) => handleNewPhoto(e)}></input>
                                                <div className="bg-blue border border-gray-300 p-4 w-[42rem] h-full rounded-lg overflow-auto flex flex-row gap-2 flex-wrap">
                                                    {
                                                        loading && <Loader />
                                                    }
                                                    {
                                                        employeeFiles?.length == 0 && newScheduleAttacments?.length == 0 ?
                                                            <p className='text-orange-800 flex justify-center items-center text-center m-auto text-2xl'>Colaborador sem arquivos anexados.</p>
                                                            :
                                                            employeeFiles?.length > 0 &&
                                                            employeeFiles.map(attachment => {
                                                                return <div className="cursor-pointer p-2 h-12 flex w-48 rounded bg-gray-200 shadow-xl hover:scale-105 duration-200 gap-2 items-center justify-center relative">
                                                                    {
                                                                        [1, 2].includes(user.type) &&
                                                                        <span onClick={() => removeThisAttachmentHistory(attachment)} className="w-6 h-6 rounded-full hover:brightness-75 bg-gray-500 absolute left-0 top-0 -translate-x-2 -translate-y-2 text-titleGrayTextDark text-xs flex flex-col items-center justify-center cursor-pointer">x</span>
                                                                    }
                                                                    <Tippy
                                                                        content={<a>Download do arquivo: {attachment?.originalFilename}</a>}
                                                                        arrow={true}
                                                                        animation='shift-away'
                                                                        placement='top'
                                                                        delay={80}>
                                                                        <div onClick={() => downloadFile(attachment.person, attachment.company, attachment.filename, attachment.originalFilename, branch)}>
                                                                            <p className='flex items-center gap-3 font-semibold'>{attachment?.originalFilename.slice(0, 10).concat('...').concat(attachment?.originalFilename.split('.')[1])}<span><FiDownload /></span></p>
                                                                        </div>
                                                                    </Tippy>
                                                                    <Tippy
                                                                        content={<a>Histórico do arquivo</a>}
                                                                        arrow={true}
                                                                        animation='shift-away'
                                                                        placement='top'
                                                                        delay={80}>
                                                                        <div className='flex ml-3'>
                                                                            <FiEye />
                                                                        </div>
                                                                    </Tippy>
                                                                </div>
                                                            })
                                                    }
                                                    {
                                                        newScheduleAttacments?.length > 0 &&
                                                        newScheduleAttacments.map(attachment => {
                                                            return <div className="cursor-pointer p-2 h-12 flex w-48 rounded bg-primaryDefaultLight shadow-xl hover:scale-105 duration-200 flex-col items-center justify-center relative">
                                                                <span onClick={() => removeThisAttachment(attachment)} className="w-6 h-6 rounded-full hover:brightness-75 bg-gray-500 absolute left-0 top-0 -translate-x-2 -translate-y-2 text-titleGrayTextDark text-xs flex flex-col items-center justify-center cursor-pointer">x</span>
                                                                <Tippy
                                                                    content={<a>Download do arquivo: {attachment?.name}</a>}
                                                                    arrow={true}
                                                                    animation='shift-away'
                                                                    placement='top'
                                                                    delay={80}>
                                                                    <div>
                                                                        <a className='flex items-center gap-3 text-white font-semibold' href={URL.createObjectURL(attachment)} download={attachment?.name}>{attachment?.name?.slice(0, 6).concat('...').concat(attachment.name.slice(attachment.name.length - 3, attachment.name.length))}<span><FiDownload /></span></a>
                                                                    </div>
                                                                </Tippy>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            ([1, 2].includes(user.type) || ([3, 4].includes(user.type) && user.canAttach)) &&
                                            <div className='pb-4 flex flex-row justify-center items-center gap-4'>
                                                <Button approval={false} onClick={() => { setShowModalFiles(false); setEmployeeTemp(); setNewScheduleAttachments([]); setAttachmentToRemove([]) }}>Cancelar</Button>
                                                <Button type={'button'} onClick={() => handleSaveAttachments()}>Salvar</Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`${showModalFiles ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-[100vw] h-[100vh] z-[103]`}></div>
                        </div>
                    }
                    <div className="conteudo px-2">
                        <div className="flex flex-col justify-center align-center lg:ml-20 w-[100%] mr-8 mt-3 gap-5">
                            <div className='flex flex-row justify-between gap-2'>
                                <div className='ml-1 w-full lg:ml-0 flex flex-row items-center justify-start gap-1'>
                                    <button
                                        onClick={() => setFilteredStatus(1)}
                                        className={`${filteredStatus == 1 ? 'bg-primaryDefaultLight text-secondaryDefaultLight' : 'bg-[#ececec]'} 
                                        font-extrabold hover:bg-gray-300 hover:text-primaryDefaultLight shadow-md 
                                        shadow-gray-400 text-primaryDefaultLight h-12 md:h-6 w-full md:w-28 flex flex-col 
                                        items-center duration-300 justify-center ${filteredStatus == 1 ? 'selectedFilterEmployee' : ''}`}
                                    >
                                        Ativos
                                    </button>
                                    <button
                                        onClick={() => setFilteredStatus(0)}
                                        className={`${filteredStatus == 0 ? 'bg-primaryDefaultLight text-secondaryDefaultLight' : 'bg-[#ececec]'} 
                                        font-extrabold hover:bg-gray-300 hover:text-primaryDefaultLight shadow-md 
                                        shadow-gray-400 text-primaryDefaultLight h-12 md:h-6 w-full md:w-28 flex flex-col items-center duration-300 
                                        justify-center ${filteredStatus == 0 ? 'selectedFilterEmployee' : ''}`}
                                    >
                                        Demitidos
                                    </button>
                                </div>
                                <div className='flex justify-end'>
                                    <Button
                                        onClick={() => navigate('/employeeAdmission')}
                                        className="flex flex-row justify-center bg-primaryDefaultLight w-24 h-10 text-secondaryDefaultLight font-semibold"
                                    > + Admissão
                                    </Button>
                                </div>
                            </div>
                            <div className='relative bg-white hidden lg:flex flex-col h-[84vh] items-start justify-start w-full boxShadow overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500'>
                                <TableDefault
                                    title={tableThead}
                                    data={employees}
                                    cursorPointer={true}
                                    customFunctions={customFunctions}
                                    quantity={employees.length}
                                    onClick={(e) => {
                                        user.type != 4 ?
                                            navigate(
                                                e.byConnect && user.type > 2 && !e.isSave ?
                                                `/employeeConsultDetail/originConnect/${e.byConnect}`
                                                : e.isSave == 1 ? `/employeeAdmissionSave/${e.byConnect}`
                                                : e.byConnect && user.type < 3 ? `/employeeAdmission/${e.byConnect}`
                                                : `/employeeConsultDetail/${e.employeeId}`
                                            )
                                        : navigate(e.isSave == 1 && `/employeeAdmissionSave/${e.byConnect}`)
                                    }}
                                    collumns={["employeeIdDescription", "employeeName", "cpf", filteredStatus == 0 ? "demissionDate" : "admissionDate", "employeePosition", "byConnectDescription", "attachments", "statusIcon"]}
                                />
                            </div>
                            {
                                !loading &&
                                <div className='relative bg-white flex lg:hidden flex-col h-[84vh] max-h-[67vh] items-start justify-start w-full boxShadow overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500'>
                                    <TableMobile
                                        title={tableThead}
                                        data={employees}
                                        collumns={["employeeIdDescription", "employeeName", "cpf", "admissionDate", "employeePosition", "byConnectDescription"]}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    );
}
