import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FiUser, FiChevronsLeft, FiChevronsRight, FiChevronDown } from 'react-icons/fi';
//images
import logo from '../../images/logo_connect_branco.png'
//contexts
import { useAuth } from '../../hooks/useAuth';
import { useMenu } from '../../hooks/useMenu';
import { useModal } from '../../hooks/useModal';
//components
import MyProfile from './myProfile/myProfile'
//config 
import menuOptions from './config'
//styles 
import './menu.css'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaHamburger, FaList } from 'react-icons/fa';
import { useCompanyBranch } from '../../hooks/useCompanyBranch'

export default function Menu() {

    const { menuStatus, setMenuStatus, menuSelected, setMenuSelected, menuTitle, setMenuTitle, showProfile, setShowProfile } = useMenu();
    const { user } = useAuth();
    const location = useLocation();
    const { show, setShow } = useModal();
    const { companyParams } = useCompanyBranch()

    function selectItemMenuClosed(option, type) {
        setMenuSelected(option);
        if (type === 'list') {

            document.getElementById('checkMenu').click();
            document.getElementById(option).click();
        }
    }

    function selectItemMenuOpen(option) {
        setMenuSelected(option);
    }

    function handleMenu() {

        const menu = document.getElementById("checkMenu")
        menu.classList.remove('hidden')
        menu.classList.add('bar')
        menu.checked = false
        setMenuStatus(0)

    }

    function handleShowMenu() {
        const menu = document.getElementById("checkMenu")
        menu.style.display = 'flex'
        menu.classList.add('bar')
        menu.checked = true
        setMenuStatus(1)
    }

    return (
        <>
            <div className='topBar text-2xl'>

                {/* <MyProfile isOpen={{showUser, showProfileConfig, setShowProfileConfig}}/> */}
                <div className="flex flex-row gap-4">
                    <img src={logo} width="60" />
                    <div className='flex lg:hidden rounded-t-xl bg-titleBlackTextLight' onClick={() => handleShowMenu()}>
                        <div className='flex flex-col w-full h-full items-center justify-center'>
                            <a className='text-white text-2xl'><FaList /></a>
                        </div>
                    </div>
                </div>
                <div className={menuStatus != 1 ? "" : ""}>
                    <h2 className='text-xt lg:text-lg'>{menuTitle}</h2>
                </div>
                <div>
                    {
                        user?.picture?.length > 0 ?
                            <Tippy
                                content={<a>Perfil</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='bottom'
                                delay={80}>
                                <img src={user?.picture} width={30} className={'rounded-full border-1 border-gray-200 -translate-x-2'} onClick={() => { setShowProfile(!showProfile) }}></img>
                            </Tippy>
                            :
                            <Tippy
                                content={<a>Perfil</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='bottom'
                                delay={80}>
                                <div>
                                    <FiUser onClick={() => {
                                        setShowProfile(!showProfile);
                                    }} color='fff' size='20' className='userIcon' />
                                </div>
                            </Tippy>
                    }

                </div>
            </div>


            <div className={`${menuStatus == 0 ? 'hidden' : 'flex z-[99999999]'} absolute animate-[wiggleRight_.4s_ease-in-out] lg:flex`}>
                <input type="checkbox" id="checkMenu" onClick={() => {
                    setMenuStatus(menuStatus == 0 ? 1 : 0)
                }}
                    defaultChecked={menuStatus !== 1 ? false : true}
                />
                <div className='bar'>
                    <label id={menuStatus != 1 ? 'iconMenuClosed' : 'icon'} htmlFor="checkMenu">
                        {
                            menuStatus != 1 ?
                                <Tippy
                                    content={<a>Expandir</a>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='right'
                                    delay={80}>
                                    <div>
                                        <FiChevronsRight size='30' />
                                    </div>
                                </Tippy>
                                :
                                <Tippy
                                    content={<a>Retrair</a>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='right'
                                    delay={80}>
                                    <div>
                                        <FiChevronsLeft size='30' />
                                    </div>
                                </Tippy>
                        }
                    </label>
                    {/* MENU FECHADO */}
                    <div id="sideBarClose" className={menuStatus != 1 ? "setFlex" : "setNone"}>
                        {menuOptions.map(option => {
                            if(![1,2,4].includes(user?.type) && !user?.hasManagerAccess && option?.name == 'Dashboard'){
                                return
                            }
                            if(![1,2,4].includes(user?.type) && !user?.hasManagerAccess && option?.name == 'Relatórios'){
                                return
                            }
                            if(![1,2,4].includes(user?.type) && !user?.hasManagerAccess && option?.name == 'Prévia'){
                                return
                            }
                            if (option.type === 'list') {
                                if (option.permission.indexOf(user.type) > -1) {
                                    return (
                                        <Tippy
                                            content={<a>{option.name}</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='left'
                                            delay={80}>
                                            <div
                                                key={option.name + ' closed'}
                                                name={option.trigger}
                                                onClick={() => selectItemMenuClosed(option.trigger, option.type)}
                                                className={
                                                    menuSelected === option.trigger && menuStatus != 1 ? `selectedItemClose` :
                                                        menuSelected === option.trigger && menuStatus == 1 ? 'selectedItem' : ''}
                                            >
                                                {option.icon}
                                            </div>
                                        </Tippy>
                                    )
                                }
                            }
                            if (option.permission.indexOf(user.type) > -1) {
                                return (
                                    <Link key={option.name + ' closed'} to={option.to}>
                                        <Tippy
                                            content={<a>{option.name}</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='left'
                                            delay={80}>
                                            <div
                                                onClick={() => selectItemMenuClosed(option.to, option.type)}
                                                className={menuSelected === option.to || location.pathname === option.to ? `selectedItem` : ''}
                                            >
                                                {option.icon}
                                            </div>
                                        </Tippy>
                                    </Link>
                                )
                            }
                        })}
                    </div>

                    {/* MENU ABERTO */}
                    <nav id='sideBarOpen' className={`${menuStatus != 1 ? "setNone" : "setShow"} select-none`}>
                        <div id='divLogoMenu'><img src={logo} width='160' /></div>
                        <br />
                        <div className='accordions'>
                            {menuOptions.map(option => {
                                if(![1,2,4].includes(user?.type) && !user?.hasManagerAccess && option?.name == 'Dashboard'){
                                    return
                                }
                                if(![1,2,4].includes(user?.type) && !user?.hasManagerAccess && option?.name == 'Relatórios'){
                                    return
                                }
                                if(![1,2,4].includes(user?.type) && !user?.hasManagerAccess && option?.name == 'Prévia'){
                                    return
                                }
                                if (option.type == 'list') {
                                    if (option.permission.indexOf(user.type) > -1) {
                                        return (
                                            <div className='accordion-item' key={option.name}>
                                                <input type="checkbox" name="accordion" id={option.trigger} />
                                                <label
                                                    onClick={() => selectItemMenuOpen(option.trigger)}
                                                    className={menuSelected == option.trigger ? "formatLabel selectedItem" : 'formatLabel'}
                                                    htmlFor={option.trigger}
                                                >
                                                    <div> {option.icon} </div>
                                                    <p>{option.name}</p>
                                                    <FiChevronDown className='chevronIcon' />
                                                </label>
                                                {
                                                    option.list?.map(item => {
                                                        if (item?.name == 'Anotações' || item?.name == 'Anotações de colaboradores'){
                                                            if (!companyParams?.useAnotations || companyParams?.useAnotations == 'N'){
                                                                return
                                                            }
                                                        }
                                                        if (item.permission.indexOf(user.type) > -1) {
                                                            return (
                                                                <div
                                                                    key={item.to}
                                                                    className={location.pathname == item.to ? "accordion-content selectedItem" : 'accordion-content'}
                                                                >
                                                                    <Link to={item.to}
                                                                        onClick={() => {
                                                                            setMenuTitle(item.name);
                                                                            setShow(false);
                                                                            setShowProfile(false);
                                                                            handleMenu();
                                                                        }}
                                                                    >
                                                                        <div>{item.name}</div>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                }
                                if (option.permission.indexOf(user.type) > -1) {
                                    return (
                                        <div
                                            key={option.name}
                                            className={
                                                menuSelected === option.to || location.pathname === option.to ?
                                                    `simple-item selectedItem` : 'simple-item'
                                            }
                                            onClick={() => { selectItemMenuOpen(option.to); setMenuTitle(option.name); handleMenu() }}
                                        >
                                            <Link to={option.to}>
                                                <div>
                                                    {option.icon}
                                                    <p>{option.name}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </nav>
                </div>
            </div>
            <MyProfile />
        </>
    )

}