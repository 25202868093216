//**Funcão para retornar PIS com as pontuações
//Desenvolvido por: Rafael Cezário */

export function pisMask(e) {

    return e
    ?.replace(/\D/g, '')
    ?.replace(/(\d{3})(\d)/, '$1.$2')
    ?.replace(/(\d{3}\.\d{5})(\d)/, '$1.$2')
    ?.replace(/(\d{3}\.\d{5}\.\d{2})(\d)/, '$1-$2')
    ?.replace(/(\d{3}\.\d{5}\.\d{2}\.\d{1})\d+?/, '$1')

}