export const specialRetirementList = [
    {
        id: 0,
        value: "Não possui aposentadoria especial"
    },
    {
        id: 1,
        value: "Não exposição a agente nocivo. Trabalhador já esteve exporto"
    },
    {
        id: 15,
        value: "Apos. especial aos quinze anos de serviço"
    },
    {
        id: 20,
        value: "Apos. especial aos vinte anos de serviço"
    },
    {
        id: 25,
        value: "Apos. especial aos vinte e cinco anos de serviço"
    },
]