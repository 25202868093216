export const permittedFiles = (file) => {

    const permittdExtensions = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/gif',
        'image/pjpeg',
        'application/pdf',
        'text/plain',
        'application/zip',
        'application/json'
    ]

    const maxFileSize =  20 * 1024 * 1024

    let returnMsg = {}

    if (!permittdExtensions.includes(file.type)) {
        console.log('formato não aceito')
        returnMsg['error'] = true
        returnMsg['response'] = 'Formato não aceito'
        returnMsg['fileTypes'] = permittdExtensions
        returnMsg['maxSize'] = maxFileSize

    }
    if (file.size / 1000 > maxFileSize) {
        console.log('Arquivo maior do que o limite (6MB)')
        returnMsg['error'] = true
        returnMsg['response'] = 'Arquivo muito grande'
        returnMsg['fileTypes'] = permittdExtensions
        returnMsg['maxSize'] = maxFileSize
    }

    return returnMsg
}