import React, { useEffect, useState, useRef } from 'react';
import Dropdown from './dropdown';

const Multiselect = ({ items, selectedLabel, id, onChange, index, value, height, placeholder }) => {
    // state showing if dropdown is open or closed
    const [dropdown, setDropdown] = useState(false);
    // managing dropdown items (list of dropdown items)
    const [insideItems, setInsideItems] = useState(items);
    const [insideItemsHistory, setInsideItemsHistory] = useState(structuredClone(items))
    //ref to close dropdown
    const dropdownRef = useRef()
    // contains selected items
    const [selectedItems, setSelected] = useState(value);
    const [listItems, setListItems] = useState(items)
    // display hidden items
    const [displayHiddenList, setDisplayHiddenList] = useState(false)
    // controls display hiddenlist 
    const [dontChangeDisplayHiddenList, setDontChangeDisplayHiddenList] = useState(false)

    useEffect(() => {
        onChange(selectedItems)
    }, [selectedItems])

    useEffect(() => {
        let nonSelectedItems = []
        listItems.filter((e, indexTotalItems) => {
            const itemSelected = selectedItems.filter((selectedItem) => {
                if (selectedItem[selectedLabel] === e[selectedLabel]) {
                    return (selectedItem)
                }
            })
            if (itemSelected?.length === 0) {
                nonSelectedItems.push(e)
            }
        })
        setInsideItems(nonSelectedItems)
    }, [])

    useEffect(() => {
        setSelected(value)
    }, [value])

    const keyBoardNavigation = (e) => {
        if (e.code === 'Escape') {
            setDropdown(false)
        }
    }

    const toogleDropdown = () => {
        setDropdown(!dropdown)
    };
    // adds new item to multiselect 
    const addTag = (item) => {
        if (item.value === 'Todas') {
            // Seleciona todos os itens, exceto o "0 - Todas"
            setSelected(listItems.filter(e => e.value !== 'Todas'));
            setInsideItems([]);
        } else {
            const input = document.getElementById(id);
            setSelected(selectedItems.concat(item));
            const thisSelectedItems = selectedItems.concat(item);
    
            setDontChangeDisplayHiddenList(false);
            input.value = '';
    
            // Atualizar lista de itens não selecionados
            let nonSelectedItems = [];
            listItems.filter((e) => {
                const itemSelected = thisSelectedItems.filter((selectedItem) => {
                    return selectedItem[selectedLabel] === e[selectedLabel];
                });
                if (itemSelected.length === 0) {
                    nonSelectedItems.push(e);
                }
            });
    
            setInsideItems(nonSelectedItems);
            setDropdown(true);
            input.focus();
            setDropdown(false);
        }
    };
    
    // Atualiza a exibição da lista ao alterar os itens selecionados
    useEffect(() => {
        // Reexibe "0 - Todas" se houver mais de 1 item disponível
        if (selectedItems.length === 0 && listItems.length > 1) {
            setInsideItems(listItems);
        } else {
            setInsideItems(listItems.filter(item => !selectedItems.includes(item) && item.value !== 'Todas'));
        }
    }, [selectedItems, listItems]);

    // Atualiza a função removeTag para lidar com a exibição do item "0 - Todas"
    const removeTag = (item) => {
        const input = document.getElementById(id);
        const filtered = selectedItems.filter((e) => e !== item);

        setSelected(filtered);

        let updatedInsideItems = insideItems.concat(item);

        // Reinsere o item "0 - Todas" se houver mais de 1 item não selecionado
        if (filtered.length === 0 && listItems.length > 1) {
            updatedInsideItems = [...listItems];
        } else {
            updatedInsideItems = updatedInsideItems.filter(e => e.value !== 'Todas');
        }

        setInsideItems(updatedInsideItems);
        setDropdown(false);
        input.focus();
        setDontChangeDisplayHiddenList(false);
    };

    const handleChange = (value) => {
        // const input = document.getElementById(id)
        if (value.target.value !== '') {
            setDropdown(true)
            const filtered = insideItemsHistory.filter((e) => e[selectedLabel]?.toLowerCase().includes(value.target.value?.toLowerCase()))
            setInsideItems(filtered)
        } else
            if (value.target.value === '') {
                let nonSelectedItems = []
                listItems.filter((e, indexTotalItems) => {
                    const itemSelected = selectedItems.filter((selectedItem) => {
                        if (selectedItem[selectedLabel] === e[selectedLabel]) {
                            return (selectedItem)
                        }
                    })
                    if (itemSelected?.length === 0) {
                        nonSelectedItems.push(e)
                    }
                })
                setInsideItems(nonSelectedItems)
                setDropdown(true)
                // input.focus()
            }
    }

    function toogleDropDownInput() {

        if (!dontChangeDisplayHiddenList) {
            setDisplayHiddenList(false)
            setDontChangeDisplayHiddenList(false)
            setDropdown(false)
        }
    }

    return (<div onKeyDown={keyBoardNavigation} className="autcomplete-wrapper" ref={dropdownRef}>
        <div className="autcomplete">
            <div className="w-80 flex flex-col items-center mx-auto">
                <div className="w-80">
                    <div className="flex flex-col items-center relative">
                        <div className="w-full">
                            <div className={`flex border border-gray-300 hover:border-gray-500 rounded-md dark:border dark:border-secondaryBorderDark bg-white dark:bg-secondaryDefaultDark h-10 ${height ? `sm:h-${height}` : 'sm:h-20'}  max-h-20 transition-all duration-100 overflow-y-auto py-px ${displayHiddenList && 'shadow-borderShadow'}`}>                                
                                <div className="flex flex-auto flex-wrap items-center justify-start">
                                    {
                                        selectedItems?.length > 0 && !displayHiddenList && !dontChangeDisplayHiddenList ?
                                        selectedItems.map((dataItem, index) => {
                                                return index < 3 ?
                                                    (
                                                        <div key={index} className="mx-1 flex justify-center items-center w-max-32 w-32 overflow-hidden font-medium px-2 text-primaryDefaultLight dark:bg-thirdDefaultDark border border-primaryDefaultLight">
                                                            <div className="text-xs font-normal leading-none max-w-full flex-initial">{dataItem[selectedLabel]?.length > 10 ? dataItem[selectedLabel]?.slice(0,10)?.concat('..') : dataItem[selectedLabel]}</div>
                                                            <div className="flex flex-auto flex-row-reverse">
                                                                <div onClick={() => removeTag(dataItem)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                                        className="feather feather-x cursor-pointer hover:text-blue-500 rounded-full w-4 h-4 ml-2">
                                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    :
                                                    index === 3 &&
                                                    <p className='text-primaryDefaultLight '>+ {selectedItems?.length - 3}</p>
                                            })
                                            :
                                            selectedItems.map((dataItem, index) => {
                                                return <div key={index} onMouseOver={() => setDontChangeDisplayHiddenList(true)} onMouseLeave={() => setDontChangeDisplayHiddenList(false)} className="mx-1 flex justify-center items-center w-max-32 w-32 overflow-hidden font-medium px-2 text-primaryDefaultLight dark:bg-thirdDefaultDark border border-primaryDefaultLight">
                                                    <div className="text-xs font-normal leading-none max-w-full flex-initial">{dataItem[selectedLabel]?.length > 10 ? dataItem[selectedLabel]?.slice(0,10)?.concat('..') : dataItem[selectedLabel]}</div>
                                                    <div className="flex flex-auto flex-row-reverse">
                                                        <div onClick={() => removeTag(dataItem)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                                className="feather feather-x cursor-pointer hover:text-blue-500 rounded-full w-4 h-4 ml-2">
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                    }
                                    <div className="flex-1">
                                        <input id={id} placeholder={placeholder && selectedItems?.length == 0 ? placeholder : ''} onClick={toogleDropdown} onFocus={() => setDisplayHiddenList(true)} onChange={handleChange} onBlur={toogleDropDownInput} className="placeholder:text-black bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800 dark:bg-primaryDefaultDark" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {dropdown ? <Dropdown
                        list={insideItems}
                        selectedLabel={selectedLabel}
                        addItem={addTag}
                        onMouseOver={() => setDontChangeDisplayHiddenList(true)}
                        onMouseLeave={() => setDontChangeDisplayHiddenList(false)}
                    ></Dropdown> : null}
                </div>
            </div>

        </div>
    </div>)
};

export default Multiselect;