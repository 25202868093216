import React, { useEffect, useState } from "react"
import { useModal } from "../../hooks/useModal"
import Modal from "../../components/Modal/modal"
import { useMenu } from "../../hooks/useMenu"
// import "./termsOfUseConfig.css"
import api from "../../services/api"
import { formatDate } from '../../utils/formatDate'
import { capitalize } from '../../utils/formatText/capitalize'
import { FiChevronRight, FiTrash2, FiX } from 'react-icons/fi'
import { TableDefault } from "../../components/table/table.default"
import { Button } from "../../components/buttons/button.default"
import { Input } from "../../components/input/input"
import { LabelInput } from "../../components/label/label.input"

export default function UseTermsConfig(){

    const { setMenuTitle } = useMenu();
    setMenuTitle("Termos de Uso");
    const { setShow, show } = useModal()
    const [ reload, setReload] = useState([])
    const [ useTerms, setUseTerms] = useState([])
    const [ title, setTitle] = useState('')
    const [ description, setDescription] = useState('')
    const [ paragraphs, setParagraphs] = useState([{ subTitle: '', paragraph: '' }])
    const token = localStorage.getItem('@auth:token')
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const [ buttonRemove, setButtonRemove ] = useState()
    const [ userTemp, setUserTemp ] = useState()

    useEffect(() => {
        async function getData(){
            const response = await api.get('/api/v1/useTerms', {
                headers: {
                    authorization: token
                }
            })

            setUseTerms(response.data.data.map((item) => {
                return {
                    ...item,
                    created_at: formatDate({date: item.created_at, type: 'database'}),
                    user_fullname: capitalize(item.user_fullname),
                    active: item.active ? <span>Ativo</span>: <span>Antigo</span>
                }
            }))
        }
        
        setReload(false)
        getData()
    }, [ reload ])

    useEffect(() => {
        if (!show){
            setUserTemp()
            setButtonRemove(false)
        }
    }, [show])

    function newParagraph(){
        setParagraphs([...paragraphs, { subTitle: '', text: '' }])
    } 

    function removeParagraph(paragraph){
        setParagraphs(paragraphs.filter(item => item !== paragraph))
    }

    async function removeUseTerm(id){

        try {
            
            await api.delete(`/api/v1/useTerms/${id}`, {
                headers: {
                    authorization: token
                }
            })

            setShowNotificationModal(true)
            setNotificationModalText('Sucesso ao realizar exclusão de termo de uso')
            return  setReload(true)            

        } catch (error) {
            alert(error?.response?.data?.message)
        }

    }

    async function handleSubmit(e){
        e.preventDefault()

        try {
            await api.post('/api/v1/useTerms', {
                title,
                description,
                active: 1,
                paragraphs
            }, {
                headers: {
                    authorization: token
                }
            })
            setReload(true)
            setShow(false)
            setTitle('')
            setDescription('')
            setParagraphs([{ subTitle: '', paragraph: '' }])
            setShowNotificationModal(true)
            return setNotificationModalText('Sucesso ao cadastrar')
        } catch (error) {
            alert(error?.response?.data?.message)
        }
    }

    async function setActive(id){

        try {
            await api.put(`/api/v1/useTerms/active/${id}`, {}, {
                headers: {
                    authorization: token
                }
            })

            setReload(true)
        } catch (error) {
            alert(error?.response?.data?.message)
        }

    }

    const tableThead = [
        {
            "name": 'Nome',
            "original_name": 'title' 
        },
        {
            "name": 'Data de criação',
            "original_name": 'created_at' 
        },
        {
            "name": 'Usuário',
            "original_name": 'user_fullname' 
        },
        {
            "name": 'Status',
            "original_name": 'active' 
        }
    ]

    return (
        <>
            {
                show && buttonRemove ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                <p className='mr-7'>Deseja realmente excluir esse termo?</p> 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-600'} onClick={() => {setShow(false); setButtonRemove(false)}}>Cancelar</Button>
                            <Button background={'bg-green-600'} type={'button'} onClick={() => {setShow(false); removeUseTerm(userTemp)}}>Confirmar</Button>
                        </div>
                    </div>
                </Modal>
                : show ?
                <Modal>
                    <div className="animate-[wiggleLeft_1s_ease-in-out] h-[36rem] w-[42rem] p-8 mb-4 flex flex-col justify-center align-center overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400">
                        <p className='flex font-extrabold text-primaryDefaultLight items-center text-lg mt-'>
                            <FiChevronRight /> Efetue o cadastro de termos de uso abaixo.
                        </p> 
                        <form onSubmit={handleSubmit} className='flex flex-col gap-6 h-full w-[100%] mt-2 p-10'>
                                <LabelInput>
                                    <Input
                                    placeholder={'Digite aqui o titulo'}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    width={50}
                                    />
                                </LabelInput>
                                <LabelInput>
                                    <textarea className='outline-none border border-gray-300 hover:border-gray-500 duration-200 border-opacity-75 rounded-lg w-90 h-36 lg:h-32 resize-none                                                
                                                    focus:border focus:border-primaryDefaultLight 
                                                    dark:focus:border dark:focus:border-primaryDefaultLight p-2 placeholder:text-black'
                                                    onChange={(e) => setDescription(e.target.value)} placeholder="Digite aqui uma breve descrição"/>
                                </LabelInput>
                            {
                                paragraphs.map((paragraph, index) => {
                                    return(
                                        <div className="flex flex-row gap-5">
                                            <LabelInput>
                                                <Input
                                                placeholder={'Título'}
                                                value={paragraph.subTitle}
                                                onChange={(e) => {
                                                    paragraphs[index].subTitle = e.target.value
                                                    setParagraphs([...paragraphs])
                                                }}     
                                                width={50}                                    
                                                />
                                            </LabelInput>
                                            <LabelInput>
                                                <textarea className='outline-none border border-gray-300 hover:border-gray-500 duration-200 border-opacity-75 rounded-lg w-[220px] p-2 lg:h-20                                                
                                                                focus:border focus:border-primaryDefaultLight placeholder:text-black'
                                                                onChange={(e) => {
                                                                    paragraphs[index].paragraph = e.target.value
                                                                    setParagraphs([...paragraphs])
                                                                }} placeholder="Parágrafo" value={paragraph.paragraph}/>
                                            </LabelInput>
                                            <button type="button" onClick={newParagraph} className="bg-green-900 h-10 w-10 rounded-md mt-10 text-white text-xl hover:bg-white hover:text-black border border-gray-500"> + </button>
                                            <button type="button" onClick={() => removeParagraph(paragraph)} className="bg-red-900 h-10 w-10 rounded-md mt-10 text-white text-xl hover:bg-white hover:text-black border border-gray-500"> - </button>
                                        </div>
                                    )
                                })
                            }
                            <div className='flex flex-col justify-center align-center mt-7 ml-[12rem]'>
                                    <Button>Cadastrar</Button>
                            </div>
                        </form>
                    </div>
                </Modal>
                : 
                <></>
            }
                <div className="hidden lg:flex flex-col justify-center align-center ml-28 w[95%] mr-16 mt-16 gap-4">                    
                    <TableDefault
                        title={tableThead}
                        data={useTerms}
                        // buttonFunction={setShow}
                        // buttonFunctionParam={true}
                        // buttonDescription={'+ Novo'}
                        collumns={["title", "created_at", "user_fullname", "active"]}
                    />
                </div>
                <div className='flex lg:hidden w-full items-center justify-center py-8 mt-8'>
                    <p className='text-titleBlackTextLight text-base text-center'>
                        Esta funcionalidade está disponível apenas para computador
                    </p>
                </div>
        </>
    )
}