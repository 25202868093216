import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
// Hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
// Components
import Loader from '../../components/Loader/loader';
import { Button } from '../../components/buttons/button.default';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';
import Multiselect from '../../components/selects/mult.select'

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaFileExcel } from 'react-icons/fa';

export function ConferenceSends(){

    const navigate = useNavigate()
    const { setShow, show } = useModal()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { company, branch } = useCompanyBranch()
    const { user } = useAuth()
    const [loading, setLoading] = useState(true)
    const { setMenuTitle } = useMenu()
    setMenuTitle('Conferências')
    const token = localStorage.getItem('@auth:token')
    const [periodsList, setPeriodsList] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState('')
    const [branchList, setBranchList] = useState([])
    const [selectedBranchs, setSelectedBranchs] = useState([])

    useEffect(() => {
        async function getPeriods(){
            setLoading(true)
            try {
                const getPeriodsAPI = await api.get(`/api/v1/periods/${company}/${branch}`, {
                    headers: {
                        Authorization: token
                    }
                })
                setPeriodsList(getPeriodsAPI?.data?.data)

                const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
                    headers: {
                        authorization: token
                    }
                })
                setBranchList(resultBranches?.data?.data?.filter(emp => emp?.value != branch))
                setLoading(false)

            } catch(error){
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText('Erro ao buscar períodos')
            }
        }

        getPeriods()
    }, [])

    async function downloadFile(){
        if (!selectedPeriod){
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Selecione um período')
        }
        setLoading(true)

        try {
            const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''
            const file = await api.get(`/api/v1/sendEvents/company/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}/period/${selectedPeriod?.idPeriod}/sends?excel=true`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `Lançamentos_${company}_${selectedPeriod?.idPeriod}_${Number(new Date())}`)
            myElement.click()
            setLoading(false)
        } catch (error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }

    }

    return (
        <>
            {
                loading ?
                <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[85vh] items-center justify-center">
                    <Loader />
                </div>
                :
                <div className='flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14'>
                    <p className='text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-5'>Lançamentos de Eventos</p>
                    <div className='flex flex-col w-fit h-fit justify-center items-center shadow-xl rounded-lg mt-16 sm:mt-28 p-2'>
                        <div className='flex flex-col justify-start h-full items-center p-3'>
                            <div className='flex flex-col justify-around h-full'>
                                <div className='flex flex-col'>
                                    <label className='mt-3'>Período *</label>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={periodsList}
                                            id={'periods'}
                                            optionList={["idPeriod", "period"]}
                                            selectedLabel={'period'}
                                            onChange={(e) => {
                                                setSelectedPeriod(e)
                                            }}
                                            value={selectedPeriod ? periodsList.filter(item => item?.idPeriod == selectedPeriod?.idPeriod)[0]?.period : ''}
                                        />
                                    </LabelInput>
                                    <label className='mt-7'>Filiais</label>
                                    <LabelInput>
                                        <Multiselect
                                            items={[{label: "0 - Todas", value: 'Todas'}, ...branchList]}
                                            mult                                                 
                                            onChange={e => {
                                                if (e[0]?.value == "Todas"){
                                                    setSelectedBranchs(branchList)
                                                } else {
                                                    setSelectedBranchs(e)
                                                }
                                            }}
                                            selectedLabel={'label'}
                                            value={selectedBranchs ? selectedBranchs : []}
                                            id={`selectedBranchs`}
                                        />                                
                                    </LabelInput>
                                </div>
                                <div className='flex flex-row justify-center w-full gap-3 mt-7'>
                                    <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                                        onClick={() => downloadFile()}>
                                        <FaFileExcel className='text-sm'/> XLSX
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}