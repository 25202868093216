
//hooks
import React, { useState, useEffect, useCallback } from 'react';
//utils
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../../../utils/formatImages/canvasUtil'
//contexts
import { useAuth } from '../../../hooks/useAuth';
import { useModal } from '../../../hooks/useModal';
import { Button } from '../../buttons/button.default';
import api from '../../../services/api';
import Loader from '../../Loader/loader';

export function MyProfilePictureEdit({ imgSrc, picPreview, onChange }) {

    const { user, setUser, validAuth } = useAuth()
    const token = localStorage.getItem('@auth:token');
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [successAvatarUpload, setSuccessAvatarUpload] = useState(false);
    const [picturePreview, setPicturePreview] = useState('');
    const [sendFile, setSendFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setShow, show } = useModal()
    // const { userAvatar, setUserAvatar } = useAuth();
    const [aspectRatio, setAspectRatio] = useState({
        width: 250,
        height: 250
    })
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImageReady = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
            )

            const mimeTypes = ['image/png', 'image/jpeg']
            const extension = ['.png', '.jpeg']

            try {

                const blob = await (await fetch(croppedImageReady)).blob();

                if (mimeTypes.indexOf(blob.type) > -1) {
                    const file = new File([blob], `image${extension[mimeTypes.indexOf(blob.type)]}`, {
                        type: blob.type
                    })
                    setCroppedImage(URL.createObjectURL(file));
                    setSendFile(file);
                }
                else {
                    throw new Error('Esse tipo de arquivo não pode ser colocado como Imagem')
                }

            } catch (error) {
                if (error.response?.data?.message) {
                    return alert(error.response.data.message)
                }
                return alert(error.message)
            }
        } catch (e) {
            console.log(e)
            return alert('Erro ao tentar salvar imagem redimensionada.')
        }

    }, [imageSrc, croppedAreaPixels])

    useEffect(() => {
        setImageSrc(imgSrc);
        setPicturePreview(picPreview)
    }, [])


    async function handleSendImgToServer() {
        setLoading(true);

        try {

            const formData = new FormData();
            formData.append('files', sendFile);
            
            const avatarPostResult = await api.post(`/api/v1/users/photo`, formData, {
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
            
            setSuccessAvatarUpload(true)
            onChange('')
            setImageSrc(null)
            setShow(false)
            setLoading(false);
            validAuth()
            setShowNotificationModal(true)
            return setNotificationModalText('Foto salva com sucesso')
        }
        catch (error) {
            setLoading(false)
            return alert(error?.response?.data?.message)
        }
    }

    return (

        picturePreview !== '' ? (
            <>
                <div className='cropArea -ml-28'>
                    {
                        loading ? (
                            <div id='userEdittedImageLoader'>
                                <Loader />
                            </div>
                        ) :
                            successAvatarUpload ?
                                (
                                    <div className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm flex flex-col items-center justify-center' id='uploadSuccessMsg'>
                                        <a className='mb-10'>Sucesso ao alterar foto de perfil</a>
                                        <button className='text-titleGrayTextLight h-8 w-20 border-none bg-primaryDefaultLight items-center justify-center' onClick={() => setSuccessAvatarUpload(false)}>Fechar</button>
                                    </div>
                                ) :
                                croppedImage !== null ? (
                                    <>
                                        <div id='croppedImageConfirmation' className='p-3 absolute top-20 items-center left-0 justify-center w-full flex flex-row gap-2 z-[202]'>
                                            <div className='p-6 rounded-2xl'>
                                                <a className='text-white text-2xl font-extrabold '>Confirme o resultado</a>
                                            </div>

                                        </div>
                                        <div className='absolute bottom-20 items-center left-0 justify-center w-full flex flex-row gap-2 z-[202]'>
                                            <button className='boxShadow border border-primaryDefaultLight bg-primaryDefaultLight hover:brightness-125 rounded-lg text-titleGrayTextLight p-2 w-28 font-semibold' onClick={() => handleSendImgToServer()}>Confirmar</button>
                                            <button className='boxShadow border border-primaryDefaultLight bg-primaryDefaultLight hover:brightness-125 rounded-lg text-titleGrayTextLight p-2 w-28 font-semibold' onClick={() => setCroppedImage(null)}>Cancelar</button>
                                        </div>
                                        <div className='flex flex-col w-full h-full bg-[#6b6b6bb6] absolute top-0 left-0 items-center justify-center z-[201]'>
                                            <div className={`boxShadow overflow-hidden flex flex-col items-center justify-center bg-white w-[${aspectRatio.width}px] h-[${aspectRatio.height}px] rounded-full`}>
                                                <img id='croppedImage' className='w-full h-full rounded-lg' srcSet={croppedImage} />
                                            </div>
                                        </div>
                                    </>) :
                                    (
                                        <div>
                                            <div className='absolute bottom-20 items-center left-0 justify-center w-full gap-2 flex flex-col z-[800]' id='croppedImageEdition'>

                                                <div className='flex flex-row gap-2'>
                                                    <button className='border border-primaryDefaultLight bg-primaryDefaultLight hover:brightness-125 rounded-lg text-titleGrayTextLight p-2 w-28 boxShadow font-semibold' onClick={showCroppedImage}>Cortar</button>
                                                    <button className='border border-primaryDefaultLight bg-primaryDefaultLight hover:brightness-125 rounded-lg text-titleGrayTextLight p-2 w-28 boxShadow font-semibold' onClick={() => {onChange(''); setShow(false)}}>Cancelar</button>
                                                </div>
                                            </div>

                                            <Cropper
                                                image={picturePreview}
                                                crop={crop}
                                                style={{
                                                    mediaStyle: {
                                                        position: 'absolute',
                                                        margin: 'auto',
                                                        top: '0', left: "0",
                                                        right: '0', bottom: '0',
                                                        borderRadius: '50%',
                                                        overflow: 'hidden',
                                                        zIndex: 200

                                                    },
                                                    containerStyle: {
                                                        // borderRadius: '50%',
                                                        cursor: 'move',
                                                        position: 'absolute',
                                                        top: '0', left: "0",
                                                        right: '0', bottom: '0',
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'col',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        overflow: 'hidden',
                                                        touchAction: 'none',
                                                        userSelect: 'none',
                                                        zIndex: 199
                                                    },
                                                    cropAreaStyle: {
                                                        borderRadius: '50%',
                                                        border: '2px solid white',
                                                        position: 'absolute',
                                                        boxSizing: 'border-box',
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        overflow: 'hidden',
                                                        boxShadow: '0 0 0 9999em',
                                                        zIndex: 201
                                                    }
                                                }}
                                                cropSize={{ width: aspectRatio.width, height: aspectRatio.height }}
                                                zoom={zoom}
                                                minZoom={0}
                                                disableAutomaticStylesInjection={true}
                                                aspect={4 / 3}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        </div>
                                    )}

                </div>

            </>

        ) : (<></>)
    )
}