import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useMenu } from '../../hooks/useMenu';
import { useCompanyBranch } from "../../hooks/useCompanyBranch";
import Loader from '../../components/Loader/loader';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';
import { BarsHorizon } from '../../components/dashboard/barHorizon';
import { Spline } from '../../components/dashboard/spline';
import { SimplePie } from '../../components/dashboard/simplePie';
import Modal from '../../components/Modal/modal';
import api from "../../services/api";
import moment from "moment";
import { FaBirthdayCake, FaBan, FaTrophy, FaIdCard, FaInfoCircle, FaSearch, FaCloudDownloadAlt } from 'react-icons/fa';
import { FiAlertTriangle, FiAward, FiX } from 'react-icons/fi';
import { useModal } from "../../hooks/useModal";
import { TableDefault } from "../../components/table/table.default";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import tableToExcel from '../../utils/tableToExcel';

export function DashboardRH({ selectedBranchs, startFilter, changeStartFilter }) {

    const navigate = useNavigate()
    const { company, branch } = useCompanyBranch();
    const { setShow, show, setShowNotificationModal, setNotificationModalText, setShowAprovalModal } = useModal()
    const [loading, setLoading] = useState(true);
    const [loadingBars, setLoadingBars] = useState(false);
    const [loadingValuesGrossAndNet, setLoadingValuesGrossAndNet] = useState(false)
    const { setMenuTitle } = useMenu()
    setMenuTitle('Dashboard')
    const token = localStorage.getItem('@auth:token');
    const [data, setData] = useState('')
    const [averagePosition, setAveragePosition] = useState([])
    const [positionQuantityPerEmployee, setPositionQuantityPerEmployee] = useState([])
    const [selectedOption, setSelectedOption] = useState({
        id: 3,
        name: 'Cargo'
    })
    const [dataQuantity, setDataQuantity] = useState('')
    const [birthdayAndContracts, setBirthdayAndContracts] = useState('')
    const [endHolidays, setEndHolidays] = useState('')
    const [companyBirthday, setCompanyBirthday] = useState('')
    const [modalDetails, setModalDetails] = useState(false)
    const [detailsEmployees, setDetailsEmployees] = useState([])
    const [detailsAdmission, setDetailsAdmission] = useState([])
    const [detailsDemission, setDetailsDemission] = useState([])
    const [detailsHoliday, setDetailsHoliday] = useState([])
    const [detailsRemoval, setDetailsRemoval] = useState([])
    const [detailsStability, setDetailsStability] = useState([])
    const [detailsTemp, setDetailsTemp] = useState('')
    const [loadingAdmissionAndDemission, setLoadingAdmissionAndDemission] = useState(true)
    const [loadingAverage, setLoadingAverage] = useState(true)
    const [loadingCards, setLoadingCards] = useState(true)
    const [loadingFourLists, setLoadingFourLists] = useState(true)
    const [loadingSexAndPosition, setLoadingSexAndPosition] = useState(true)
    const [loadingSalaryLast, setLoadingSalaryLast] = useState(true)
    const [quantityGender, setQuantityGender] = useState([])
    const [selectedModel, setSelectedModel] = useState(1)
    const [dataLastSalary, setDataLastSalary] = useState([])
    const [loadingDownloadGender, setLoadingDownloadGender] = useState(false)
    const [yearOptions, setYearOptions] = useState([])
    const [selectedYear, setSelectedYear] = useState('')
    const [selectedYearQuantity, setSelectedYearQuantity] = useState({year: new Date().getFullYear()})
    const [yearList, setYearList] = useState([])

    useEffect(() => {
        if (startFilter){
            getData()
            changeStartFilter(false)
        }        
    }, [startFilter])

    async function getData() {
        setLoading(true)        
        setLoadingAverage(true)
        setLoadingCards(true)
        setLoadingFourLists(true)
        setLoadingSexAndPosition(true)
        setLoadingValuesGrossAndNet(true)
        setLoadingSalaryLast(true)
        
        const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''

        try {
            await getQuantityAdmissionAndDemission(selectedYearQuantity?.year)

            const getAveragePositionAPI = await api.get(`api/v1/dashboard/position/company/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}`, {
                headers: {
                    authorization: token
                }
            })
            setAveragePosition(getAveragePositionAPI?.data?.data.map((pos, index) => {
                return {
                    name: pos.titcar,
                    value: pos.average.toString().replace('.', ','),
                    tableDescription: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pos.average),
                    position: `${index + 1}º`,
                    quantity: pos?.quantity
                }
            }))
            const getQuantityEmployeesPositionAPI = await api.get(`api/v1/dashboard/position/company/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}?quantity=true`, {
                headers: {
                    authorization: token
                }
            })
            setPositionQuantityPerEmployee(getQuantityEmployeesPositionAPI?.data?.data.map((pos, index) => {
                return {
                    name: pos.titcar,
                    position: `${index + 1}º`,
                    quantity: pos?.quantity
                }
            }))                

            const getDataAPI = await api.get(`api/v1/dashboard/company/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}`, {
                headers: {
                    authorization: token
                }
            })
            setData(getDataAPI?.data?.data)

            const getCompanyBirthdayInMonthAPI = await api.get(`api/v1/dashboard/companyBirthdayInMonth/company/${company}?branch=${existsBranchs ? `${branch},${existsBranchs}` : branch}`, {
                headers: {
                    authorization: token
                }
            })
            setCompanyBirthday(getCompanyBirthdayInMonthAPI?.data?.data)
            setLoadingCards(false)            

            const getBirthdayAndContractsAPI = await api.get(`api/v1/dashboard/birthdayAndContracts/company/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}`, {
                headers: {
                    authorization: token
                }
            })
            setBirthdayAndContracts(getBirthdayAndContractsAPI?.data?.data)

            const getHolidaysAPI = await api.get(`api/v1/dashboard/endHolidays/company/${company}?branch=${existsBranchs ? `${branch},${existsBranchs}` : branch}`, {
                headers: {
                    authorization: token
                }
            })
            setEndHolidays(getHolidaysAPI?.data?.data)
            setLoadingFourLists(false)
            setLoadingAverage(false)

            const getQuantityGender = await api.get(`api/v1/dashboard/quantityGender/company/${company}?branch=${existsBranchs ? `${branch},${existsBranchs}` : branch}`, {
                headers: {
                    authorization: token
                }
            })
            setQuantityGender(getQuantityGender?.data?.data)
            setLoadingSexAndPosition(false)

            const getDetailsAPI = await api.get(`api/v1/dashboard/details/company/${company}?branch=${existsBranchs ? `${branch},${existsBranchs}` : branch}`, {
                headers: {
                    authorization: token
                }
            })
            setDetailsEmployees(getDetailsAPI?.data?.data?.employeesDetails.map(emp => {
                return {
                    ...emp,
                    valsal: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(emp?.valsal)
                }
            }))
            setDetailsAdmission(getDetailsAPI?.data?.data?.getAdmissionOnMonth.map(adm => {
                return {
                    ...adm,
                    datadm: moment(adm?.datadm).utc(false).format('DD/MM/YYYY')
                }
            }))
            setDetailsDemission(getDetailsAPI?.data?.data?.getDemissionOnMonth.map(dem => {
                return {
                    ...dem,
                    datafa: moment(dem?.datafa).utc(false).format('DD/MM/YYYY')
                }
            }))
            setDetailsHoliday(getDetailsAPI?.data?.data?.getEmployeesHoliday.map(hol => {
                return {
                    ...hol,
                    datafa: moment(hol?.datafa).utc(false).format('DD/MM/YYYY'),
                    datter: moment(hol?.datter).utc(false).format('DD/MM/YYYY')
                }
            }))
            setDetailsRemoval(getDetailsAPI?.data?.data?.getEmployeesRemoval)
            setDetailsStability(getDetailsAPI?.data?.data?.getEmployeesStability)

            // await getLastIncreaseSalary()

            setLoading(false)
            
        } catch (error) {
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function getQuantityAdmissionAndDemission(year){
    
        const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''

        setLoadingAdmissionAndDemission(true)
        try {            
            const getQuantityAPI = await api.get(`api/v1/dashboard/quantity/company/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}?year=${year}`, {
                headers: {
                    authorization: token
                }
            })
            setDataQuantity(getQuantityAPI?.data?.data)
            setLoadingAdmissionAndDemission(false)
        } catch(error){
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    useEffect(() => {
        if(company === 0 || branch === 0){
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }

        const yearCurrent = moment().year();
        const arrayYears = [{year: yearCurrent}]

        for (let i = 0; i < 5; i++) {
            arrayYears.push({year: yearCurrent - i - 1})
        }

        setYearList(arrayYears)

        getData()

    }, [])

    useEffect(() => {
        if (!show) {
            setModalDetails(false)
            setDetailsTemp('')
        }
    }, [show])

    async function getLastIncreaseSalary(){
        setLoadingSalaryLast(true)
        try {
            const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''
            const getLastSalaryAPI = await api.get(`api/v1/dashboard/lastIncreaseSalary/company/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch} ${selectedYear ? `?year=${selectedYear}` : ''}`, {
                headers: {
                    authorization: token
                }
            })
            setDataLastSalary(getLastSalaryAPI?.data?.data?.persons?.map((emp, index) => {
                return {
                    ...emp,
                    position: `${index + 1}º`
                }
            }))
            setYearOptions(getLastSalaryAPI?.data?.data?.years)
            return setLoadingSalaryLast(false)
        } catch(error){
            setLoadingSalaryLast(false)
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados de salários.')
        }
    }

    const options = [
        {
            id: 1,
            name: 'Local de Organograma'
        },
        {
            id: 2,
            name: 'Centro de Custo'
        },
        {
            id: 3,
            name: 'Cargo'
        }
    ]

    const tableThead = [
        {
            "name": "Posição",
            "original_name": "position",
        },
        {
            "name": "Descrição",
            "original_name": "name",
        },
        {
            "name": "Valor",
            "original_name": "tableDescription",
        }
    ]

    const tableTheadQuantityPosition = [
        {
            "name": "Posição",
            "original_name": "position",
        },
        {
            "name": "Descrição",
            "original_name": "name",
        },
        {
            "name": "Quantidade",
            "original_name": "quantity",
        }
    ]

    const tableTheadAverageSalary = [
        {
            "name": "Nome",
            "original_name": "nomfun",
        },
        {
            "name": "Salário",
            "original_name": "valsal",
        },
    ]

    const tableTheadAverageAge = [
        {
            "name": "Nome",
            "original_name": "nomfun",
        },
        {
            "name": "Idade",
            "original_name": "idade",
        },
    ]

    const tableTheadAdmissionOnMonth = [
        {
            "name": "Nome",
            "original_name": "nomfun",
        },
        {
            "name": "Data admissão",
            "original_name": "datadm",
        },
    ]

    const tableTheadDemissionOnMonth = [
        {
            "name": "Nome",
            "original_name": "nomfun",
        },
        {
            "name": "Data demissão",
            "original_name": "datafa",
        },
    ]

    const tableTheadHoliday = [
        {
            "name": "Nome",
            "original_name": "nomfun",
        },
        {
            "name": "Data início",
            "original_name": "datafa",
        },
        {
            "name": "Data término",
            "original_name": "datter",
        },
    ]

    const tableTheadRemoval = [
        {
            "name": "Nome",
            "original_name": "nomfun",
        },
        {
            "name": "Situação",
            "original_name": "dessit",
        },
        {
            "name": "Data Afastamento",
            "original_name": "datafa",
        },
    ]

    const tableTheadStability = [
        {
            "name": "Nome",
            "original_name": "nomfun",
        },
        {
            "name": "Início Estabilidade",
            "original_name": "inietb",
        },
        {
            "name": "Fim Estabilidade",
            "original_name": "fimetb",
        },
        {
            "name": "Motivo",
            "original_name": "codetb",
        },
    ]

    async function handleNewAverage(data) {
        setLoadingBars(true)
        if (data?.id == 1) {
            try {

                const getDataAPI = await api.get(`api/v1/dashboard/local/company/${company}/branch/${branch}`, {
                    headers: {
                        authorization: token
                    }
                })
                setAveragePosition(getDataAPI?.data?.data.map((local, index) => {
                    return {
                        name: local?.localName,
                        value: local?.average?.replace('.', ','),
                        tableDescription: `R$ ${local.average.toString().replace('.', ',')}`,
                        position: `${index + 1}º`
                    }
                }))

            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        if (data?.id == 2) {
            try {

                const getDataAPI = await api.get(`api/v1/dashboard/ccu/company/${company}/branch/${branch}`, {
                    headers: {
                        authorization: token
                    }
                })
                setAveragePosition(getDataAPI?.data?.data.map((ccu, index) => {
                    return {
                        name: ccu?.nomccu,
                        value: ccu?.average?.replace('.', ','),
                        tableDescription: `R$ ${ccu.average.toString().replace('.', ',')}`,
                        position: `${index + 1}º`
                    }
                }))

            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        if (data?.id == 3) {
            try {

                const getDataAPI = await api.get(`api/v1/dashboard/position/company/${company}/branch/${branch}`, {
                    headers: {
                        authorization: token
                    }
                })
                setAveragePosition(getDataAPI?.data?.data.map((pos, index) => {
                    return {
                        name: pos.titcar,
                        value: pos.average.toString().replace('.', ','),
                        tableDescription: `R$ ${pos.average.toString().replace('.', ',')}`,
                        position: `${index + 1}º`,
                        quantity: pos?.quantity
                    }
                }))

            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        setLoadingBars(false)
    }

    async function exportGendersExcel(){
        setLoadingDownloadGender(true)
        try {
            const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''
            const file = await api.get(`api/v1/dashboard/quantityGender/company/${company}?branch=${existsBranchs ? `${branch},${existsBranchs}` : branch}&excel=true`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `RelacaoColabsPorGenero_${Number(new Date())}`)
            myElement.click()
            setLoadingDownloadGender(false)
        } catch(error){
            setLoadingDownloadGender(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro no download')
        }
    }

    return (
        <>
            {
                show && modalDetails ?
                    <Modal>
                        <div className={`h-fit max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[50rem] p-4`}>
                            <div className='flex justify-between pl-3 py-5 items-center mb-5'>
                                <p className='flex flex-row font-extrabold items-center text-base sm:text-sm xl:text-lg'>
                                    {
                                        detailsTemp == 'averageSalary' ?
                                            <p className='mr-7 flex justify-center items-center'>Detalhes da média salarial</p>
                                            : detailsTemp == 'averageAge' ?
                                                <p className='mr-7 flex justify-center items-center'>Detalhes da média de idade</p>
                                                : detailsTemp == 'admissionMonth' ?
                                                    <p className='mr-7 flex justify-center items-center'>Detalhes admissões do mês</p>
                                                    : detailsTemp == 'demissionMonth' ?
                                                        <p className='mr-7 flex justify-center items-center'>Detalhes demissões do mês</p>
                                                        : detailsTemp == 'removal' ?
                                                            <p className='mr-7 flex justify-center items-center'>Detalhes colaboradores afastados</p>
                                                            : detailsTemp == 'quantityPosition' ?
                                                                <p className='mr-7 flex justify-center items-center'>Quantidade colaboradores por cargo</p>
                                                                : detailsTemp == 'stability' ?
                                                                    <p className='mr-7 flex justify-center items-center'>Detalhes colaboradores com estabilidade</p>
                                                                    :
                                                                    <p className='mr-7 flex justify-center items-center'>Detalhes férias do mês</p>
                                    }
                                    <Tippy
                                        content={<a>Exportar</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={80}>
                                        <div>
                                            <FaCloudDownloadAlt onClick={() => tableToExcel(
                                                detailsTemp == 'averageSalary' ?
                                                `Média Salarial ${Number(new Date())}.xls` :
                                                detailsTemp == 'averageAge' ?
                                                `Média Idade ${Number(new Date())}.xls` :
                                                detailsTemp == 'admissionMonth' ?
                                                `Admissão do Mês ${Number(new Date())}.xls` :
                                                detailsTemp == 'demissionMonth' ?
                                                `Demissão do Mês ${Number(new Date())}.xls` :
                                                detailsTemp == 'removal' ?
                                                `Colaboradores Afastados ${Number(new Date())}.xls` :
                                                detailsTemp == 'stability' ?
                                                `Colaboradores Estabilidade ${Number(new Date())}.xls` : `Colaboradores de Férias ${Number(new Date())}.xls`
                                            )} className="flex flex-col justify-center cursor-pointer duration-100 text-primaryDefaultLight text-xl h-full"/>
                                        </div>
                                    </Tippy>
                                    <a id="link-to-download" href="!#">{}</a> 
                                </p>
                                
                                <Tippy
                                    content={<a>Fechar</a>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={80}>
                                    <div>
                                        <FiX className='text-gray-500 text-2xl cursor-pointer' onClick={e => setShow(false)} />
                                    </div>
                                </Tippy>
                            </div>
                            {
                                detailsTemp == 'averageSalary' ?
                                    <TableDefault
                                        title={tableTheadAverageSalary}
                                        data={detailsEmployees}
                                        quantity={detailsEmployees.length}
                                        collumns={["nomfun", "valsal"]}
                                        id={'TableToExport'}
                                    />
                                    : detailsTemp == 'averageAge' ?
                                        <TableDefault
                                            title={tableTheadAverageAge}
                                            data={detailsEmployees}
                                            quantity={detailsEmployees.length}
                                            collumns={["nomfun", "idade"]}
                                            id={'TableToExport'}
                                        />
                                        : detailsTemp == 'admissionMonth' ?
                                            <TableDefault
                                                title={tableTheadAdmissionOnMonth}
                                                data={detailsAdmission}
                                                quantity={detailsAdmission.length}
                                                collumns={["nomfun", "datadm"]}
                                                id={'TableToExport'}
                                            />
                                            : detailsTemp == 'demissionMonth' ?
                                                <TableDefault
                                                    title={tableTheadDemissionOnMonth}
                                                    data={detailsDemission}
                                                    quantity={detailsDemission.length}
                                                    collumns={["nomfun", "datafa"]}
                                                    id={'TableToExport'}
                                                />
                                                : detailsTemp == 'removal' ?
                                                    <TableDefault
                                                        title={tableTheadRemoval}
                                                        data={detailsRemoval}
                                                        quantity={detailsRemoval.length}
                                                        collumns={["nomfun", "dessit", "datafa"]}
                                                        id={'TableToExport'}
                                                    />
                                                    : detailsTemp == 'quantityPosition' ?
                                                        <TableDefault
                                                            title={tableTheadQuantityPosition}
                                                            data={positionQuantityPerEmployee}
                                                            quantity={positionQuantityPerEmployee.length}
                                                            collumns={["position", "name", "quantity"]}
                                                            id={'TableToExport'}
                                                        />
                                                        : detailsTemp == 'stability' ?
                                                            <TableDefault
                                                                title={tableTheadStability}
                                                                data={detailsStability}
                                                                quantity={detailsStability.length}
                                                                collumns={["nomfun", "inietb", "fimetb", "codetb"]}
                                                                id={'TableToExport'}
                                                            />
                                                            :
                                                            <TableDefault
                                                                title={tableTheadHoliday}
                                                                data={detailsHoliday}
                                                                quantity={detailsHoliday.length}
                                                                collumns={["nomfun", "datafa", "datter"]}
                                                                id={'TableToExport'}
                                                            />
                            }
                        </div>
                    </Modal>
                    :
                    <Modal>
                        <div className={`h-fit max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[50rem] p-4`}>
                            <div className='flex justify-between pl-3 py-5 items-center mb-5'>
                                <p className='flex flex-row font-extrabold items-center text-base sm:text-sm xl:text-lg'>
                                    <p className='mr-7 flex justify-center items-center'>Média salarial por - {selectedOption?.name}</p>
                                </p>
                                <Tippy
                                    content={<a>Fechar</a>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={80}>
                                    <div>
                                        <FiX className='text-gray-500 text-2xl cursor-pointer' onClick={e => setShow(false)} />
                                    </div>
                                </Tippy>
                            </div>
                            <TableDefault
                                title={tableThead}
                                data={averagePosition}
                                quantity={averagePosition.length}
                                collumns={["position", "name", "tableDescription"]}
                            />
                        </div>
                    </Modal>
            }
            <div className="w-[96%] ml-14 h-full gap-3 hidden lg:flex lg:flex-col xl:flex-col 2xl:flex-col lg:overflow-x-hidden xl:overflow-auto 2xl:overflow-x-hidden overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                <div className="flex flex-col w-full gap-3">
                    <div className="h-full gap-3 w-full flex">
                        {
                            loadingAdmissionAndDemission ?
                                <div className="py-6 w-[50%] xl:h-[31rem] shadow-borderShadow rounded-lg bg-white flex justify-center items-center">
                                    <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                                </div>
                                :
                                <>
                                    <div className="py-6 w-[50%] h-full shadow-borderShadow rounded-lg bg-white">
                                        <div className="h-9 w-full">
                                            <p className="flex flex-row items-center justify-center gap-2 text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">
                                                Admissões e Demissões -
                                                <LabelInput>
                                                    <InputAutoComplete
                                                        data={yearList}
                                                        selectedLabel={'year'}
                                                        optionList={['year']}
                                                        preSelectedValue={selectedYearQuantity?.year}
                                                        onChange={(e) => {
                                                            setSelectedYearQuantity(e)
                                                            if (typeof(e) == 'object'){
                                                                getQuantityAdmissionAndDemission(e?.year)
                                                            }
                                                        }}
                                                        width={24}
                                                    />
                                                </LabelInput>
                                            </p>
                                            <p></p>
                                        </div>
                                        <Spline
                                            id={'dashboardDesktop'}
                                            data={dataQuantity}
                                        />
                                    </div>
                                </>
                        }
                        {
                            loadingCards ?
                            <div className="flex w-[50%] h-full gap-3">
                                {/* Cards de quantidades estaticas */}
                                <div className="h-full rounded-2xl w-full flex flex-col gap-3">
                                    <div className="flex w-full h-[50%] p-3 gap-3">
                                        <div className="flex shadow-borderShadow w-[50%] h-full lg:h-32 2xl:h-36 p-5 rounded-lg gap-2 justify-center items-center bg-white">
                                            <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md"></div>
                                        </div>
                                        <div className="flex shadow-borderShadow w-[50%] h-full lg:h-32 2xl:h-36 p-5 rounded-lg gap-2 justify-center items-center bg-white">
                                            <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md"></div>
                                        </div>
                                        <div className="flex shadow-borderShadow w-[50%] h-full lg:h-32 2xl:h-36 p-5 rounded-lg gap-2 justify-center items-center bg-white">
                                            <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md"></div>
                                        </div>
                                        <div className="flex shadow-borderShadow w-[50%] h-full lg:h-32 2xl:h-36 p-5 rounded-lg gap-2 justify-center items-center bg-white">
                                            <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md"></div>
                                        </div>
                                    </div>
                                    <div className="flex w-full h-[50%] p-3 gap-3">
                                        <div className="flex shadow-borderShadow w-[50%] h-full lg:h-32 2xl:h-36 p-5 rounded-lg gap-2 justify-center items-center bg-white">
                                            <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md"></div>
                                        </div>
                                        <div className="flex shadow-borderShadow w-[50%] h-full lg:h-32 2xl:h-36 p-5 rounded-lg gap-2 justify-center items-center bg-white">
                                            <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md"></div>
                                        </div>
                                        <div className="flex shadow-borderShadow w-[50%] h-full lg:h-32 2xl:h-36 p-5 rounded-lg gap-2 justify-center items-center bg-white">
                                            <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md"></div>
                                        </div>
                                        <div className="flex shadow-borderShadow w-[50%] h-full lg:h-32 2xl:h-36 p-5 rounded-lg gap-2 justify-center items-center bg-white">
                                            <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="flex w-[50%] h-full gap-3">
                                <div className="h-full rounded-2xl w-full flex flex-col gap-3">
                                    <div className="flex w-full h-[50%] p-3 gap-3">
                                        <div className={`bg-white flex shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2 ${detailsEmployees?.length > 0 ? 'hover:cursor-pointer hover:scale-105 duration-200' : ''}`} onClick={() => { if (detailsEmployees?.length == 0) { } else { setDetailsTemp('averageSalary'); setModalDetails(true); setShow(true) } }}>
                                            <p className="text-center 2xl:text-base sm:text-sm xl:text-base font-thin h-14">Média salarial</p>
                                            <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight mt-2">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data?.averageSalary)}</p>
                                        </div>
                                        <div className={`bg-white flex shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2 ${detailsEmployees?.length > 0 ? 'hover:cursor-pointer hover:scale-105 duration-200' : ''}`} onClick={() => { if (detailsEmployees?.length == 0) { } else { setDetailsTemp('averageAge'); setModalDetails(true); setShow(true) } }}>
                                            <p className="text-center 2xl:text-base sm:text-sm xl:text-base font-thin h-14">Média idade</p>
                                            <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight mt-2">{data?.averageYear}</p>
                                        </div>
                                        <div className={`flex bg-white shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2 ${data?.totalEmployeesActives > 0 ? 'hover:cursor-pointer hover:scale-105 duration-200' : ''}`} onClick={() => { if (data?.totalEmployeesActives == 0) { } else navigate('/employeeConsult') }}>
                                            <p className="text-center 2xl:text-base sm:text-sm xl:text-base font-thin h-14">Funcionários ativos</p>
                                            <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight mt-2">{data?.totalEmployeesActives}</p>
                                        </div>
                                        <div className={`flex bg-white shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2 ${data?.totalEmployeesRemoval > 0 ? 'hover:cursor-pointer hover:scale-105 duration-200' : ''}`} onClick={() => { if (detailsRemoval.length == 0) { } else { setDetailsTemp('removal'); setModalDetails(true); setShow(true) } }}>
                                            <p className="text-center 2xl:text-base sm:text-sm xl:text-base font-thin h-14">Funcionários afastados</p>
                                            <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight mt-2">{data?.totalEmployeesRemoval}</p>
                                        </div>
                                    </div>
                                    <div className="flex w-full h-[50%] p-3 gap-3">
                                        <div className={`flex bg-white shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2 ${detailsAdmission.length > 0 ? 'hover:cursor-pointer hover:scale-105 duration-200' : ''}`} onClick={() => { if (detailsAdmission.length == 0) { } else { setDetailsTemp('admissionMonth'); setModalDetails(true); setShow(true) } }}>
                                            <p className="text-center 2xl:text-base sm:text-sm xl:text-base font-thin h-14">Quantidade admissão (mês)</p>
                                            <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight mt-2 xl:mt-5 2xl:mt-0">{data?.quantityAdmission}</p>
                                        </div>
                                        <div className={`flex bg-white shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2 ${detailsDemission.length > 0 ? 'hover:cursor-pointer hover:scale-105 duration-200' : ''}`} onClick={() => { if (detailsDemission.length == 0) { } else { setDetailsTemp('demissionMonth'); setModalDetails(true); setShow(true) } }}>
                                            <p className="text-center 2xl:text-base sm:text-sm xl:text-base font-thin h-14">Quantidade demissão (mês)</p>
                                            <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight mt-2 xl:mt-5 2xl:mt-0">{data?.quantityDemission}</p>
                                        </div>
                                        <div className={`flex bg-white shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2 ${detailsHoliday.length > 0 ? 'hover:cursor-pointer hover:scale-105 duration-200' : ''}`} onClick={() => { if (detailsHoliday.length == 0) { } else { setDetailsTemp('holidayMonth'); setModalDetails(true); setShow(true) } }}>
                                            <p className="text-center 2xl:text-base sm:text-sm xl:text-base font-thin h-14">Funcionários de férias</p>
                                            <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight mt-2 xl:mt-5 2xl:mt-0">{data?.totalEmployeesHoliday}</p>
                                        </div>
                                        <div className={`flex bg-white shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2 ${detailsStability.length > 0 ? 'hover:cursor-pointer hover:scale-105 duration-200' : ''}`} onClick={() => { if (detailsStability.length == 0) { } else { setDetailsTemp('stability'); setModalDetails(true); setShow(true) } }}>
                                            <p className="text-center 2xl:text-base sm:text-sm xl:text-base font-thin h-14">Funcionários com estabilidade</p>
                                            <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight mt-2 xl:mt-5 2xl:mt-0">{data?.totalEmployessStability}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    loadingFourLists ?
                        <div className="h-full py-5 items-center justify-center gap-3 px-10 bg-white shadow-borderShadow rounded-lg flex sm:flex-col xl:flex-row 2xl:flex-row">
                            {/* Aniversários do mês */}
                            <div className="w-full h-80 flex flex-col bg-slate-200 justify-center items-center rounded-md animate-pulse"></div>
                            {/* Contratos do mês */}
                            <div className="w-full h-80 flex flex-col bg-slate-200 justify-center items-center rounded-md animate-pulse"></div>
                            {/* Tempo de empresa */}
                            <div className="w-full h-80 flex flex-col bg-slate-200 justify-center items-center rounded-md animate-pulse"></div>
                            {/* Férias a vencer */}
                            <div className="w-full h-80 flex flex-col bg-slate-200 justify-center items-center rounded-md animate-pulse"></div>
                        </div>
                        :
                        <>
                            <div className="h-full items-center justify-center gap-3 px-10 bg-white shadow-borderShadow rounded-lg flex sm:flex-col lg:flex-row xl:flex-row 2xl:flex-row">
                                <div className="w-full lg:max-h-[80%] flex flex-col justify-start">
                                    <div className="flex flex-col justify-center items-center w-full text-center gap-1">
                                        <p className="text-xl text-pink-600"><FaBirthdayCake /></p>
                                        <p className="text-base sm:text-sm xl:text-base font-semibold text-[#311962]">Aniversariantes do mês</p>
                                    </div>
                                    <div className="w-full flex flex-col gap-3 lg:max-h-[32rem] h-[40rem] mt-2 shadow-inner rounded-lg m-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3">
                                        {
                                            birthdayAndContracts?.birthdayInMonth.length > 0 ?
                                                birthdayAndContracts?.birthdayInMonth.map(person => {
                                                    const splitDay = person.birthday.split('/')
                                                    const formatDay = `${splitDay[0]}/${splitDay[1]}`
                                                    return (
                                                        <div className={`h-24 w-full rounded-lg flex flex-col justify-center text-center`}>
                                                            <p className="sm:text-base 2xl:text-sm font-medium sm:mt-2 xl:mt-3 2xl:mt-2 xl:text-sm">{person?.employeeName}</p>
                                                            <p className="text-sm font-semibold mt-1 text-[#311962]">{formatDay}</p>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="flex flex-col items-center justify-center m-auto text-gray-400">
                                                    <p className="text-base sm:text-sm xl:text-lg font-semibold text-center">NÃO HÁ ANIVERSARIANTES ESSE MÊS</p>
                                                    <p className="text-base sm:text-sm xl:text-lg"><FaBan /></p>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="w-full lg:max-h-[80%] flex flex-col justify-start h-full">
                                    <div className="flex flex-col justify-center items-center w-full text-center gap-1">
                                        <p className="text-xl text-orange-600"><FiAlertTriangle /></p>
                                        <p className="text-base sm:text-sm xl:text-base flex flex-row items-center font-semibold text-[#311962]">Contratos a vencer (próximos 90 dias)
                                            <Tippy
                                                content={'Primeiro Vencimento - Segundo Vencimento'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={80}>
                                                <div>
                                                    <FaInfoCircle className="text-sm text-blue-900 ml-2 cursor-help" />
                                                </div>
                                            </Tippy>
                                        </p>
                                    </div>
                                    <div className="w-full flex flex-col gap-3 max-h-[32rem] h-full mt-2 shadow-inner rounded-lg m-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3">
                                        {
                                            birthdayAndContracts?.endContracts.length > 0 ?
                                                birthdayAndContracts?.endContracts.map(cont => {
                                                    return (
                                                        <div className={`h-24 w-full rounded-lg flex flex-col justify-center text-center`}>
                                                            <p className="sm:text-base 2xl:text-sm font-medium sm:mt-2 xl:mt-3 2xl:mt-2 xl:text-sm">{cont?.employeeName}</p>
                                                            <p className="text-sm font-semibold mt-1 text-[#311962]">{cont?.usu_datini + ' - ' + cont?.usu_datfim}</p>                                                                                    
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="flex flex-col items-center justify-center m-auto text-gray-400">
                                                    <p className="text-base sm:text-sm xl:text-lg font-semibold text-center">NÃO HÁ CONTRATOS</p>
                                                    <p className="text-base sm:text-sm xl:text-lg"><FaBan /></p>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="w-full lg:max-h-[80%] flex flex-col justify-start">
                                    <div className="flex flex-col justify-center items-center w-full text-center gap-1">
                                        <p className="text-xl text-green-600"><FaIdCard /></p>
                                        <p className="text-base sm:text-sm xl:text-base font-semibold text-[#311962]">Aniversário de empresa</p>
                                    </div>
                                    <div className="w-full flex flex-col gap-3 lg:max-h-[32rem] h-[40rem] mt-2 shadow-inner rounded-lg m-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3">
                                        {
                                            companyBirthday?.length > 0 ?
                                                companyBirthday?.map(person => {
                                                    return (
                                                        <div className={`h-24 w-full rounded-lg flex flex-col justify-center text-center`}>
                                                            <p className="sm:text-base 2xl:text-sm font-medium sm:mt-2 xl:mt-3 2xl:mt-2 xl:text-sm">{person?.nomfun}</p>
                                                            <p className="text-sm font-semibold mt-1 text-[#311962]">{person?.datadm} - {person?.willDo} anos</p>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="flex flex-col items-center justify-center m-auto text-gray-400">
                                                    <p className="text-base sm:text-sm xl:text-lg font-semibold text-center">NÃO HÁ COLABORADORES ESSE MÊS</p>
                                                    <p className="text-base sm:text-sm xl:text-lg"><FaBan /></p>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="w-full lg:max-h-[80%] flex flex-col justify-start">
                                    <div className="flex flex-col justify-center items-center w-full text-center gap-1">
                                        <p className="text-xl text-orange-600"><FiAlertTriangle /></p>
                                        <p className="text-base sm:text-sm xl:text-base flex flex-row items-center font-semibold text-[#311962]">Férias a vencer (próximos 90 dias)
                                            <Tippy
                                                content={'A gestão de vencimentos de férias é de responsabilidade do cliente. Esta lista é para facilitar o controle. (Limite Pagamento)'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={80}>
                                                <div>
                                                    <FaInfoCircle className="text-sm text-blue-900 ml-2 cursor-help" />
                                                </div>
                                            </Tippy>
                                        </p>
                                    </div>
                                    <div className="w-full flex flex-col gap-3 lg:max-h-[32rem] h-[40rem] mt-2 shadow-inner rounded-lg m-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3">
                                        {
                                            endHolidays?.length > 0 ?
                                                endHolidays?.map(person => {
                                                    return (
                                                        <div className={`h-24 w-full rounded-lg flex flex-col justify-center text-center`}>
                                                            <p className={`sm:text-base 2xl:text-sm font-medium sm:mt-2 xl:mt-3 2xl:mt-2 xl:text-sm ${moment().utc(false).isAfter(moment(person?.limite, 'DD/MM/YYYY')) && 'text-red-600'}`}>{person?.nomfun}</p>
                                                            <p className={`text-sm font-semibold mt-1 ${moment().utc(false).isAfter(moment(person?.limite, 'DD/MM/YYYY')) ? 'text-red-600' : 'text-[#311962]'}`}>{person?.limite}</p>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="flex flex-col items-center justify-center m-auto text-gray-400">
                                                    <p className="text-base sm:text-sm xl:text-lg font-semibold text-center">NÃO HÁ COLABORADORES</p>
                                                    <p className="text-base sm:text-sm xl:text-lg"><FaBan /></p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                }
                {
                    loadingAverage ?
                    <div className="flex flex-row gap-3">
                        <div className="py-6 w-[50%] h-96 border shadow-borderShadow rounded-lg flex justify-center items-center bg-white">
                            <div className="h-72 w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                        </div>
                        <div className="py-6 w-[50%] h-96 border shadow-borderShadow rounded-lg flex justify-center items-center bg-white">
                            <div className="h-72 w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                        </div>
                    </div>
                    :
                    <div className="flex flex-row gap-3">
                        <div className="py-6 w-[70%] h-full border shadow-borderShadow rounded-lg flex flex-col bg-white">
                            {
                                loadingBars ?
                                    <div className="py-6 w-full h-96 border shadow-borderShadow rounded-lg flex justify-center items-center bg-white">
                                        <div className="h-72 w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                                    </div>
                                    :
                                    <>
                                        <div className="w-[95%] justify-center items-center m-auto">
                                            <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">Top 10 - Média salarial de {selectedOption ? selectedOption?.name.toLowerCase() : ''}</p>
                                            <BarsHorizon
                                                data={averagePosition.slice(0, 10)}
                                                id={'dashboardBarsHorizonDesktop'}

                                            />
                                            <div className="w-[95%] h-12 items-center p-2 m-auto flex justify-between">
                                                <div className="w-96">
                                                    <LabelInput>
                                                        <InputAutoComplete
                                                            data={options}
                                                            selectedLabel={'name'}
                                                            optionList={['name']}
                                                            value={selectedOption ? selectedOption?.name : ''}
                                                            placeHolder={'Selecione uma opção'}
                                                            onChange={(e) => { setSelectedOption(e); handleNewAverage(e) }}
                                                        />
                                                    </LabelInput>
                                                </div>
                                                <button className="p-1 w-24 rounded-md text-white font-semibold bg-primaryDefaultLight hover:bg-primaryDefaultLightHover duration-200"
                                                    onClick={() => setShow(true)}
                                                >
                                                    Ver mais
                                                </button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                        <div className="h-full w-[30%] gap-1 bg-white shadow-borderShadow rounded-lg flex flex-col items-center justify-center overflow-hidden">
                            <p className="mt-8 text-base sm:text-sm xl:text-lg text-titleBlackTextLight font-semibold">Top 5 - Tempo de casa</p>
                            <div className="w-full h-full flex justify-center mt-3">
                                <div className="flex flex-col w-96 h-full gap-7 rounded-lg items-center xl:pb-7 2xl:pb-0">
                                    <div className="h-14 w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-xl font-semibold">1º</p>
                                            <p className="text-xl text-orange-400 ml-3"><FaTrophy /></p>
                                        </div>
                                        <p className="text-sm text-">{data?.companyTime[0]?.employeeName?.length > 25 ? data?.companyTime[0]?.employeeName?.slice(0, 25) : data?.companyTime[0]?.employeeName} <span className="font-semibold">({data?.companyTime[0]?.companyTime} anos)</span></p>
                                    </div>
                                    <div className="h-14 w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-xl font-semibold">2º</p>
                                            <p className="text-xl text-gray-400 ml-3"><FaTrophy /></p>
                                        </div>
                                        <p className="text-sm">{data?.companyTime[1]?.employeeName.length > 25 ? `${data?.companyTime[1]?.employeeName?.slice(0, 25)}...` : data?.companyTime[1]?.employeeName} <span className="font-semibold">({data?.companyTime[1]?.companyTime} anos)</span></p>
                                    </div>
                                    <div className="h-14 w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-xl font-semibold">3º</p>
                                            <p className="text-xl text-amber-700 ml-3"><FaTrophy /></p>
                                        </div>
                                        <p className="text-sm">{data?.companyTime[2]?.employeeName.length > 25 ? `${data?.companyTime[2]?.employeeName?.slice(0, 25)}...` : data?.companyTime[2]?.employeeName} <span className="font-semibold">({data?.companyTime[2]?.companyTime} anos)</span></p>
                                    </div>
                                    <div className="h-14 w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-xl font-semibold">4º</p>
                                            <p className="text-xl text-gray-400 ml-3"><FiAward /></p>
                                        </div>
                                        <p className="text-sm">{data?.companyTime[3]?.employeeName.length > 25 ? `${data?.companyTime[3]?.employeeName?.slice(0, 25)}...` : data?.companyTime[3]?.employeeName} <span className="font-semibold">({data?.companyTime[3]?.companyTime} anos)</span></p>
                                    </div>
                                    <div className="h-14 w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-xl font-semibold">5º</p>
                                            <p className="text-xl text-gray-400 ml-3"><FiAward /></p>
                                        </div>
                                        <p className="text-sm">{data?.companyTime[4]?.employeeName.length > 25 ? `${data?.companyTime[4]?.employeeName?.slice(0, 25)}...` : data?.companyTime[4]?.employeeName} <span className="font-semibold">({data?.companyTime[4]?.companyTime} anos)</span></p>
                                    </div>
                                </div>
                                {/* <div className="flex flex-col w-96 h-full gap-8 bg-blue-500 items-center justify-start">
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                }
                {
                    loadingSexAndPosition ?
                        <div className="flex flex-row w-full gap-3 h-full">
                            <div className="py-6 w-[30%] h-full border shadow-borderShadow rounded-lg flex justify-center items-center bg-white">
                                <div className="h-72 w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                            </div>
                            <div className="py-6 w-[70%] h-full border shadow-borderShadow rounded-lg flex justify-center items-center bg-white">
                                <div className="h-72 w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                            </div>
                        </div>
                        :
                        <div className={`flex flex-row w-full gap-3`}>
                            <div className={`py-6 w-[30%] h-full shadow-borderShadow rounded-lg bg-white`}>
                                <div className="h-full w-full justify-center items-center">
                                    <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2 flex flex-row items-center justify-around">
                                        Colaboradores por gênero
                                        <Tippy
                                            content={<a>Exportar</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={80}>
                                            <div>
                                                <FaCloudDownloadAlt className="text-lg cursor-pointer" onClick={() => exportGendersExcel()}/>
                                            </div>
                                        </Tippy>                                                        
                                    </p>
                                    <p></p>
                                    <SimplePie
                                        data={quantityGender}
                                        id={'dashGender'}
                                    />
                                </div>
                            </div>
                            <div className="py-6 w-[70%] h-full shadow-borderShadow rounded-lg bg-white">
                                <div className="flex flex-row justify-between px-10">
                                    <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">Quantidade colaboradores por cargo</p>
                                    <button className="p-1 h-7 w-20 rounded-md text-white font-semibold bg-primaryDefaultLight hover:bg-primaryDefaultLightHover duration-200"
                                        onClick={() => { setDetailsTemp('quantityPosition'); setModalDetails(true); setShow(true) }}
                                    >
                                        Ver mais
                                    </button>
                                </div>
                                <BarsHorizon
                                    data={positionQuantityPerEmployee.slice(0, 10)}
                                    id={'dashboardBarsHorizonDesktop2'}
                                    quantity={true}
                                />
                            </div>
                        </div>
                }
                {/* {
                    loadingSalaryLast ?
                    <div className="flex flex-row w-full gap-3 h-full">
                        <div className="py-6 w-full h-full border shadow-borderShadow rounded-lg flex justify-center items-center bg-white">
                            <div className="h-72 w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                        </div>
                    </div>
                    :
                    <div className="flex flex-row w-full gap-3">
                        <div className="py-6 w-full shadow-borderShadow rounded-lg bg-white">
                            <div className="w-full lg:max-h-72 h-fit flex flex-col justify-start p-2">
                                <div className="flex flex-row items-center justify-between px-2">
                                    <p className="flex flex-row items-center justify-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-3">
                                        Colaboradores sem aumento de salário
                                        <Tippy
                                            content={'CCT/Dissídio não estão listados'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={80}>
                                            <div>
                                                <FaInfoCircle className="text-sm text-blue-900 ml-2 cursor-help" />
                                            </div>
                                        </Tippy>
                                    </p>
                                    <div className="flex flex-row items-center pb-3">
                                        <p className="flex flex-row text-sm mr-2">Desde</p>
                                        <select name="select" className="w-20 rounded-sm p-1 h-7" onChange={(e) => setSelectedYear(e?.target?.value)}>
                                            {
                                                yearOptions?.map(year => {
                                                    return (
                                                        <option value={year} selected={selectedYear == year ? 'selected' : ''}>{year}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <FaSearch className="text-sm flex flex-row items-center ml-2 cursor-pointer text-primaryDefaultLight" onClick={() => getLastIncreaseSalary()}/>
                                    </div>
                                </div>
                                <div className="h-62 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                                    <table className="border border-primaryDefaultLight table-auto w-full p-1">
                                        <tr className="border border-gray-300 bg-gray-50">
                                            <th className="border border-gray-300 text-primaryDefaultLight">Posição</th>
                                            <th className="border border-gray-300 text-primaryDefaultLight">Nome</th>
                                            <th className="border border-gray-300 text-primaryDefaultLight">Salário</th>
                                            <th className="border border-gray-300 text-primaryDefaultLight">Alteração</th>
                                            <th className="border border-gray-300 text-primaryDefaultLight">Motivo</th>
                                        </tr>
                                        {
                                            dataLastSalary?.map((emp, index) => {
                                                return (
                                                    <tr className={`text-center ${index % 2 == 0 ? 'bg-gray-200' : 'bg-white'}`}>
                                                        <td className="border border-gray-300 p-1">{emp?.position}</td>
                                                        <td className="border border-gray-300 p-1">{emp?.nomfun}</td>
                                                        <td className="border border-gray-300 p-1">{emp?.valsal}</td>
                                                        <td className="border border-gray-300 p-1">{emp?.last_datalt}</td>
                                                        <td className="border border-gray-300 p-1">{emp?.nommot}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}
            </div>
            {/* mobile */}
            {
                loading ?
                <div className="flex sm:hidden w-full h-full justify-center items-center">
                    <Loader />
                </div>
                :
                <div className="ml-2 mt-20 h-full bg-gray-200 gap-3 md:hidden overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                    <div className="border bg-white w-full py-2 shadow-borderShadow rounded-lg flex-col items-center justify-start">
                        <div className="w-[95%] justify-center items-center m-auto">
                            <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">Top 10 - Média salarial de {selectedOption ? selectedOption?.name.toLowerCase() : ''}</p>
                            {
                                loadingBars ?
                                    <div className="w-[95%] h-96 m-auto items-center flex justify-center">
                                        <Loader />
                                    </div>
                                    :
                                    <BarsHorizon
                                        data={averagePosition.slice(0, 10)}
                                        id={'dashboardBarsHorizonMobile'}
                                    />
                            }
                        </div>
                        <div className="w-[95%] h-12 items-center p-2 m-auto flex justify-between">
                            <div className="w-96">
                                <LabelInput>
                                    <InputAutoComplete
                                        data={options}
                                        width={40}
                                        selectedLabel={'name'}
                                        optionList={['name']}
                                        value={selectedOption ? selectedOption?.name : ''}
                                        placeHolder={'Selecione uma opção'}
                                        onChange={(e) => { setSelectedOption(e); handleNewAverage(e) }}
                                    />
                                </LabelInput>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 border bg-white w-full py-2 shadow-borderShadow rounded-lg flex-col items-center justify-start">
                        <div className="h-9 w-full mt-3">
                            <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">Admissões e Demissões - {new Date().getFullYear()}</p>
                            <p></p>
                        </div>
                        <div>
                            <Spline
                                id={'dashboardSplineMobile'}
                                data={dataQuantity}
                            />
                        </div>
                    </div>
                    <div className="mt-4 gap-2 flex flex-col border w-full items-center justify-start">
                        <div className="flex gap-2 w-full flex-row items-center justify-center" >
                            <div className="flex-col w-[50%] h-full p-5 gap-2 shadow-borderShadow rounded-lg bg-white">
                                <p className="text-center 2xl:text-base sm:text-sm xl:text-lg font-thin h-14">Média salarial</p>
                                <p className="text-center xl:text-xl 2xl:text-2xl font-semibold text-titleBlackTextLight">R$ {data?.averageSalary?.toString().replace('.', ',')}</p>
                            </div>
                            <div className="flex-col w-[50%] h-full p-5 gap-2 shadow-borderShadow rounded-lg bg-white">
                                <p className="text-center 2xl:text-base sm:text-sm xl:text-lg font-thin h-14">Média idade</p>
                                <p className="text-center xl:text-xl 2xl:text-2xl font-semibold text-titleBlackTextLight">{data?.averageYear}</p>
                            </div>
                        </div>

                        <div className="flex gap-2 w-full flex-row items-center justify-center" >
                            <div className="flex-col w-[50%] h-full p-5 gap-2 shadow-borderShadow rounded-lg bg-white">
                                <p className="text-center 2xl:text-base sm:text-sm xl:text-lg font-thin h-14">Funcionários ativos</p>
                                <p className="text-center xl:text-xl 2xl:text-2xl font-semibold text-titleBlackTextLight">{data?.totalEmployeesActives}</p>
                            </div>
                            <div className="flex-col w-[50%] h-full p-5 gap-2 shadow-borderShadow rounded-lg bg-white">
                                <p className="text-center 2xl:text-base sm:text-sm xl:text-lg font-thin h-14">Quantidade admissão (mês)</p>
                                <p className="text-center xl:text-xl 2xl:text-2xl font-semibold text-titleBlackTextLight xl:mt-5 2xl:mt-0">{data?.quantityAdmission}</p>
                            </div>
                        </div>
                        <div className="flex gap-2 w-full flex-row items-center justify-center" >
                            <div className="flex-col w-[50%] h-full p-5 gap-2 shadow-borderShadow rounded-lg bg-white">
                                <p className="text-center 2xl:text-base sm:text-sm xl:text-lg font-thin h-14">Quantidade demissão (mês)</p>
                                <p className="text-center xl:text-xl 2xl:text-2xl font-semibold text-titleBlackTextLight xl:mt-5 2xl:mt-0">{data?.quantityDemission}</p>
                            </div>
                            <div className="flex-col w-[50%] h-full p-5 gap-2 shadow-borderShadow rounded-lg bg-white">
                                <p className="text-center 2xl:text-base sm:text-sm xl:text-lg font-thin h-14">Funcionários de férias</p>
                                <p className="text-center xl:text-xl 2xl:text-2xl font-semibold text-titleBlackTextLight xl:mt-5 2xl:mt-0">{data?.totalEmployeesHoliday}</p>
                            </div>
                        </div>
                        <div className="flex gap-2 w-full flex-row items-center justify-center" >
                            <div className={`flex bg-white shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2`}>
                                <p className="text-center 2xl:text-base sm:text-sm xl:text-lg font-thin h-14">Funcionários afastados</p>
                                <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight">{data?.totalEmployeesRemoval}</p>
                            </div>
                            <div className={`flex bg-white shadow-borderShadow flex-col w-[50%] h-full p-5 rounded-lg gap-2`}>
                                <p className="text-center 2xl:text-base sm:text-sm xl:text-lg font-thin h-14">Funcionários com estabilidade</p>
                                <p className="text-center xl:text-xl 2xl:text-xl font-semibold text-titleBlackTextLight">{data?.totalEmployessStability}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 border bg-white w-full py-2 shadow-borderShadow rounded-lg flex-col items-center justify-start">
                        <div className="h-full gap-1 w-full flex flex-col items-center justify-center overflow-hidden py-8">
                            <p className="text-base sm:text-sm xl:text-lg text-titleBlackTextLight font-semibold">Top 5 - Tempo de casa</p>
                            <div className="w-full h-full flex justify-center mt-3">
                                <div className="flex flex-col w-96 h-full gap-7 rounded-lg xl:pb-7 2xl:pb-0">
                                    <div className="w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-lg font-semibold">1º</p>
                                            <p className="text-xl text-orange-400 ml-3"><FaTrophy /></p>
                                        </div>
                                        <p className="text-sm font-light">{data?.companyTime[0]?.employeeName.length > 25 ? (data?.companyTime[0]?.employeeName)?.slice(0, 25) : (data?.companyTime[0]?.employeeName)} <span className="font-semibold">({data?.companyTime[0]?.companyTime} anos)</span></p>
                                    </div>
                                    <div className="w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-lg font-semibold">2º</p>
                                            <p className="text-xl text-gray-400 ml-3"><FaTrophy /></p>
                                        </div>
                                        <p className="text-sm font-light">{data?.companyTime[1]?.employeeName.length > 25 ? `${(data?.companyTime[1]?.employeeName)?.slice(0, 25)}...` : (data?.companyTime[1]?.employeeName)} <span className="font-semibold">({data?.companyTime[1]?.companyTime} anos)</span></p>
                                    </div>
                                    <div className="w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-lg font-semibold">3º</p>
                                            <p className="text-xl text-amber-700 ml-3"><FaTrophy /></p>
                                        </div>
                                        <p className="text-sm font-light">{data?.companyTime[2]?.employeeName.length > 25 ? `${(data?.companyTime[2]?.employeeName)?.slice(0, 25)}...` : (data?.companyTime[2]?.employeeName)} <span className="font-semibold">({data?.companyTime[2]?.companyTime} anos)</span></p>
                                    </div>
                                    <div className="w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-lg font-semibold">4º</p>
                                            <p className="text-xl text-gray-400 ml-3"><FiAward /></p>
                                        </div>
                                        <p className="text-sm font-light">{data?.companyTime[3]?.employeeName.length > 25 ? `${(data?.companyTime[3]?.employeeName)?.slice(0, 25)}...` : (data?.companyTime[3]?.employeeName)} <span className="font-semibold">({data?.companyTime[3]?.companyTime} anos)</span></p>
                                    </div>
                                    <div className="w-full flex flex-col items-center text-center">
                                        <div className="flex items-center">
                                            <p className="text-lg font-semibold">5º</p>
                                            <p className="text-xl text-gray-400 ml-3"><FiAward /></p>
                                        </div>
                                        <p className="text-sm font-light">{data?.companyTime[4]?.employeeName.length > 25 ? `${(data?.companyTime[4]?.employeeName)?.slice(0, 25)}...` : (data?.companyTime[4]?.employeeName)} <span className="font-semibold">({data?.companyTime[4]?.companyTime} anos)</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 border bg-white w-full py-2 shadow-borderShadow gap-2 rounded-lg flex-col items-center justify-start">
                        <div className="w-full lg:max-h-[40%] flex flex-col justify-start">
                            <div className="flex flex-col justify-center items-center w-full text-center gap-1">
                                <p className="text-2xl text-pink-600"><FaBirthdayCake /></p>
                                <p className="text-base sm:text-sm xl:text-lg font-semibold text-[#311962]">Aniversariantes do mês</p>
                            </div>
                            <div className="w-[90%] flex flex-col gap-3 lg:max-h-[32rem] h-full max-h-72 mt-2 shadow-inner rounded-lg m-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3">
                                {
                                    birthdayAndContracts?.birthdayInMonth.length > 0 ?
                                        birthdayAndContracts?.birthdayInMonth.map(person => {
                                            const splitDay = person.birthday.split('/')
                                            const formatDay = `${splitDay[0]}/${splitDay[1]}`
                                            return (
                                                <div className={`h-24 w-full rounded-lg flex flex-col justify-start text-center`}>
                                                    <p className="sm:text-base 2xl:text-base font-medium sm:mt-2 xl:mt-3 2xl:mt-2 xl:h-[90%] sm:h-[50%] 2xl:h-[50%] xl:text-sm">{(person?.employeeName)}</p>
                                                    <p className="text-base font-semibold mt-1 text-[#311962]">{formatDay}</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="flex flex-col items-center justify-center m-auto text-gray-400">
                                            <p className="text-base sm:text-sm xl:text-lg font-semibold">NÃO HÁ ANIVERSARIANTES ESSE MÊS</p>
                                            <p className="text-base sm:text-sm xl:text-lg"><FaBan /></p>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="w-full lg:max-h-[40%] flex flex-col justify-start h-full">
                            <div className="flex flex-col justify-center items-center w-full text-center gap-1 mt-3">
                                <p className="text-2xl text-orange-600"><FiAlertTriangle /></p>
                                <p className="text-base sm:text-sm xl:text-lg font-semibold text-[#311962]">Contratos a vencer (próximos 90 dias)</p>
                            </div>
                            <div className="w-[90%] flex flex-col gap-3 lg:max-h-[32rem] h-full max-h-72 mt-2 shadow-inner rounded-lg m-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3">
                                {
                                    birthdayAndContracts?.endContracts.length > 0 ?
                                        birthdayAndContracts?.endContracts.map(cont => {
                                            return (
                                                <div className={`h-24 w-full rounded-lg flex flex-col justify-start text-center`}>
                                                    <p className="sm:text-base 2xl:text-base font-medium sm:mt-2 xl:mt-3 2xl:mt-2 xl:h-[90%] sm:h-[50%] 2xl:h-[50%] xl:text-sm">{(cont?.employeeName)}</p>
                                                    <p className="text-base font-semibold mt-1 text-[#311962]">{cont?.usu_datini + ' - ' + cont?.usu_datfim}</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="flex flex-col items-center justify-center m-auto text-gray-400">
                                            <p className="text-base sm:text-sm xl:text-lg font-semibold">NÃO HÁ CONTRATOS</p>
                                            <p className="text-base sm:text-sm xl:text-lg"><FaBan /></p>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="w-full lg:max-h-[40%] flex flex-col justify-start h-full">
                            <div className="flex flex-col justify-center items-center w-full text-center gap-1 mt-3">
                                <p className="text-2xl text-green-600"><FaIdCard /></p>
                                <p className="text-base sm:text-sm xl:text-lg font-semibold text-[#311962]">Tempo de empresa (completando)</p>
                            </div>
                            <div className="w-[90%] flex flex-col gap-3 lg:max-h-[32rem] h-full max-h-72 mt-2 shadow-inner rounded-lg m-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3">
                                {
                                    companyBirthday?.length > 0 ?
                                        companyBirthday?.map(person => {
                                            return (
                                                <div className={`h-24 w-full rounded-lg flex flex-col justify-start text-center`}>
                                                    <p className="sm:text-base 2xl:text-base font-medium sm:mt-2 xl:mt-3 2xl:mt-2 xl:h-[90%] sm:h-[50%] 2xl:h-[50%] xl:text-sm">{person?.nomfun}</p>
                                                    <p className="text-base font-semibold mt-1 text-[#311962]">{person?.datadm} - {person?.willDo} anos</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="flex flex-col items-center justify-center m-auto text-gray-400">
                                            <p className="text-base sm:text-sm xl:text-lg font-semibold">NÃO HÁ COLABORADORES ESSE MÊS</p>
                                            <p className="text-base sm:text-sm xl:text-lg"><FaBan /></p>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="w-full lg:max-h-[40%] flex flex-col justify-start h-full">
                            <div className="flex flex-col justify-center items-center w-full text-center gap-1 mt-3">
                                <p className="text-2xl text-orange-600"><FiAlertTriangle /></p>
                                <p className="text-base sm:text-sm xl:text-lg font-semibold text-[#311962]">Férias a vencer (próximos 90 dias)</p>
                            </div>
                            <div className="w-[90%] flex flex-col gap-3 lg:max-h-[32rem] h-full max-h-72 mt-2 shadow-inner rounded-lg m-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3">
                                {
                                    endHolidays?.length > 0 ?
                                        endHolidays?.map(person => {
                                            return (
                                                <div className={`h-24 w-full rounded-lg flex flex-col justify-start text-center`}>
                                                    <p className="sm:text-base 2xl:text-base font-medium sm:mt-2 xl:mt-3 2xl:mt-2 xl:h-[90%] sm:h-[50%] 2xl:h-[50%] xl:text-sm">{person?.nomfun}</p>
                                                    <p className="text-base font-semibold mt-1 text-[#311962]">{person?.limite}</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="flex flex-col items-center justify-center m-auto text-gray-400">
                                            <p className="text-base sm:text-sm xl:text-lg font-semibold">NÃO HÁ COLABORADORES ESSE MÊS</p>
                                            <p className="text-base sm:text-sm xl:text-lg"><FaBan /></p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="border bg-white w-full mt-3 py-2 shadow-borderShadow rounded-lg flex-col items-center justify-start relative">
                        <div className="w-[95%] justify-center items-center m-auto">
                            <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2 flex flex-row items-center justify-around">
                                Colaboradores por gênero
                                <FaCloudDownloadAlt className="text-lg cursor-pointer" onClick={() => exportGendersExcel()}/>
                            </p>
                            <SimplePie
                                data={quantityGender}
                                width={'[90%]'}
                                id={'dashGenderMobile'}
                            />
                        </div>
                    </div>
                    <div className="border bg-white w-full mt-3 py-2 shadow-borderShadow rounded-lg flex-col items-center justify-start">
                        <div className="w-[95%] justify-center items-center m-auto">
                            <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">Quantidade de colaboradores por cargo</p>
                            <BarsHorizon
                                data={positionQuantityPerEmployee.slice(0, 10)}
                                id={'dashboardBarsHorizonMobile2'}
                                quantity={true}
                            />
                        </div>
                    </div>
                    {/* <div className="w-full max-h-96 h-fit flex flex-col justify-start p-2 mt-3">
                        <div className="flex flex-row items-center justify-between px-2">
                            <p className="flex flex-row items-center justify-center text-xs font-semibold text-titleBlackTextLight pb-3">
                                Sem aumento de salário
                                <Tippy
                                    content={'CCT/Dissídio não estão listados'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={80}>
                                    <div>
                                        <FaInfoCircle className="text-xs text-blue-900 cursor-help ml-1" />
                                    </div>
                                </Tippy>
                            </p>
                            <div className="flex flex-row items-center pb-3">
                                <p className="flex flex-row text-sm mr-2">Desde</p>
                                <select name="select" className="w-20 rounded-sm p-1 h-7" onChange={(e) => setSelectedYear(e?.target?.value)}>
                                    {
                                        yearOptions?.map(year => {
                                            return (
                                                <option value={year?.year} selected={selectedYear == year?.year ? 'selected' : ''}>{year?.year}</option>
                                            )
                                        })
                                    }
                                </select>
                                <FaSearch className="text-sm flex flex-row items-center ml-2 cursor-pointer text-primaryDefaultLight" onClick={() => getLastIncreaseSalary()}/>
                            </div>
                        </div>
                        <div className="h-96 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                            <table className="border border-primaryDefaultLight table-auto w-full">
                                <tr className="border border-gray-300 bg-gray-50">
                                    <th className="border border-gray-300 text-sm text-primaryDefaultLight">Posição</th>
                                    <th className="border border-gray-300 text-sm text-primaryDefaultLight">Nome</th>
                                    <th className="border border-gray-300 text-sm text-primaryDefaultLight">Salário</th>
                                    <th className="border border-gray-300 text-sm text-primaryDefaultLight">Alteração</th>
                                    <th className="border border-gray-300 text-sm text-primaryDefaultLight">Motivo</th>
                                </tr>
                                {
                                    dataLastSalary?.map((emp, index) => {
                                        return (
                                            <tr className={`text-center ${index % 2 == 0 ? 'bg-gray-200' : 'bg-white'}`}>
                                                <td className="border border-gray-300 text-sm">{emp?.position}</td>
                                                <td className="border border-gray-300 text-sm">{emp?.nomfun}</td>
                                                <td className="border border-gray-300 text-sm">{emp?.valsal}</td>
                                                <td className="border border-gray-300 text-sm">{emp?.last_datalt}</td>
                                                <td className="border border-gray-300 text-sm">{emp?.nommot}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                        </div>
                    </div> */}
                </div>
            }
        </>
    )

}