export const typeEventsList = [
    {
        id: 1,
        description: 'Horas Trabalhadas'
    },
    {
        id: 2,
        description: 'Horas Falta'
    },
    {
        id: 3,
        description: 'Horas Extra'
    },
    {
        id: 4,
        description: 'Variáveis'
    },
    {
        id: 5,
        description: 'Adicional Noturno'
    },
    {
        id: 6,
        description: 'Adiantamento 13º Salário'
    },
    {
        id: 7,
        description: 'Adiantamento Salarial'
    },
    {
        id: 8,
        description: 'Pagamento Integral'
    },
]