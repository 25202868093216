import React, { useState, useEffect } from 'react'
import { FaArrowDown } from 'react-icons/fa'

export function InputAutoComplete({ data, id, onChange, placeHolder, separator, selectedLabel, preSelectedValue, optionList, width, height, value, inactive, disabled, autoFocus, typeInactive, onBlur, existsLabel }) {

    const [showThisList, setShowThisList] = useState(null)
    const [showList, setShowList] = useState(false)
    const [selectedValue, setSelectedValue] = useState(value ? value : '')
    const [mouseHover, setMouseHover] = useState(false)
    const [cursor, setCursor] = useState(-1)
    const widthInput = width > 0 ? width : 96
    const heightList = height > 0 && height + 'px'

    async function handleSelectedValue(dataValue, selectedLabel) {
        
        setMouseHover(true)
        setSelectedValue(dataValue[selectedLabel])
        setShowThisList(null)
        onChange(dataValue)
        await new Promise(resolve => setTimeout(resolve, 300))
        setMouseHover(false)

    }

    function keyBoardNavigation(e) {

        let options = document.getElementById('user-select-options').children

        if (e.key === 'ArrowDown') {
            setCursor(c => (c < options.length - 1 ? c + 1 : c))
        }

        if (e.key === 'ArrowUp') {
            setCursor(c => (c > 0 ? c - 1 : 0))
        }

        if (e.key === 'Enter') {
            setSelectedValue(options[cursor].children[0].getAttribute('value'))
            onChange(JSON.parse(options[cursor].children[0].getAttribute('selectedData')))
            setShowThisList(null)
        }

        if (e.key === 'Escape') {
            setCursor(-1)
            setShowThisList(null)
        }
    }

    function handleSelectValueDefault(value) {

        const valueSelected = data.filter((dataValue) => {
            if (dataValue[selectedLabel] === value) {
                return dataValue
            }
        })
        onChange(valueSelected[0])
    }

    useEffect(() => {

        setSelectedValue(preSelectedValue)


    }, [preSelectedValue])

    useEffect(() => {

        const listOptions = document.getElementById('user-select-options')

        if (showThisList && listOptions) {
            listOptions.style.opacity = '1'
        } else if (listOptions) {
            listOptions.style.opacity = '0'
        }

    }, [showThisList])

    return (
        <>
            <div className='hidden sm:flex flex-col'>

                <input disabled={inactive || disabled ? true : false}
                    autoFocus={autoFocus ? true : false}
                    autoComplete="off" type='text'
                    onKeyDown={e => keyBoardNavigation(e)}
                    placeHolder={placeHolder ? placeHolder : ''}
                    id={id}
                    value={value ? value : selectedValue}
                    onFocus={() => { !mouseHover && setShowThisList(1); setShowList(true) }}
                    onClick={() => { !mouseHover && setShowThisList(1); setShowList(true) }}
                    onBlur={(e) => { !mouseHover && setShowThisList(null) }}
                    onChange={(e) => { !typeInactive && setSelectedValue(e.target.value); !typeInactive && onChange(e.target.value); !typeInactive && setShowList(false) }}
                    datavalue='text'
                    className={`pl-2 text-lg sm:text-sm border placeholder:text-black text-black border-gray-300 hover:border-gray-500 rounded-lg focus:bg-titleGrayTextLight dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark h-20 sm:h-8 outline-none focus:border focus:border-primaryDefaultLight dark:focus:border z-20 dark:focus:border-primaryDefaultLight w-${widthInput} ${inactive || disabled ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}`}>
                </input>
                <div className={`flex flex-row w-full items-center cursor-text pr-4 mr-3 absolute z-20 justify-end lg:justify-end h-20 sm:h-8  w-${widthInput}`}>
                    <FaArrowDown className={`${showThisList === 1 ? 'rotate-180 text-primaryDefaultLight' : 'rotate-0 text-titleBlackTextLight'} cursor-pointer lg:pr-0 text-lg sm:text-base transition-all duration-300`} size='10'></FaArrowDown>
                </div>
                {
                    showThisList === 1 &&
                    <ul id='user-select-options' style={{ maxHeight: heightList?.length > 0 ? heightList : '200px', overflowX: 'hidden' }} onMouseMove={() => { setMouseHover(true) }} onMouseLeave={() => { setMouseHover(false) }} className={`opacity-0 transition-all bg-white ${existsLabel && 'mt-5'} duration-300 flex z-30 bg-bgPrimaryLight rounded-lg boxShadow flex-col items-start absolute translate-y-20 sm:translate-y-9 w-${widthInput} overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        {
                            data.map((dataValue, index) => {
                                if (dataValue[selectedLabel]?.toString()?.toLowerCase()?.indexOf(selectedValue?.toString()?.toLowerCase()) > -1 || showList) {
                                    return <li key={index} className={`p-2 hover:bg-bgSecondaryLight dark:hover:bg-secondaryDefaultDark focus:bg-bgSecondaryLight cursor-pointer dark:focus:bg-secondaryDefaultDark ${cursor === index ? 'bg-bgSecondaryLight dark:bg-secondaryDefaultDark dark:text-titleGrayTextLight ' : 'bg-bgPrimaryLight dark:bg-thirdDefaultDark dark:text-titleGrayTextLight'} w-full transition-all duration-300`} onClick={() => handleSelectedValue(dataValue, selectedLabel)}>
                                        <a className='w-full sm:text-base text-2xl text-black' selectedData={JSON.stringify(dataValue)} value={dataValue[selectedLabel]}>{optionList?.length > 0 ? optionList.map((option, index) => {
                                            if (index < optionList.length - 1) {
                                                return separator !== undefined ?
                                                    dataValue[option] + separator : dataValue[option] + ' - '
                                            }
                                            return dataValue[option]
                                        }) : dataValue[selectedLabel]}</a>
                                    </li>
                                }
                            })
                        }
                    </ul>
                }
            </div>
            <div className='flex sm:hidden'>
                <select onChange={(e) => { setSelectedValue(e.target.value); handleSelectValueDefault(e.target.value); setShowList(false) }} className="bg-gray-50 border dark:bg-secondaryDefaultDark border-gray-300 dark:border-primaryBorderDark text-gray-900 dark:text-titleGrayTextDark h-10 text-sm outline-none focus:ring-blue-500 focus:border-primaryDefaultLight block w-[300px] sm:w-96 p-2.5">
                    {
                        !selectedValue &&
                        <option selected={true}>-Selecione-</option>
                    }
                    {data.map((dataValue, index) => {
                        return <option selected={!selectedValue ? false : selectedValue === dataValue[selectedLabel] ? selectedValue : false} >{dataValue[selectedLabel]}</option>
                    })}
                </select>
            </div>
        </>
    )
}
