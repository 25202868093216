import moment from "moment"

export function formattTimeToShow(apiTime) {
    try {
        let localTime = moment(apiTime).utc().format("DD/MM/YYYY HH:MM");
        // let localTime = moment.utc(time).utcOffset(offset).format("L LT");
        return localTime
    } catch (error) {
        return apiTime
    }
}