import React, { useContext, useState, createContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import api from '../services/api';

export const CompanyBranchContext = createContext({});

export const CompanyBranchProvider = ({ children }) => {

    const [ company, setCompany ] = useState(null);
    const [ branch, setBranch ] = useState(null);
    const [ companyName, setCompanyName ] = useState('');
    const [ branchCnpj, setBranchCnpj ] = useState('');
    const [companyParams, setCompanyParams] = useState({})
    
    const token = localStorage.getItem('@auth:token');

    useEffect(() => {  

        const sessionData = JSON.parse(localStorage.getItem('@session:data'))
        setCompany(sessionData?.company ? sessionData?.company : 0)
        setBranch(sessionData?.branch ? sessionData?.branch : 0)
        setCompanyName(sessionData?.companyName ? sessionData?.companyName : '')
        setBranchCnpj(sessionData?.branchCnpj ? sessionData?.branchCnpj : '')
    }, [])

    useEffect(() => {
        if (company && branch) {
            getParams()
            const sessionData = {
                company,
                companyName,
                branch,
                branchCnpj
            }
            localStorage.setItem('@session:data', JSON.stringify(sessionData));
        }
    }, [company, branch])

    async function getParams(){
        try {
            const getConfigCompanyAPI = await api.get(`api/v1/companies/getCompanyConfig/company/${company}?branch=${branch}`, {
                headers: {
                    Authorization: token
                }
            })
            setCompanyParams(getConfigCompanyAPI?.data?.data[0])
        } catch(error){
            console.log('Erro ao buscar configurações da empresa')
        }
    }

    return (
        <CompanyBranchContext.Provider value={{ 
            company, 
            setCompany, 
            branch, 
            setBranch,
            companyName,
            setCompanyName,
            branchCnpj,
            setBranchCnpj,
            companyParams,
            setCompanyParams
        }}>
            { children }
        </CompanyBranchContext.Provider>
    )

}

export function useCompanyBranch(){
    return useContext(CompanyBranchContext)
}