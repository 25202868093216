/**
 * Desenvolvedor: Tiago Murilo Ochôa da Luz
 * Descrição: Conexão com o backend.
 */
import axios from 'axios';

const api = axios.create({
    baseURL: 'https://folpagconnect.we4u.net.br',
    //baseURL: 'http://localhost:8081/'
});

export default api;