import React, { useEffect, useState } from 'react'

export function LabelInput({ text, required, children, flexRow, error }) {
    
    const [fieldError, setFieldError] = useState(false)

    useEffect(()=> {
        setFieldError(error)
    },[error])

    return (
        <label className={`flex ${flexRow ? 'flex-row-reverse' : 'flex-col'} relative gap-1 justify-start`}>
            <a className={`tex-lg sm:text-sm  ${fieldError ? 'text-red-700' : 'text-inputPlaceholderLight' } `}>{text} {required ? '*' : ''}</a>
            {children}
        </label>
    )
}