import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiTrash2, FiLink, FiPlus, FiChevronRight, FiCheck, FiMinus, FiCheckCircle, FiSend, FiArrowUp, FiArrowDown, FiTrash, FiX, FiEdit } from "react-icons/fi";
import { RiArrowUpDownLine } from "react-icons/ri";
import api from "../../services/api";
import { useAuth } from "../../hooks/useAuth";
import Modal from "../../components/Modal/modal";
import { useModal } from "../../hooks/useModal";
import Loader from "../../components/Loader/loader";
// import "./users.css";
import { useMenu } from "../../hooks/useMenu";
import { capitalize } from '../../utils/formatText/capitalize'
import { Button } from "../../components/buttons/button.default";
import { TableDefault } from "../../components/table/table.default";
import { LabelInput } from "../../components/label/label.input";
import { Input } from "../../components/input/input";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { TableMobile } from "../../components/table/table.mobile";

const Users = () => {
    const token = localStorage.getItem("@auth:token");

    const { show, setShow } = useModal();
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const { user } = useAuth()
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const [fullname, setFullName] = useState("");
    const [username, setUsername] = useState("");
    const [selectedAccess, setSelectedAccess] = useState(0);
    const [selectedCanAttach, setSelectedCanAttach] = useState('');
    const [selectedHasManagerAccess, setSelectedHasManagerAccess] = useState('');
    const [filteredValue, setFilteredValue] = useState("")
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(true);
    const [apiAction, setApiAction] = useState(false);
    const [filterTypeAccessClient, setFilterTypeAccessClient] = useState(true);
    const [filterTypeAccessRH, setFilterTypeAccessRH] = useState(true);
    const [filterTypeAccessAdmin, setFilterTypeAccessAdmin] = useState(true);
    const navigate = useNavigate();
    const typeAccess = ["", "Admin", "Folpag", "Cliente", "C. Admissão"];
    const { setMenuTitle } = useMenu();
    setMenuTitle("Cadastro de Usuários");
    const [buttonRemove, setButtonRemove] = useState()
    const [buttonEdit, setButtonEdit] = useState()
    const [userTemp, setUserTemp] = useState()
    const [buttonActive, setButtonActive] = useState()
    const [nameDescriptionEdit, setNameDescriptionEdit] = useState('')
    const [quantity, setQuantity] = useState('')
    const [page, setPage] = useState(1)
    const [usersShow, setUsersShow] = useState([])

    useEffect(() => {
        setApiAction(false);
        setLoading(true);

        const filter = []

        filterTypeAccessClient ? filter.push(3) && filter.push(4) : null
        filterTypeAccessRH ? filter.push(2) : null
        filterTypeAccessAdmin ? filter.push(1) : null

        api.get(`/api/v1/users/registeredUsers?type_access=${filter.join(',')}`, {
            headers: {
                Authorization: token,
            },
        }).then((response) => {
            setRegisteredUsers(response.data.map((item) => {
                return {
                    ...item,
                    fullname: item.fullname ? capitalize(item.fullname) : '',
                    access: item.access ? typeAccess[item.access] : '',
                    active: item.active ? <FiCheck className="ml-[50%] text-green-700" /> : <FiMinus className="ml-[50%] text-red-800" />,
                    resend: item.access == 1 ? <span className="text-red-800">-</span> :
                        <Tippy content={<span>Reenviar dados de acesso</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiSend className="cursor-pointer ml-[50%] text-blue-600" onClick={() => resendAccessData(item.id)} />
                            </div>
                        </Tippy>,
                    link:
                        <Tippy content={<span>Vincular Cliente</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiLink className="cursor-pointer ml-[50%] text-black" onClick={() => linkClick(item)} />
                            </div>
                        </Tippy>,
                    edit:
                        <Tippy content={<span>Editar Usuário</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiEdit className="cursor-pointer ml-[50%] text-blue-800" onClick={() => { setUserTemp(item); setShow(true); setButtonEdit(true); setNameDescriptionEdit(item?.fullname) }} />
                            </div>
                        </Tippy>,
                    remove: item.active ?
                        <Tippy content={<span>Excluir</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiTrash2 className="cursor-pointer ml-[50%] text-red-800" onClick={() => { setUserTemp(item); setShow(true); setButtonRemove(true) }} />
                            </div>
                        </Tippy>
                        :
                        <Tippy content={<span>Ativar</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiCheckCircle className="cursor-pointer ml-[50%] text-green-700" onClick={() => { setUserTemp(item); setShow(true); setButtonActive(true) }} />
                            </div>
                        </Tippy>,
                    functions: item.active ?
                        [
                            {
                                func: <FiSend onClick={() => resendAccessData(item.id)} />
                            },
                            {
                                func: <FiEdit onClick={() => { setUserTemp(item); setShow(true); setButtonEdit(true); setNameDescriptionEdit(item?.fullname) }} />
                            },
                            {
                                func: <FiTrash2 onClick={() => { setUserTemp(item); setShow(true); setButtonRemove(true) }} />
                            }
                        ]
                        :
                        [
                            {
                                func: <FiSend onClick={() => resendAccessData(item.id)} />
                            },
                            {
                                func: <FiEdit onClick={() => { setUserTemp(item); setShow(true); setButtonEdit(true); setNameDescriptionEdit(item?.fullname) }} />
                            },
                            {
                                func: <FiCheckCircle onClick={() => { setUserTemp(item); setShow(true); setButtonActive(true) }} />
                            }
                        ]
                }
            }));
            const quantityPages = Math.round(response.data.length / 40)
            setQuantity(quantityPages)
            setLoading(false);
        });
    }, [token, apiAction, filterTypeAccessClient, filterTypeAccessRH, filterTypeAccessAdmin]);

    useEffect(() => {
        if (!show) {
            setButtonRemove(false)
            setButtonEdit(false)
            setButtonActive(false)
            setUserTemp()
            setNameDescriptionEdit('')
            setFullName('')
            setEmail('')
            setUsername('')
            setSelectedAccess('')
            setSelectedCanAttach('')
            setSelectedHasManagerAccess('')
        }
    }, [show])

    useEffect(() => {

        if (registeredUsers.length > 0) {
            const finish = page * 40 - 1
            const initial = finish - 39

            const selectedPeriods = registeredUsers.map((period, index) => {
                if (index >= initial && index <= finish) {
                    return period
                }
            }).filter(period => period)

            setUsersShow(selectedPeriods)
        }
        setLoading(false)

    }, [quantity, page, filterTypeAccessClient, filterTypeAccessRH, filterTypeAccessAdmin])

    async function handleSubmit(e) {
        e.preventDefault();

        if (selectedAccess === 0 || fullname === "" || username === "" || email === "") {
            return alert("Para cadastrar você precisá preencher todos os campos.");
        } else {
            try {
                await api.post("/api/v1/users/registeredUsers",
                    {
                        fullname: fullname,
                        username: username,
                        type: selectedAccess,
                        email: email,
                        canAttach: selectedCanAttach ? selectedCanAttach : 0,
                        hasManagerAccess: selectedHasManagerAccess ? selectedHasManagerAccess : 0
                    },
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                );
                setApiAction(true);
                setShow(false);
                setShowNotificationModal(true)
                return setNotificationModalText('Sucesso ao realizar cadastro de usuário')
            } catch (error) {
                return alert(error?.response?.data?.message || 'Erro interno, entre em contato com o suporte.');
            }
        }
    }

    async function handleEdit(e) {
        e.preventDefault();

        try {
            await api.put(`/api/v1/users/${userTemp?.id}`,
                {
                    fullname: userTemp?.fullname ? userTemp?.fullname : undefined,
                    username: userTemp?.username ? userTemp?.username : undefined,
                    type: userTemp?.access ? userTemp?.access : undefined,
                    email: userTemp?.email ? userTemp?.email : undefined,
                    canAttach: userTemp?.canAttach ? 1 : userTemp?.canAttach == 0 ? 0 : undefined,
                    hasManagerAccess: userTemp?.hasManagerAccess ? 1 : userTemp?.hasManagerAccess == 0 ? 0 : undefined
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            setApiAction(true);
            setShow(false);
            setLoading(false)
            setShowNotificationModal(true)
            return setNotificationModalText('Sucesso ao realizar edição no cadastro do usuário')
        } catch (error) {
            return alert(error?.response?.data?.message || 'Erro interno, entre em contato com o suporte.');
        }

    }

    async function handleDelete(element) {
        if (element.id == user.type) {
            return alert("Não é possível executar está ação.");
        }
        try {
            const responseDelete = await api.delete(
                `/api/v1/users/registeredUsers/${element.id}`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            setApiAction(true);
            setShowNotificationModal(true)
            return setNotificationModalText(responseDelete.data)
        } catch (error) {
            return alert("Erro ao tentar excluir o usuário. Se o erro persistir, entre em contato com o suporte.");
        }
    }

    async function handleActive(element) {
        try {
            const responseDelete = await api.put(
                `/api/v1/users/registeredUsers/${element.id}/active`, {}, {
                headers: {
                    Authorization: token
                }
            }
            );
            setApiAction(true);
            setShowNotificationModal(true)
            return setNotificationModalText(responseDelete.data)
        } catch (error) {
            return alert("Erro ao tentar ativar o usuário. Se o erro persistir, entre em contato com o suporte.");
        }
    }

    function linkClick(person) {
        return navigate(
            `/users/${person.id}/${person.username}/${person.fullname}`
        );
    }

    function getfilteredValue() {
        if (!filteredValue) {
            return registeredUsers;
        }

        return registeredUsers.filter((ruser) => {
            return (
                ruser.fullname.toLowerCase().indexOf(filteredValue.toLowerCase()) > -1 ||
                ruser.username.toLowerCase().indexOf(filteredValue.toLowerCase()) > -1 ||
                ruser.email.toLowerCase().indexOf(filteredValue.toLowerCase()) > -1 ||
                typeAccess[ruser.access].toLowerCase().indexOf(filteredValue.toLowerCase()) > -1
            );
        });
    }

    async function resendAccessData(id) {

        try {

            if (confirm('A senha deste usuário será gerada automaticamente pelo sistema. Você realmente deseja reenviar os dados de acesso?')) {

                await api.put(`/api/v1/users/passwordResend/${id}`, {}, {
                    headers: {
                        Authorization: token
                    }
                })

                alert('Sucesso ao realizar envio dos dados de acesso e geração automatica de senha.')

            }

        } catch (error) {
            return alert('Erro ao tentar reenviar nova senha.')
        }

    }

    const tableThead = [
        {
            "name": "Código",
            "original_name": "id"
        },
        {
            "name": "Email",
            "original_name": "email"
        },
        {
            "name": "Nome",
            "original_name": "fullname"
        },
        {
            "name": "Usuário",
            "original_name": "username"
        },
        {
            "name": "Tipo",
            "original_name": "access"
        },
        {
            "name": "Status",
            "original_name": "active"
        },
        {
            "name": "Reenviar",
            "original_name": "resend"
        },
        {
            "name": "Vincular",
            "original_name": "link"
        },
        {
            "name": "Editar",
            "original_name": "edit"
        },
        {
            "name": "Excluir",
            "original_name": "remove"
        }
    ]

    const permission = [
        {
            "value": 1,
            "name": "Administrador"
        },
        {
            "value": 2,
            "name": "Folpag - RH"
        },
        {
            "value": 3,
            "name": "Cliente"
        },
        {
            "value": 4,
            "name": "Cliente - Admissão"
        }
    ]

    const permissionAttach = [
        {
            id: 0,
            value: 0,
            description: 'Não Permitir'
        },
        {
            id: 1,
            value: 1,
            description: 'Permitir'
        }
    ]

    return (
        <>
            <Modal>
                {
                    show && buttonRemove ?
                    <Modal>
                        <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                            <div className='flex justify-between pl-3 py-5 items-center'>
                                <p className='flex flex-row font-extrabold items-center text-lg'>
                                    <p className='mr-7'>Deseja realmente excluir esse usuário?</p>
                                </p>
                                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                            </div>
                            <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                <Button background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                                <Button background={'bg-green-800'} type={'button'} onClick={() => { setShow(false); handleDelete(userTemp) }}>Confirmar</Button>
                            </div>
                        </div>
                    </Modal>
                    : show && buttonActive ?
                    <Modal>
                        <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                            <div className='flex justify-between pl-3 py-5 items-center'>
                                <p className='flex flex-row font-extrabold items-center text-lg'>
                                    <p className='mr-7'>Deseja realmente ativar esse usuário?</p>
                                </p>
                                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                            </div>
                            <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                <Button background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                                <Button background={'bg-green-800'} type={'button'} onClick={() => { setShow(false); handleActive(userTemp) }}>Confirmar</Button>
                            </div>
                        </div>
                    </Modal>
                    : show && buttonEdit ?
                    <div className={`p-2 flex flex-col justify-center items-center overflow-auto`}>
                        <p className='flex flex-row font-extrabold text-primaryDefaultLight text-lg items-center '>
                            <FiChevronRight /> Edição no cadastro do usuário {nameDescriptionEdit.split(' ')[0]}
                        </p>
                        <form onSubmit={handleEdit} autocomplete='off' className='flex flex-col gap-2 h-full w-full p-5'>
                            <LabelInput text={'Nome Completo'}>
                                <Input
                                    value={userTemp?.fullname ? userTemp?.fullname : ''}
                                    onChange={(e) => setUserTemp(prev => { return { ...prev, fullname: e.target.value } })}
                                    width={50}
                                />
                            </LabelInput>
                            <LabelInput text={'Email'}>
                                <Input
                                    value={userTemp?.email ? userTemp?.email : ''}
                                    onChange={(e) => setUserTemp(prev => { return { ...prev, email: e.target.value } })}
                                    width={50}
                                />
                            </LabelInput>
                            <LabelInput text={'Usuário'}>
                                <Input
                                    value={userTemp?.username ? userTemp?.username : ''}
                                    onChange={(e) => setUserTemp(prev => { return { ...prev, username: e.target.value } })}
                                    width={50}
                                />
                            </LabelInput>
                            <LabelInput text={'Permissão'}>
                                <InputAutoComplete
                                    data={permission}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    existsLabel={true}
                                    preSelectedValue={userTemp?.access ? permission.filter(e => { return e?.value == userTemp?.access })[0]?.name : ''}
                                    value={permission.filter(e => { return e?.value == userTemp?.access })[0]?.name}
                                    onChange={(e) => {
                                        if (typeof (e) == 'object') {
                                            if ([1, 2].includes(e.value)) {
                                                setUserTemp(prev => { return { ...prev, canAttach: null } })
                                            }
                                            setUserTemp(prev => { return { ...prev, access: e.value } })
                                        }
                                    }}
                                    height={72}
                                    width={80}
                                />
                            </LabelInput>
                            {
                                [3, 4].includes(userTemp?.access) &&
                                <LabelInput text={'Permissão arquivos'}>
                                    <InputAutoComplete
                                        data={permissionAttach}
                                        existsLabel={true}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        preSelectedValue={userTemp?.canAttach !== 0 ? permissionAttach.filter(e => { return e?.value == 1 })[0]?.description : permissionAttach[0].description}
                                        value={permissionAttach.filter(e => { return e?.value == userTemp?.canAttach })[0]?.description}
                                        onChange={(e) => {
                                            if (typeof (e) == 'object') {
                                                setUserTemp(prev => { return { ...prev, canAttach: e.value } })
                                            }
                                        }}
                                        width={80}
                                    />
                                </LabelInput>
                            }
                            {
                                ![1, 2, 4].includes(userTemp?.access) &&
                                <LabelInput text={'Permissão Gerencial'}>
                                    <InputAutoComplete
                                        data={permissionAttach}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        existsLabel={true}
                                        preSelectedValue={userTemp?.hasManagerAccess !== 0 ? permissionAttach.filter(e => { return e?.value == 1 })[0]?.description : permissionAttach[0].description}
                                        value={permissionAttach.filter(e => { return e?.value == userTemp?.hasManagerAccess })[0]?.description}
                                        onChange={(e) => {
                                            if (typeof (e) == 'object') {
                                                setUserTemp(prev => { return { ...prev, hasManagerAccess: e.value } })
                                            }
                                        }}
                                        width={80}
                                    />
                                </LabelInput>
                            }
                            <div className={`flex flex-col justify-center items-center mt-3 pb-5 lg:pb-0`}>
                                <Button>Editar Usuário</Button>
                            </div>
                        </form>
                    </div>
                    : show ?
                    <div className={`p-2 flex flex-col justify-center items-center overflow-auto`}>
                        <p className='flex flex-row font-extrabold text-primaryDefaultLight text-lg items-center '>
                            <FiChevronRight /> Efetue o cadastro de usuário abaixo.
                        </p>
                        <form onSubmit={handleSubmit} autocomplete='off' className='flex flex-col gap-2 h-full w-full p-5'>
                            <LabelInput text={'Nome Completo'}>
                                <Input
                                    value={fullname ? fullname : ''}
                                    onChange={(e) => setFullName(e.target.value)}
                                    width={50}
                                />
                            </LabelInput>
                            <LabelInput text={'Email'}>
                                <Input
                                    value={email ? email : ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    width={50}
                                />
                            </LabelInput>
                            <LabelInput text={'Usuário'}>
                                <Input
                                    value={username ? username : ''}
                                    onChange={(e) => setUsername(e.target.value)}
                                    width={50}
                                />
                            </LabelInput>
                            <LabelInput text={'Permissão'}>
                                <InputAutoComplete
                                    data={permission}
                                    existsLabel={true}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    preSelectedValue={selectedAccess ? selectedAccess.name : ''}
                                    value={selectedAccess.name}
                                    onChange={(e) => {
                                        if (typeof (e) == 'object') {
                                            setSelectedAccess(e.value)
                                        }
                                    }}
                                    height={96}
                                    width={80}
                                />
                            </LabelInput>
                            {
                                [3, 4].includes(selectedAccess) &&
                                <LabelInput text={'Permissão arquivos'}>
                                    <InputAutoComplete
                                        data={permissionAttach}
                                        existsLabel={true}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        preSelectedValue={selectedCanAttach ? selectedCanAttach?.description : selectedCanAttach?.description}
                                        value={selectedCanAttach?.description}
                                        onChange={(e) => {
                                            if (typeof (e) == 'object') {
                                                setSelectedCanAttach(e.value)
                                            }
                                        }}
                                        width={80}
                                    />
                                </LabelInput>
                            }
                            {
                                [2, 3].includes(selectedAccess) &&
                                <LabelInput text={'Permissão Gerencial'}>
                                    <InputAutoComplete
                                        data={permissionAttach}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        existsLabel={true}
                                        preSelectedValue={selectedCanAttach ? selectedCanAttach?.description : selectedCanAttach?.description}
                                        value={selectedHasManagerAccess?.description}
                                        onChange={(e) => {
                                            if (typeof (e) == 'object') {
                                                setSelectedHasManagerAccess(e.value)
                                            }
                                        }}
                                        width={80}
                                    />
                                </LabelInput>
                            }
                            <div className='flex justify-center items-center mt-5 pb-5 gap-5 lg:pb-0'>
                                <Button background={'bg-red-800'} onClick={() => setShow(false)}>Cancelar</Button>
                                <Button>Cadastrar</Button>
                            </div>
                        </form>
                    </div>
                    :
                    <></>
                }
            </Modal>
            <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
                {
                    loading ?
                        <Loader />
                        : (
                            <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                                <div className="hidden sm:flex flex-col justify-start align-center ml-20 w-[100%] h-[92vh] mr-8 gap-5">
                                    <div className='flex flex-row justify-between'>
                                        <div className='flex flex-row items-center justify-start gap-1'>
                                            <button
                                                onClick={() => setFilterTypeAccessClient(!filterTypeAccessClient)}
                                                className={`font-extrabold shadow-md shadow-gray-400 text-primaryDefaultLight 
                                                    h-12 md:h-6 w-full md:w-28 flex flex-col duration-300 items-center justify-center 
                                                    ${filterTypeAccessClient ? 'bg-primaryDefaultLight text-secondaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight' :
                                                    'bg-[#ececec] text-primaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight'}`}
                                            >
                                                Cliente
                                            </button>
                                            <button
                                                onClick={() => setFilterTypeAccessRH(!filterTypeAccessRH)}
                                                className={`font-extrabold shadow-md shadow-gray-400 text-primaryDefaultLight h-12 md:h-6 w-full 
                                                    md:w-28 flex flex-col duration-300 items-center justify-center 
                                                    ${filterTypeAccessRH ? 'bg-primaryDefaultLight text-secondaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight' :
                                                    'bg-[#ececec] text-primaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight'}`}
                                            >
                                                Folpag
                                            </button>
                                            <button
                                                onClick={() => setFilterTypeAccessAdmin(!filterTypeAccessAdmin)}
                                                className={`font-extrabold shadow-md shadow-gray-400 text-primaryDefaultLight h-12 md:h-6 
                                                    w-full md:w-28 flex flex-col duration-300 items-center justify-center 
                                                    ${filterTypeAccessAdmin ? 'bg-primaryDefaultLight text-secondaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight' :
                                                    'bg-[#ececec] text-primaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight'}`}
                                            >
                                                Admin
                                            </button>
                                        </div>
                                        <div className='flex justify-end'>
                                            <Button
                                                onClick={() => { setShow(true); setUsername('') }}
                                                className="flex flex-row justify-center bg-primaryDefaultLight w-24 h-10 text-secondaryDefaultLight font-semibold"
                                            > + Novo
                                            </Button>
                                        </div>
                                    </div>
                                    <TableDefault
                                        title={tableThead}
                                        data={registeredUsers}
                                        quantity={registeredUsers.length}
                                        collumns={["id", "email", "fullname", "username", "access", "active", "resend", "link", "edit", "remove"]}
                                    />
                                </div>
                                {/* Mobile */}
                                <div className='relative bg-white flex lg:hidden flex-col w-full px-3 h-[84vh] items-start justify-start boxShadow overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500'>
                                    <div className='flex flex-row justify-between w-full'>
                                        <div className='flex flex-row items-center gap-1'>
                                            <button
                                                onClick={() => setFilterTypeAccessClient(!filterTypeAccessClient)}
                                                className={`font-extrabold shadow-md shadow-gray-400 text-primaryDefaultLight 
                                    h-12 w-16 flex flex-col duration-300 items-center justify-center 
                                    ${filterTypeAccessClient ? 'bg-primaryDefaultLight text-secondaryDefaultLight' :
                                                        'bg-[#ececec] text-primaryDefaultLight'}`}
                                            >
                                                Cliente
                                            </button>
                                            <button
                                                onClick={() => setFilterTypeAccessRH(!filterTypeAccessRH)}
                                                className={`font-extrabold shadow-md shadow-gray-400 text-primaryDefaultLight h-12 w-16 
                                    flex flex-col duration-300 items-center justify-center 
                                    ${filterTypeAccessRH ? 'bg-primaryDefaultLight text-secondaryDefaultLight' :
                                                        'bg-[#ececec] text-primaryDefaultLight'}`}
                                            >
                                                Folpag
                                            </button>
                                            <button
                                                onClick={() => setFilterTypeAccessAdmin(!filterTypeAccessAdmin)}
                                                className={`font-extrabold shadow-md shadow-gray-400 text-primaryDefaultLight h-12 
                                    w-16 flex flex-col duration-300 items-center justify-center 
                                    ${filterTypeAccessAdmin ? 'bg-primaryDefaultLight text-secondaryDefaultLight' :
                                                        'bg-[#ececec] text-primaryDefaultLight'}`}
                                            >
                                                Admin
                                            </button>
                                        </div>
                                        <div className='flex flex-row'>
                                            <Button
                                                onClick={() => { setShow(true); setUsername('') }}
                                                className="flex flex-row justify-center bg-primaryDefaultLight w-24 h-10 text-secondaryDefaultLight font-semibold"
                                            > + Novo
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="mt-7 w-full flex flex-row gap-2">
                                        {
                                            Array.apply(null, Array(quantity)).map((item, index) => {

                                                const button = <button
                                                    className={`border flex items-center p-3 text-white duration-200 font-medium ${index + 1 == page ? 'bg-primaryDefaultLight' : 'bg-white text-black'}`}
                                                    onClick={() => setPage(index + 1)}
                                                >{index + 1}</button>
                                                let existsPoints = false
                                                let showPointers = false

                                                if (quantity < 5) {
                                                    return button
                                                } else {
                                                    existsPoints = true
                                                    if (index == 0 || index + 1 == quantity) {
                                                        return button
                                                    } else if (page + 2 > index + 1 && page - 2 < index + 1) {
                                                        return button
                                                    } else {
                                                        if (page + 2 < index + 1 || page - 2 > index + 1) {
                                                            showPointers = true
                                                        }
                                                        if (existsPoints && showPointers == false) {
                                                            return <a className='flex items-center'>...</a>
                                                        }
                                                    }
                                                }

                                            })
                                        }
                                    </div>
                                    {
                                        usersShow.length > 0 ?
                                            <div className="mt-10 w-full">
                                                <TableMobile
                                                    title={tableThead}
                                                    data={usersShow}
                                                    collumns={["id", "email", "fullname", "username", "access", "active"]}
                                                />
                                            </div>
                                            :
                                            <div className="flex items-center justify-center m-auto">
                                                <Loader />
                                            </div>
                                    }
                                </div>
                            </div>
                        )
                }
            </div>
        </>
    )
};

export default Users;
