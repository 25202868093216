import React, { useContext, useEffect, useState } from 'react';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu' 
import './body.css'
import {FaCog, FaExchangeAlt} from 'react-icons/fa'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { Button } from '../buttons/button.default';
import { FiX } from 'react-icons/fi';
import { InputAutoComplete } from '../input/input.autocomplete';
import api from '../../services/api';
import { LabelInput } from '../label/label.input';
import {LoadingPulse} from '../Loader/loadingPulse'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Logo from '../../images/logo_connect.png'
import ReactQuill from 'react-quill';
import { Quillmodules } from '../richText/quill.modules';

const Body = ({children}) => {

    const { menuStatus, setShowProfile, setMenuStatus} = useMenu();
    const navigate = useNavigate()
    const { companyName, branchCnpj, company, branch, setBranch, setBranchCnpj} = useCompanyBranch()
    const [ modalTradeBranch, setModalTradeBranch ] = useState(false)
    const [branches, setBranches] = useState([]);
    const token = localStorage.getItem('@auth:token');
    const [selectedBranch, setSelectedBranch] = useState('')
    const [loading, setLoading] = useState(false)
    const { user } = useAuth()
    const [showRelease, setShowRelease] = useState(false)
    const [release, setRelease] = useState('')
    const [fileRelease, setFileRelease] = useState('')
    const [selectedNotSeeAgain, setSelectedNotSeeAgain] = useState(false)

    useEffect(() => {

        async function getData(){

            try {

                const findIfSeeReleaseAPI = await api.get('api/v1/release/findIfSeeRelease', {
                    headers: {
                        authorization: token
                    }
                })

                if (findIfSeeReleaseAPI?.data?.data){
                    const releaseAPI = findIfSeeReleaseAPI?.data?.data

                    if (releaseAPI?.filename){
                        try {

                            const findFileAPI = await api.get(`api/v1/release/getFile/${releaseAPI?.id}`, {
                                responseType: 'blob',
                                responseEncoding: 'base64',
                                headers: {
                                    authorization: token
                                }
                            })

                            const fileObj = URL.createObjectURL(findFileAPI?.data)

                            setFileRelease(fileObj)

                        } catch(error){
                            console.log(error)
                        }
                    }

                    setRelease(releaseAPI)
                    setShowRelease(true)
                }

            } catch(error){
                console.log(error)
            }
        }

        getData()

    }, [])

    async function getBranchs(){
        setLoading(true)
        const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
            headers: {
                authorization: token
            }
        })
        setBranches(resultBranches.data.data)            
        setLoading(false)
    }

    function handleMenu(){

        const menu = document.getElementById("checkMenu")
        menu.checked = false

    }

    async function handleTradeBranch(){
        if (!selectedBranch){
            clearState()
            setModalTradeBranch(false)
            return
        } else {
            setBranch(selectedBranch.value)
            setBranchCnpj(selectedBranch.label)
            clearState()
            setModalTradeBranch(false)
            return navigate(0)
        }

    }

    async function clearState(){
        setSelectedBranch('')
    }

    function closeRelease(){        
        if (selectedNotSeeAgain){            
            api.post('api/v1/userReleaseViewed', {
                release_id: release?.id
            }, {
                headers: {
                    authorization: token
                }
            })
        }

        setShowRelease(false)
    }
    
    return(
        <>
            <div className={`${modalTradeBranch ? 'flex flex-col overflow-hidden' : 'overflow-hidden'}`}>
                <div className={`${modalTradeBranch ? 'flex ' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[99999]`}>
                    <div className={`animate-[wiggle_.4s_ease-in-out] pb-16 lg:pb-0 absolute lg:relative bottom-0 w-full lg:w-auto transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                        {
                            loading ?
                            <div className='flex justify-cente pl-3 py-5 items-center ml-7'>
                                <LoadingPulse/>
                            </div>
                            :
                            <>
                                <div className='flex justify-between pl-3 py-5 items-center'>
                                    <p className='flex flex-row font-extrabold items-start text-sm lg:text-lg'>
                                        <span className='mr-7'>Deseja realmente trocar de filial?</span>
                                    </p>
                                    <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => {clearState(); setModalTradeBranch(false)}} title={'Fechar'} />
                                </div>
                                <div className='flex flex-col items-center justify-center w-full mb-10'>
                                    <div className='mt-2'>
                                        <LabelInput>
                                            <InputAutoComplete
                                                data={branches?.filter(item => item?.value != branch)}
                                                id='branchs'
                                                selectedLabel={'label'}
                                                optionList={['label']}
                                                preSelectedValue={branches?.filter(item => item?.value == branch)[0]?.label}
                                                value={selectedBranch ? selectedBranch?.label : ''}
                                                onChange={(e) => {
                                                    setSelectedBranch(e)
                                                }}
                                                width={80}
                                            />
                                        </LabelInput>
                                    </div>
                                </div>
                                <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                                    <div className='flex flex-col items-center justify-center w-96 p-1'>
                                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                            <Button background={'bg-red-800'} onClick={() => { clearState(); setModalTradeBranch(false) }}>Cancelar</Button>
                                            <Button background={'bg-green-800'} type={'button'} onClick={() => { handleTradeBranch() }}>Confirmar</Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className={`${modalTradeBranch ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[103]`}></div>
            </div>
            <div className={`${showRelease ? 'flex flex-col overflow-hidden' : 'overflow-hidden'}`}>
                <div className={`${showRelease ? 'flex ' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[99999]`}>
                    <div className={`animate-[wiggle_.4s_ease-in-out] lg:pb-0 absolute lg:relative bottom-0 w-full lg:w-auto transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow max-h-[30rem] h-[30rem] overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        {
                            loading ?
                            <div className='flex justify-cente pl-3 py-5 items-center ml-7'>
                                <LoadingPulse/>
                            </div>
                            :
                            <div className='p-5 flex flex-col justify-center items-center gap-2 w-full'>
                                <p className='text-primaryDefaultLight font-semibold text-lg'>Versão {release?.version}</p>
                                <div className='flex flex-col justify-center items-center mt-2 gap-2 w-96 max-h-96 h-full'>
                                    {
                                        fileRelease ?
                                        <img width={500} height={300} src={fileRelease}/>
                                        : 
                                        <img width={150} height={150} src={Logo}/>
                                    }
                                </div>
                                <ReactQuill
                                    modules={Quillmodules}                                
                                    readOnly
                                    className='w-96'
                                    value={release?.details}
                                    theme={"bubble"}               
                                />
                                <div className='flex flex-row w-full items-center justify-start gap-2 mt-2'>
                                    <input type='checkbox' onClick={() => setSelectedNotSeeAgain(!selectedNotSeeAgain)} value={selectedNotSeeAgain}/>
                                    <p className='font-semibold text-xs'>Não exibir novamente</p>
                                </div>
                                <div className='w-full flex items-center justify-end '>
                                    <Button height={7} width={20} onClick={() => closeRelease()}>Fechar</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={`${showRelease ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[103]`}></div>
            </div>
            <div onClick={() => {setShowProfile(false); setMenuStatus(0); handleMenu()}} className={`body overflow-hidden h-screen`}>
                <br /> 
                {children}
                <div className={`flex flex-col lg:flex-row items-center justify-center sm:justify-evenly fixed bottom-0 w-full bg-zinc-100 py-1 lg:py-0 z-20`}>
                        <p className='text-xt lg:text-sm flex items-center'>
                            Empresa:{companyName}
                            {
                                [1].includes(user?.type) && companyName &&
                                <Tippy
                                    content={'Configuração'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={80}>
                                    <div className='ml-3'>
                                        <FaCog
                                            className='cursor-pointer'
                                            onClick={() => {
                                                navigate('/companyConfig')
                                            }}
                                        />
                                    </div>
                                </Tippy>
                            }
                        </p>
                        <p className='text-xt lg:text-sm flex items-center'>
                            Filial: {branchCnpj}
                            {
                                branchCnpj &&
                                <Tippy
                                    content={'Trocar Filial'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={80}>
                                    <div className='ml-3'>
                                        <FaExchangeAlt 
                                            className='cursor-pointer'
                                            onClick={() => {
                                                setModalTradeBranch(true)
                                                getBranchs()
                                            }}
                                        />
                                    </div>
                                </Tippy>
                            }
                        </p>
                </div>
            </div>
        </>
    )
}

export default Body