//**Funcão para retornar CPF com as pontuações
//Desenvolvido por: Rafael Cezário */

export function cpfMask(e) {

    return e
        ?.replace(/\D/g, '') // substitui qualquer caracter que não seja numero por nada
        ?.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 3, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d{1,2})/, '$1-$2')
        ?.replace(/(\d{3})(\d)/, '$1-$2') // captura 1 grupo de numeros com 3 digitos e adiciona um hífen ao final
        ?.replace(/(-\d{2})\d+?/, '$1')// captura dois numeros seguidos de um hífen e não deixa digitar nada além disto.
}