import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiClock, FiArrowDown, FiArrowUp, FiTrash2, FiX, FiSearch, FiEye } from 'react-icons/fi';
import { RiArrowUpDownLine } from 'react-icons/ri';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import Loader from '../../components/Loader/loader';
import { capitalize } from '../../utils/formatText/capitalize'

// import './employeeConsultPendings.css'
import { useAuth } from '../../hooks/useAuth';
import { TableDefault } from '../../components/table/table.default';
import Modal from '../../components/Modal/modal';
import { Button } from '../../components/buttons/button.default';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { TableMobile } from '../../components/table/table.mobile';
import { FaTrash } from 'react-icons/fa';

export default function EmployeeConsultPendings() {

    const token = localStorage.getItem('@auth:token');
    const {
        setCompany,
        setBranch,
        setCompanyName,
        setBranchCnpj
    } = useCompanyBranch();

    const { user } = useAuth()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const { setShow, show } = useModal()
    const [loading, setLoading] = useState(false);
    const [filteredValue, setFilteredValue] = useState('')
    const [employees, setEmployees] = useState([]);
    const [filteredStatus, setFilteredStatus] = useState(1)
    const { setMenuTitle } = useMenu()
    setMenuTitle('Colaboradores Pendentes')
    const navigate = useNavigate()
    const [employeeTemp, setEmployeeTemp] = useState()
    const [buttonRemove, setButtonRemove] = useState(false)
    const [updateEffect, setUpdateEffect] = useState(false)
    const [showModalProcced, setShowModalProcced] = useState(false)

    useEffect(() => {
        setLoading(true)

        async function getApiDatas() {

            try {
                const employeesResponse = await api.get(`api/v1/employees/pendings`, {
                    headers: {
                        authorization: token
                    }
                })

                const pendingsEmployees = employeesResponse.data.data.map((item) => {
                    return {
                        ...item,
                        branchName: capitalize(item.branchName),
                        folpag: <b>Folpag Connect</b>,
                        status: <FiClock className='ml-[50%] text-yellow-500' />,
                        remove:
                            <Tippy
                                content={<a>Excluir Colaborador Pendente</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                className='ml-3'
                                delay={80}>
                                <div>
                                    <FiTrash2 className='ml-[50%] text-red-800 cursor-pointer' onClick={() => { setShow(true); setEmployeeTemp(item); setButtonRemove(true) }} />
                                </div>
                            </Tippy>,
                        functions: [1].includes(user?.type) ?
                            [
                                {
                                    func: <FiEye
                                        onClick={() => {
                                            setCompany(item.company)
                                            setBranch(item.branch)
                                            setCompanyName(item.companyName)
                                            setBranchCnpj(item.branchName)
                                            navigate(`/employeeAdmission/${item.employeeId}`)
                                        }} />
                                },
                                {
                                    func: <FiTrash2 onClick={() => { setShow(true); setEmployeeTemp(item); setButtonRemove(true) }} />
                                }
                            ]
                            :
                            [
                                {
                                    func: <FiEye
                                        onClick={() => {
                                            setCompany(item.company)
                                            setBranch(item.branch)
                                            setCompanyName(item.companyName)
                                            setBranchCnpj(item.branchName)
                                            navigate(`/employeeAdmission/${item.employeeId}`)
                                        }} />
                                }
                            ]
                    }
                })

                setEmployees(pendingsEmployees)
                setLoading(false)
            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de colaboradores.')
            }
        }
        getApiDatas()

    }, [token, updateEffect]);

    useEffect(() => {
        if (!show) {
            setButtonRemove(false)
            setEmployeeTemp()
            setShowModalProcced(false)
        }
    }, [show])

    const tableThead = [
        {
            "name": "Empresa",
            "original_name": "companyName"
        },
        {
            "name": "Filial",
            "original_name": "branchName"
        },
        {
            "name": "Colaborador",
            "original_name": "employeeName"
        },
        {
            "name": "Data Admissão",
            "original_name": "admissionDate"
        },
        {
            "name": "Origem",
            "original_name": "folpag"
        },
        {
            "name": "Status",
            "original_name": "status"
        }
    ]

    const tableTheadADM = [
        {
            "name": "Empresa",
            "original_name": "companyName"
        },
        {
            "name": "Filial",
            "original_name": "branchName"
        },
        {
            "name": "Colaborador",
            "original_name": "employeeName"
        },
        {
            "name": "Data Admissão",
            "original_name": "admissionDate"
        },
        {
            "name": "Origem",
            "original_name": "folpag"
        },
        {
            "name": "Status",
            "original_name": "status"
        },
        {
            "name": "Excluir",
            "original_name": "remove"
        }
    ]

    const customFunctions = {
        isValid: true,
        collumn: [6]
    }

    async function handleRemoveEmployeePending(id) {
        setLoading(true)

        try {

            await api.delete(`api/v1/employees/pending/${id}`, {
                headers: {
                    Authorization: token
                }
            });

            setShow(false)
            setShowNotificationModal(true)
            setNotificationModalText('Colaborador removido com sucesso')
            setLoading(false)
            setUpdateEffect(!updateEffect)
            return navigate('/employeeConsultPendings')


        } catch (error) {
            setShow(false)
            setLoading(false)
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao remover colaboradores pendente.')
        }

    }

    async function mobileFunctionRemove(item) {
        setShow(true)
        setEmployeeTemp(item)
        setButtonRemove(true)
    }

    async function mobileRedirectFunction(e) {

    }

    async function proccedModal(e) {
        setLoading(true)
        setShow(false)
        try {
            await api.patch(`api/v1/companiesConnect/setSession/${e?.companies_id}`, {}, {
                headers: {
                    Authorization: user.token
                }
            })
            setCompany(e.company)
            setBranch(e.branch)
            setCompanyName(e.companyName)
            setBranchCnpj(e.branchName)
            setLoading(false)
            return navigate(`/employeeAdmission/${e.employeeId}`)              
        } catch(error){
            setLoading(false)
            console.log(error)
            return alert(error?.message)
        }
    }

    return (
        <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
            {
                show && buttonRemove ?
                    <Modal>
                        <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                            <div className='flex justify-between pl-3 py-5 items-center'>
                                <p className='flex flex-row font-extrabold items-center text-lg'>
                                    <p className='mr-7'>Deseja excluir esse colaborador pendente?</p>
                                </p>
                                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                            </div>
                            <p className='text-center text-lg mb-5'><b>{employeeTemp?.employeeName.split(' ')[0]}</b></p>
                            <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                <Button background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                                <Button background={'bg-green-800'} type={'button'} onClick={() => { handleRemoveEmployeePending(employeeTemp?.employeeId) }}>Confirmar</Button>
                            </div>
                        </div>
                    </Modal>
                    : show && showModalProcced &&
                    <Modal>
                        <div className={`h-[18rem] max-h-[40rem] flex flex-col items-center justify-center bg-gray-200 rounded-lg w-[30rem]`}>
                            <p className=' font-bold text-center text-lg border-b-2 border-blue-500'>Deseja prosseguir?</p>
                            <div className='flex flex-col font-semibold items-start text-md gap-3 p-[25px]'>
                                <p>Empresa: {employeeTemp?.companyName}</p>
                                <p>Filial: {employeeTemp?.branchName}</p>
                            </div>
                            <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                <Button background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                                <Button background={'bg-green-800'} type={'button'} onClick={() => { proccedModal(employeeTemp) }}>Confirmar</Button>
                            </div>
                        </div>
                    </Modal>
            }
            {
                loading ?
                    <div className='animate-[wiggleLeft_1s_ease-in-out]'>
                        <Loader />
                    </div>
                    : (
                        <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                            <div className="hidden lg:flex flex-col justify-start align-center ml-20 mt-3 w-[100%] h-[90vh] mr-8 gap-5">
                                {
                                    ![1].includes(user.type) ?
                                        <TableDefault
                                            title={tableThead}
                                            data={employees}
                                            cursorPointer={true}
                                            onClick={(e) => {
                                                setEmployeeTemp(e)
                                                setShowModalProcced(true)
                                                setShow(true)
                                            }}
                                            collumns={["companyName", "branchName", "employeeName", "admissionDate", "folpag", "status"]}
                                        />
                                        :
                                        <TableDefault
                                            title={tableTheadADM}
                                            data={employees}
                                            cursorPointer={true}
                                            customFunctions={customFunctions}
                                            quantity={employees.length}
                                            onClick={(e) => {
                                                setEmployeeTemp(e)
                                                setShowModalProcced(true)
                                                setShow(true)
                                            }
                                            }
                                            collumns={["companyName", "branchName", "employeeName", "admissionDate", "folpag", "status", "remove"]}
                                        />
                                }
                            </div>
                            <div className='relative bg-white flex lg:hidden flex-col w-full px-3 h-[84vh] max-h-[67vh] items-start justify-start boxShadow overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500'>
                                {
                                    ![1].includes(user.type) ?
                                        <TableMobile
                                            title={tableThead}
                                            data={employees}
                                            collumns={["companyName", "branchName", "employeeName", "admissionDate", "folpag", "status"]}
                                        />
                                        :
                                        <TableMobile
                                            title={tableTheadADM}
                                            data={employees}
                                            collumns={["companyName", "branchName", "employeeName", "admissionDate", "folpag", "status"]}
                                        />
                                }
                            </div>
                        </div>
                    )
            }
        </div>
    );
}