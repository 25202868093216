import React, { useEffect, useState } from 'react';
import { Button } from '../../components/buttons/button.default';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import Loader from '../../components/Loader/loader';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai'
import { FaAngleRight, FaAngleDown } from 'react-icons/fa'
import CurrencyInput from '../../components/input/inputCurrencyPtBr';
import { useMenu } from '../../hooks/useMenu';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FiDownload } from "react-icons/fi";
import { useModal } from '../../hooks/useModal';

export function SalaryIncreaseSingle() {

    const [loading, setLoading] = useState(true);
    const [newSalary, setNewSalary] = useState('')
    const [useSimpleCompany, setUseSimpleCompany] = useState('')
    const { company, branch } = useCompanyBranch();
    const token = localStorage.getItem('@auth:token')
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { setMenuTitle } = useMenu()
    setMenuTitle('Prévia')
    const navigate = useNavigate()
    const [ratCompany, setRatCompany] = useState('')
    const [discountsNewSalary, setDiscountsNewSalary] = useState('')
    const [salaryTemp, setSalaryTemp] = useState('')
    const [loadingNewSalary, setLoadingNewSalary] = useState(false)
    const [detailsDiscountsNew, setDetailsDiscountsNew] = useState('')
    const [companyZoneNew, setCompanyZoneNew] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)

    useEffect(() => {
        if (company === 0 || branch === 0) {
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }

        setLoading(true)

        async function getSimpleCompany() {
            try {
                const simpleCompany = await api.get(`api/v1/companies/verifyUseSimple/company/${company}/branch/${branch}`, {
                    headers: {
                        Authorization: token
                    }
                })
                setRatCompany(simpleCompany?.data?.data?.persat)
                setUseSimpleCompany(simpleCompany?.data?.data?.useSimple)
                setLoading(false)
            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        getSimpleCompany()
    }, [])

    async function getDiscountsPerNewSalary(){
        setLoadingNewSalary(true)
        try {
            const getDiscountsAPI = await api.get(`api/v1/general/discountINSSPerSalary?newSalary=${salaryTemp}`, {
                headers: {
                    Authorization: token
                }
            })
            setDiscountsNewSalary(getDiscountsAPI?.data?.data)
            setNewSalary(salaryTemp)
            setLoadingNewSalary(false)

        } catch(error) {
            setLoadingNewSalary(false)
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function handleExport(){
        setLoadingExport(true)
        try {
            const file = await api.post(`api/v1/general/exportSalarySingle/branch/${branch}`, {
                newSalary: newSalary ? newSalary : undefined, 
                useSimple: useSimpleCompany ? useSimpleCompany : undefined, 
                persat: ratCompany ? ratCompany : undefined,
                discountsNew: discountsNewSalary ? discountsNewSalary?.discounts : undefined, 
                totalDiscountNew: discountsNewSalary ? discountsNewSalary?.totalDiscount : undefined
            }, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    Authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `PreviaSalarioAvulso_${Number(new Date())}`)
            myElement.click()
            setLoadingExport(false)
        } catch (error){
            setLoadingExport(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro no download')
        }
    }

    let totalValuesChargeNewSalary = ((20 * newSalary) / 100)
    totalValuesChargeNewSalary += ((ratCompany * newSalary) / 100)
    totalValuesChargeNewSalary += ((5.8 * newSalary) / 100)
    totalValuesChargeNewSalary += ((8 * newSalary) / 100)

    let totalValuesProvisionsNewSalary = ((newSalary) / 12)
    totalValuesProvisionsNewSalary += ((newSalary / 12) / 3)
    totalValuesProvisionsNewSalary += (20 * (newSalary / 12) / 100)
    totalValuesProvisionsNewSalary += (ratCompany * (newSalary / 12) / 100)
    totalValuesProvisionsNewSalary += (5.8 * (newSalary / 12) / 100)
    totalValuesProvisionsNewSalary += (8 * (newSalary / 12) / 100)
    totalValuesProvisionsNewSalary += ((newSalary) / 12)
    totalValuesProvisionsNewSalary += (20 * (newSalary / 12) / 100)
    totalValuesProvisionsNewSalary += (ratCompany * (newSalary / 12) / 100)
    totalValuesProvisionsNewSalary += (5.8 * (newSalary / 12) / 100)
    totalValuesProvisionsNewSalary += (8 * (newSalary / 12) / 100)

    let totalValuesNewSalary = ((newSalary) / 12)
    totalValuesNewSalary += ((20 * newSalary) / 100)
    totalValuesNewSalary += ((ratCompany * newSalary) / 100)
    totalValuesNewSalary += ((5.80 * newSalary) / 100)
    totalValuesNewSalary += ((8 * newSalary) / 100)
    totalValuesNewSalary += ((newSalary / 12) / 3)
    totalValuesNewSalary += (20 * (newSalary / 12) / 100)
    totalValuesNewSalary += (ratCompany * (newSalary / 12) / 100)
    totalValuesNewSalary += (5.8 * (newSalary / 12) / 100)
    totalValuesNewSalary += (8 * (newSalary / 12) / 100)
    totalValuesNewSalary += ((newSalary) / 12)
    totalValuesNewSalary += (20 * (newSalary / 12) / 100)
    totalValuesNewSalary += (ratCompany * (newSalary / 12) / 100)
    totalValuesNewSalary += (5.8 * (newSalary / 12) / 100)
    totalValuesNewSalary += (8 * (newSalary / 12) / 100)

    let totalValuesProvisionsSimpleNewSalary = ((newSalary) / 12)
    totalValuesProvisionsSimpleNewSalary += ((newSalary / 12) / 3)
    totalValuesProvisionsSimpleNewSalary += (0 * (newSalary / 12) / 100)
    totalValuesProvisionsSimpleNewSalary += (0.00 * (newSalary / 12) / 100)
    totalValuesProvisionsSimpleNewSalary += (0.00 * (newSalary / 12) / 100)
    totalValuesProvisionsSimpleNewSalary += (8 * (newSalary / 12) / 100)
    totalValuesProvisionsSimpleNewSalary += ((newSalary) / 12)
    totalValuesProvisionsSimpleNewSalary += (0 * (newSalary / 12) / 100)
    totalValuesProvisionsSimpleNewSalary += (0.00 * (newSalary / 12) / 100)
    totalValuesProvisionsSimpleNewSalary += (0.00 * (newSalary / 12) / 100)
    totalValuesProvisionsSimpleNewSalary += (8 * (newSalary / 12) / 100)

    let totalValuesChargeNewSalarySimple = ((0 * newSalary) / 100)
    totalValuesChargeNewSalarySimple += ((0.00 * newSalary) / 100)
    totalValuesChargeNewSalarySimple += ((0.00 * newSalary) / 100)
    totalValuesChargeNewSalarySimple += ((8 * newSalary) / 100)

    let totalValuesNewSalarySimple = ((newSalary) / 12)
    totalValuesNewSalarySimple += ((0 * newSalary) / 100)
    totalValuesNewSalarySimple += ((0.00 * newSalary) / 100)
    totalValuesNewSalarySimple += ((0.00 * newSalary) / 100)
    totalValuesNewSalarySimple += ((8 * newSalary) / 100)
    totalValuesNewSalarySimple += ((newSalary / 12) / 3)
    totalValuesNewSalarySimple += (0 * (newSalary / 12) / 100)
    totalValuesNewSalarySimple += (0 * (newSalary / 12) / 100)
    totalValuesNewSalarySimple += (0 * (newSalary / 12) / 100)
    totalValuesNewSalarySimple += (8 * (newSalary / 12) / 100)
    totalValuesNewSalarySimple += ((newSalary) / 12)
    totalValuesNewSalarySimple += (0 * (newSalary / 12) / 100)
    totalValuesNewSalarySimple += (0 * (newSalary / 12) / 100)
    totalValuesNewSalarySimple += (0 * (newSalary / 12) / 100)
    totalValuesNewSalarySimple += (8 * (newSalary / 12) / 100)

    return (
        <div className={`overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 h-[100vh] ${loadingExport ? 'cursor-wait' : ''}`}>
            <div className="w-full flex flex-col justify-center items-center mt-8">
                <p className='text-lg md:text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-8'>Aumento de salário avulso</p>            
            </div>
            {
                loading ?
                <div className="w-full justify-center flex items-center">
                    <Loader />
                </div>
                : useSimpleCompany && !loading ?
                <div className='flex flex-col justify-center items-center md:mb-20 lg:mb-40 mt-0 sm:mt-12'>
                    <div className='hidden md:flex flex-col justify-center items-center'>
                        <table className='w-[500px] bg-white shadow-lg shadow-gray-500/75'>
                            <thead className='bg-gray-200'>
                                <tr className='p-1 flex justify-center font-semibold text-lg text-primaryDefaultLight border border-gray-300'>
                                    <tr>SIMULAÇÃO</tr>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loadingNewSalary ?
                                    <>
                                        <tr className='flex justify-between px-[20px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Salário Base</td>
                                            <td className='flex items-center gap-1 w-24 bg-gray-300 animate-pulse'></td>
                                        </tr>
                                        <tr className='flex justify-between px-[20px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Salário Líquido</td>
                                            <td className='flex items-center gap-1 w-24 bg-gray-300 animate-pulse'></td>
                                        </tr>
                                        <tr className='flex justify-between px-[20px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Área da Empresa</td>
                                            <td className='flex items-center gap-1 w-24 bg-gray-300 animate-pulse'></td>
                                        </tr>
                                        {
                                            companyZoneNew &&
                                            <>
                                                <tr className='flex bg-purple-100 border border-gray-300 p-1'>
                                                    <th className='p-1 flex flex-col justify-center font-semibold text-md text-primaryDefaultLight'>Encargos</th>
                                                    <td className={newSalary ? `w-full flex justify-center gap-20` : `w-full flex justify-center gap-16`}>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>INSS Patronal</td>
                                                            <td>RAT + FAP</td>
                                                            <td>Terceiros</td>
                                                            <td>FGTS</td>
                                                            <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Encargos)</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5.80%</td>
                                                            <td>8%</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr className='flex p-1 border border-gray-300'>
                                                    <th className='p-[3px] flex flex-col justify-center font-semibold text-md text-primaryDefaultLight'>Provisões</th>
                                                    <td className='w-full flex justify-start pl-2 gap-10'>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>1 avo férias</td>
                                                            <td>1/3 férias</td>
                                                            <td>INSS Férias</td>
                                                            <td>RAT + FAP Férias</td>
                                                            <td>Terceiro Férias</td>
                                                            <td>FGTS Férias</td>
                                                            <td>1 avo 13º Salário</td>
                                                            <td>INSS 13º salário</td>
                                                            <td>RAT + FAP 13º</td>
                                                            <td>13º Terceiros</td>
                                                            <td>FGTS 13º salário</td>
                                                            <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Provisões)</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>1/12</td>
                                                            <td>1/3</td>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5,8%</td>
                                                            <td>8%</td>
                                                            <td>1/12</td>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5,8%</td>
                                                            <td>8%</td>
                                                        </td>
                                                        <td className='flex flex-col ml-[50px] font-semibold gap-1 whitespace-nowrap'>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr className='flex justify-between font-bold px-[30px] p-1 border border-gray-300'>
                                                    <td>TOTAL</td>
                                                    <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                </tr>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <tr className='flex justify-between px-[30px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Salário Base</td>
                                            <td className='flex items-center gap-1'>
                                                <AiOutlineArrowRight className='text-lg text-primaryDefaultLightHover animate-pulse' />
                                                <CurrencyInput id='originalValue' placeHolder={salaryTemp ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(salaryTemp) : 'R$ 0,00'}
                                                    onChange={(e) => {
                                                        setSalaryTemp(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                    }}
                                                    className='text-black font-semibold text-md text-center border border-gray-300 bg-purple-100'
                                                >
                                                </CurrencyInput>
                                                <div className='ml-2'>
                                                    <Button width={20} height={6} onClick={() => getDiscountsPerNewSalary()}>Calcular</Button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='flex justify-between px-[30px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Salário Líquido</td>
                                            <td className='font-bold flex flex-row items-center'>
                                                {
                                                    newSalary ? 
                                                    <td className='flex flex-row items-center'>
                                                        {(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(newSalary - discountsNewSalary?.totalDiscount))}
                                                        {
                                                            detailsDiscountsNew ?
                                                            <Tippy
                                                                content={'Retrair Detalhes'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                className='ml-2'
                                                                delay={80}>
                                                                <div>
                                                                    <FaAngleDown className='text-sm ml-2 cursor-pointer duration-100' onClick={() => setDetailsDiscountsNew(!detailsDiscountsNew)}/>                                              
                                                                </div>
                                                            </Tippy>                                            
                                                            :
                                                            <Tippy
                                                                content={'Detalhes'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                className='ml-2'
                                                                delay={80}>
                                                                <div>
                                                                    <FaAngleRight className='text-sm ml-2 cursor-pointer duration-100' onClick={() => setDetailsDiscountsNew(!detailsDiscountsNew)}/>                                                
                                                                </div>
                                                            </Tippy>
                                                        }
                                                    </td> 
                                                    : 
                                                    <td>R$0,00</td>
                                                }
                                            </td>
                                        </tr>
                                        {
                                            detailsDiscountsNew &&
                                            discountsNewSalary?.discounts?.map(disc => {
                                                return (
                                                    <tr className='flex justify-around p-1 border border-gray-300'>
                                                        <td className='font-semibold text-md text-primaryDefaultLight'>{disc?.description}</td>
                                                        <td className='font-semibold text-md'>{disc?.ref}</td>
                                                        <td className='font-bold flex flex-row items-center'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(disc?.value))} </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr className='flex justify-between px-[30px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Área da Empresa</td>
                                            <td className='font-bold flex flex-row items-center'>
                                                {
                                                    companyZoneNew ?
                                                    <Tippy
                                                        content={'Retrair'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={80}>
                                                        <div>
                                                            <FaAngleDown className='text-sm cursor-pointer duration-100' onClick={() => setCompanyZoneNew(!companyZoneNew)}/>                                              
                                                        </div>
                                                    </Tippy>                                            
                                                    :
                                                    <Tippy
                                                        content={'Expandir'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={80}>
                                                        <div>
                                                            <FaAngleRight className='text-sm cursor-pointer duration-100' onClick={() => setCompanyZoneNew(!companyZoneNew)}/>                                                
                                                        </div>
                                                    </Tippy>
                                                }
                                            </td>
                                        </tr>
                                        {
                                            companyZoneNew &&
                                            <>
                                                <tr className='flex bg-purple-100 border border-gray-300 p-1'>
                                                    <th className='p-1 flex flex-col justify-center font-semibold text-md text-primaryDefaultLight'>Encargos</th>
                                                    <td className={newSalary ? `w-full flex justify-center gap-[85px]` : `w-full flex justify-center gap-[75px]`}>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>INSS Patronal</td>
                                                            <td>RAT + FAP</td>
                                                            <td>Aposent. Especial</td>
                                                            <td>Terceiros</td>
                                                            <td>FGTS</td>
                                                            <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Encargos)</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>0%</td>
                                                            <td>0.00%</td>
                                                            <td>0.00%</td>
                                                            <td>0.00%</td>
                                                            <td>8%</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0.00 * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0.00 * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0.00 * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td className='bg-purple-800 text-white p-1 rounded-md'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValuesChargeNewSalarySimple))}</td> : <td>R$0,00</td>}
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr className='flex p-1 border border-gray-300'>
                                                    <th className='p-[3px] flex flex-col justify-center font-semibold text-md text-primaryDefaultLight'>Provisões</th>
                                                    <td className='w-full flex justify-start pl-[25px] gap-[45px]'>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>1 avo férias</td>
                                                            <td>1/3 férias</td>
                                                            <td>INSS Férias</td>
                                                            <td>RAT + FAP Férias</td>
                                                            <td>Aposent. Esp. Férias</td>
                                                            <td>Terceiro Férias</td>
                                                            <td>FGTS Férias</td>
                                                            <td>1 avo 13º Salário</td>
                                                            <td>INSS 13º salário</td>
                                                            <td>RAT + FAP 13º</td>
                                                            <td>Aposent. Esp. 13º</td>
                                                            <td>13º Terceiros</td>
                                                            <td>FGTS 13º salário</td>
                                                            <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Provisões)</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>1/12</td>
                                                            <td>1/3</td>
                                                            <td>0</td>
                                                            <td>0%</td>
                                                            <td>0%</td>
                                                            <td>0</td>
                                                            <td>8%</td>
                                                            <td>1/12</td>
                                                            <td>0</td>
                                                            <td>0%</td>
                                                            <td>0%</td>
                                                            <td>0</td>
                                                            <td>8%</td>
                                                        </td>
                                                        <td className='flex flex-col ml-[50px] font-semibold gap-1 whitespace-nowrap'>
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary) / 12))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary / 12) / 3))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary) / 12))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td className='bg-purple-800 text-white p-1 rounded-md'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValuesProvisionsSimpleNewSalary))}</td> : <td>R$0,00</td>}
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr className='flex justify-between font-bold px-[30px] p-1 border border-gray-300'>
                                                    <td>TOTAL</td>
                                                    {newSalary > 0 ?
                                                        <td className='bg-purple-800 text-white p-1 rounded-md'>R$ {(newSalary + totalValuesNewSalarySimple).toFixed(2).toLocaleString()}</td>
                                                        :
                                                        <td className='pr-[15px]'>R$ 0,00</td>
                                                    }
                                                </tr>
                                            </>
                                        }
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='md:hidden flex flex-col justify-center items-center'>
                        <table className='w-[350px] bg-white shadow-lg shadow-gray-500/75 my-20'>
                            <thead className='bg-gray-200'>
                                <tr className='p-1 flex justify-between font-semibold text-lg text-primaryDefaultLight border border-gray-300'>
                                    <tr>SIMULAÇÃO</tr>
                                    <div className='ml-2'>
                                        <Button width={20} height={6} onClick={() => getDiscountsPerNewSalary()}>Calcular</Button>
                                    </div>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='flex justify-between px-1 p-1 border border-gray-300'>
                                    <td className='font-semibold text-md text-primaryDefaultLight'>Salário Base</td>
                                    <td className='flex items-center gap-1'>
                                        <AiOutlineArrowRight className='text-lg text-primaryDefaultLightHover animate-pulse' />
                                        <CurrencyInput id='originalValue' placeHolder={salaryTemp ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(salaryTemp) : 'R$ 0,00'}
                                            onChange={(e) => {
                                                setSalaryTemp(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                            }}
                                            className='text-black font-semibold text-md text-center border border-gray-300 bg-purple-100'
                                        >
                                        </CurrencyInput>
                                    </td>
                                </tr>
                                <tr className='flex justify-between px-1 p-1 border border-gray-300'>
                                    <td className='font-semibold text-md text-primaryDefaultLight'>Salário Líquido</td>
                                    <td className='flex font-bold items-center gap-1'>
                                        {
                                            newSalary ? 
                                            <td className='flex flex-row items-center'>
                                                {(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(newSalary - discountsNewSalary?.totalDiscount))}
                                                {
                                                    detailsDiscountsNew ?
                                                    <FaAngleDown className='text-sm ml-2 cursor-pointer duration-100' onClick={() => setDetailsDiscountsNew(!detailsDiscountsNew)}/>
                                                    :                                     
                                                    <FaAngleRight className='text-sm ml-2 cursor-pointer duration-100' onClick={() => setDetailsDiscountsNew(!detailsDiscountsNew)}/>                                                
                                                }
                                            </td>
                                            : 
                                            <td>R$0,00</td>
                                        }
                                    </td>
                                </tr>
                                {
                                    detailsDiscountsNew &&
                                    discountsNewSalary?.discounts?.map(disc => {
                                        return (
                                            <tr className='flex justify-around p-1 border border-gray-300'>
                                                <td className='font-semibold text-md text-primaryDefaultLight'>{disc?.description}</td>
                                                <td className='font-semibold text-md'>{disc?.ref}</td>
                                                <td className='font-bold flex flex-row items-center'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(disc?.value))} </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr className='text-center border border-gray-300'>
                                    <th className='p-1 font-semibold text-md text-primaryDefaultLight'>Encargos</th>
                                </tr>
                                <tr className='flex justify-between px-1 border border-gray-300'>
                                    <td className='flex flex-col font-semibold'>
                                        <td>INSS Patronal</td>
                                        <td>RAT + FAP</td>
                                        <td>Aposent. Especial</td>
                                        <td>Terceiros</td>
                                        <td>FGTS</td>
                                        <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Encargos)</td>
                                    </td>
                                    <td className='flex flex-col font-semibold'>
                                        <td>0%</td>
                                        <td>0.00%</td>
                                        <td>0.00%</td>
                                        <td>0.00%</td>
                                        <td>8%</td>
                                    </td>
                                    <td className='flex flex-col font-semibold'>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0.00 * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0.00 * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0.00 * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td className='bg-purple-800 text-white p-1 rounded-md'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValuesChargeNewSalarySimple))}</td>
                                    </td>
                                </tr>
                                <tr className='text-center border border-gray-300'>
                                    <th className='p-1 font-semibold text-md text-primaryDefaultLight'>Provisões</th>
                                </tr>
                                <tr className='flex justify-between px-1 border border-gray-300'>
                                    <td className='flex flex-col font-semibold'>
                                        <td>1 avo férias</td>
                                        <td>1/3 férias</td>
                                        <td>INSS Férias</td>
                                        <td>RAT + FAP Férias</td>
                                        <td>Aposent. Esp. Férias</td>
                                        <td>Terceiro Férias</td>
                                        <td>FGTS Férias</td>
                                        <td>1 avo 13º Salário</td>
                                        <td>INSS 13º salário</td>
                                        <td>RAT + FAP 13º</td>
                                        <td>Aposent. Esp. 13º</td>
                                        <td>13º Terceiros</td>
                                        <td>FGTS 13º salário</td>
                                        <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Provisões)</td>
                                    </td>
                                    <td className='flex flex-col font-semibold'>
                                        <td>1/12</td>
                                        <td>1/3</td>
                                        <td>0</td>
                                        <td>0%</td>
                                        <td>0%</td>
                                        <td>0</td>
                                        <td>8%</td>
                                        <td>1/12</td>
                                        <td>0</td>
                                        <td>0%</td>
                                        <td>0%</td>
                                        <td>0</td>
                                        <td>8%</td>
                                    </td>
                                    <td className='flex flex-col font-semibold'>
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary) / 12))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary / 12) / 3))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary) / 12))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((0 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td className='bg-purple-800 text-white p-1 rounded-md'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValuesProvisionsSimpleNewSalary))}</td> : <td>R$0,00</td>}
                                    </td>
                                </tr>
                                <tr className='flex justify-between font-bold px-1 p-1 border border-gray-300'>
                                    <td>TOTAL</td>
                                    {newSalary > 0 ?
                                        <td className='bg-purple-800 text-white p-1 rounded-md'>R$ {(newSalary + totalValuesNewSalarySimple).toFixed(2).toLocaleString()}</td>
                                        :
                                        <td className='pr-[15px]'>R$ 0,00</td>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className='flex flex-col justify-center items-center md:mb-20 lg:mb-40 mt-0 sm:mt-12'>                                        
                    <div className='hidden md:flex flex-col justify-center items-center'>
                        <table className='w-[500px] bg-white shadow-lg shadow-gray-500/75 mb-20 lg:mb-0'>
                            <thead className='bg-gray-200 relative'>
                                <tr className='p-1 flex justify-center items-center font-semibold text-lg text-primaryDefaultLight border border-gray-300'>
                                    <tr>SIMULAÇÃO</tr>
                                    {
                                        newSalary ?
                                        <div className='absolute right-2'>
                                            <Tippy
                                                content={'Exportar'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={80}>
                                                <div>
                                                    <FiDownload className='cursor-pointer text-primaryDefaultLight' onClick={() => handleExport()}/>                                              
                                                </div>
                                            </Tippy>
                                        </div>
                                        : <></>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loadingNewSalary ?
                                    <>
                                        <tr className='flex justify-between px-[20px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Salário Base</td>
                                            <td className='flex items-center gap-1 w-24 bg-gray-300 animate-pulse'></td>
                                        </tr>
                                        <tr className='flex justify-between px-[20px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Salário Líquido</td>
                                            <td className='flex items-center gap-1 w-24 bg-gray-300 animate-pulse'></td>
                                        </tr>
                                        <tr className='flex justify-between px-[20px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Área da Empresa</td>
                                            <td className='flex items-center gap-1 w-24 bg-gray-300 animate-pulse'></td>
                                        </tr>
                                        {
                                            companyZoneNew &&
                                            <>
                                                <tr className='flex bg-purple-100 border border-gray-300 p-1'>
                                                    <th className='p-1 flex flex-col justify-center font-semibold text-md text-primaryDefaultLight'>Encargos</th>
                                                    <td className={newSalary ? `w-full flex justify-center gap-20` : `w-full flex justify-center gap-16`}>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>INSS Patronal</td>
                                                            <td>RAT + FAP</td>
                                                            <td>Terceiros</td>
                                                            <td>FGTS</td>
                                                            <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Encargos)</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5.80%</td>
                                                            <td>8%</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr className='flex p-1 border border-gray-300'>
                                                    <th className='p-[3px] flex flex-col justify-center font-semibold text-md text-primaryDefaultLight'>Provisões</th>
                                                    <td className='w-full flex justify-start pl-2 gap-10'>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>1 avo férias</td>
                                                            <td>1/3 férias</td>
                                                            <td>INSS Férias</td>
                                                            <td>RAT + FAP Férias</td>
                                                            <td>Terceiro Férias</td>
                                                            <td>FGTS Férias</td>
                                                            <td>1 avo 13º Salário</td>
                                                            <td>INSS 13º salário</td>
                                                            <td>RAT + FAP 13º</td>
                                                            <td>13º Terceiros</td>
                                                            <td>FGTS 13º salário</td>
                                                            <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Provisões)</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>1/12</td>
                                                            <td>1/3</td>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5,8%</td>
                                                            <td>8%</td>
                                                            <td>1/12</td>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5,8%</td>
                                                            <td>8%</td>
                                                        </td>
                                                        <td className='flex flex-col ml-[50px] font-semibold gap-1 whitespace-nowrap'>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                            <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse mr-2'></td>
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr className='flex justify-between font-bold px-[30px] p-1 border border-gray-300'>
                                                    <td>TOTAL</td>
                                                    <td className='flex items-center gap-1 w-24 h-5 bg-gray-300 animate-pulse'></td>
                                                </tr>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <tr className='flex justify-between px-[30px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Salário Base</td>
                                            <td className='flex items-center gap-1'>
                                                <AiOutlineArrowRight className='text-lg text-primaryDefaultLightHover animate-pulse' />
                                                <CurrencyInput id='originalValue' placeHolder={salaryTemp ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(salaryTemp) : 'R$ 0,00'}
                                                    onChange={(e) => {
                                                        setSalaryTemp(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                    }}
                                                    className='text-black font-semibold text-md text-center border border-gray-300 bg-purple-100'
                                                >
                                                </CurrencyInput>
                                                <div className='ml-2'>
                                                    <Button width={20} height={6} onClick={() => getDiscountsPerNewSalary()}>Calcular</Button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='flex justify-between px-[30px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Salário Líquido</td>
                                                <td className='font-bold flex flex-row items-center'>
                                                {
                                                    newSalary ? 
                                                    <td className='flex flex-row items-center'>
                                                        {(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(newSalary - discountsNewSalary?.totalDiscount))}
                                                        {
                                                            detailsDiscountsNew ?
                                                            <Tippy
                                                                content={'Retrair Detalhes'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                className='ml-3'
                                                                delay={80}>
                                                                <div>
                                                                    <FaAngleDown className='text-sm ml-3 cursor-pointer duration-100' onClick={() => setDetailsDiscountsNew(!detailsDiscountsNew)}/>                                              
                                                                </div>
                                                            </Tippy>                                            
                                                            :
                                                            <Tippy
                                                                content={'Detalhes'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                className='ml-3'
                                                                delay={80}>
                                                                <div>
                                                                    <FaAngleRight className='text-sm ml-3 cursor-pointer duration-100' onClick={() => setDetailsDiscountsNew(!detailsDiscountsNew)}/>                                                
                                                                </div>
                                                            </Tippy>
                                                        }
                                                    </td> 
                                                    : 
                                                    <td>R$0,00</td>
                                                }
                                            </td>
                                        </tr>
                                        {
                                            detailsDiscountsNew &&
                                            discountsNewSalary?.discounts?.map(disc => {
                                                return (
                                                    <tr className='flex justify-around p-1 border border-gray-300'>
                                                        <td className='font-semibold text-md text-primaryDefaultLight'>{disc?.description}</td>
                                                        <td className='font-semibold text-md'>{disc?.ref}</td>
                                                        <td className='font-bold flex flex-row items-center'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(disc?.value))} </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr className='flex justify-between px-[30px] p-1 border border-gray-300'>
                                            <td className='font-semibold text-md text-primaryDefaultLight'>Área da Empresa</td>
                                            <td className='font-bold flex flex-row items-center'>
                                                {
                                                    companyZoneNew ?
                                                    <Tippy
                                                        content={'Retrair'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={80}>
                                                        <div>
                                                            <FaAngleDown className='text-sm cursor-pointer duration-100' onClick={() => setCompanyZoneNew(!companyZoneNew)}/>                                              
                                                        </div>
                                                    </Tippy>                                            
                                                    :
                                                    <Tippy
                                                        content={'Expandir'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={80}>
                                                        <div>
                                                            <FaAngleRight className='text-sm cursor-pointer duration-100' onClick={() => setCompanyZoneNew(!companyZoneNew)}/>                                                
                                                        </div>
                                                    </Tippy>
                                                }
                                            </td>
                                        </tr>
                                        {
                                            companyZoneNew &&
                                            <>
                                                <tr className='flex bg-purple-100 border border-gray-300 p-1'>
                                                    <th className='p-1 flex flex-col justify-center font-semibold text-md text-primaryDefaultLight'>Encargos</th>
                                                    <td className={newSalary ? `w-full flex justify-center pl-2 gap-16` : `w-full flex justify-center gap-20`}>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>INSS Patronal</td>
                                                            <td>RAT + FAP</td>
                                                            <td>Terceiros</td>
                                                            <td>FGTS</td>
                                                            <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Encargos)</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5.80%</td>
                                                            <td>8%</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((20 * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((ratCompany * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((5.80 * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * newSalary) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td className='bg-purple-800 text-white p-1 rounded-md'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValuesChargeNewSalary))}</td> : <td>R$0,00</td>}
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr className='flex p-1 border border-gray-300'>
                                                    <th className='p-[3px] flex flex-col justify-center font-semibold text-md text-primaryDefaultLight'>Provisões</th>
                                                    <td className='w-full flex justify-start pl-2 gap-10'>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>1 avo férias</td>
                                                            <td>1/3 férias</td>
                                                            <td>INSS Férias</td>
                                                            <td>RAT + FAP Férias</td>
                                                            <td>Terceiro Férias</td>
                                                            <td>FGTS Férias</td>
                                                            <td>1 avo 13º Salário</td>
                                                            <td>INSS 13º salário</td>
                                                            <td>RAT + FAP 13º</td>
                                                            <td>13º Terceiros</td>
                                                            <td>FGTS 13º salário</td>
                                                            <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Provisões)</td>
                                                        </td>
                                                        <td className='flex flex-col font-semibold gap-1 whitespace-nowrap'>
                                                            <td>1/12</td>
                                                            <td>1/3</td>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5,8%</td>
                                                            <td>8%</td>
                                                            <td>1/12</td>
                                                            <td>20%</td>
                                                            <td>{ratCompany}%</td>
                                                            <td>5,8%</td>
                                                            <td>8%</td>
                                                        </td>
                                                        <td className='flex flex-col ml-[50px] font-semibold gap-1 whitespace-nowrap'>
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary) / 12))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary / 12) / 3))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((20 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((ratCompany * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((5.8 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary) / 12))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((20 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((ratCompany * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((5.8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                                            {newSalary ? <td className='bg-purple-800 text-white p-1 rounded-md'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValuesProvisionsNewSalary))}</td> : <td>R$0,00</td>}
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr className='flex justify-between font-bold px-[30px] p-1 border border-gray-300'>
                                                    <td>TOTAL</td>
                                                    {newSalary > 0 ?
                                                        <td className='bg-purple-800 text-white p-1 rounded-md'>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(newSalary + totalValuesNewSalary)}</td>
                                                        :
                                                        <td className='pr-[15px]'>R$ 0,00</td>
                                                    }
                                                </tr>
                                            </>
                                        }
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='md:hidden flex flex-col justify-center items-center'>
                        <table className='w-[350px] bg-white shadow-lg shadow-gray-500/75 my-20'>
                            <thead className='bg-gray-200'>
                                <tr className='p-1 flex justify-between font-semibold text-lg text-primaryDefaultLight border border-gray-300'>
                                    <tr>SIMULAÇÃO</tr>
                                    <div className='ml-2'>
                                        <Button width={20} height={6} onClick={() => getDiscountsPerNewSalary()}>Calcular</Button>
                                    </div>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='flex justify-between px-1 p-1 border border-gray-300'>
                                    <td className='font-semibold text-md text-primaryDefaultLight'>Salário Base</td>
                                    <td className='flex items-center gap-1'>
                                        <AiOutlineArrowRight className='text-lg text-primaryDefaultLightHover animate-pulse' />
                                        <CurrencyInput id='originalValue' placeHolder={salaryTemp ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(salaryTemp) : 'R$ 0,00'}
                                            onChange={(e) => {
                                                setSalaryTemp(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                            }}
                                            className='text-black font-semibold text-md text-center border border-gray-300 bg-purple-100'
                                        >
                                        </CurrencyInput>
                                    </td>
                                </tr>
                                <tr className='flex justify-between px-1 p-1 border border-gray-300'>
                                    <td className='font-semibold text-md text-primaryDefaultLight'>Salário Líquido</td>
                                    <td className='flex font-bold items-center gap-1'>
                                        {
                                            newSalary ? 
                                            <td className='flex flex-row items-center'>
                                                {(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(newSalary - discountsNewSalary?.totalDiscount))}
                                                {
                                                    detailsDiscountsNew ?
                                                    <FaAngleDown className='text-sm ml-2 cursor-pointer duration-100' onClick={() => setDetailsDiscountsNew(!detailsDiscountsNew)}/>
                                                    :                                     
                                                    <FaAngleRight className='text-sm ml-2 cursor-pointer duration-100' onClick={() => setDetailsDiscountsNew(!detailsDiscountsNew)}/>                                                
                                                }
                                                
                                            </td>
                                            : 
                                            <td>R$0,00</td>
                                        }
                                    </td>
                                </tr>
                                {
                                    detailsDiscountsNew &&
                                    discountsNewSalary?.discounts?.map(disc => {
                                        return (
                                            <tr className='flex justify-around p-1 border border-gray-300'>
                                                <td className='font-semibold text-md text-primaryDefaultLight'>{disc?.description}</td>
                                                <td className='font-semibold text-md'>{disc?.ref}</td>
                                                <td className='font-bold flex flex-row items-center'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(disc?.value))} </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr className='text-center border border-gray-300'>
                                    <th className='p-1 font-semibold text-md text-primaryDefaultLight'>Encargos</th>
                                </tr>
                                <tr className='flex justify-between px-1 border border-gray-300'>
                                    <td className='flex flex-col font-semibold'>
                                        <td>INSS Patronal</td>
                                        <td>RAT + FAP</td>
                                        <td>Terceiros</td>
                                        <td>FGTS</td>
                                        <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Encargos)</td>
                                    </td>
                                    <td className='flex flex-col font-semibold'>
                                        <td>20%</td>
                                        <td>{ratCompany}%</td>
                                        <td>5.80%</td>
                                        <td>8%</td>
                                    </td>
                                    <td className='flex flex-col font-semibold'>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((20 * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((ratCompany * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((5.80 * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * newSalary) / 100)))}</td> : <td>R$0,00</td>}</td>
                                        <td className='bg-purple-800 text-white p-1 rounded-md'>{newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValuesChargeNewSalary))}</td> : <td>R$0,00</td>}</td>
                                    </td>
                                </tr>
                                <tr className='text-center border border-gray-300'>
                                    <th className='p-1 font-semibold text-md text-primaryDefaultLight'>Provisões</th>
                                </tr>
                                <tr className='flex justify-between px-1 border border-gray-300'>
                                    <td className='flex flex-col font-semibold'>
                                        <td>1 avo férias</td>
                                        <td>1/3 férias</td>
                                        <td>INSS Férias</td>
                                        <td>RAT + FAP Férias</td>
                                        <td>Terceiro Férias</td>
                                        <td>FGTS Férias</td>
                                        <td>1 avo 13º Salário</td>
                                        <td>INSS 13º salário</td>
                                        <td>RAT + FAP 13º</td>
                                        <td>13º Terceiros</td>
                                        <td>FGTS 13º salário</td>
                                        <td className='bg-purple-800 text-white p-1 rounded-md'>Total (Provisões)</td>
                                    </td>
                                    <td className='flex flex-col font-semibold'>
                                        <td>1/12</td>
                                        <td>1/3</td>
                                        <td>20%</td>
                                        <td>{ratCompany}%</td>
                                        <td>5,8%</td>
                                        <td>8%</td>
                                        <td>1/12</td>
                                        <td>20%</td>
                                        <td>{ratCompany}%</td>
                                        <td>5,8%</td>
                                        <td>8%</td>
                                    </td>
                                    <td className='flex flex-col font-semibold'>
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary) / 12))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary / 12) / 3))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((20 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((ratCompany * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((5.8 * newSalary) / 12) / 100))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((newSalary) / 12))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((20 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((ratCompany * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((5.8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(((8 * (newSalary) / 12) / 100)))}</td> : <td>R$0,00</td>}
                                        {newSalary ? <td className='bg-purple-800 text-white p-1 rounded-md'>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValuesProvisionsNewSalary))}</td> : <td>R$0,00</td>}
                                    </td>
                                </tr>
                                <tr className='flex justify-between font-bold px-1 p-1 border border-gray-300'>
                                    <td>TOTAL</td>
                                    {newSalary > 0 ?
                                        <td className='bg-purple-800 text-white p-1 rounded-md'>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(newSalary + totalValuesNewSalary)}</td>
                                        :
                                        <td className='pr-[15px]'>R$ 0,00</td>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}