import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FiUserCheck, FiPlus, FiUserMinus, FiClock, FiAlertCircle, FiArrowDown, FiArrowUp, FiTrash2, FiX, FiPaperclip, FiDownload } from 'react-icons/fi';
import { RiArrowUpDownLine } from 'react-icons/ri';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import Loader from '../../components/Loader/loader';
import { permittedFiles } from '../../utils/filesValidadtor/permittedFiles'
import { cnpjMask } from '../../utils/formatText/cnpjMask';

// import './employeeConsult.css'
import { useAuth } from '../../hooks/useAuth';
import { handleOrdenation } from '../../utils/tableOrdenation';
import { TableDefault } from '../../components/table/table.default';
import { Button } from '../../components/buttons/button.default';
import Modal from '../../components/Modal/modal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';

export default function confirmedReadFile() {

    const token = localStorage.getItem('@auth:token');
    const { setCompany, setBranch, setCompanyName, setBranchCnpj, branch } = useCompanyBranch();
    const params = useParams()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const [loading, setLoading] = useState(true);

    const { setMenuTitle } = useMenu()
    setMenuTitle('Documentos')
    const [ employeeName, setEmployeeName ] = useState('')
    const [ employeeDocs, setEmployeeDocs ] = useState([])

    useEffect(() => {
        if (params.uuid) {
            setCompany(params.company)
            setBranch(params.branch)
            setCompanyName(params.companyName)
            setBranchCnpj(`${params.branch} - ${cnpjMask(params.branchCnpj)}`)
        }
    }, [])

    useEffect(() => {

        async function getDocsToPerson(){
            try {
                
                const employeeDocsAPI = await api.get(`api/v1/employeesDocs/confirmRead/${params.uuid}`, {
                    headers: {
                        authorization: token
                    }
                })
                setEmployeeName(`${employeeDocsAPI?.data?.data[0]?.person} - ${employeeDocsAPI?.data?.data[0]?.nomfun}`)
                setEmployeeDocs(employeeDocsAPI?.data?.data)

                setLoading(false)
                
            } catch (error){ 
                setLoading(false)
                alert('Erro ao buscar dados do colaborador, se o problema persistir entre em contato com o suporte.')
            }
        }

        getDocsToPerson()

    }, [])

    async function downloadFile(person, company, filename, originalFilename, branch){
        
        const employeeFile = await api.get(`/api/v1/employeesDocs/${person}/${company}/${branch}/${filename}`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: token
            }
        })
        
        let fileObj = URL.createObjectURL(employeeFile?.data)
        
        const myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute('download', originalFilename)
        myElement.click()

    }

    return (
        <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
            {
                loading ?
                    <div className=''>
                        <Loader />
                    </div>
                    :
                    <>
                        <div className='w-full h-24 mt-12 flex justify-center items-center'>
                            <p className='text-primaryDefaultLight text-2xl'>Colaborador: <i>{employeeName}</i></p>
                        </div>
                        <div className='w-full flex flex-col h-full'>
                            <div className='w-full flex justify-center items-center'>
                                <p className='font-semibold text-lg'>Documentos:</p>
                            </div>
                        </div>
                        <div className='w-full mt-8 flex flex-col justify-center items-center max-h-[30rem]'>
                            <div className='flex flex-col gap-5 p-8 mt-5 h-full overflow-auto shadow-borderShadow rounded-md scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                                {
                                    employeeDocs.map(docs => {
                                        return <div className='border border-gray-200 w-[40rem] h-16 flex justify-between items-center p-5 rounded-md shadow-xl'>
                                        <p className='text-lg flex'><li className='text-primaryDefaultLight'/>{docs.originalFilename}</p>
                                        <Tippy
                                            content={<a>Download do arquivo</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={80}>
                                            <div>
                                                <FiDownload className='text-lg cursor-pointer text-primaryDefaultLight' onClick={() => downloadFile(docs.person, docs.company, docs.filename, docs.originalFilename, branch)}/>
                                            </div>
                                        </Tippy>                                          
                                    </div>
                                    })
                                }                            
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}