export const graparList = [
    {
        id: 1,
        description: "Filho(a)"
    },
    {
        id: 2,
        description: "Cônjuge"
    },
    {
        id: 3,
        description: "Pai/Mãe"
    },
    {
        id: 4,
        description: "Avô/Avó"
    },
    {
        id: 5,
        description: "Bisavô(ó)"
    },
    {
        id: 6,
        description: "Sobrinho(a)"
    },
    {
        id: 7,
        description: "Tio(a)"
    },
    {
        id: 8,
        description: "Neto(a)"
    },
    {
        id: 9,
        description: "Sogro(a)"
    },
    {
        id: 10,
        description: "Genro/Nora"
    },
    {
        id: 11,
        description: "Enteado(a)"
    },
    {
        id: 12,
        description: "Irmão(a)"
    },
    {
        id: 14,
        description: "Filho(a) Adotivo(a)"
    },
    {
        id: 15,
        description: "Pensionistas"
    },
    {
        id: 16,
        description: "Companheiro(a)"
    },
    {
        id: 17,
        description: "Tutelado"
    },
    {
        id: 18,
        description: "Menor sob Guarda"
    },
    {
        id: 19,
        description: "Madrasta"
    },
    {
        id: 20,
        description: "Padrasto"
    },
    {
        id: 21,
        description: "Tutor"
    },
    {
        id: 22,
        description: "Ex-Esposo(a)"
    },
    {
        id: 23,
        description: "Bisneto(a)"
    },
    {
        id: 24,
        description: "Ex-Companheiro(a)"
    },
    {
        id: 25,
        description: "Concubino(a)"
    },
    {
        id: 26,
        description: "Curatelado"
    },
    {
        id: 27,
        description: "Pai/Mãe Socioafetivos"
    }
]