import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';

export function SimplePieAdminUserPerAccess({data, id, mobile}){    
    useEffect(() => {
        const options = {
            series: data?.map(type => {return type?.quantity}),
            colors: ['#005f9e', '#2084c7', '#311962', '#635285'],
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: data?.map(type => {return type?.access}),          
            responsive: [{
                breakpoint: 480,
                options: {
                chart: {
                    width: mobile ? 350 : 200
                },
                legend: {
                    position: 'bottom'
                }
                }
            }]
        }
  
        var chart = new ApexCharts(document.querySelector(`#${id}`), options);
        chart.render();

    }, [])

    return (
        <div id={id} className={`flex justify-center items-center h-full w-full`}>

        </div>
    )
}