import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import Loader from '../../components/Loader/loader';
import Modal from '../../components/Modal/modal';
import { FiChevronRight, FiPlus, FiTrash2 } from 'react-icons/fi';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';
import { Button } from '../../components/buttons/button.default';
import { TableDefault } from '../../components/table/table.default';
import { useMenu } from '../../hooks/useMenu';
import api from '../../services/api'
import { useCompanyBranch } from '../../hooks/useCompanyBranch'

export function ParameterizationAverage() {

    const token = localStorage.getItem('@auth:token');
    const { company } = useCompanyBranch();
    const { setMenuTitle } = useMenu();
    const navigate = useNavigate()
    setMenuTitle("Parametrização de Rateios");
    const { setShow, show } = useModal()
    const [loading, setLoading] = useState(false)
    const [rateios, setRateios] = useState([])
    const [rateio, setRateio] = useState('')
    const [isRateio, setIsRateio] = useState(false)
    const [params, setParams] = useState([])
    const [submitParam, setSubmitParam] = useState(false)
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()

    useEffect(() => {

        if (company === 0) {
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }

        async function getRateios() {

            setLoading(true)

            try {

                const getRateiosApi = await api.get(`/api/v1/averages/${company}`, { //1066 para testes
                    headers: {
                        Authorization: token
                    }
                })
                setRateios(getRateiosApi?.data?.data.map((rateio) => {
                    return {
                        ...rateio,
                        rateioDescription: `${rateio.averageCode} - ${rateio.averageDescription}`
                    }
                }))
                setIsRateio(true)

                const getRateiosParams = await api.get(`/api/v1/averages/param/${company}`, {
                    headers: {
                        Authorization: token
                    }
                })
                setParams(getRateiosParams?.data?.data.map((param) => {
                    return {
                        ...param,
                        remove: <FiTrash2 className='ml-[50%] text-red-800 cursor-pointer' onClick={() => handleDelete(param)} title={'Remover'} />
                    }
                }))

                setSubmitParam(false)
                setLoading(false)

            } catch (error) {
                setLoading(false)
                setIsRateio(false)
            }

        }

        getRateios()

    }, [submitParam, company, token])

    useEffect(() => {
        if (!show) {
            setRateio()
        }
    }, [show])

    const tableThead = [
        {
            "name": "Código",
            "original_name": "average"
        },
        {
            "name": "Descrição",
            "original_name": "description"
        },
        {
            "name": "Remover",
            "original_name": "remove"
        }
    ]

    async function handleDelete(param) {
        setLoading(true)
        try {

            await api.delete(`/api/v1/averages/param/${company}/average/${param.average}`, {
                headers: {
                    Authorization: token
                }
            })
            setSubmitParam(true)
            setLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Sucesso ao realizar exclusão de parametrização de rateio')


        } catch (error) {
            setLoading(false)
            setSubmitParam(false)
            alert(error?.response?.data?.message)
        }
    }

    async function handleSubmit() {
        setLoading(true)
        try {

            await api.post(`/api/v1/averages/param/${company}`,
                {
                    "average": `${rateio?.averageCode}`,
                    "description": `${rateio?.averageDescription}`,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                })
            setSubmitParam(true)
            setLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Sucesso ao realizar parametrização de rateio')
            setShow(false)

        } catch (error) {
            setSubmitParam(false)
            setLoading(false)
            alert(error?.response?.data?.message)
        }

    }

    return (
        <div>
            <Modal>
                <div className='animate-[wiggleLeft_1s_ease-in-out]'>
                    <p className='flex flex-row font-extrabold items-center text-primaryDefaultLight text-lg p-2'>
                        <FiChevronRight /> Vincule um Rateio.
                    </p>
                    <form onSubmit={handleSubmit} className='flex flex-col p-12'>
                        <LabelInput>
                            <InputAutoComplete
                                data={rateios}
                                selectedLabel={'rateioDescription'}
                                optionList={['rateioDescription']}
                                preSelectedValue={rateio ? rateio?.rateioDescription : ''}
                                onChange={(e) => {
                                    if (e) {
                                        if (typeof (e) == 'object') {
                                            setRateio(e);
                                        }
                                    }
                                }}
                                placeHolder='Selecione um Rateio'
                            />
                        </LabelInput>
                        <div className='flex flex-col justify-center items-center mt-12'>
                            <Button>Vincular</Button>
                        </div>
                    </form>
                </div>
            </Modal>
            {
                loading ?
                    <div className='flex flex-col w-full items-center justify-center py-8'>
                        <Loader />
                    </div>
                    : !isRateio ?
                        <h1 className='animate-[wiggleLeft_1s_ease-in-out] text-center text-base lg:text-2xl flex justify-center items-center mt-20 text-primaryDefaultLight'>Essa empresa não trabalha com rateios.</h1>
                        :
                        (
                            <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                                <div className="hidden lg:flex flex-col justify-start align-center ml-20 w-[100%] mr-8 mt-3 gap-5 h-[90vh]">
                                    <TableDefault
                                        buttonDescription={'+ Novo'}
                                        buttonFunction={setShow}
                                        buttonFunctionParam={true}
                                        title={tableThead}
                                        data={params}
                                        quantity={params.length}
                                        collumns={["average", "description", "remove"]}
                                    />
                                </div>
                                <div className='flex lg:hidden w-full items-center justify-center py-8'>
                                    <p className='text-titleBlackTextLight text-base text-center'>
                                        Esta funcionalidade está disponível apenas para computador
                                    </p>
                                </div>
                            </div>
                        )
            }
        </div>
    )
}
