import React, { createContext, useContext, useState } from 'react'

const menuContext = createContext({});

export const MenuProvider = ({ children }) => {
    const [menuStatus, setMenuStatus] = useState(0);
    const [menuSelected, setMenuSelected] = useState('');
    const [menuTitle, setMenuTitle] = useState('');
    const [showProfile, setShowProfile] = useState(false);

    return (
        <menuContext.Provider value={{
            menuStatus, setMenuStatus,
            menuSelected, setMenuSelected,
            menuTitle, setMenuTitle,
            showProfile, setShowProfile
        }}>
            {children}
        </menuContext.Provider>
    )
}

// Cria Hook para mandar context do menu
export function useMenu() {
    return useContext(menuContext);
}