import React, { useEffect, useRef } from 'react'
import { useModal } from '../../hooks/useModal'
import { useMenu } from '../../hooks/useMenu'

export default function Modal({ children }) {

    const { show, setShow } = useModal()
    const modalRef = useRef();
    const { menuStatus } = useMenu()

    const closeModal = (e) =>{

        if (!modalRef?.current?.contains(e?.target) && !e?.target?.childNodes[0]?.length && !e?.target?.childNodes[0]?.childNodes[0]?.length) {
            setShow(false)
        }

    }

    useEffect(() => {

        if (show) {
            window.document.addEventListener('click', closeModal)

            return () => window.document.removeEventListener('click', closeModal)
        }
    
    }, [show])

    const eventListener = (event) => {
        if(event.key == 'Escape'){
            setShow(false)
        }
    }

    if(show === true){
        window.addEventListener('keydown', eventListener)
    }else{
        window.removeEventListener('keydown', eventListener)
    }

    return (
        <div className='flex flex-col'>
            <div className={`${show === true ? 'flex' : 'hidden'} absolute w-full h-full flex flex-col items-center justify-center top-0 left-0 z-[999]`}>               
               <div ref={modalRef} className={`bg-white w-full lg:h-fit lg:w-fit flex flex-col justify-center items-center rounded-lg ${menuStatus == 1 ? 'lg:ml-60 ml-0' : ''}`}>                
                {children}
               </div>
            </div>
            <div className={`${show === true ? 'flex' : 'hidden'} bg-neutral-500 opacity-80 absolute w-full h-full top-0 left-0 z-[50]`}/>
        </div>

    )
}