import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import './param.css';
import { FiTrash2, FiPlus, FiChevronRight, FiArrowUp, FiArrowDown, FiTrash, FiEdit2, FiEdit, FiX } from 'react-icons/fi';
import api from '../../services/api';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import Modal from '../../components/Modal/modal'
import { useModal } from '../../hooks/useModal';
import { useMenu } from '../../hooks/useMenu';
import Loader from '../../components/Loader/loader';
import { TableDefault } from '../../components/table/table.default'
import { Button } from '../../components/buttons/button.default'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { LabelInput } from '../../components/label/label.input'
import { Toggle } from '../../components/toggle/toggleButton';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { Input } from '../../components/input/input';
import { justNumber } from '../../utils/justNumber'
import { FaArrowLeft, FaArrowRight, FaList } from 'react-icons/fa';

export function TypesNotes(){

    const token = localStorage.getItem('@auth:token');
    const { company, companyName, branch, setCompany, setBranch, setCompanyName, setBranchCnpj } = useCompanyBranch();
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { setMenuTitle } = useMenu()
    setMenuTitle("Tipos de Anotação")
    const navigate = useNavigate()
    const { setShow, show } = useModal()
    const [loading, setLoading] = useState(false)
    const [typesList, setTypesList] = useState([])
    const [selectedType, setSelectedType] = useState('')
    const [buttonRemove, setButtonRemove] = useState(false)
    const [buttonCreate, setButtonCreate] = useState(false)
    const [buttonEdit, setButtonEdit] = useState(false)
    const [selectedDescription, setSelectedDescription] = useState('')

    useEffect(() => {
        getData()
    }, [])

    async function getData(){
        setLoading(true)
        try {
            const getTypesApi = await api.get(`api/v1/typeNotes/company/${company}`, {
                headers: {
                    Authorization: token
                }
            })
            setTypesList(getTypesApi?.data?.data?.map(item => {
                return {
                    ...item,
                    edit:
                        <Tippy content={<span>Editar</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiEdit className='cursor-pointer ml-[50%] text-blue-600' onClick={() => {setSelectedType(item); setButtonEdit(true); setShow(true)}} />
                            </div>
                        </Tippy>,
                    trash:
                        <Tippy content={<span>Excluir</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiTrash2 className='cursor-pointer ml-[50%] text-red-800' onClick={() => {setSelectedType(item); setButtonRemove(true); setShow(true)}} />
                            </div>
                        </Tippy>
                    }
            }))
            setLoading(false)
        } catch(error){
            setLoading(false)
        }
    }

    useEffect(() => {
        if(!show){
            clearState()          
        }
    }, [show])

    async function handleDelete(){
        setLoading(true)
        try {
            await api.delete(`api/v1/typeNotes/${selectedType?.id}`, {
                headers: {
                    Authorization: token
                }
            })
            setShowAprovalModal(false)
            setShowNotificationModal(true)
            setNotificationModalText('Tipo removido com sucesso')
            getData()

        } catch(error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText(error?.response?.data?.message)
        }
    }

    async function handleSubmit(){
        if (!selectedDescription){
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Preencha os campos')
        }

        setLoading(true)
        setShow(false)

        try {
            await api.post(`api/v1/typeNotes`, {
                company,
                description: selectedDescription
            }, {
                headers: {
                    Authorization: token
                }
            })
            setLoading(false)
            setShowAprovalModal(false)
            setShowNotificationModal(true)
            setNotificationModalText('Tipo criado com sucesso')
            getData()

        } catch(error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao criar tipo de anotação')
        }
    }

    async function handleEdit(){
        setLoading(true)
        setShow(false)

        try {
            await api.put(`api/v1/typeNotes/${selectedType?.id}`, {
                description: selectedType?.description,
            }, {
                headers: {
                    Authorization: token
                }
            })
            setLoading(false)
            setShowAprovalModal(false)
            setShowNotificationModal(true)
            setNotificationModalText('Tipo atualizado com sucesso')
            getData()

        } catch(error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao criar anotação')
        }
    }

    function clearState(){
        setButtonRemove(false)
        setButtonCreate(false)
        setButtonEdit(false)
        setSelectedDescription('')
    }

    const tableThead = [
        {
            "name": "Descrição",
            "original_name": "description"
        },
        {
            "name": "Editar",
            "original_name": "edit"
        },
        {
            "name": "Remover",
            "original_name": "trash"
        }
    ]

    return (
        <>
            <Modal>
            {
                show && buttonRemove ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-semibold items-center text-sm'>
                                <p className='mr-7'>Deseja realmente excluir esse tipo?</p>
                            </p>
                            <FiX className='text-gray-500 text-lg mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                        </div>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button height={6} width={24} background={'bg-green-800'} type={'button'} onClick={() => { setShow(false); handleDelete() }}>Confirmar</Button>
                            <Button height={6} width={24} background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                        </div>
                    </div>
                </Modal>
                : show && buttonCreate ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full sm:w-[28rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-semibold items-center text-sm'>
                                <p className='mr-7'>Criação de tipo de anotação</p>
                            </p>
                            <FiX className='text-gray-500 text-lg mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                        </div>
                        <form className='flex flex-col gap-2 h-full w-full p-5 items-center justify-center mt-3 mb-5'>
                            <textarea
                                className='resize-none p-2 w-80 text-sm rounded-md h-32'
                                maxLength={255}
                                onChange={(e) => {
                                    setSelectedDescription(e?.target?.value)
                                }}
                                value={selectedDescription ? selectedDescription : ''}
                                placeholder='Tipo de anotação'
                            />
                        </form>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4 mt-5'>
                            <Button height={6} width={24} background={'bg-green-800'} type={'button'} onClick={() => { handleSubmit() }}>Confirmar</Button>
                            <Button height={6} width={24} background={'bg-red-800'} onClick={() => { setShow(false); setButtonCreate(false) }}>Cancelar</Button>
                        </div>
                    </div>
                </Modal>
                : show && buttonEdit ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full sm:w-[28rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-semibold items-center text-sm'>
                                <p className='mr-7'>Edição de tipo de anotação</p>
                            </p>
                            <FiX className='text-gray-500 text-lg mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                        </div>
                        <form className='flex flex-col gap-2 h-full w-full p-5 items-center justify-center mt-3 mb-5'>
                            <textarea
                                className='resize-none p-2 w-80 text-sm rounded-md h-32'
                                maxLength={255}
                                onChange={(e) => {
                                    setSelectedType(prev => ({...prev, description: e?.target?.value}))
                                }}
                                value={selectedType?.description ? selectedType?.description : ''}
                                placeholder='Tipo de anotação'
                            />
                        </form>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4 mt-5'>
                            <Button height={6} width={24} background={'bg-green-800'} type={'button'} onClick={() => { handleEdit() }}>Editar</Button>
                            <Button height={6} width={24} background={'bg-red-800'} onClick={() => { setShow(false); setButtonEdit(false) }}>Cancelar</Button>
                        </div>
                    </div>
                </Modal>
                :
                <></>
            }
            </Modal>
            <div className="hidden lg:flex conteudo">
                {
                    loading ? 
                    <Loader/>
                    :
                    <div className="hidden lg:flex flex-col justify-start align-center px-2 lg:ml-20 w-[100%] mr-8 mt-3 gap-5 h-[90vh]">                        
                        <div className='flex flex-row items-center justify-between'>
                            <p className='flex flex-row items-center justify-center gap-1 cursor-pointer hover:text-gray-700 duration-100' onClick={() => navigate('/notes')}>
                                <FaArrowLeft className='text-xs items-center flex justify-center'/> 
                                Voltar
                            </p>
                            <Button width={20} height={6} onClick={() => {setButtonCreate(true); setShow(true)}}>+ Novo</Button>
                        </div>
                        <TableDefault
                            onClick={() => {}}
                            title={tableThead}
                            data={typesList}
                            quantity={typesList.length}
                            buttonDescription={'+ Novo'}
                            collumns={["description", "edit", "trash"]}
                        />
                    </div>
                }
            </div>
        </>
    )
} 