import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import Loader from '../../components/Loader/loader';
import { LoadingPulse } from '../../components/Loader/loadingPulse';
import { LabelInput } from '../../components/label/label.input';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { justNumber } from '../../utils/justNumber';
import { useMenu } from '../../hooks/useMenu'
import { FaPrint } from 'react-icons/fa';
import { useModal } from '../../hooks/useModal';
import { Input } from '../../components/input/input';

export function Holiday() {

    const token = localStorage.getItem('@auth:token')
    const [loading, setLoading] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingPeriod, setLoadingPeriod] = useState(true)
    const { company, branch } = useCompanyBranch()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { setMenuTitle } = useMenu()
    setMenuTitle('Prévia')
    const [employeeList, setEmployeeList] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState('')
    const [dataEmployee, setDataEmployee] = useState({})
    const [selectedDays, setSelectedDays] = useState('')
    const [selectedAllowance, setSelectedAllowance] = useState('')
    const [selectedDate, setSelectedDate] = useState('')

    useEffect(() => {

        setLoading(true)

        async function getEmployee() {
            try {
                const employeeData = await api.get(`api/v1/employees/company/${company}/branch/${branch}?status=1`, {
                    headers: {
                        Authorization: token
                    }
                })
                setEmployeeList(employeeData?.data?.data)
            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        getEmployee()
        setLoading(false)

    }, [])

    async function downloadFile(){
        setLoadingDownload(true)

        if (!selectedEmployee){
            setLoadingDownload(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Selecione um colaborador')
        }

        if (!selectedDays || selectedDays > dataEmployee?.dias){
            setLoadingDownload(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Verifique os dias disponíveis')
        }

        if (selectedAllowance){
            if (Number(selectedAllowance) + Number(selectedDays) > dataEmployee?.dias){
                setLoadingDownload(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText('Mais dias do que o disponível.')
            }
            const limitDaysAllowance = Number(dataEmployee?.dias / 3)

            if (Number(selectedAllowance) > limitDaysAllowance){
                setLoadingDownload(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText('Abono tem mais dias do que o permitido.')
            }
        }

        if (selectedDays && selectedDays < 5){
            setLoadingDownload(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Férias precisa ser maior que 5 dias.')
        }

        try {
            const file = await api.post(`api/v1/preview/holiday/company/${company}/branch/${branch}`,{
                numcad: selectedEmployee?.employeeId,
                days: selectedDays,
                allowance: selectedAllowance ? selectedAllowance : undefined,
                pdf: true,
                dateToLeave: selectedDate ? selectedDate : undefined
            }, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `PreviaFerias_${Number(new Date())}`)
            myElement.click()
            setLoadingDownload(false)
        } catch (error){
            setLoadingDownload(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro no download')
        }

    }

    async function getPeriodAndAvailable(person){
        setLoadingPeriod(true)

        try {

            const getPeriodAPI = await api.get(`api/v1/employees/daysAvailable/company/${company}/branch/${branch}/numcad/${person?.employeeId}`, {
                headers: {
                    authorization: token
                }
            })
            setDataEmployee(getPeriodAPI?.data?.data)
            setSelectedDays('')
            setLoadingPeriod(false)

        } catch(error){
            setLoadingPeriod(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao buscar período')
        }
    }

    return (
        <>
            {
                loading ?
                <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[85vh] items-center justify-center">
                    <Loader />
                </div>
                :
                <>
                    <div className={`flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14 ${loadingDownload && 'cursor-wait'}`}>
                        <p className='text-lg md:text-xl font-semibold text-center justify-center text-primaryDefaultLight 2xl:mt-5'>Férias</p>
                        <div className={`flex flex-col w-fit h-fit justify-center items-center shadow-xl rounded-lg sm:mt-1 2xl:mt-10 p-2 ${loadingDownload && 'cursor-wait'}`}>
                            <div className='flex flex-col justify-center h-full items-center p-3'>
                                <div className={`flex flex-col items-center sm:items-start h-full ${loadingDownload && 'cursor-wait'}`}>
                                    <label className='mt-0 sm:mt-2 sm:text-sm text-xs'>Colaborador *</label>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={employeeList}
                                            id={'employee'}
                                            preSelectedValue={selectedEmployee ? selectedEmployee?.employeeName : ''}
                                            value={selectedEmployee ? selectedEmployee?.employeeName : ''}
                                            optionList={["employeeId", "employeeName"]}
                                            onChange={(e) => {
                                                setSelectedEmployee(e)
                                                if (typeof(e) == 'object'){
                                                    getPeriodAndAvailable(e)
                                                }
                                            }}
                                            selectedLabel={'employeeName'}
                                        />
                                    </LabelInput>
                                    {
                                        selectedEmployee && typeof(selectedEmployee) == 'object' && loadingPeriod ?
                                        <div className='flex flex-col items-center justify-center w-full h-full'>
                                            <LoadingPulse/>
                                        </div>
                                        : !loadingPeriod ?
                                        <div className='flex flex-col items-center justify-center w-full h-full gap-2 sm:gap-2 mt-5 sm:mt-3'>
                                            <div className=''>
                                                <div className='flex flex-col w-60 p-1 shadow shadow-gray-300 justify-center items-center rounded-md'>
                                                    <p className='font-semibold text-xs sm:text-sm'>Período</p>
                                                    <p className='mt-1 text-xs sm:text-sm'>{dataEmployee?.iniper} à {dataEmployee?.fimper}</p>
                                                </div>
                                                <div className='flex flex-col w-60 p-1 shadow shadow-gray-300 justify-center items-center rounded-md'>
                                                    <p className='font-semibold text-xs sm:text-sm'>Limite Pagamento</p>
                                                    <p className='mt-1 text-xs sm:text-sm'>{dataEmployee?.datlim}</p>
                                                </div>
                                                <div className='flex flex-col w-60 p-1 shadow shadow-gray-300 justify-center items-center rounded-md'>
                                                    <p className='font-semibold text-xs sm:text-sm'>Dias Disponíveis</p>
                                                    <p className='mt-1 text-xs sm:text-sm'>{dataEmployee?.dias}</p>
                                                </div>
                                            </div>
                                            <div className='flex flex-col items-center sm:items-start'>
                                                <label className='mt-1 mb-1 sm:text-sm text-xs'>Data das férias</label>
                                                <Input
                                                    id={'dateToLeave'}
                                                    type={'date'}
                                                    onChange={(e) => {
                                                        setSelectedDate(e?.target?.value)
                                                    }}
                                                    value={selectedDate ? selectedDate : ''}
                                                />
                                                <label className='mt-1 mb-1 sm:text-sm text-xs'>Quantidade de dias férias *</label>
                                                <Input
                                                    id={'days'}
                                                    type={'text'}
                                                    onChange={(e) => {
                                                        setSelectedDays(justNumber(e?.target?.value))
                                                    }}
                                                    value={selectedDays ? selectedDays : ''}
                                                />
                                                <label className='mt-1 mb-1 sm:text-sm text-xs'>Quantidade de dias abono</label>
                                                <Input
                                                    id={'allowance'}
                                                    type={'text'}
                                                    onChange={(e) => {
                                                        setSelectedAllowance(justNumber(e?.target?.value))
                                                    }}
                                                    value={selectedAllowance ? selectedAllowance : ''}
                                                />
                                            </div>
                                            <div className={`flex h-full flex-col w-full items-center justify-center ${loadingDownload && 'cursor-wait'}`}>
                                                <div className={`mt-0 sm:mt-1 bg-primaryDefaultLight text-white w-52 ${loadingDownload ? 'cursor-wait' : 'cursor-pointer'} h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95`} 
                                                    onClick={() => {downloadFile()}}
                                                >
                                                    <FaPrint className='text-sm'/> Gerar PDF
                                                </div>
                                            </div>
                                        </div>
                                        : 
                                        <div className='flex flex-col items-center justify-center w-full h-full gap-5 mt-10 pb-10'>
                                            <p className='text-lg'>Selecione um colaborador</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}