export function formatDate({date, type = 'string'}){
    
    switch (type) {
        case 'database':
            const dateFormat = new Date(date)
            return Intl.DateTimeFormat('pt-BR').format(dateFormat)
        case 'js':
            return Intl.DateTimeFormat('pt-BR').format(date)
        default:
            return date
    }

}

