import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';

export function SplineFinance({ data, id }) {

    const dataGrossValue = data?.grossValue?.map(apiData => apiData)
    const dataNetValue = data?.liquidValue?.map(apiData => apiData)

    useEffect(() => {
        var options = {
            series: [{
                name: 'Valor Bruto',
                data: dataGrossValue
            }, {
                name: 'Valor Líquido',
                data: dataNetValue
            }],
            chart: {
                height: 350,
                type: 'area'
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            colors: ['#311962', '#ef4444'],
            xaxis: {
                type: 'category',
                categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
            }
        };

        var chart = new ApexCharts(document.querySelector(`#${id}`), options);
        chart.render();

    }, [])

    return (
        <div id={id}>

        </div>
    )
}