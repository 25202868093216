import React from 'react';
import { Link } from 'react-router-dom'

import './notFound.css';

export default function NotFound(){
    return (
        <div className='notFoundBody'>
            <br/>
            <h1> 404 </h1>
            <hr/>
            <br/>
            <h2> PAGE NOT FOUND </h2>
            <br/>
            <Link to='/'><button>Go to home page</button></Link>
        </div>
    );
}