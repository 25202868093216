import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import './param.css';
import { FiTrash2, FiPlus, FiChevronRight, FiArrowUp, FiArrowDown, FiTrash, FiEdit2, FiEdit, FiX } from 'react-icons/fi';
import api from '../../services/api';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import Modal from '../../components/Modal/modal'
import { useModal } from '../../hooks/useModal';
import { useMenu } from '../../hooks/useMenu';
import Loader from '../../components/Loader/loader';
import { TableDefault } from '../../components/table/table.default'
import { Button } from '../../components/buttons/button.default'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { LabelInput } from '../../components/label/label.input'
import { Toggle } from '../../components/toggle/toggleButton';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { Input } from '../../components/input/input';
import { justNumber } from '../../utils/justNumber'
import { FaList } from 'react-icons/fa';

export default function Param() {

  const token = localStorage.getItem('@auth:token');
  const { company, companyName, branch, setCompany, setBranch, setCompanyName, setBranchCnpj } = useCompanyBranch();
  const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()

  const { setMenuTitle } = useMenu();
  setMenuTitle("Parametrização de Eventos");
  const navigate = useNavigate()
  const { setShow, show } = useModal()
  const [events, setEvents] = useState([]);
  const [params, setParams] = useState([]);
  const [sortDirection, setSortDirection] = useState({
    tableData: params,
    sortTo: true,
    collumnName: ''
  })
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedType, setSelectedType] = useState(0);
  const [submitValue, setSubmitValue] = useState(false);
  const [loading, setLoading] = useState();
  const [filteredValue, setFilteredValue] = useState('')
  const typeEvent = ["", "Referência / Horas", "Valor / R$"];
  const [toggleButton, setToggleButton] = useState(false)
  const [selectedSequence, setSelectedSequence] = useState('')
  const [eventTemp, setEventTemp] = useState('')
  const [modalEdit, setModalEdit] = useState(false)
  const [notExistsEventsToBranch, setNotExistsEventsToBranch] = useState(false)
  const [showMaster, setShowMaster] = useState(false)
  const [eventsMasterList, setEventsMasterList] = useState([])
  const [branchEventsListFake, setBranchEventsListFake] = useState([])
  const [withoutEvents, setWithoutEvents] = useState(false)

  useEffect(() => {
    setShow(false)
    setLoading(true)
    setSelectedEvent('')
    setSelectedType(0)
    setToggleButton(false)
    setSelectedSequence('')
    async function consultData() {
      const responseParam = await api.get(`/api/v1/eventsParam/${company}/${branch}`, {
        headers: {
          Authorization: token
        }
      })

      if (responseParam?.data.length > 0) {
        if (branch != 1 && responseParam?.data[0]?.branch == 1) {
          setNotExistsEventsToBranch(true)
          const formatResponseParam = responseParam.data.map((item) => {
            return {
              ...item,
              eventTypeDesc: item.eventType == 2 ? 'Valor / R$' : 'Referência / Horas',
              sequence: item.sequence != 1000 ? item.sequence : ' - ',
              edit:
                <Tippy content={<span>Editar</span>}
                  arrow={true}
                  animation='perspective'
                  placement='top'
                  className="ml-3"
                  delay={100}>
                  <div>
                    <FiEdit className='cursor-pointer ml-[50%] text-blue-600' onClick={() => { setEventTemp(item); setShow(true); setModalEdit(true); }} />
                  </div>
                </Tippy>,
              trash:
                <Tippy content={<span>Excluir</span>}
                  arrow={true}
                  animation='perspective'
                  placement='top'
                  className="ml-3"
                  delay={100}>
                  <div>
                    <FiTrash2 className='cursor-pointer ml-[50%] text-red-800' onClick={() => handleDelete(item.event)} />
                  </div>
                </Tippy>
            }
          })
          setEventsMasterList(formatResponseParam)
          if (branch == 1) {
            const formatResponseParam = responseParam.data.map((item) => {
              return {
                ...item,
                eventTypeDesc: item.eventType == 2 ? 'Valor / R$' : 'Referência / Horas',
                sequence: item.sequence != 1000 ? item.sequence : ' - '
              }
            })
            setParams(formatResponseParam);
          }
        } else {
          const formatResponseParam = responseParam.data.map((item) => {
            return {
              ...item,
              eventTypeDesc: item.eventType == 2 ? 'Valor / R$' : 'Referência / Horas',
              sequence: item.sequence != 1000 ? item.sequence : ' - ',
              edit:
                <Tippy content={<span>Editar</span>}
                  arrow={true}
                  animation='perspective'
                  placement='top'
                  className="ml-3"
                  delay={100}>
                  <div>
                    <FiEdit className='cursor-pointer ml-[50%] text-blue-600' onClick={() => { setEventTemp(item); setShow(true); setModalEdit(true); }} />
                  </div>
                </Tippy>,
              trash:
                <Tippy content={<span>Excluir</span>}
                  arrow={true}
                  animation='perspective'
                  placement='top'
                  className="ml-3"
                  delay={100}>
                  <div>
                    <FiTrash2 className='cursor-pointer ml-[50%] text-red-800' onClick={() => handleDelete(item.event)} />
                  </div>
                </Tippy>
            }
          })
          setParams(formatResponseParam);
        }
      } else {
        if (branch == 1) {
          const formatResponseParam = responseParam.data.map((item) => {
            return {
              ...item,
              eventTypeDesc: item.eventType == 2 ? 'Valor / R$' : 'Referência / Horas',
              sequence: item.sequence != 1000 ? item.sequence : ' - '
            }
          })
          setParams(formatResponseParam);
        } else {
          setWithoutEvents(true)
        }
      }

      setSortDirection((prev) => ({
        ...prev,
        tableData: responseParam.data
      }))

      const responseEvents = await api.get('/api/v1/eventsParam', {
        headers: {
          Authorization: token,
          company: company
        }
      })

      setSubmitValue(false)
      setEvents(responseEvents.data);

      setLoading(false)
    }
    consultData()

  }, [token, company, submitValue]);

  useEffect(() => {
    if (!show) {
      setSelectedEvent({})
      setSelectedType()
      setSelectedSequence('')
      setToggleButton(false)
      setModalEdit(false)
      setShowMaster(false)
    }
  }, [show])

  if (company === 0) {
    alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
    return navigate('/')
  }

  const optionsType = [
    { value: 1, label: 'Referência / Horas' },
    { value: 2, label: 'Valor / R$' }
  ]

  async function handleSubmit(element) {
    element.preventDefault();
    setLoading(true)

    if (selectedEvent === "" || selectedEvent === undefined) {
      alert('Selecione um evento');
      setLoading(false)
      return false;
    } else if (selectedType === undefined || selectedType === "") {
      alert('Selecione um evento');
      setLoading(false)
      return false;
    } else {

      const selectedEventObject = selectedEvent;

      try {
        const responseSubmit = await api.post('/api/v1/eventsParam', {
          company: company,
          branch: branch,
          event: selectedEventObject.event,
          description: selectedEventObject.description,
          type: selectedType,
          sequence: selectedSequence ? selectedSequence : undefined
        }, {
          headers: {
            Authorization: token,
          }
        })
        setToggleButton(false)
        setSelectedEvent({})
        setSelectedType('')
        setSubmitValue(true)
        setLoading(false)
        setShowNotificationModal(true)
        setNotificationModalText('Cadastrado realizado com sucesso')
        return navigate('/parameterization')

      } catch (error) {
        setLoading(false)
        return alert('Erro ao realizar cadastro. Se o problema persistir entre em contato com o suporte.')
      }
    }

  }

  async function handleDelete(element) {
    setLoading(true)
    api.delete(`/api/v1/eventsParam/company/${company}/branch/${branch}/event/${element}`, {
      headers: {
        Authorization: token,
      }
    }).then(response => {
      setSubmitValue(true)
      setLoading(false)
      setShowNotificationModal(true)
      setNotificationModalText(response.data)
      return navigate('/parameterization')
    });
  }

  async function handleEdit(element) {
    setLoading(true)

    if (!selectedSequence) {
      alert('Selecione uma ordem');
      setLoading(false)
      return false;
    }

    api.put(`/api/v1/eventsParam/`, {
      company: element?.company,
      branch: branch,
      event: element?.event,
      sequence: selectedSequence
    }, {
      headers: {
        Authorization: token,
      }
    }).then(response => {
      setSubmitValue(true)
      setLoading(false)
      setShowNotificationModal(true)
      setNotificationModalText(response.data)
      return navigate('/parameterization')
    });
  }

  async function handleShowDisabled() {
    setLoading(true)
    setParams([])
    await new Promise(resolve => setTimeout(resolve, 50))
    setNotExistsEventsToBranch(false)
    setLoading(false)
  }

  async function paramEventsMaster() {
    setLoading(true)
    setParams([])
    const resultBranches = await api.get(
      `api/v1/companies/branch/${company}`, {
      headers: {
        authorization: token
      }
    }
    )

    const sessionData = {
      company,
      companyName,
      branch: resultBranches?.data?.data[0]?.value,
      branchCnpj: resultBranches?.data?.data[0]?.label
    }
    setBranch(resultBranches?.data?.data[0]?.value);
    setBranchCnpj(resultBranches?.data?.data[0]?.label);
    localStorage.setItem('@session:data', JSON.stringify(sessionData));

    setWithoutEvents(false)
    setLoading(false)
  }

  const tableThead = [
    {
      "name": "Código",
      "original_name": "event"
    },
    {
      "name": "Descrição",
      "original_name": "description"
    },
    {
      "name": "Tipo",
      "original_name": "eventTypeDesc"
    },
    {
      "name": "Ordenação",
      "original_name": "sequence"
    },
    {
      "name": "Editar",
      "original_name": "edit"
    },
    {
      "name": "Remover",
      "original_name": "trash"
    }
  ]

  const tableTheadMaster = [
    {
      "name": "Código",
      "original_name": "event"
    },
    {
      "name": "Descrição",
      "original_name": "description"
    },
    {
      "name": "Tipo",
      "original_name": "eventTypeDesc"
    },
    {
      "name": "Ordenação",
      "original_name": "sequence"
    }
  ]

  return (
    <div>
      <Modal>
        {
          show && modalEdit ?
            <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
              <div className='flex justify-between pl-3 py-5 items-center'>
                <p className='flex flex-row font-extrabold items-center text-lg'>
                  <p className='mr-7'>Edição - {eventTemp?.description}</p>
                </p>
                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
              </div>
              <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                <LabelInput>
                  <Input
                    id={'sequence'}
                    onChange={(e) => setSelectedSequence(justNumber(e.target.value))}
                    value={selectedSequence ? selectedSequence : ''}
                    placeholder={'Ordem'}
                  />
                </LabelInput>
              </div>
              <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                <Button background={'bg-red-800'} onClick={() => { setShow(false); setModalEdit(false) }}>Cancelar</Button>
                <Button background={'bg-green-800'} type={'button'} onClick={() => { setShow(false); handleEdit(eventTemp) }}>Editar</Button>
              </div>
            </div>
            : show && showMaster ?
              <div className={`h-fit max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[50rem] p-4`}>
                <div className='flex justify-between pl-3 py-5 items-center mb-5'>
                  <p className='flex flex-row font-extrabold items-center text-lg'>
                    <p className='mr-7 flex justify-center items-center'><FaList className='flex mr-2' />Eventos da matriz</p>
                  </p>
                  <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                </div>
                <TableDefault
                  title={tableTheadMaster}
                  data={eventsMasterList}
                  collumns={["event", "description", "eventTypeDesc", "sequence"]}
                />
              </div>
              :
              <div className={`${!show ? 's' : 'conf'} p-10 flex flex-col justify-center items-center bg-gray-200 rounded-lg w-[35rem]`}>
                <p className='flex flex-row font-extrabold text-primaryDefaultLight items-center text-lg'>
                  <FiChevronRight /> Efetue a parametrização de evento abaixo.
                </p>
                <form onSubmit={handleSubmit} className='flex flex-col gap-5 h-full mt-7'>
                  <LabelInput>
                    <InputAutoComplete
                      data={events}
                      selectedLabel={'label'}
                      optionList={['label']}
                      preSelectedValue={selectedEvent?.description ? selectedEvent?.description : ''}
                      onChange={(e) => {
                        setSelectedEvent({
                          event: e.codeve,
                          description: e.deseve
                        })
                      }}
                      placeHolder='Selecione um Evento'
                    />
                  </LabelInput>
                  <LabelInput>
                    <InputAutoComplete
                      data={optionsType}
                      selectedLabel={'label'}
                      optionList={['label']}
                      preSelectedValue={selectedType ? optionsType.filter(e => { return e?.value === selectedType })[0]?.label : ''}
                      onChange={(e) => {
                        setSelectedType(e.value)
                      }}
                      placeHolder='Selecione um Tipo'
                    />
                  </LabelInput>
                  <div>
                    <Tippy
                      content={<a>Adicionar ordenação</a>}
                      arrow={true}
                      animation='shift-away'
                      placement='top'
                      delay={80}>
                      <div className='flex justify-center items-center'>
                        <Toggle status={toggleButton ? true : false} onClick={(e) => { setToggleButton(!toggleButton) }} />
                      </div>
                    </Tippy>
                  </div>
                  {
                    toggleButton &&
                    <LabelInput>
                      <Input
                        id={'sequence'}
                        onChange={(e) => setSelectedSequence(justNumber(e.target.value))}
                        value={selectedSequence ? selectedSequence : ''}
                        placeholder={'Ordem'}
                      />
                    </LabelInput>
                  }
                  <div className='flex flex-col justify-center align-center mt-10 ml-32'>
                    <Button>Adicionar</Button>
                  </div>
                </form>
              </div>
        }
      </Modal>
      {
        loading ?
          <div className='animate-[wiggleLeft_1s_ease-in-out]'>
            <br />
            <br />
            <br />
            <div className='flex flex-col items-center justify-center h-[85vh]'>
              <Loader />
            </div>
          </div>
          : notExistsEventsToBranch ?
            <div className="flex animate-[wiggleLeft_1s_ease-in-out] conteudo flex-col justify-center items-center">
              <div className='w-[90%] h-96 flex flex-col justify-center items-center'>
                <div className='w-[65rem] lg:ml-10 justify-center items-center rounded-lg text-center border bg-gray-50 h-24'>
                  <p className='flex justify-center items-center text-xl text-red-800 mt-8'>
                    Não existem eventos para essa filial,
                    os eventos parametrizados por padrão serão os mesmos da filial um (matriz).
                  </p>
                </div>
                <div className='flex justify-center items-center gap-10 mt-10'>
                  <Button width={96} onClick={() => { setShowMaster(true); setShow(true) }}>Visualizar eventos da matriz</Button>
                  <Button width={96} onClick={() => handleShowDisabled()}>Parametrizar para essa filial</Button>
                </div>
              </div>
            </div>
            : withoutEvents ?
              <div className="conteudo flex flex-col justify-center items-center">
                <div className='w-[90%] h-96 flex flex-col justify-center items-center'>
                  <div className='w-[65rem] ml-10 justify-center items-center rounded-lg text-center border bg-gray-50 h-24'>
                    <p className='flex justify-center items-center text-xl text-red-800 mt-8'>
                      Não existem eventos para essa filial e também para a matriz.
                    </p>
                  </div>
                  <div className='flex justify-center items-center gap-10 mt-10'>
                    <Button width={96} onClick={() => paramEventsMaster()}>Parametrizar para a matriz</Button>
                    <Button width={96} onClick={() => setWithoutEvents(false)}>Parametrizar para essa filial</Button>
                  </div>
                </div>
              </div>
              :
              (
                <>
                  <div className="hidden lg:flex conteudo">
                    <div className="hidden lg:flex flex-col justify-start align-center px-2 lg:ml-20 w-[100%] mr-8 mt-3 gap-5 h-[90vh]">
                      <TableDefault
                        title={tableThead}
                        data={params}
                        buttonFunction={setShow}
                        buttonFunctionParam={true}
                        quantity={params.length}
                        buttonDescription={'+ Novo'}
                        collumns={["event", "description", "eventTypeDesc", "sequence", "edit", "trash"]}
                      />
                    </div>
                  </div>
                  <div className='flex lg:hidden items-center justify-center py-10'>
                    <p className='text-center text-titleBlackTextLight text-base'>Esta funcionalidade está disponível apenas para computador</p>
                  </div>
                </>
              )
      }
    </div>
  );
}