import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useMenu } from "../../hooks/useMenu";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { Button } from '../../components/buttons/button.default';
import { useAuth } from "../../hooks/useAuth";
import api from '../../services/api';
import Loader from "../../components/Loader/loader";
import { TableDefault } from "../../components/table/table.default";
import { FiChevronRight, FiEdit, FiTrash2, FiX } from "react-icons/fi";
import Modal from "../../components/Modal/modal";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { LabelInput } from "../../components/label/label.input";

export function Companies() {
    const { user } = useAuth()
    const {
        showNotificationModal,
        setShowNotificationModal,
        notificationModalText,
        setShowAprovalModal,
        setNotificationModalText,
    } = useModal()

    const { setMenuTitle } = useMenu()
    setMenuTitle("Empresas")
    const navigate = useNavigate()
    const { setShow, show } = useModal()
    const [loading, setLoading] = useState()
    const [companies, setCompanies] = useState([])
    // const [selectedOrigin, setSelectedOrigin] = useState('senior')
    const [companyTemp, setCompanyTemp] = useState('')
    const [buttonRemove, setButtonRemove] = useState()
    const [buttonEdit, setButtonEdit] = useState()
    const [selectedInputOrigin, setSelectedInputOrigin] = useState('')
    const [selectedInputCompany, setSelectedInputCompany] = useState('')
    const [companiesOfSelectInputOrigin, setCompaniesOfSelectInputOrigin] = useState([])
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [loadingCreateSubmit, setLoadingCreateSubmit] = useState(false)
    const [alteredOriginEdit, setAlteredOriginEdit] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (!show){
            setSelectedInputOrigin('')
            setSelectedInputCompany('')
            setCompaniesOfSelectInputOrigin([])
            setButtonRemove(false)
            setButtonEdit(false)
            setCompanyTemp('')
            setLoadingCreateSubmit(false)
            setAlteredOriginEdit(false)
        }
    }, [show])

    async function getData(){
        setLoading(true)

        try {
            const getCompaniesAPI = await api.get(`/api/v1/companiesConnect`, {
                headers: {
                    Authorization: user?.token
                }
            })

            setCompanies(getCompaniesAPI?.data?.data?.map(item => {
                return {
                    ...item,
                    id_senior: item?.id_senior ? item?.id_senior : ' - ',
                    id_questor: item?.id_questor ? item?.id_questor : ' - ',
                    edit:
                        <Tippy content={<span>Editar</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiEdit className="cursor-pointer ml-[50%] text-blue-800" onClick={() => { setCompanyTemp(item); setShow(true); setButtonEdit(true) }} />
                            </div>
                        </Tippy>,
                    remove: 
                        <Tippy content={<span>Excluir</span>}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            className="ml-3"
                            delay={100}>
                            <div>
                                <FiTrash2 className="cursor-pointer ml-[50%] text-red-800" onClick={() => { setCompanyTemp(item); setShow(true); setButtonRemove(true) }} />
                            </div>
                        </Tippy>
                }
            }))
            setLoading(false)
        } catch(error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao buscar dados')
        }
    }

    async function handleDelete(){
        setShow(false)
        try {
            await api.delete(`api/v1/companiesConnect/${companyTemp?.id}`, {
                headers: {
                    authorization: user.token
                }
            })

            setShowNotificationModal(true)
            setNotificationModalText('Sucesso ao realizar exclusão')            
            return getData()

        } catch(error){
            if (error?.response?.data?.message){
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText(error?.response?.data?.message)
            } else {
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText(`Erro ao excluir empresa`)
            }
        }
    }

    async function handleSubmit(){
        if (!selectedInputOrigin || !selectedInputCompany){
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText(`Preencha todos os campos`)
        }

        setLoadingCreateSubmit(true)

        try {

            const companyParam = {}
            if (selectedInputOrigin == 'questor'){
                companyParam.id_questor = selectedInputCompany?.value
            } else {
                companyParam.id_senior = selectedInputCompany?.value
            }

            await api.post('api/v1/companiesConnect', {
                ...companyParam,
                name: selectedInputCompany?.nomemp,
                companyOrigin: selectedInputOrigin
            }, {
                headers: {
                    authorization: user.token
                }
            })

            setShow(false);
            setShowNotificationModal(true)
            setNotificationModalText('Sucesso ao realizar cadastro de empresa')            
            return getData()

        } catch(error){
            if (error?.response?.data?.message){
                setLoadingCreateSubmit(false)
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText(error?.response?.data?.message)
            } else {
                setLoadingCreateSubmit(false)
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText(`Erro ao cadastrar empresa`)
            }
        }
    }

    async function handleEdit(){
        if (!companyTemp?.companyOrigin){
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText(`Preencha todos os campos`)
        }

        if (companyTemp?.companyOrigin == 'questor' && !companyTemp?.id_questor){
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText(`Preencha todos os campos`)
        }

        if (companyTemp?.companyOrigin == 'senior' && !companyTemp?.id_senior){
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText(`Preencha todos os campos`)
        }

        setLoadingCreateSubmit(true)

        try {

            const companyParam = {}
            if (companyTemp?.companyOrigin == 'questor'){
                companyParam.id_questor = companyTemp?.id_questor
            } else {
                companyParam.id_senior = companyTemp?.id_senior
            }

            await api.put(`api/v1/companiesConnect/${companyTemp?.id}`, {
                ...companyParam,
                companyOrigin: companyTemp?.companyOrigin
            }, {
                headers: {
                    authorization: user.token
                }
            })

            setShow(false);
            setShowNotificationModal(true)
            setNotificationModalText('Sucesso ao realizar atualização')            
            return getData()

        } catch(error){
            if (error?.response?.data?.message){
                setLoadingCreateSubmit(false)
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText(error?.response?.data?.message)
            } else {
                setLoadingCreateSubmit(false)
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText(`Erro ao atualizar empresa`)
            }
        }
    }

    async function getCompaniesAfterSelectedOrigin(origin){
        setLoadingCreate(true)
        try {
            if (origin == 'senior'){
                const getCompaniesApi = await api.get('api/v1/companies/', {
                    headers: {
                        authorization: user.token,
                    }
                })
                setCompaniesOfSelectInputOrigin(getCompaniesApi.data);
            } else {
                const getCompaniesApi = await api.get('api/v1/companies/companiesAtQuestor', {
                    headers: {
                        authorization: user.token,
                    }
                })
                setCompaniesOfSelectInputOrigin(getCompaniesApi.data?.data);
            }

            setLoadingCreate(false)
            return
        } catch (error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText(`Erro ao buscar empresas do ${origin}`)
        }
    }

    const tableThead = [
        {
            "name": "Código Senior",
            "original_name": "id_senior"
        },
        {
            "name": "Código Questor",
            "original_name": "id_questor"
        },
        {
            "name": "Nome",
            "original_name": "name"
        },
        {
            "name": "Origem",
            "original_name": "companyOrigin"
        },
        {
            "name": "Editar",
            "original_name": "edit"
        },
        {
            "name": "Excluir",
            "original_name": "remove"
        }
    ]

    const optionsOrigin = [
        {
            id: 'senior',
            description: "Senior"
        },
        {
            id: 'questor',
            description: "Questor"
        }
    ]

    return (
        <>
            <Modal>
                {
                    show && buttonRemove ?
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                <p className='mr-7'>Deseja realmente excluir essa empresa?</p>
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                        </div>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                            <Button background={'bg-green-800'} type={'button'} onClick={() => { setShow(false); handleDelete(companyTemp) }}>Confirmar</Button>
                        </div>
                    </div>
                    : show && buttonEdit ?
                    <div className={`p-2 flex flex-col justify-center items-center overflow-auto h-80`}>                       
                        <p className='flex flex-row font-extrabold text-primaryDefaultLight text-base items-center justify-start w-full '>
                            <FiChevronRight /> Edição de empresa
                        </p>
                        <form autocomplete='off' className='flex flex-col gap-2 h-full w-full p-5 justify-evenly'>
                            {
                                loadingCreateSubmit ?
                                <p className="w-[376px] h-[320] flex items-center justify-center animate-pulse">Enviando...</p>
                                :
                                <>
                                    <LabelInput text={'Sistema'}>
                                        <InputAutoComplete
                                            data={optionsOrigin}
                                            selectedLabel={'description'}
                                            optionList={['description']}
                                            existsLabel={true}
                                            value={optionsOrigin.filter(e => { return e?.id == companyTemp?.companyOrigin })[0]?.description}
                                            preSelectedValue={optionsOrigin.filter(e => { return e?.id == companyTemp?.companyOrigin })[0]?.description}
                                            onChange={(e) => {
                                                if (typeof (e) == 'object') {
                                                    const paramForClean = {}
                                                    if (e?.value == 'questor'){
                                                        paramForClean.id_questor
                                                    } else {
                                                        paramForClean.id_senior = ''
                                                    }
                                                    setAlteredOriginEdit(true)
                                                    setCompanyTemp(prev => ({...prev, companyOrigin: e?.id, name: '', ...paramForClean}))
                                                    getCompaniesAfterSelectedOrigin(e?.id)
                                                }
                                            }}
                                            width={80}
                                        />
                                    </LabelInput>
                                    <>
                                        {
                                            loadingCreate && alteredOriginEdit ?
                                            <p className="w-full flex items-center justify-center animate-pulse">Carregando...</p>
                                            :
                                            alteredOriginEdit ?
                                            <LabelInput text={'Empresa'}>
                                                <InputAutoComplete
                                                    data={companiesOfSelectInputOrigin}
                                                    selectedLabel={'label'}
                                                    optionList={['label']}
                                                    existsLabel={true}
                                                    value={companyTemp?.name ? companyTemp?.name : ''}
                                                    preSelectedValue={companyTemp?.name ? companyTemp?.name : ''}
                                                    onChange={(e) => {
                                                        if (typeof (e) == 'object') {
                                                            const paramForClean = {}
                                                            if (companyTemp?.companyOrigin == 'questor'){
                                                                paramForClean.id_questor = e?.value
                                                            } else {
                                                                paramForClean.id_senior = e?.value
                                                            }
                                                            setCompanyTemp(prev => ({...prev, name: e?.nomemp, ...paramForClean}))
                                                        } else {
                                                            setCompanyTemp(prev => ({...prev, name: ''}))
                                                        }
                                                    }}
                                                    width={80}
                                                    height={80}
                                                />
                                            </LabelInput>
                                            : 
                                            <LabelInput text={'Empresa'}>
                                                <InputAutoComplete
                                                    data={companiesOfSelectInputOrigin}
                                                    selectedLabel={'label'}
                                                    optionList={['label']}
                                                    existsLabel={true}
                                                    value={companyTemp?.name ? companyTemp?.name : ''}
                                                    preSelectedValue={companyTemp?.name ? companyTemp?.name : ''}
                                                    width={80}
                                                    height={80}
                                                    disabled={true}
                                                />
                                            </LabelInput>
                                        }
                                    </>
                                </>
                            }
                        </form>
                        {
                            !loadingCreateSubmit &&
                            <div className="flex flex-row items-center w-full justify-center gap-5 pb-2">
                                <Button onClick={() => setShow(false)} background={'bg-red-800'}>Cancelar</Button>
                                <Button onClick={() => handleEdit()}>Editar</Button>
                            </div>
                        }
                    </div>
                    : show ?
                    <div className={`p-2 flex flex-col justify-center items-center overflow-auto h-80`}>                       
                        <p className='flex flex-row font-extrabold text-primaryDefaultLight text-base items-center justify-start w-full '>
                            <FiChevronRight /> Cadastro de empresa
                        </p>
                        <form autocomplete='off' className='flex flex-col gap-2 h-full w-full p-5 justify-evenly'>
                            {
                                loadingCreateSubmit ?
                                <p className="w-[376px] h-[320] flex items-center justify-center animate-pulse">Enviando...</p>
                                :
                                <>
                                    <LabelInput text={'Sistema'}>
                                        <InputAutoComplete
                                            data={optionsOrigin}
                                            selectedLabel={'description'}
                                            optionList={['description']}
                                            existsLabel={true}
                                            value={selectedInputOrigin ? optionsOrigin.filter(e => { return e?.id == selectedInputOrigin })[0]?.description : ''}
                                            preSelectedValue={selectedInputOrigin ? optionsOrigin.filter(e => { return e?.id == selectedInputOrigin })[0]?.description : ''}
                                            onChange={(e) => {
                                                if (typeof (e) == 'object') {
                                                    setSelectedInputOrigin(e?.id)
                                                    getCompaniesAfterSelectedOrigin(e?.id)
                                                }
                                            }}
                                            width={80}
                                            disabled={loadingCreateSubmit ? true : false}
                                        />
                                    </LabelInput>
                                    <>
                                        {
                                            selectedInputOrigin && loadingCreate ?
                                            <p className="w-full flex items-center justify-center animate-pulse">Carregando...</p>
                                            : selectedInputOrigin &&
                                            <LabelInput text={'Empresa'}>
                                                <InputAutoComplete
                                                    data={companiesOfSelectInputOrigin}
                                                    selectedLabel={'label'}
                                                    optionList={['label']}
                                                    existsLabel={true}
                                                    value={selectedInputCompany?.label ? selectedInputCompany?.label : ''}
                                                    preSelectedValue={selectedInputCompany?.label ? selectedInputCompany?.label : ''}
                                                    onChange={(e) => {
                                                        if (typeof (e) == 'object') {
                                                            setSelectedInputCompany(e)
                                                        } else {
                                                            setSelectedInputCompany('')
                                                        }
                                                    }}
                                                    width={80}
                                                    height={80}
                                                    disabled={loadingCreateSubmit ? true : false}
                                                />
                                            </LabelInput>
                                        }
                                    </>
                                </>
                            }
                        </form>
                        {
                            !loadingCreateSubmit &&
                            <div className={`flex flex-col justify-center items-center mt-3 pb-5 lg:pb-2`}>
                                <Button onClick={() => handleSubmit()}>Cadastrar Empresa</Button>
                            </div>
                        }
                    </div>
                    :
                    <></>
                }
            </Modal>
            <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
                {
                    loading ? 
                    <Loader/>
                    :
                    <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                        <div className="hidden sm:flex flex-col justify-start align-center ml-20 w-[100%] h-[92vh] mr-8 gap-5">
                            <TableDefault
                                onClick={() => {}}
                                buttonDescription={'+ Empresa'}
                                buttonFunction={setShow}
                                buttonFunctionParam={true}
                                title={tableThead}
                                data={companies}
                                quantity={companies?.length}
                                collumns={["id_senior", "id_questor", "name", "companyOrigin", "edit", "remove"]}
                            /> 
                        </div>
                    </div>
                }
            </div>
        </>
    )
}