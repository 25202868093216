export const typesLinkList = [
    {
        value: 10,
        description: '10 - Empregado'
    },
    {
        value: 55,
        description: '55 - Menor Aprendiz'
    },
    {
        value: 90,
        description: '90 - Estágiario'
    },
    {
        value: 11,
        description: '10 - Intermitente'
    },
    {
        value: 49,
        description: '49 - Doméstica'
    },
    {
        value: 80,
        description: '80 - Pró Labore'
    },
    {
        value: 60,
        description: '60 - Determinado - Lei 9.601/98'
    }
]