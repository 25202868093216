export const reportStyle = `
#exportEmployee{
    font-family: Arial, Helvetica, sans-serif !important;
    display: flex;
    font-size: 4px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: red
}

h1{
    padding: 0rem !important;
    margin-top: 0.4rem !important;
    text-align: center
}

h2{
    color: #005f9e;    
    margin-top: 0.4rem !important;
    padding: 0rem !important;
    font-family: Arial, Helvetica, sans-serif !important;
}
.divMainContainerForm div{
    width: 100%;
    overflow: hidden;
}

.containerFormsRequestAdmission{
    width: 85vw;
    margin-top: 1rem;
    margin-left: 2.8rem;
    box-shadow: 0px 0px 15px -8px #000;
}

.containerFormsRequestAdmissionRigth{
    margin-top: 1rem;
    width: 75vw;
    box-shadow: 0px 0px 15px -8px #000;
}

.headerFormRequestAdmission {
    width: 100%;
    background-color: #ececec;
    color: white;
    display: flex;
    align-items: center;
}

.headerFormRequestAdmission div{
    background-color: #ececec;
    color: #005F9E;
    width: auto;
    height: 3rem;
    position: relative;
    padding: 0rem 0.8rem 0rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    transition: .25s;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #005F9E;
    gap: 0.4rem;
}

.headerFormRequestAdmission div:last-child button{
    cursor: pointer;
    diplay: none;
    padding: 0.5rem;
    border: 1px solid #005F9E;
    border-radius: 0.2rem;
    background-color: #005F9E;
    color: #fff;
    transition: .2s;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.headerFormRequestAdmission div:last-child button:hover{
    background-color: #fff;
    color: #005F9E;
}

.headerFormRequestAdmission div:last-child button:first-child{
    diplay: none;
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #999999;
    border-radius: 0.2rem;
    background-color: #999999;
    color: #fff;
    transition: .2s;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.headerFormRequestAdmission div:last-child button:first-child:hover{
    diplay: none;
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #999999;
    border-radius: 0.2rem;
    background-color: #fff;
    color: #999999;
    transition: .2s;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.headerFormRequestAdmission .hasHoverRequest:hover{
    background-color: #fff;
    color: #005F9E;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    border-bottom: solid 1px #005f9e36;
    cursor: pointer;
}

.headerFormRequestAdmission .selected{
    background-color: #fff;
    color: #005F9E;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: solid 1px #005f9e36;
    cursor: pointer;
}

.headerFormRequestAdmission div:last-child{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #005F9E;
}

.FormRequestContainer{
    position: relative;
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: stretch;
    align-self: auto;
    flex-wrap: wrap;
}

.FormRequestContainer div{
    margin: 0.2rem 0.2rem;
    flex-grow: 1;
}

.FormRequestContainer div input{
    height: 2rem;
    border: 1px solid #999999;
    border-radius: 0.2rem;
    color: #464545;
    font-family: 'Roboto';
    font-size: 0.9rem;
    padding: 0.2rem;
    font-weight: normal;
}

.FormRequestContainer div textarea{
    border: 1px solid #999999;
    border-radius: 0.2rem;
    color: #464545;
    font-family: 'Roboto';
    font-size: 0.9rem;
    padding: 0.3rem;
    font-weight: normal;
    width: 17rem;
    height: 2rem;
}

.FormRequestContainer div label{
    font-size: 0.78rem;
}

.FormRequestContainer div input, label{
    display: block;
    align-self: auto;
    margin: 0
}

.FormRequestContainer div:focus-within > label,input{
    color: #005F9E
}

.FormRequestContainer div:focus-within > input{
    color: #005F9E;
    border: 1px solid #005F9E
}

.FormRequestContainer div input:disabled {
    background-color: #bbbbbb6b;
    color: #4e4e4e;
}

.FormRequestContainer div textarea:disabled {
    background-color: #bbbbbb6b;
    color: #4e4e4e;
}

.FormRequestContainer div select:disabled {
    background-color: #bbbbbbd2;
    color: #000;
    font-weight: 600;
    border: 1px solid #616161;
    font-weight: 400;
}

.FormRequestContainer div input[type='button'].notCompleted{
    display:none;
    background-color: #9e000857 !important;
    border: 0px;
    border: 1px solid #9e000857 !important;
    font-weight: 200 !important;
    color: #383838 !important;
    cursor: pointer;
    transition: .2s;
}

.FormRequestContainer div input[type='button'].notCompleted:hover{
    background-color: #fff !important;
    color: #9e0008a9 !important;
    cursor: pointer;
}

.FormRequestContainer div input[type='button']{
    display:none;
    background-color: #005f9e57 !important;
    border: 0px;
    border: 1px solid #005f9e57 !important;
    font-weight: 200 !important;
    color: #383838 !important;
    cursor: pointer;
    transition: .2s;
}


.FormRequestContainer div input[type='button']:hover {
    background-color: #fff !important;
    color: #005f9ec7 !important;
    cursor: pointer;
}

.FormRequestContainer div:focus-within > select{
    color: #005F9E;
    border: 1px solid #005F9E
}

.hiddenModal{
    display: none;
}
.configmodal{
    width: 40rem !important;
}

.configmodal button{
    margin: 1rem;
    margin-top: 0.5rem;
    padding: 0.3rem;
    background-color: #005F9E;
    border: 1px solid #005F9E;
    border-radius: 0.2rem;
    color: #fff;
    cursor: pointer;
    transition: .2s;
    display:none;

}

.configmodal button:hover,
.configmodal button:focus{
    background-color: #fff;
    color: #005F9E;
    display:none;

}

.modalSchedulesDescription{
    width: 100%;
}

.modalFields{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 0.8rem;
    width: 100%
}

.modalFields div input{
    height: 2rem;
    border: 1px solid #999999;
    border-radius: 0.2rem;
    color: #464545;
    font-family: 'Roboto';
    font-size: 0.9rem;
    padding: 0.3rem;
    font-weight: normal;
}

.modalFields div label{
    font-size: 0.78rem;
}

.modalFields div input, label{
    display: block;
    align-self: auto;
    margin: 0
}

div input[type='button'], label[for="employeeSchedule"]{
    display: none !important
}

.modalFields div:focus-within > label,input{
    color: #005F9E
}

.modalFields div:focus-within > input{
    color: #005F9E;
    border: 1px solid #005F9E
}

.modalFields div input:disabled {
    background-color: #adadad6b;
    color: #797979;
}

.modalFields div select:disabled {
    background-color: #bbbbbbd2;
    color: #000;
    font-weight: 600;
    border: 1px solid #616161;
    font-weight: 400;
}

.modalFields div select{
    height: 2rem;
    border: 1px solid #999999;
    border-radius: 0.1rem;
    padding: 0.2rem;
    color: #464545;
    background-color: #fff;
    font-family: 'Roboto';
    font-size: 0.85rem;
    flex-grow: 1;
    width: 100%;
}

.FormRequestContainer div select{
    height: 2rem;
    border: 1px solid #999999;
    border-radius: 0.1rem;
    padding: 0.2rem;
    color: #464545;
    background-color: #fff;
    font-family: 'Roboto';
    font-size: 0.85rem;
    flex-grow: 1;
    width: 100%;
}

.FormRequestContainer .smallInput{
    height: 2rem;
    min-width: 8.7rem;
    flex-grow: 1;
    width: 100%;
}

.FormRequestContainer .mediumInput{
    min-width: 15rem;
    width: 100%;
    flex-grow: 1;
}

.FormRequestContainer .bigInput{
    min-width: 22rem;
    width: 100%;
    flex-grow: 1;
}

.FormRequestContainer .soSmallInput{
    min-width: 7.5rem !important;
    width: 100%;
    flex-grow: 1;
}

.soSmallInputLittle{
    width: 6.4rem !important;
    width: 100%;
    flex-grow: 1;
}

.loadingFormRequest{
    padding: 2rem;
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
}

.loadingFormRequest div{
    padding: 2rem;
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
}

.insideLoadingForm{
    width: calc(100% - 2.5rem);
    height: calc(100% - 2rem);
    position: absolute;
    display: flex  !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #ffffff8c;
    flex-grow: 1;
}

.insideLoadingForm .ldsDualRing{ 
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.formDescription{
    display:none;
    width: 100%;
    color: #005F9E;
    font-weight: bold;
}

.dependentsContainerFormat{
    display: flex;
    align-items: center;
    justify-content:  center;
    gap: 2rem;
}

.dependentsContainer{
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #005f9e36;
}

.dependentsContainer input{
    margin: 0px !important;
}

.dependentsContainer select{
    margin: 0px !important;
    min-width: 6rem;
}

.dependentsMultiplesContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.disableFlexGrow{
    flex-grow: 0 !important;
}

.AlignButtonCenterForm{
    margin:0auto;
    margin-top: 0.7rem;
    border: 1px solid #005F9E;
    padding: 0.35rem;
    display: flex;
    gap: 0.2rem;
    background-color: #005F9E;
    color: white;
    transition: .2s;
    border-radius: 0.2rem;
    display:none;
    cursor: pointer;
}

.AlignButtonCenterForm:hover{
    background-color: #ffffff;
    display:none;
    color: #005F9E;
}

#folpagConnectToSeniorContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#folpagConnectToSeniorTableForm{
    width: 100%;
}

#folpagConnectToSeniorTableForm thead{
    background-color: #ECECEC;
    font-weight: 600;
}

#folpagConnectToSeniorTableForm thead td{
    background-color: #ECECEC;
    font-weight: 600;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
}

#folpagConnectToSeniorTableForm thead td div{
    display: flex;
    align-items: center;
    justify-content: center;
}

#folpagConnectToSeniorTableForm tr td{
    width: 100%;
    border-bottom: 1px solid #cecece;
}

#folpagConnectToSeniorTableForm td:nth-child(1){
    width: 45% !important;
    color: #005F9E;
    font-size: 1rem;
    border-left: 1px solid #cecece;
}

#folpagConnectToSeniorTableForm td:nth-child(2){
    width:10% !important;
    color: #4d4d4d;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 600;
}

#folpagConnectToSeniorTableForm td:nth-child(3){
    width: 45% !important;
    color: #008267;
    font-size: 1rem;
    border-right: 1px solid #cecece;
}

#folpagConnectToSeniorTableForm td div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.connectToSeniorContainer .separatorConnectToSeniorItems{
    border-top: 1px solid #cecece;
}

.connectToSeniorContainer .separatorConnectToSeniorItems:nth-child(1){
    border-top: none;
    padding-bottom: 0.8rem;
}

.connectToSeniorContainer .MuiAutocomplete-endAdornment{
    top: 0 !important;
    margin-right: -7rem;
    margin-top: 0.2rem;
}

.connectToSeniorContainer .MuiFormLabel-root{
    margin-top: -0.1rem;
}


.connectToSeniorContainer .MuiInputBase-root {
    height: 2.5rem;
}

.connectToSeniorContainer .MuiInputBase-input{
    margin-top: -0.5rem;
}

.connectToSeniorContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    top: auto;
    padding: 0.5rem;
}

.connectToSeniorContainer div{
    width: 100%;
    display: grid;
    grid-template-columns: 48% 4% 48%;
    padding: 0.3rem;
}


.connectToSeniorContainer div div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.connectToSeniorContainer div div .arrowConnectToSenior{
    size: 30px !important;
    color: black;
}

.connectToSeniorContainer div .seniorImportContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #008267;
}

.connectToSeniorContainer div .folpagConnectImportContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #005F9E
}

.connectToSeniorContainer div .folpagConnectImportContainer button{
    padding: 0.5rem;
    border: 1px solid #005F9E;
    border-radius: 0.2rem;
    background-color: #005F9E;
    display:none;
    color: #ffffff;
    transition: .2s;
    cursor: pointer;
    font-size: 1rem;
}

.connectToSeniorContainer div .seniorImportContainer .inputSeniorContainer{
    padding: 0.6rem;
    border: 1px solid #c4c4c4;
    border-radius: 0.2rem;
    font-size: 1rem;
    color: #000;
    width: 17.5rem;
    margin: 0.7rem;
}

.connectToSeniorContainer div .folpagConnectImportContainer button:hover{
    display:none;
    background-color: #ffffff;
    color: #005F9E;
}


.connectToSeniorContainer div .folpagConnectImportContainer p {
    font-size: 1rem;    
}

.connectToSeniorContainer div .folpagConnectImportContainer p b {
    font-weight: 500;
}

.connectToSeniorContainer div .folpagConnectImportContainer p i {
    font-weight: 600;
}


.alertMsg {
    background: white;
    width: 40rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.alertMsg p {
    font-size: 1rem;
    font-weight: 600;
}

.alertMsg button {
    display:none;
    margin-top: 2rem;
    border: none;
    background: #005F9E;
    width: 10rem;
    height: 2rem;
    color: white;
    transition: 0.2s;
}

.alertMsg button:hover {
    display:none;
    background: white;
    border: 1px solid #005F9E;
    color: #005F9E;
    cursor: pointer;
}


#exportEmployee{
    display: none;
    font-size: 4px;
    width: 100%;
    align-items: center;
    flex-direction: column;
}              

#onlyMobile{
    display: none
}

#hiddenButtonDependents{
    display: none
}
`