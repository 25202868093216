import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
// Hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
// Components
import Loader from '../../components/Loader/loader';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { Input } from '../../components/input/input';
import { LabelInput } from '../../components/label/label.input';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaFilePdf, FaFileExcel, FaPrint, FaUser, FaInfoCircle } from 'react-icons/fa';
import Multiselect from '../../components/selects/mult.select'
import { justNumber } from '../../utils/justNumber'

export function ReportCostEmployees(){

    const navigate = useNavigate()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { company, branch } = useCompanyBranch();
    const { user } = useAuth()
    const [loading, setLoading] = useState(true);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Relatórios')
    const token = localStorage.getItem('@auth:token');
    let selectedPDF = false   
    let selectedXLSX = false
    const [selectedOrder, setSelectedOrder] = useState()
    const [selectedPorcentage, setSelectedPorcentage] = useState('')
    const [branchList, setBranchList] = useState([])
    const [selectedBranchs, setSelectedBranchs] = useState([])

    useEffect(() => {
        setLoading(true)
        async function getData(){
            if(company === 0 || branch === 0){
                alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
                return navigate('/')
            }

            try {

                const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
                    headers: {
                        authorization: token
                    }
                })
                setBranchList(resultBranches?.data?.data?.filter(emp => emp?.value != branch))
                setLoading(false)

            } catch (error){
                setLoading(false)
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }
        getData()
    }, [])

    async function downloadFile(){
        setLoadingDownload(true)
        try {

            let param = []
            selectedOrder && param.push(`orderBy=${selectedOrder?.value}`)
            selectedPorcentage && param.push(`porcentage=${selectedPorcentage}`)
            param = param.join('&')

            const type = selectedPDF ? 'pdf' : 'excel'
            const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''
            const file = await api.get(`api/v1/reports/costEmployees/numemp/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}/type/${type}${param ? `?${param}` : ''}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `CustoEmpregados_${Number(new Date())}`)
            myElement.click()
            setLoadingDownload(false)
        } catch (error){
            setLoadingDownload(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro no download')
        }

    }

    const orderByList = [
        {
            name: 'Matrícula',
            value: 'numcad'
        },
        {
            name: 'Nome',
            value: 'nomfun'
        },
        {
            name: 'Data Admissão',
            value: 'datadm'
        },
        {
            name: 'Centro de Custo',
            value: 'codccu'
        },
        {
            name: 'Local de Organograma',
            value: 'numloc'
        },
        {
            name: 'Posto de Trabalho',
            value: 'postra'
        },
    ]

    return (
        <div className={`${loadingDownload ? 'cursor-wait' : ''}`}>
            {
                loading ?
                <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[85vh] items-center justify-center">
                    <Loader />
                </div>
                :
                <div className='flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14'>
                    <p className='text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-5'>Custo de Empregados</p>
                    <div className='flex flex-col w-fit h-fit justify-center items-center shadow-xl rounded-lg mt-14 p-2'>
                        <div className='flex flex-col justify-around h-full items-center p-3'>
                            <div className='flex flex-col'>
                                <label className='mt-3'>Ordenar por</label>
                                <LabelInput>
                                    <InputAutoComplete
                                        data={orderByList}
                                        id={'orderBy'}
                                        optionList={["name"]}
                                        selectedLabel={'name'}
                                        preSelectedValue={selectedOrder ? selectedOrder?.name : ''}
                                        value={selectedOrder ? selectedOrder?.name : ''}
                                        onChange={(e) => {
                                            setSelectedOrder(e)
                                        }}
                                    />
                                </LabelInput>
                                <label className='mt-7 flex flex-row justify-between items-center'>
                                    Porcentagem
                                    <Tippy
                                        content={'Simulação de aumento de salário coletivo'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={80}>
                                        <div className='pr-1 cursor-help text-blue-700'>
                                            <FaInfoCircle/>
                                        </div>
                                    </Tippy>
                                </label>
                                <LabelInput>
                                    <Input
                                        id={'selectedPorcentage'}
                                        onChange={(e) => setSelectedPorcentage(e?.target?.value)}
                                        value={selectedPorcentage ?? ''}
                                        type={'number'}
                                    />
                                </LabelInput>
                                <label className='mt-7'>Filiais</label>
                                <LabelInput>
                                    <Multiselect
                                        items={[{label: "0 - Todas", value: 'Todas'}, ...branchList]}
                                        mult                                                 
                                        onChange={e => {
                                            if (e[0]?.value == "Todas"){
                                                setSelectedBranchs(branchList)
                                            } else {
                                                setSelectedBranchs(e)
                                            }
                                        }}
                                        selectedLabel={'label'}
                                        value={selectedBranchs ? selectedBranchs : []}
                                        id={`selectedBranchs`}
                                    />                                
                                </LabelInput>
                            </div>
                            <div className='flex flex-row justify-between w-full gap-3 mt-10'>
                                <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                                    onClick={() => {
                                        selectedXLSX = false
                                        selectedPDF = true
                                        downloadFile()
                                    }}
                                >
                                    <FaPrint className='text-sm'/> PDF
                                </div>
                                <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                                    onClick={() => {
                                        selectedPDF = false
                                        selectedXLSX = true
                                        downloadFile()}}
                                    >
                                    <FaFileExcel className='text-sm'/> XLSX
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}