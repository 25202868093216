import { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './useAuth'
import { useLoading } from './useLoad'
import api from '../services/api'

const TermOfUseContext = createContext({})

export function TermOfUseProvider({ children }){
    
    const { logged } = useAuth()
    const { setLoading } = useLoading()
    const [ accepted, setAccepted ] = useState(null)
    const [ verify, setVerify ] = useState(false)
    const token = localStorage.getItem('@auth:token')

    useEffect(() => {

        setLoading(false)
        async function getData(){
            if(logged == true && token){
                try {

                    const verifyAcceptedUseTerms = await api.get('/api/v1/useTerms/verifyAccepted', {
                        headers: {
                            authorization: token
                        }
                    })

                    if(verifyAcceptedUseTerms.data.accepted == true){
                        setLoading(false)
                        return setAccepted(true)
                    }
        
                    setLoading(false)
                    return setAccepted(false)    

                } catch (error) {
                    setLoading(false)
                    return alert(error?.response?.data?.message)
                }
               
            }
            
        }

        setVerify(false)    
        getData()

    }, [ logged, verify ])

    async function acceptTerm(termOfUse_id){

        try {
            
            await api.post('/api/v1/useTerms/accept', {
                termOfUse_id: termOfUse_id
            }, {
                headers: {
                    authorization: token
                }
            })

            setVerify(true)  

        } catch (error) {
            alert(error?.response?.data?.message)
        }

    }

    return (
        <TermOfUseContext.Provider value={{ accepted, acceptTerm }}>
            {children}
        </TermOfUseContext.Provider>
    )

}

export function useTermsOfUse(){
    return useContext(TermOfUseContext)
}