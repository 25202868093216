import React, {useState, useEffect} from "react";
import Loader from "../../components/Loader/loader";
import { useModal } from "../../hooks/useModal";
import { useAuth } from "../../hooks/useAuth";
import { useMenu } from "../../hooks/useMenu";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import MultiselectEvents from '../../components/selectsEvents/mult.select.events'
import { Button } from "../../components/buttons/button.default";
import { LabelInput } from "../../components/label/label.input";
import { Input } from "../../components/input/input";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import api from "../../services/api";
import {typeEventsList} from '../../utils/typeEventsList'
import { LoadingPulse } from "../../components/Loader/loadingPulse";
import { FiX } from "react-icons/fi";
import Modal from "../../components/Modal/modal";

export function EventsConfigDefault(){

    const token = localStorage.getItem("@auth:token");
    const { show, setShow } = useModal();
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setMenuTitle } = useMenu();
    setMenuTitle("Eventos Padrão");
    const [buttonEdit, setButtonEdit] = useState(false)
    const [tabeveList, setTabeveList] = useState([])
    const [params, setParams] = useState('')
    const [selectedData, setSelectedData] = useState({})
    const [selectedDataHistory, setSelectedDataHistory] = useState({})
    const [eventsList, setEventsList] = useState([])
    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    async function eventsParams(param){
        setLoadingList(true)
        try {
            const eventsParamsAPI = await api.get(`/api/v1/general/eventsPerTabeve/tabeve/${param}`, {
                headers: {
                    Authorization: token
                }
            })
            setEventsList(eventsParamsAPI?.data?.data?.map(event => {
                return {
                    ...event,
                    inputLabel: `${event?.codeve} - ${event?.deseve}`
                }
            }))
            setLoadingList(false)
        } catch(error){
            setLoadingList(false)
            setLoading(false)
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    useEffect(() => {
        if (!show){
            setSelectedData('')
            setButtonEdit(false)
        }
    }, [show])

    async function getData(){
        setLoading(true)
        try {
            const readTabeveListAPI = await  api.get('/api/v1/general/tabeves', {
                headers: {
                    Authorization: token
                }
            })
            setTabeveList(readTabeveListAPI?.data?.data)

            const getDataEvents = await api.get('/api/v1/calculatorDefault', {
                headers: {
                    Authorization: token
                }
            })
            setParams(getDataEvents?.data?.data)

            const obj = {}
            if (getDataEvents?.data?.data?.length > 0){
                eventsParams(getDataEvents?.data?.data[0]?.tabeve)
                typeEventsList?.map(type => {
                    const filterEvents = getDataEvents?.data?.data?.filter(item => item?.tipeve == type?.id)
                    if (filterEvents?.length > 0){
                        const data = {
                            tipeve: type?.id,
                            codeve: filterEvents?.map(item => {return {
                                codeve: item?.codeve,
                                deseve: item?.deseve,
                                inputLabel: `${item?.codeve} - ${item?.deseve}`
                            }})
                        }
                        obj[type?.id] = data
                    } else {
                        const data = {
                            tipeve: type?.id,
                            codeve: []
                        }
                        obj[type?.id] = data
                    }                    
                })
            }

            setSelectedData(obj)
            setSelectedDataHistory(structuredClone(obj))
            setLoading(false)

        } catch(error){
            setLoading(false)
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function addParamsOnTipeve(tipeve, events){
        const formatData = {
            tipeve,
            codeve: events
        }        
        selectedData[tipeve] = formatData
    }

    async function handleSubmit(){
        const events = []
        typeEventsList?.map(tipeve => {
            if (tipeve?.id == selectedData[tipeve?.id]?.tipeve){
                events.push({
                    tipeve: selectedData[tipeve?.id].tipeve,
                    codeve: selectedData[tipeve?.id]?.codeve?.map(item => {return item?.codeve})
                })
            }
        })

        try {
            setShow(false)
            setLoading(true)
            await api.post('/api/v1/calculatorDefault', {
                tabeve: params[0]?.tabeve ? params[0]?.tabeve : selectedData?.tabeve,
                events
            },{
                headers: {
                    Authorization: token
                }
            })
            setShow(false)
            setShowAprovalModal(false)
            setShowNotificationModal(true)
            setNotificationModalText('Sucesso ao parametrizar eventos padrão')
            getData()

        } catch(error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao parametrizar eventos padrão.')
        }
    }

    return (
        <>
            <Modal>
                <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full sm:w-[28rem]`}>
                    <div className='flex justify-between pl-3 py-5 items-center'>
                        <p className='flex flex-row font-extrabold items-center text-lg'>
                            <p className='mr-7 text-sm'>Deseja realmente criar esses parâmetros?</p>
                        </p>
                        <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} />
                    </div>
                    <div className='mb-4 flex flex-row justify-center items-center gap-4 mt-2'>
                        <Button width={24} background={'bg-red-800'} onClick={() => { setShow(false) }}>Cancelar</Button>
                        <Button width={24} background={'bg-green-800'} type={'button'} onClick={() => { handleSubmit() }}>Confirmar</Button>
                    </div>
                </div>
            </Modal>
            <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
                {
                    loading ?
                    <Loader/>
                    :
                    <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                        <div className="flex flex-col justify-start sm:ml-20 w-full h-[90vh] mr-8 gap-5 pb-20">
                            <div className={`h-full w-full overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 ${(buttonEdit && params.length > 0) || selectedData?.tabeve ? 'lg:pb-96 2xl:pb-0' : ''}`}>
                                <div className="w-full flex flex-col gap-2 sm:gap-0 sm:flex-row justify-around items-center mt-5">
                                    {
                                        params?.length == 0 ?
                                        <>
                                            <LabelInput>
                                                <InputAutoComplete
                                                    data={tabeveList}
                                                    selectedLabel={'destab'}
                                                    optionList={['codtab', 'destab']}
                                                    placeHolder={'Tabela de Eventos'}
                                                    onChange={(e) => {
                                                        if (typeof(e) == 'object'){
                                                            setSelectedData(prev => ({...prev, tabeve: e?.codtab}))
                                                            eventsParams(e?.codtab)
                                                        }
                                                    }}
                                                />
                                            </LabelInput>
                                        </>
                                        :
                                        <>
                                            {
                                                buttonEdit ?
                                                <>
                                                    <LabelInput>
                                                        <InputAutoComplete
                                                            data={tabeveList}
                                                            selectedLabel={'destab'}
                                                            optionList={['codtab', 'destab']}
                                                            placeHolder={'Tabela de Eventos'}
                                                            onChange={(e) => {
                                                                if (typeof(e) == 'object'){
                                                                    setSelectedData(prev => ({...prev, tabeve: e?.codtab}))
                                                                    setSelectedData({})
                                                                    eventsParams(e?.codtab)
                                                                }
                                                            }}
                                                        />
                                                    </LabelInput>
                                                    <Button onClick={() => {setButtonEdit(!buttonEdit); setSelectedData(selectedDataHistory)}} width={20} height={7}>Cancelar</Button>
                                                </>
                                                :
                                                <>
                                                    <p className="text-primaryDefaultLight font-semibold">Tabela de Eventos: {params[0]?.tabeve}</p>
                                                    <Button onClick={() => setButtonEdit(!buttonEdit)} width={20} height={7}>Editar</Button>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                                <div className="h-80 flex flex-wrap justify-center w-full gap-4 mt-12 items-center">
                                    {
                                        loadingList ?
                                        <LoadingPulse/>
                                        :
                                        <>
                                            {
                                                (buttonEdit && params.length > 0) || selectedData?.tabeve ?
                                                <>
                                                    {
                                                        typeEventsList?.map(tipeve => {
                                                            return (
                                                                <div className="flex flex-col rounded-lg p-2">
                                                                    <p className="flex justify-center font-semibold text-white bg-primaryDefaultLight rounded-sm p-1">{tipeve?.description}</p>
                                                                    <div className=" mt-0">
                                                                        <LabelInput>
                                                                            <MultiselectEvents
                                                                                items={eventsList ? eventsList : []}
                                                                                mult                                                 
                                                                                onChange={e => {
                                                                                    if (typeof(e) == 'object' && e?.length > 0){
                                                                                        addParamsOnTipeve(tipeve?.id, e)
                                                                                    } else {
                                                                                        delete selectedData[tipeve?.id]
                                                                                    }
                                                                                }}
                                                                                selectedLabel={'inputLabel'}                                                                            
                                                                                value={selectedData[tipeve?.id] ? selectedData[tipeve?.id].codeve : []}
                                                                                id={`events-${tipeve?.tipeve}`}
                                                                                key={`${tipeve?.tipeve}`}
                                                                            />                                
                                                                        </LabelInput>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })                                                        
                                                    }
                                                </>
                                                : !selectedData?.tabeve && params?.length == 0 ?
                                                <p className="text-lg">Selecione uma tabela de eventos</p>
                                                : !buttonEdit ?
                                                <>
                                                    {
                                                        typeEventsList?.map(tipeve => {
                                                            return (
                                                                <div className="flex flex-col mt-10">
                                                                    <p className="flex justify-center font-semibold text-white bg-primaryDefaultLight rounded-sm p-1">
                                                                        {tipeve?.description}
                                                                    </p>
                                                                    <div key={tipeve?.id} className="flex flex-col rounded-sm p-2 h-[30rem] overflow-auto overflow-x-hidden scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 shadow-xl border border-gray-100 pb-5">
                                                                        <div className="w-80 flex flex-col gap-1 h-full mt-3">
                                                                            {
                                                                                params?.map(param => {
                                                                                    if (param?.tipeve == tipeve?.id){
                                                                                        return (
                                                                                            <div className="w-full p-1 border border-gray-100">{param?.codeve} - {param?.deseve}</div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })                                                        
                                                    }
                                                </>
                                                :
                                                <p className="text-lg">Selecione uma tabela de eventos</p>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {
                                selectedData?.tabeve ?
                                <div className="w-full flex justify-end pr-10">
                                    <Button onClick={() => setShow(true)}>Confirmar</Button>
                                </div>
                                : buttonEdit ?
                                <>
                                    <div className="hidden sm:flex w-full justify-end pr-10">
                                        <Button height={7} onClick={() => setShow(true)}>Confirmar</Button>
                                    </div>
                                    <div className="flex sm:hidden w-full justify-end pr-10">
                                        <Button height={7} onClick={() => setShow(true)}>Confirmar</Button>
                                    </div>
                                </>
                                :
                                <></>
                                
                            }
                        </div>                                     
                    </div>
                }
            </div>
        </>
    )
}