import React from 'react';
import { Route, HashRouter, Routes } from 'react-router-dom';
//pages
import TermsOfUse from '../pages/termsOfUse/termsOfUse';

export default function NotAuthRoute(){
    return ( 
        <HashRouter> 
            <Routes>  
                <Route path="/" element={<TermsOfUse/>} />
                <Route path="/*" element={<TermsOfUse/>}/>
            </Routes>
        </HashRouter>
    )
}
