import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiClock, FiArrowDown, FiArrowUp, FiTrash2, FiX, FiEye } from 'react-icons/fi';
import { RiArrowUpDownLine } from 'react-icons/ri';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import Loader from '../../components/Loader/loader';
import { capitalize } from '../../utils/formatText/capitalize'

// import './employeeConsultPendings.css'
import { useAuth } from '../../hooks/useAuth';
import { TableDefault } from '../../components/table/table.default';
import Modal from '../../components/Modal/modal';
import { Button } from '../../components/buttons/button.default';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { TableMobile } from '../../components/table/table.mobile';

export default function EmployeeConsultAdmissionsPendings() {

    const token = localStorage.getItem('@auth:token');
    const {
        setCompany,
        setBranch,
        setCompanyName,
        setBranchCnpj
    } = useCompanyBranch();

    const { user } = useAuth()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const { setShow, show } = useModal()
    const [loading, setLoading] = useState(false);
    const [admissions, setAdmissions] = useState([]);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Admissões em andamento')
    const navigate = useNavigate()
    const [employeeTemp, setEmployeeTemp] = useState()
    const [buttonRemove, setButtonRemove] = useState(false)
    const [procced, setProcced] = useState(false)
    const [updateEffect, setUpdateEffect] = useState(false)

    useEffect(() => {
        setLoading(true)

        async function getApiDatas() {

            try {
                const admissionsPendingsAPI = await api.get(`api/v1/employeesSave`, {
                    headers: {
                        authorization: token
                    }
                })

                const pendingsAdmissions = admissionsPendingsAPI.data.data.map((item) => {
                    return {
                        ...item,
                        employeeName: item?.employeeName ? capitalize(item?.employeeName) : ' - ',
                        admissionDate: item?.admissionDate ? item?.admissionDate : ' - ',
                        remove:
                            <Tippy
                                content={<a>Excluir Admissão</a>}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                className='ml-3'
                                delay={80}>
                                <div>
                                    <FiTrash2 className='ml-[50%] text-red-800 cursor-pointer' onClick={() => { setShow(true); setEmployeeTemp(item); setButtonRemove(true) }} />
                                </div>
                            </Tippy>,
                        functions: [1].includes(user.type) ?
                            [
                                {
                                    func: <FiEye
                                        onClick={() => {
                                            setCompany(item.company)
                                            setBranch(item.branch)
                                            setCompanyName(item.nomemp)
                                            setBranchCnpj(item.branchName)
                                            navigate(`/employeeAdmissionSave/${item.id}`)
                                        }} />
                                },
                                {
                                    func: <FiTrash2 onClick={() => { setShow(true); setEmployeeTemp(item); setButtonRemove(true) }} />
                                }
                            ]
                            :
                            [
                                {
                                    func: <FiEye
                                        onClick={() => {
                                            setCompany(item.company)
                                            setBranch(item.branch)
                                            setCompanyName(item.nomemp)
                                            setBranchCnpj(item.branchName)
                                            navigate(`/employeeAdmissionSave/${item.id}`)
                                        }} />
                                }
                            ]
                    }
                })

                setAdmissions(pendingsAdmissions)
                setLoading(false)
            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de colaboradores.')
            }
        }
        getApiDatas()

    }, [token, updateEffect]);

    useEffect(() => {
        if (!show) {
            setButtonRemove(false)
            setEmployeeTemp()
        }
    }, [show])

    const tableThead = [
        {
            "name": "Código Empresa",
            "original_name": "company"
        },
        {
            "name": "Empresa",
            "original_name": "admissionDate"
        },
        {
            "name": "Filial",
            "original_name": "branchName"
        },
        {
            "name": "Colaborador",
            "original_name": "employeeName"
        },
        {
            "name": "Data Admissão",
            "original_name": "admissionDate"
        },
    ]

    const tableTheadADM = [
        {
            "name": "Código Empresa",
            "original_name": "company"
        },
        {
            "name": "Empresa",
            "original_name": "admissionDate"
        },
        {
            "name": "Filial",
            "original_name": "branchName"
        },
        {
            "name": "Colaborador",
            "original_name": "employeeName"
        },
        {
            "name": "Data Admissão",
            "original_name": "admissionDate"
        },
        {
            "name": "Excluir",
            "original_name": "remove"
        }
    ]

    const customFunctions = {
        isValid: true,
        collumn: [5]
    }

    async function handleRemoveAdmissionPending(data) {
        setLoading(true)

        try {

            await api.delete(`api/v1/employeesSave/company/${data?.company}/branch/${data?.branch}/save_id/${data?.id}`, {
                headers: {
                    Authorization: token,
                }
            });

            setShow(false)
            setShowNotificationModal(true)
            setNotificationModalText('Admissão deletada com sucesso')
            setLoading(false)
            setUpdateEffect(true)
            return navigate('/employeeConsultAdmissionsPendings')


        } catch (error) {
            setShow(false)
            setLoading(false)
            console.log(error)
            return alert('Erro ao deletar admissão salva')
        }

    }

    async function mobileRedirectFunction(e) {
        setCompany(e.company)
        setBranch(e.branch)
        setCompanyName(e.nomemp)
        setBranchCnpj(e.branchName)
        navigate(`/employeeAdmissionSave/${e.id}`)
    }

    async function proccedModal(e) {
        setLoading(true)
        setShow(false)
        try {
            await api.patch(`api/v1/companiesConnect/setSession/${e?.companies_id}`, {}, {
                headers: {
                    Authorization: user.token
                }
            })
            setCompany(e.company)
            setBranch(e.branch)
            setCompanyName(e.nomemp)
            setBranchCnpj(e.branchName)
            setLoading(false)
            return navigate(`/employeeAdmissionSave/${e.id}`)           
        } catch(error){
            setLoading(false)
            console.log(error)
            return alert(error?.message)
        }
    }

    return (
        <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
            {
                show && buttonRemove ?
                    <Modal>
                        <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                            <div className='flex justify-between pl-3 py-5 items-center'>
                                <p className='flex flex-row font-extrabold items-center text-lg'>
                                    <p className='mr-7'>Deseja excluir essa admissão em andamento?</p>
                                </p>
                                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                            </div>
                            <p className='text-center text-lg mb-5'><b>{employeeTemp?.company} - {employeeTemp?.employeeName?.split(' ')[0]?.slice(0)?.toUpperCase()}</b></p>
                            <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                <Button background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                                <Button background={'bg-green-800'} type={'button'} onClick={() => handleRemoveAdmissionPending(employeeTemp)}>Confirmar</Button>
                            </div>
                        </div>
                    </Modal>
                    :
                    show && procced &&
                    <Modal>
                        <div className={`h-[18rem] max-h-[40rem] flex flex-col items-center justify-center bg-gray-200 rounded-lg w-[30rem]`}>
                            <p className=' font-bold text-center text-lg border-b-2 border-blue-500'>Deseja prosseguir?</p>
                            <div className='flex flex-col font-semibold items-start text-md gap-3 p-[25px]'>
                                <p>Empresa: {employeeTemp?.nomemp}</p>
                                <p>Filial: {employeeTemp?.branchName}</p>
                            </div>
                            <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                <Button background={'bg-red-800'} onClick={() => { setShow(false); setButtonRemove(false) }}>Cancelar</Button>
                                <Button background={'bg-green-800'} type={'button'} onClick={() => { proccedModal(employeeTemp) }}>Confirmar</Button>
                            </div>
                        </div>
                    </Modal>
            }
            {
                loading ?
                    <Loader />
                    : (
                        <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                            <div className="hidden sm:flex flex-col justify-start align-center ml-20 mt-3 w-[100%] h-[90vh] mr-8 gap-5">
                                {
                                    ![1].includes(user.type) ?
                                        <TableDefault
                                            title={tableThead}
                                            data={admissions}
                                            cursorPointer={true}
                                            onClick={(e) => {
                                                setEmployeeTemp(e)
                                                setShow(true)
                                                setProcced(true)
                                            }}
                                            collumns={["company", "nomemp", "branchName", "employeeName", "admissionDate"]}
                                        />
                                        :
                                        <TableDefault
                                            title={tableTheadADM}
                                            data={admissions}
                                            quantity={admissions.length}
                                            cursorPointer={true}
                                            customFunctions={customFunctions}
                                            onClick={(e) => {
                                                setEmployeeTemp(e)
                                                setShow(true)
                                                setProcced(true)
                                            }}
                                            collumns={["company", "nomemp", "branchName", "employeeName", "admissionDate", "remove"]}
                                        />
                                }
                            </div>
                            <div className='relative bg-white flex lg:hidden flex-col w-full px-3 h-[84vh] max-h-[67vh] items-start justify-start boxShadow overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500'>
                                {
                                    ![1].includes(user.type) ?
                                        <TableMobile
                                            title={tableThead}
                                            data={admissions}
                                            collumns={["company", "nomemp", "branchName", "employeeName", "admissionDate"]}
                                        />
                                        :
                                        <TableMobile
                                            title={tableThead}
                                            data={admissions}
                                            collumns={["company", "nomemp", "branchName", "employeeName", "admissionDate"]}
                                        />
                                }
                            </div>
                        </div>
                    )
            }
        </div>
    );
}