import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//services
import api from '../../services/api'
//hooks
import { useAuth } from '../../hooks/useAuth'
// components
import { Input } from '../../components/input/input';
import { LabelInput } from '../../components/label/label.input';
import { Button } from '../../components/buttons/button.default'
import { useModal } from '../../hooks/useModal'

export default function ResetPassword() {

    const { setShowNotificationModal, setNotificationModalText } = useModal()
    const [ oldPassword, setOldPassword ] = useState('')
    const [ newPassword, setNewPassword ] = useState('')
    const [ confirmNewPassword, setConfirmNewPassword ] = useState('')
    const token = localStorage.getItem('@auth:token')
    const { user } = useAuth()
    const navigate = useNavigate()

    async function handleSubmit(){
        try{
            await api.post(`api/v1/users/resetPassword/${user.id}`, {
                oldPassword,
                newPassword,
                confirmNewPassword
            }, {
                headers: {
                    authorization: token
                }
            })

            setShowNotificationModal(true)
            setNotificationModalText('Senha atualizada com sucesso')
            return navigate(-1)

        }catch(error){
            if(error?.response?.data?.message != undefined){
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar alteração de senha, se o problema persistir entre em contato com o suporte.')
        }
    }

    return(
        <div className='flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14'>
            <p className='text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-5'>Alterar senha</p>
            <div className='flex flex-col w-fit h-80 justify-center items-center shadow-xl rounded-lg mt-20 sm:mt-20 p-2'>
                <div className='flex flex-col justify-around h-full items-center p-3'>
                    <div className='flex flex-col gap-3'>
                        <LabelInput text={'Senha atual'}>
                            <Input 
                                type="password"
                                onChange={(e) => setOldPassword(e.target.value)}
                                value={oldPassword ? oldPassword : ''}
                            />
                        </LabelInput>
                        <LabelInput text={'Nova Senha'}>
                            <Input 
                                type="password"
                                onChange={(e) => setNewPassword(e.target.value)}
                                value={newPassword ? newPassword : ''}
                            />
                        </LabelInput>
                        <LabelInput text={'Confirme Nova Senha'}>
                            <Input 
                                type="password"
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                value={confirmNewPassword ? confirmNewPassword : ''}
                            />
                        </LabelInput>
                    </div>
                    <div className='flex flex-row justify-between w-full gap-3'>
                        <Button approval={false} background={'bg-red-500'} type='button' onClick={() => navigate(-1)}> Cancelar</Button>
                        <Button onClick={() => handleSubmit()}>Confirmar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}