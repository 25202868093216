import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';

export function SimplePie({data, id, width}){

    useEffect(() => {
        const options = {
            series: data.map(item => item?.value),
            colors: ['#005f9e', '#db2777'],
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: data.map(item => item?.name),
            responsive: [{
            breakpoint: 480,
                options: {
                    chart: {
                        
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        };
    
        let chart = new ApexCharts(document.querySelector(`#${id}`), options);
        chart.render();

    }, [])

    return (
        <div id={id} className={`flex justify-center items-center h-full ${width ? `w-${width}` : 'w-full'}`}>

        </div>
    )
}