import React, { useEffect, useRef, useState } from 'react'
import { useModal } from '../../hooks/useModal'

export function NotificationModal() {

    const [showContainerModal, setShowContainerModal] = useState(false)
    const [showAproval, setShowAproval] = useState(false)
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, showAprovalModal, setShowAprovalModal } = useModal()

    useEffect(() => {
        setShowContainerModal(showNotificationModal)
        setShowAproval(showAprovalModal)

        let timer

        if (showNotificationModal) animationTimer()

        function animationTimer() {
            timer = setTimeout(() => {
                setShowContainerModal(false)
                setShowNotificationModal(false)
                setShowAprovalModal(false)
            }, 3000)
        }

    }, [showNotificationModal])

    useEffect(() => {
        if (showNotificationModal){    
            setShowNotificationModal(true)
        }else{
            setShowNotificationModal(false)
            setShowAprovalModal(false)
        }
    }, [showNotificationModal])

    return (
        <div className={`${showContainerModal ? 'flex overflow-hidden' : 'hidden overflow-hidden'}`}>
            <div className={`${showAproval ? 'bg-red-600' : 'bg-green-600'}`}>
                <div id='notificationModal' className={`${showNotificationModal ? 'translate-x-0 duration-200 ease-in-out' : 'translate-x-[110%] duration-200 ease-in-out'} flex flex-row justify-center duration-200 transition-all right-0 top-0 w-[20rem] p-2 absolute z-[2000] rounded-md ${showAproval ? 'bg-red-700' : 'bg-green-700'} opacity-1 mt-[4.8rem] mr-[1rem] shadow-xl h-[5rem]`}>
                    <div className={`flex justify-center items-center text-white`}>
                        <p className='text-base'>{notificationModalText}</p>
                    </div>
                    <button className={`w-[1rem] h-[1rem] absolute justify-center items-center right-0 border-none rounded-lg outline-none cursor-pointer opacity-1 mr-[0.2rem] pb-[2px] pl-[1px] hover:duration-[200ms] hover:opacity-5`} title={'Fechar'} onClick={() => {
                        setShowContainerModal(false)
                        setShowNotificationModal(false)
                    }}>x</button>
                </div>
            </div>
        </div>
    )
}