import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FiX, FiTrash2, FiArrowLeft, FiChevronRight, FiRepeat, FiSearch, FiAlertTriangle } from 'react-icons/fi'; 
import WindowedSelect from 'react-windowed-select';
import { components, createFilter } from 'react-windowed-select';
import api from '../../services/api';
import { TableDefault } from '../../components/table/table.default';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/buttons/button.default';
import { Input } from '../../components/input/input';
import { LabelInput } from '../../components/label/label.input';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { useMenu } from '../../hooks/useMenu';
import { useModal } from '../../hooks/useModal';
import Modal from '../../components/Modal/modal';
import Loader from '../../components/Loader/loader';
// import './users.css';

export default function LinkCompany(props){

  const navigate = useNavigate()
  const { id, fullname } = useParams()
  const token =  localStorage.getItem('@auth:token');
  const { setMenuTitle } = useMenu()
  setMenuTitle('Cadastro de Usuários')
  const [companies, setCompanies] = useState([]);
  const { setShow, show } = useModal()
  const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
  const [company, setCompany] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [registers, setRegisters] = useState([])
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([])
  const [userId, setUserId] = useState()
  const [ buttonRemove, setButtonRemove ] = useState()
  const [ clientTemp, setClientTemp ] = useState()
  const [ errorCompaniesEmpty, setErrorCompaniesEmpty ] = useState(false)
  const [ errorUser, setErrorUser ] = useState(false)

  useEffect(() => {
      getData()
  }, [token, selectedCompanies])

  async function getData(){
    try {
      const companiesApi = await api.get('/api/v1/companiesConnect', {
        headers: {
            authorization: token,
        }
      });
      setCompanies(companiesApi.data.data?.map(item => {
        const setCompanyWithBaseAtOrigin = item?.companyOrigin == 'questor' ? item?.id_questor : item?.id_senior
        return {
          ...item,
          label: `${setCompanyWithBaseAtOrigin} - ${item?.name}`,
          nomemp: item?.name,
          value: setCompanyWithBaseAtOrigin
        }
      }))

      const registerApi = await api.get(`/api/v1/users/linkCompanies/${id}`, {
        headers: {
            authorization: token,
        }
      });
      setRegisters(registerApi.data.data.map((item) => {
        return {
          ...item,
          check: <input type='checkbox' className='cursor-pointer companyTransferCheckbox' onChange={(e) => {
            if(e.target.checked){
              setSelectedCompanies((prev) => ([...prev, item.id]))
              getData()
            } else {
              const position = selectedCompanies.indexOf(item.id)
              if (position > -1){
                const items = selectedCompanies
                items.splice(position, 1)
                setSelectedCompanies([...items])
                getData()
              }
            }
          }}/>,
          remove: <FiTrash2 onClick={() => {setClientTemp(item); setShow(true); setButtonRemove(true)}} className={'cursor-pointer ml-[50%] text-red-800'} title={'Excluir'}/>
        }
      }))
      const getUsersApi = await api.get('/api/v1/users', {
        headers: {
            authorization: token
        }
      })

      setUsersList(getUsersApi?.data.map((user) => {
          return {
              ...user,
              access: user.access ? typeAccess[user.access - 1]?.name : '',
              inputDescription: `${user.fullname} - ${typeAccess[user.access - 1]?.name}`
          }
      }))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return alert(error?.response?.data?.message || 'Erro interno, entre em contato com o suporte') 
    }    
  }

  useEffect(() => {
    if (!show){
      setButtonRemove(false)
      setClientTemp()
      setErrorCompaniesEmpty(false)
      setErrorUser(false)
      setUserId()
    }
  }, [show])

  async function handleSubmit(element){
    element.preventDefault();
    setLoading(true)

    if(company === null || company === "null" || company === "" || company === undefined){
      return alert("Selecione uma empresa!")
    }else{
      try{
        api.post('/api/v1/users/linkCompanies', {
          user_id: id,
          company: company
        },{
          headers: {
            Authorization: token,
          }
        }).then((response, error) => {
          if(error){
            return alert("Erro ao realizar cadastro.");
          }
          
          setCompany('')
          setCompanyName('')
          setLoading(false)
          setRegisters([]);
          setShowNotificationModal(true)
          getData()
          return setNotificationModalText(response.data.response)
  
        });
      }catch(error){
        setLoading(false)
        return alert("Erro ao realizar cadastro!")
      }
    }
  }

  async function handleDelete(companyParam){
    setLoading(true)
    if(companyParam.company === null || companyParam.company === "" || companyParam.company === undefined){
      return alert("Erro ao realizar exclusão");
    }

    try{
      await api.delete(`/api/v1/users/linkCompanies/${id}/${companyParam.company}`, {
        headers: {
          authorization: token
        }
      });
      
      setRegisters([])
      setLoading(false)
      setShowNotificationModal(true)
      getData()
      return setNotificationModalText('Exclusão realizada com sucesso')

    }catch(error){
      setLoading(false)
      return alert("Erro ao realizar exclusão, fale com o administrador");
    }

  }

  const tableThead = [
    {
      "name":"Código",
      "original_name": "company"
    },
    {
      "name":"Empresas",
      "original_name": "company_name"
    },
    {
      "name":"Excluir",
      "original_name": "remove"
    }
  ]

  const typeAccess = [
    {
        "value": 1,
        "name": "Administrador"
    },
    {
        "value": 2,
        "name": "Folpag - RH"
    },
    {
        "value": 3,
        "name": "Cliente"
    },
    {
        "value": 4,
        "name": "Cliente - Admissão"
    }
]

  const handleCheckAll = (e) => {
    
    if (!e.target.checked) {
      const checkboxes = document.querySelectorAll('.companyTransferCheckbox')
      Array.from(checkboxes).map((checkbox) => {
        checkbox.checked = false
      })
      setSelectedCompanies([])      
    } else {
      const checkboxes = document.querySelectorAll('.companyTransferCheckbox')
      Array.from(checkboxes).map((checkbox) => {
        checkbox.checked = true
      })
      registers.map((item, index) => {
        setSelectedCompanies([])
        setSelectedCompanies([...selectedCompanies, selectedCompanies[index] = item?.company])   
      })
    }
  }

  async function transferCompanies(){
    if(selectedCompanies.length == 0){
      setShow(true)
      return setErrorCompaniesEmpty(true)
    }
    if(!userId){
      setShow(true)
      return setErrorUser(true)
    }

    setLoading(true)
    try {
  
      await api.put(`/api/v1/users/linkCompanies/transfer/from/${id}/to/${userId}`, {
        companies: selectedCompanies
      }, {
        headers: {
          authorization: token
        }
      })
      
       setShow(false)
       setShowNotificationModal(true)
       setNotificationModalText('Sucesso ao realizar transferência de empresa')
       return navigate('/users')
      
    } catch (error) {
      setLoading(false)
      return alert(error?.response?.data?.message || 'Erro interno, entre em contato com o administrador.')
    }
  }

  return (
    <div>
      {
        show && buttonRemove ?
          <Modal>
              <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                  <div className='flex justify-between pl-3 py-5 items-center'>
                      <p className='flex flex-row font-extrabold items-center text-lg'>
                          <p className='mr-7'>Deseja realmente excluir esse cliente?</p> 
                      </p>
                      <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                  </div>
                  <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                      <Button background={'bg-red-800'} onClick={() => {setShow(false); setButtonRemove(false)}}>Cancelar</Button>
                      <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleDelete(clientTemp)}}>Confirmar</Button>
                  </div>
              </div>
          </Modal>
        : show && errorCompaniesEmpty ?
          <Modal>
              <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                  <div className='flex justify-between pl-3 py-5 items-center'>
                      <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                          <FiAlertTriangle className='text-red-700 mr-7'/> Você deve selecionar uma ou mais empresas para poder transferir.
                      </p>
                      <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                  </div>
              </div>
          </Modal>
        : show && errorUser ?
          <Modal>
              <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                  <div className='flex justify-between pl-3 py-5 items-center'>
                      <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                          <FiAlertTriangle className='text-red-700 mr-7'/> Você deve informar um usuário para enviar as empresas.
                      </p>
                      <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                  </div>
              </div>
          </Modal>
        : show ?
          <Modal>
            <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[50rem]`}>
              <div className='flex justify-between pl-3 pt-3'>
                <p className='flex flex-row font-extrabold text-primaryDefaultLight items-center text-lg'>
                  <FiChevronRight/> Transferência de empresas.
                </p>
                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
              </div>
              <div className='w-full flex flex-col justify-center items-center pt-4 gap-2'>
                <p className='font-black text-primaryDefaultLight'>De: {fullname}</p>
                <div className='font-black text-primaryDefaultLight flex gap-3 items-center'>
                  Para:
                  <LabelInput>
                      <InputAutoComplete
                          data={usersList}
                          selectedLabel={'inputDescription'}
                          optionList={['inputDescription']}                                
                          onChange={(e) => {
                            setUserId(e.id)
                          }}
                          placeHolder='Selecione um Usuário'
                          width={60}
                      />
                  </LabelInput>
                </div>
              </div>
              <div className='flex flex-col h-fit mt-5 w-[40rem] ml-[5.2rem] shadow-xl rounded-md justify-center items-center overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                <table className='w-full border-collapse sticky max-h-[14rem]'>
                  <thead className='top-0 sticky'>
                    <tr className='bg-gray-300 text-sm text-white font-extrabold'>
                      <th className='sticky left-0 top-0 p-2 bg-primaryDefaultLight'>
                        <input 
                          type={'checkbox'} 
                          title={'Selecionar todos'} 
                          className={'cursor-pointer'}
                          onClick={handleCheckAll}
                        />
                      </th>
                      <th className='sticky left-0 top-0 bg-primaryDefaultLight'>Código</th>
                      <th className='sticky left-0 top-0 bg-primaryDefaultLight'>Empresa</th>
                    </tr>
                  </thead>
                  <tbody className=''>
                    {
                    registers.map((item, index) => {
                      return (
                        <tr className={`${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'}`}>
                          <td className={`text-center py-1 ${index == 0 ? '': ''}`}>{item.check}</td>
                          <td className='text-center'>{item.company}</td>
                          <td className='text-center'>{item.company_name}</td>
                        </tr>
                        )
                      })
                    }
                  </tbody>              
                </table>
              </div>
              <div className='flex flex-row justify-center items-center my-8'>
                <Button onClick={() => transferCompanies()}>Tranferir Empresas</Button>
              </div>
            </div>
          </Modal>
        :
        <></>
      }
      <div className="flex justify-aroun ml-[5rem] mt-12">
        <span onClick={e => navigate('/users')} className='hover:text-gray-500 cursor-pointer flex text-base justify-center align-center gap-1'><FiArrowLeft className='mt-1'/>Voltar</span>
      </div>
      <h1 className={'flex w-full justify-center items-center text-lg text-primaryDefaultLight font-black'}>{`${id} - ${fullname}`}</h1>
      <form onSubmit={handleSubmit} className={'flex flex-col justify-center items-center'}>
          <LabelInput>
              <InputAutoComplete
                  data={companies}
                  selectedLabel={'label'}
                  optionList={['label']}
                  placeHolder={'Selecione uma empresa'}                      
                  onChange={(e) => {
                    if (e !== null) {
                        setCompany(e.id);
                        setCompanyName(e.label);
                    }
                  }}
              />
          </LabelInput>
          <div className='flex flex-col justify-center items-center mt-2'>
            <Button>Cadastrar</Button>
          </div>
      </form>
      <div className="flex flex-col justify-center align-center ml-28 w[95%] max-h-[72vh] mr-16 mt-5 gap-4">
        { 
          loading ?  <Loader/>
            : <TableDefault
                title={tableThead}
                data={registers}
                quantity={registers.length}
                buttonFunction={setShow}
                buttonFunctionParam={true}
                buttonDescription={'Tranferir Empresas'}
                collumns={["company", "company_name", "remove"]}
              />
        }
      </div>
    </div>
  )
}FiRepeat