import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';

export function FunnelAdminAdmission({data, title, id}){    
    useEffect(() => {

        var options = {
            series: [
            {
              name: 'Admissões',
              data: data?.map(item => { return item?.quantityAdmission }),
            },
          ],
            chart: {
            type: 'bar',
            height: 300,
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              horizontal: true,
              barHeight: '80%',
              isFunnel: true,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
            },
            dropShadow: {
              enabled: true,
            },
          },
          title: {
            text: title,
            align: 'middle',
            color: '#311962'
          },
          colors: ['#311962'],
          xaxis: {
            categories: data?.map(item => { return item?.name })
          },
          legend: {
            show: false,
          },
        };
  
        var chart = new ApexCharts(document.querySelector(`#${id}`), options);
        chart.render();

    }, [])

    return (
        <div id={id}></div>
    )

}