import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useTermsOfUse } from '../../hooks/useTermsOfUse'
import api from '../../services/api'
import './termsOfUse.css'

export default function UseTerms(){

    const { signOut } = useAuth()
    const { acceptTerm } = useTermsOfUse()
    const [ termOfUseId, setTermOfUseId ] = useState(null)
    const [ loadingTermOfUse, setLoadingTermOfUse ] = useState(true)
    const [ terms, setTerms ] = useState([])
    const token = localStorage.getItem("@auth:token");

    useEffect(() => {
        async function getData(){
            setLoadingTermOfUse(true)
            const useTerms = await api.get('api/v1/useTerms/active', {
                headers: {
                    Authorization: token
                }
            })
            setTerms(useTerms.data.data.paragraphs)
            setTermOfUseId(useTerms.data.data.id)

            setLoadingTermOfUse(false)
        }

        getData()
    }, [])

    return (
        <div className='animate-[wiggleLeft_1s_ease-in-out] useTerms-container'>
            <div className='useTerms-content'>
                <h2>Termos de uso - Folpag Connect</h2>
                {
                    !loadingTermOfUse ? (
                        <>
                            <div className='useTerms-textBox'>
                                <div className='useTerms-text'>
                                    {
                                        terms.map(data => {
                                            return (
                                                <div>
                                                    <p>{data.subTitle}</p>
                                                    <p>{data.paragraph}</p>
                                                </div>
                                            )
                                        })
                                    }     
                                </div>
                            </div>
                            <div className='accept-terms'>
                                <button onClick={() => acceptTerm(termOfUseId)}>Aceitar</button>
                                <button onClick={() => signOut(false)}>Recusar</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='termOfUseLoading'>
                                <br />
                                <br />
                                <div className="lds-dual-ring"></div>
                            </div>
                        </>
                    )
                }
                
            </div>
        </div>
    )
}