import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMenu } from '../../../hooks/useMenu';
import { useAuth } from '../../../hooks/useAuth';
import userIcon from '../../../images/iconeUsuario.png'
import './myProfile.css'
import Modal from '../../../components/Modal/modal'
import { MyProfilePictureEdit } from './myProfile.editPicture';
import { useModal } from '../../../hooks/useModal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FiX } from 'react-icons/fi';
import { Button } from '../../buttons/button.default';

export default function MyProfile() {

    const { showProfile, setShowProfile } = useMenu()
    const { signOut, user } = useAuth()
    const [imageSrc, setImageSrc] = useState(null)
    const [picPreview, setPicturePreview] = useState('')
    const { setShow, show } = useModal()
    const [modalExit, setModalExit] = useState(false)
    const [modalPhoto, setModalPhoto] = useState(false)

    const eventListener = (event) => {
        if (event.key == 'Escape') {
            setShowProfile(false)
        }
    }

    if (showProfile === true) {
        window.addEventListener('keydown', eventListener)
    } else {
        window.removeEventListener('keydown', eventListener)
    }

    function handlePicture() {
        const input = document.getElementById('myProfilePicture')
        input.click()
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    async function showPreview(e) {
        const file = e.target.files[0];
        let imageDataUrl = await readFile(file)
        setImageSrc(imageDataUrl);
        setPicturePreview(URL.createObjectURL(file))
        setModalPhoto(true)
    }

    const handleClosePhoto = (e) => {
        setModalPhoto(e)
        const input = document.getElementById('myProfilePicture')
        input.value = ''
    }

    return (
        <>

            <div className={`${modalExit ? 'flex flex-col overflow-hidden' : 'overflow-hidden'}`}>
                <div className={`${modalExit ? 'flex ' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[800] `}>
                    <div className={`animate-[wiggle_.4s_ease-in-out] pb-16 lg:pb-0 absolute lg:relative bottom-0 w-full lg:w-auto transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                        <div className='flex justify-end pl-3 py-5 items-center'>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setModalExit(false)} title={'Fechar'} />
                        </div>
                        <div className='flex flex-col items-center justify-center w-full mb-10'>
                            <p className='flex flex-row font-extrabold items-center text-sm lg:text-lg'>
                                <span className='mr-7'>Deseja realmente sair do sistema?</span>
                            </p>
                        </div>
                        <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                            <div className='flex flex-col items-center justify-center w-96 p-1'>
                                <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                    <Button background={'bg-red-800'} onClick={() => { setShow(false); setModalExit(false) }}>Cancelar</Button>
                                    <Button background={'bg-green-800'} type={'button'} onClick={() => { setShow(false); signOut() }}>Confirmar</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${modalExit ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[103]`}></div>
            </div>
            {
                modalPhoto &&
                <MyProfilePictureEdit onChange={handleClosePhoto} imgSrc={imageSrc} picPreview={picPreview} />
            }
            <div id={showProfile == true ? 'myProfileMenu' : 'myProfileHidden'}>
                <>
                    <div onClick={() => { handlePicture() }}>
                        {
                            user?.picture?.length > 0 ?
                                <Tippy
                                    content={<a>Editar Foto</a>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={80}>
                                    <img src={user?.picture} className={'rounded-full border-2 border-gray-200 hover:brightness-75 hover:cursor-pointer'}></img>
                                </Tippy>
                                :
                                <Tippy
                                    content={<a>Adicionar Foto</a>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={80}>
                                    <img src={userIcon} className={'hover:brightness-75 hover:cursor-pointer'} />
                                </Tippy>
                        }
                        <input id='myProfilePicture' type='file' onChange={(e) => showPreview(e)} hidden={true}></input>
                    </div>
                    <h5>{user.fullname}</h5>
                    <p onClick={() => setShowProfile(false)}>
                        <Link to='/' id='resetPassLink'>
                            Trocar empresa
                        </Link>
                    </p>
                    <p onClick={() => setShowProfile(false)}>
                        <Link to='resetPassword' id='resetPassLink'>
                            Alterar senha
                        </Link>
                    </p>
                    <p onClick={() => {
                        setShowProfile(false)
                        setModalExit(true)
                    }}>
                        Sair
                    </p>
                </>

            </div>
        </>
    )
}