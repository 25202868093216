import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
// Hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
// Components
import Loader from '../../components/Loader/loader';
import { Button } from '../../components/buttons/button.default';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';
import Modal from '../../components/Modal/modal';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaFilePdf, FaFileExcel, FaPrint, FaUser } from 'react-icons/fa';
import months from '../../utils/listOfMonths'
import Multiselect from '../../components/selects/mult.select'

export function ReportNotes(){

    const navigate = useNavigate()
    const { setShowNotificationModal, setNotificationModalText, setShowAprovalModal } = useModal()
    const { company, branch } = useCompanyBranch();
    const [loading, setLoading] = useState(false);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Relatórios')
    const token = localStorage.getItem('@auth:token');
    const [selectedMonth, setSelectedMonth] = useState('')
    let selectedPDF = false   
    let selectedXLSX = false
    const [selectedOrder, setSelectedOrder] = useState()
    const [branchList, setBranchList] = useState([])
    const [selectedBranchs, setSelectedBranchs] = useState([])
    const [employeesList, setEmployessList] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState({employeeId: 0, employeeName: 'TODOS'})
    const [typesList, setTypesList] = useState([])
    const [selectedType, setSelectedType] = useState('')

    useEffect(() => {
        setLoading(true)
        async function getData(){
            if(company === 0 || branch === 0){
                alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
                return navigate('/')
            }

            try {

                const getEmployeesAPI = await api.get(`api/v1/employees/company/${company}/branch/${branch}?status=1`, {
                    headers: {
                        Authorization: token
                    }
                })
                setEmployessList(getEmployeesAPI?.data?.data)

                const getTypeApi = await api.get(`api/v1/typeNotes/company/${company}`, {
                    headers: {
                        Authorization: token
                    }
                })
                setTypesList(getTypeApi?.data?.data)

                const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
                    headers: {
                        authorization: token
                    }
                })
                setBranchList(resultBranches?.data?.data?.filter(emp => emp?.value != branch))
                setLoading(false)

            } catch (error){
                setLoading(false)
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        getData()
    }, [])

    async function downloadFile(){
        setLoading(true)

        try {
            const type = selectedPDF ? 'pdf' : 'excel'
            const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value})?.join(',') : ''            
            let params = []
            selectedEmployee?.employeeId != 0 && params.push(`numcad=${selectedEmployee?.employeeId}`)
            selectedType && params.push(`typeNotes=${selectedType?.id}`)
            params = params.join('&')

            const file = await api.get(`api/v1/notes/reports/company/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}/type/${type}${params ? '?' + params : ''}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `AnotacoesColaboradores_${Number(new Date())}`)
            myElement.click()
            setSelectedType('')
            setLoading(false)

        } catch (error){
            console.log(error)
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }

    }

    return (
        <>
            {
                loading ?
                <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[85vh] items-center justify-center">
                    <Loader />
                </div>
                :
                <div className='flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14'>
                    <p className='text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-5'>Anotações de colaboradores</p>
                    <div className='flex flex-col w-fit h-[55%] justify-center items-center shadow-xl rounded-lg mt-20 sm:mt-20 p-2'>
                        <div className='flex flex-col justify-around h-full items-center p-3'>
                            <div className='flex flex-col'>
                                <label className='mt-3'>Colaborador *</label>
                                <LabelInput>
                                    <InputAutoComplete
                                        data={[{employeeId: 0, employeeName: 'TODOS'}, ...employeesList]}
                                        id={'employee'}
                                        preSelectedValue={selectedEmployee ? selectedEmployee?.employeeName : ''}
                                        value={selectedEmployee ? selectedEmployee?.employeeName : ''}
                                        optionList={["employeeId", "employeeName"]}
                                        selectedLabel={'employeeName'}
                                        onChange={(e) => {
                                            setSelectedEmployee(e)
                                        }}
                                    />
                                </LabelInput>
                                <label className='mt-7'>Tipo de anotação</label>
                                <LabelInput>
                                    <InputAutoComplete
                                        data={typesList}
                                        id={'typeFilter'}
                                        preSelectedValue={selectedType ? selectedType?.description : ''}
                                        value={selectedType ? selectedType?.description : ''}
                                        optionList={["description"]}
                                        selectedLabel={'description'}
                                        onChange={(e) => {
                                            setSelectedType(e)
                                        }}
                                    />
                                </LabelInput>
                                <label className='mt-7'>Filiais</label>
                                <LabelInput>
                                    <Multiselect
                                        items={[{label: "0 - Todas", value: 'Todas'}, ...branchList]}
                                        mult                                                 
                                        onChange={e => {
                                            if (e[0]?.value == "Todas"){
                                                setSelectedBranchs(branchList)
                                            } else {
                                                setSelectedBranchs(e)
                                            }
                                        }}
                                        selectedLabel={'label'}
                                        value={selectedBranchs ? selectedBranchs : []}
                                        id={`selectedBranchs`}
                                    />                                
                                </LabelInput>
                            </div>
                            <div className='flex flex-row justify-between w-full gap-3 mt-5'>
                                <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                                    onClick={() => {
                                        selectedXLSX = false
                                        selectedPDF = true
                                        downloadFile()
                                    }}
                                >
                                    <FaPrint className='text-sm'/> PDF
                                </div>
                                <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                                    onClick={() => {
                                        selectedPDF = false
                                        selectedXLSX = true
                                        downloadFile()}}
                                    >
                                    <FaFileExcel className='text-sm'/> XLSX
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}