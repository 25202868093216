import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
// Hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
// Components
import Loader from '../../components/Loader/loader';
import { Button } from '../../components/buttons/button.default';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';
import Modal from '../../components/Modal/modal';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaFilePdf, FaFileExcel, FaPrint, FaUser } from 'react-icons/fa';
import months from '../../utils/listOfMonths'

export function ReportAbsenteeismTurnover() {

    const navigate = useNavigate()
    const { setShow, show } = useModal()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { company, branch } = useCompanyBranch();
    const { user } = useAuth()
    const [loading, setLoading] = useState(false);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Relatórios')
    const token = localStorage.getItem('@auth:token');
    const [selectedCompetenceDate, setSelectedCompetanceDate] = useState('')
    const [selectedLocal, setSelectedLocal] = useState('')

    useEffect(() => {
        if (company === 0 || branch === 0) {
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }
    }, [])

    async function downloadFilePDF() {
        setLoading(true)
        try {
            const file = await api.get(`api/v1/reports/absenteeismAndTurnover/numemp/${company}/branch/${branch}?dateCompetence=${`${selectedCompetenceDate}-01`}&type=${selectedLocal}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `AbsenteismoTurnover_${Number(new Date())}`)
            myElement.click()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }
    }

    async function downloadFileExcel() {
        setLoading(true)
        try {
            const file = await api.get(`api/v1/reports/absenteeismAndTurnover/numemp/${company}/branch/${branch}?dateCompetence=${`${selectedCompetenceDate}-01`}&type=${selectedLocal}&excel=true`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `AbsenteismoTurnover_${Number(new Date())}`)
            myElement.click()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }
    }

    const options = [
        {
            id: "org",
            name: 'Local de Organograma'
        },
        {
            id: "ccu",
            name: 'Centro de Custo'
        },
        {
            id: "pos",
            name: 'Posto de Trabalho'
        }
    ]

    return (
        <>
            {
                loading ?
                    <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[85vh] items-center justify-center">
                        <Loader />
                    </div>
                    :
                    <div className='flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14'>
                        <p className='text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-5'>Absenteísmo e Turnover - Sintético</p>
                        <div className='flex flex-col w-fit h-[60%] 2xl:h-[50%] justify-center items-center shadow-xl rounded-lg mt-14 sm:mt-20 p-2'>
                            <div className='flex flex-col justify-around h-full items-center p-3'>
                                <div className='flex flex-col gap-3'>
                                    <label className=''>Competência</label>
                                    <input
                                        className='w-80 md:w-96 border border-gray-300 text-black h-8 px-2 rounded-md text-base mt-1 cursor-text'
                                        onChange={(e) => {
                                            setSelectedCompetanceDate(e.target.value)
                                        }}
                                        type='month'
                                        value={selectedCompetenceDate ? selectedCompetenceDate : ''}
                                    />
                                    <label className='mt-3'>Emitir por</label>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={options}
                                            id={'name'}
                                            optionList={["name"]}
                                            selectedLabel={'name'}
                                            onChange={(e) => {
                                                setSelectedLocal(e?.id)
                                            }}
                                            value={selectedLocal ? options.filter(e => e?.id == selectedLocal)[0]?.name : ''}
                                        />
                                    </LabelInput>
                                </div>
                                <div className='flex flex-row justify-between w-full gap-3'>
                                    <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95'
                                        onClick={() => {
                                            downloadFilePDF()
                                        }}
                                    >
                                        <FaPrint className='text-sm' /> PDF
                                    </div>
                                    <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95'
                                        onClick={() => {
                                            downloadFileExcel()
                                        }}
                                    >
                                        <FaFileExcel className='text-sm' /> XLSX
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}