import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMenu } from "../../hooks/useMenu";
import { Button } from "../../components/buttons/button.default";
import { TableDefault } from "../../components/table/table.default";
import api from "../../services/api";
import Loader from "../../components/Loader/loader";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment";
import { useModal } from '../../hooks/useModal'
import Modal from '../../components/Modal/modal';
import { Quillmodules } from "../../components/richText/quill.modules";
import Logo from '../../images/logo_connect.png'
import ReactQuill from "react-quill";
import { LoadingPulse } from "../../components/Loader/loadingPulse";

export function Release(){

    const navigate = useNavigate()
    const { show, setShow } = useModal()
    const token = localStorage.getItem("@auth:token");
    const { setMenuTitle } = useMenu();
    setMenuTitle("Atualizações");
    const { user } = useAuth()
    const [loading, setLoading] = useState(true)
    const [loadingRelease, setLoadingRelease] = useState(true)
    const [releases, setReleases] = useState([])       
    const [releaseSelected, setReleaseSelected] = useState('')
    const [fileRelease, setFileRelease] = useState('')
    const [selectedView, setSelectedView] = useState(1)

    useEffect(() => {

        async function getData(){
            try {
                
                const getReleasesAPI = await api.get('api/v1/release', {
                    headers: {
                        Authorization: token
                    }
                })

                setReleases(getReleasesAPI?.data?.data?.map(rel => {
                    return {
                        ...rel,
                        created_at: moment(rel.created_at, 'YYYY-MM-DD')?.format('DD/MM/YYYY')
                    }
                }))

                setLoading(false)

            } catch(error){
                console.log(error)
            }
        }

        getData()

    }, [])

    async function getData(release){
        try {
            setLoadingRelease(true)
            if (release?.filename){
                try {

                    const findFileAPI = await api.get(`api/v1/release/getFile/${release?.id}`, {
                        responseType: 'blob',
                        responseEncoding: 'base64',
                        headers: {
                            authorization: token
                        }
                    })

                    const fileObj = URL.createObjectURL(findFileAPI?.data)

                    setFileRelease(fileObj)

                } catch(error){
                    console.log(error)
                }
            }

            setReleaseSelected(release)
            setLoadingRelease(false)

        } catch(error){
            console.log(error)
        }
    }

    const tableThead = [
        {
            "name": "Versão",
            "original_name": "version"
        },
        {
            "name": "Criado em",
            "original_name": "created_at"
        }
    ]

    return (
        <>
            <Modal>
                <div className={`relative w-fulltransition-all p-5 duration-300 bg-bgPrimaryLight rounded-lg max-h-[30rem] h-[30rem] overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                    {
                        loadingRelease ?
                        <div className="flex flex-row justify-center items-center w-96 h-96 ml-8">
                            <LoadingPulse/>
                        </div>
                        :
                        <>
                            <div className="flex flex-row justify-start items-start gap-2">
                                <button
                                    onClick={() => setSelectedView(1)}
                                    className={`font-extrabold text-primaryDefaultLight 
                                        h-12 md:h-6 w-full md:w-28 flex flex-col duration-300 items-center justify-center 
                                        ${selectedView == 1 ? 'bg-primaryDefaultLight text-secondaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight' :
                                        'bg-[#ececec] text-primaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight'}`
                                    }
                                >
                                    Público
                                </button> 
                                <button
                                    onClick={() => setSelectedView(2)}
                                    className={`font-extrabold text-primaryDefaultLight 
                                        h-12 md:h-6 w-full md:w-28 flex flex-col duration-300 items-center justify-center 
                                        ${selectedView == 2 ? 'bg-primaryDefaultLight text-secondaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight' :
                                        'bg-[#ececec] text-primaryDefaultLight hover:bg-gray-300 hover:text-primaryDefaultLight'}`
                                    }
                                >
                                    Interno
                                </button>      
                            </div>
                            {
                                selectedView == 1 ?
                                <>
                                    <p className='text-primaryDefaultLight flex w-full items-center justify-center font-semibold text-lg mt-3'>Versão {releaseSelected?.version}</p>
                                    <div className='mt-3 flex items-center justify-center'>
                                        {
                                            fileRelease ?
                                            <img width={500} height={300} src={fileRelease}/>
                                            : 
                                            <img width={150} height={150} src={Logo}/>
                                        }
                                    </div>
                                    <ReactQuill
                                        modules={Quillmodules}
                                        readOnly
                                        className='w-96'
                                        value={releaseSelected?.details}
                                        theme={"bubble"}               
                                    />
                                </>
                                :
                                <textarea value={releaseSelected?.observation ?? 'Nenhuma observação'} className="w-96 h-96 flex justify-center resize-none items-center mt-3"/>
                            }
                        </>
                    }
                </div>
            </Modal>
            <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
                {
                    loading ?
                    <Loader/>
                    :
                    <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                        <div className="hidden sm:flex flex-col justify-start align-center ml-20 w-[100%] h-[92vh] mr-8 gap-5">
                            <TableDefault
                                title={tableThead}
                                data={releases}
                                quantity={releases.length}
                                collumns={["version", "created_at"]}
                                onClick={(e) => {setFileRelease(''); getData(e); setShow(true)}}
                                cursorPointer={true}
                                buttonFunction={user?.isSuport ? () => navigate('/releaseCreate') : ''}
                                buttonFunctionParam={user?.isSuport ? true : ''}
                                buttonDescription={user?.isSuport ? 'Novo' : ''}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )

}