import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';

export function BarsHorizon({data, title, id, quantity}) {

    useEffect(() => {

        const dataValue = quantity ? data.map(apiData => apiData.quantity) : data.map(apiData => apiData.value)
        const namesValue = data.map(apiData => apiData.name)

        var options = {
        chart: {
            type: 'bar',
            height: 350
        },
        series: [{
                name: !quantity ? 'Valor' : 'Quantidade',
                data: dataValue
        }],
        colors: '#311962',
        tooltip: {
            y: {
              formatter: function(val) {
                if (!quantity){
                    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
                } else {
                    return val
                }
              }
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true
            }
        },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: namesValue,
            labels: {
                style: {
                    colors: 'black'
                },
            },
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false
            }
          }
          };
  
          var chart = new ApexCharts(document.querySelector(`#${id}`), options);
          chart.render();
    } ,[])

    return (
        <div id={id}>

        </div>
    )
}