import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';
import moment from 'moment';

export function SplineAdminSendEvents({data, title, id}){
    
    const dataValueSendEventsCurrentYear = data?.arrayQuantitySendsCurrentYear?.map(apiData => apiData)
    const dataValueSendEventsPreviousYear = data?.arrayQuantitySendsPreviousYear?.map(apiData => apiData)

    const currentYear = moment().year()
    const previousYear = moment().year() - 1

    useEffect(() => {

        var options = {
            series: [{
                name: currentYear,
                data: dataValueSendEventsCurrentYear
            }, {
            name: previousYear,
            data: dataValueSendEventsPreviousYear
            }],
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            colors: ['#311962', '#9e96b0'],
            xaxis: {
                type: 'category',
                categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
            }
        };
  
          var chart = new ApexCharts(document.querySelector(`#${id}`), options);
          chart.render();

    }, [])

    return (
        <div id={id}>

        </div>
    )
}