import React, { createContext, useContext, useState } from 'react'

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
    const [ size, setSize ] = useState('M');
    const [ show, setShow ] = useState(false)
    const [ type, setType ] = useState(false)
    const [ showNotificationModal, setShowNotificationModal ] = useState()
    const [ notificationModalText, setNotificationModalText ] = useState()
    const [ showAprovalModal, setShowAprovalModal] = useState()

    return (
        <ModalContext.Provider value={{
            setSize,
            size,
            setShow,
            show, 
            setType,
            type,
            showNotificationModal, setShowNotificationModal,
            notificationModalText, setNotificationModalText,
            showAprovalModal, setShowAprovalModal
        }}>
            { children }
        </ModalContext.Provider>
    )
}

export function useModal(){
    return useContext(ModalContext);
}