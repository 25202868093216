import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; //comportamento SPA, não recarrega todo react ao trafegar por paginas
import { FiArrowLeft, FiUpload, FiCheck, FiDownload, FiX, FiChevronRight, FiAlertTriangle } from 'react-icons/fi'; 
// import {StickyTable, Row, Cell} from 'react-sticky-table';
import underscore from 'underscore';
import { Button } from '../../components/buttons/button.default'
import Modal from '../../components/Modal/modal'
import { useModal } from '../../hooks/useModal';
import api from '../../services/api';
import sendEventsUtil from './sendAverageUtil'
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu';
import Loader from '../../components/Loader/loader';
import { useLayoutEffect } from 'react';
 
 export function SendAverage() {
 
     const { company, branch } = useCompanyBranch();
     const periodName = sessionStorage.getItem('period');
     const period = sessionStorage.getItem('periodId');
     const token = localStorage.getItem('@auth:token');
     
     const [persons, setPersons] = useState([]); //lista de colaboradores
     const [events, setEvents] = useState([]); //lista de eventos
     const [saveAveragesApi, setSaveAveragesApi] = useState([]);
     const [selectedOption, setSelectedOption] = useState(1);
     const [documentToImport, setDocumentToImport] = useState('');
     const [loading, setLoading] = useState(true); 
     const { setMenuTitle, menuStatus } = useMenu()
     const [sendsAverage, setSendAverage] = useState(null);
     const [ isValid, setIsValid ] = useState(false)
     const { setShow, show } = useModal()
     const navigate = useNavigate();
     setMenuTitle('Lançar Rateios')
     const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
     const [ errorSave, setErrorSave ] = useState(false)
     const [ errorSubmit, setErrorSubmit ] = useState(false)
     const [ errorAttach, setErrorAttach ] = useState(false)
     const [ buttonSubmit, setButtonSubmit ] = useState(false)
     const [ buttonSave, setButtonSave ] = useState(false)
     const [ buttonSubmitImport, setButtonSubmitImport ] = useState(false)
     const [ errorFormat, setErrorFormat ] = useState(false)
 
     useEffect(()=>{
        setLoading(true);
        
        async function apiConsult(){
            
            try {
                const getRateiosParams = await api.get(`/api/v1/averages/param/${company}`, {
                    headers: {
                        Authorization: token
                    }
                })
                setEvents(getRateiosParams?.data?.data);

                const reponsePersons = await api.get(`api/v1/persons/persons/${company}/${branch}/${period}`, {
                    headers: {
                        Authorization: token,
                    }
                })
                setPersons(reponsePersons.data);

                const responseSaveAverages = await api.get(`/api/v1/sendAverages/saveSendAverages/company/${company}/${branch}/period/${period}`, {
                    headers: {
                        Authorization: token
                    }
                })
                setSaveAveragesApi(responseSaveAverages.data)
                
                let sendsAverageConfig = {}

                reponsePersons.data.map(person => {
                    sendsAverageConfig[person.numcad] = getRateiosParams?.data?.data?.map(average => {        
                        
                        const verifyExistsSaveAverage = responseSaveAverages?.data?.filter(save => {
                            return person.numcad == save.person && save.average == average.average
                        })
                        
                        return {
                            average_id: average.average,
                            value: verifyExistsSaveAverage?.length > 0 ? verifyExistsSaveAverage[0].value : 0
                        }
                    })
                })
                setSendAverage(sendsAverageConfig)

                setLoading(false);
            } catch (error) {
                alert('Erro ao buscar dados, se o problema persistir entre em contato com o suporte')
                setLoading(false);
            }            
        }

         apiConsult();
 
     },  [ token, company, period, selectedOption ]);

     useEffect(() => {
        if (!show){
            setErrorSave(false)
            setErrorSubmit(false)
            setErrorAttach(false)
            setButtonSubmitImport(false)
            setErrorFormat(false)
        }
     }, [show])

     async function handleSubmitForSave(element){
         
         try{
             await api.post('api/v1/sendAverages/saveSendAverage', {
                 company: company,
                 branch: branch,
                 period: period,
                 sendAverage: sendsAverage
             },{
                 headers: {
                     Authorization: token,
                 }
             });
 
         }catch(error){
            console.log(error)
            setShow(true)
            setErrorSave(true)
             return
         }
 
        setLoading(false)
        setShowNotificationModal(true)
        setNotificationModalText('Lançamentos salvos com sucesso')
        return navigate('/historyAverage')
       
     }
 
     async function handleSubmitDefinitive(){
        setLoading(true)
        const daysOfPeriod = daysOfMonth(periodName)
                
        Object.keys(sendsAverage)?.map((key) => {
            let count = 0
            sendsAverage[key].map(data => {
                count += data.value
            })
            if(count > daysOfPeriod || count < daysOfPeriod && count != 0){
                setShow(true)
                return setErrorSubmit(true)
            }
        })
        
        const formatAverages = Object.keys(sendsAverage).map(key => {

            return {
                person_id: key,
                averages: sendsAverage[key].map(averages => {
                    return {
                        average_id: averages.average_id,
                        value: averages.value
                    }
                })
            }
            
        })

        try{
            await api.post('/api/v1/sendAverages/sendAverages', {
            company: company,
            branch: branch,
            period: period,
            daysOfPeriod: daysOfPeriod,
            sendAverages: formatAverages
            },{
            headers: {
                Authorization: token,
            }
            });

        }catch(error){
            setLoading(false)
            console.log(error);
            return alert('Erro ao enviar lançamentos')
        }

        setLoading(false)
        setShowNotificationModal(true)
        setNotificationModalText('Rateios lançados com sucesso')
        return navigate('/historyAverage')
    }
     

    function daysOfMonth(period) {
        const periodSplit = period.split('/')
        var date = new Date(periodSplit[1], periodSplit[0], 0);
        return date.getDate();
    }
 
     async function getPlan(){ //função que realiza download do arquivo excel.
         try{
             setLoading(true)
             const responseApi = await api({
                 url: `api/v1/sendAverages/company/${company}/branch/${branch}/period/${period}`, //sua URL
                 method: 'GET',
                 responseType: 'blob', //faz com que se entenda em arquivo bruto
                 headers: {
                     Authorization: token,
                 }
             })
             const url = window.URL.createObjectURL(new Blob([responseApi.data])); // cria um novo objeto documento instanciando o que foi recebido no download
             const link = document.createElement('a'); // cria elemento do dom 'a'
             link.href = url; // vincula dom ao objeto criado
             link.setAttribute('download', `(${company} - ${branch}) Planilha de Importação.xlsx`); //coloca os atributos 
             document.body.appendChild(link); 
             link.click(); //clica para que a web entenda o download
             setLoading(false)
             return
         }catch(error){
             return alert('Erro ao tentar realizar download da planilha modelo')
         }
     }
 
     async function handleImportPlan(e){
          
        if(!documentToImport){
            setShow(true)
            return setErrorAttach(true)
        }
     
        setLoading(true)
        const sends = await sendEventsUtil.readPlanToImport(documentToImport, events, persons);

        if(sends === "ERROR"){
            setShow(true)
            setErrorFormat(true)
            setDocumentToImport('')
            setSelectedOption(1)
            return setSelectedOption(selectedOption + 1)
        }
 
        try{

            await api.post('api/v1/sendAverages/importSendAverage',
                {
                    period: period,
                    branch: branch,
                    company: company,
                    sends: sends
                },{
                    headers: {
                        Authorization: token,
                    }
                }
            )

            setLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Importação realizada com Sucesso, Verifique antes de "Enviar em Definitivo"')
            navigate('/historyAverage')
            navigate('/sendAverage')
            return

        }catch(error){
        setLoading(0)
        return alert('Erro ao realizar importação, verifique a planilha.')
        }
         
       
     }

    function verifyIsValid(person_numcad){
        const daysOfPeriod = daysOfMonth(periodName)
        let sumSends = 0 

        sendsAverage[person_numcad]?.map(item => {
            sumSends += item.value
        })

        if(sumSends > daysOfPeriod || sumSends < daysOfPeriod && sumSends != 0){
            return false
        }
        return true
    }
 
     function handleSendHours(){
         if(document.getElementById('sistemaCheck')!== null && selectedOption === 1){
             document.getElementById('sistemaCheck').checked = true;
         }else if(document.getElementById('sistemaCheck')!== null && selectedOption !== 1){
             document.getElementById('sistemaCheck').checked = false;
         }
         if(document.getElementById('excelCheck')!== null && selectedOption === 2){
            document.getElementById('excelCheck').checked = true;
         }else if(document.getElementById('excelCheck')!== null && selectedOption !== 2){
            document.getElementById('excelCheck').checked = false;
        }
 
         if(selectedOption === 1){
             return(
                 <div className='flex flex-col gap-5 justify-center items-center'>
                     <form method="#" id="formulario" className='border border-primaryDefaultLight flex flex-col h-[60vh] w-[85%] mt-5 overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 z-0'>
                         <table className='border-collapse'>
                             <thead className='m-0 bg-primaryDefaultLight text-white font-bold relative'>
                                <tr className='sticky top-0 z-[300]'>
                                    <th className='sticky left-0 top-0 bg-primaryDefaultLight'>
                                        <div className='min-w-[20rem] z-5 py-3 px-2 border-0 border-r-2 border-r-gray-300 w-full'>Colaborador</div>
                                    </th>
                                    {
                                        events.map(event => {
                                            return (
                                                <th id={'event' + event.average} className={`bg-primaryDefaultLight`}>{ event.average } - { event.description } </th>
                                            )
                                        })
                                    }
                                </tr>
                             </thead>
                             <tbody className='pb-3 m-0'>
                                 {
                                     persons.map((person, index) => {
                                        return (
                                             <tr className={`${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'}`}>
                                                 <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'} z-3 ${verifyIsValid(person.numcad) == true ? 'text-black' : 'text-red-600'}`}> 
                                                     <div id={'person'+ person.numcad} className={` min-w-[20rem] py-2 px-2 border-0 border-r-2 border-r-gray-300 w-full`}>{person.numcad} - {person.nomfun} </div> 
                                                 </td>
                                                 {
                                                     events.map(event => {
                                                        let value = ''
                                                        saveAveragesApi.map(average => {
                                                            if (person.numcad === average.person && event.average === average.average){
                                                                value = average.value                                                             
                                                            }
                                                        }) 
                                                         return (
                                                            <td className='px-4'> 
                                                                <input 
                                                                    type="number" 
                                                                    className={`border border-gray-400 text-primaryDefaultLight px-1 py-1 flex m-auto`}
                                                                    defaultValue={value}
                                                                    onFocus={element => {
                                                                        element.target.classList.add('activeInput')
                                                                        document.getElementById(`event${event.average}`).classList.add('selectedeventsend')
                                                                        document.getElementById(`person${person.numcad}`).classList.add('selectedpersonsend')
                                                                    }}
                                                                    onBlur={element => {
                                                                        element.target.classList.remove('activeInput')
                                                                        document.getElementById(`event${event.average}`).classList.remove('selectedeventsend')
                                                                        document.getElementById(`person${person.numcad}`).classList.remove('selectedpersonsend')
                                                                    }}
                                                                    onChange={element => {                                                                      
                                                                        const personSends =  sendsAverage[person.numcad] 
                                                                        const eventToSend = personSends.filter((item) => item.average_id == event.average)

                                                                        eventToSend[0].value = element.target.value ? Number(element.target.value) : 0
                                                                        setSendAverage(sendsAverage)
                                                                        
                                                                        let verifyIsValidSends = verifyIsValid(person.numcad)
                                                                        if(!verifyIsValidSends){
                                                                            return document.getElementById(`person${person.numcad}`).style.color = 'red'
                                                                        }else{
                                                                            return document.getElementById(`person${person.numcad}`).style.color = 'black'
                                                                        }
                                                                    }}
                                                                /> 
                                                            </td>
                                                         )
                                                     })
                                                 }
                                             </tr>
                                         )
                                     })
                                 }
                             </tbody>
                         </table>                  
                     </form>
                     <div className='items-end gap-2 flex'>
                         <Button onClick={() => {setShow(true); setButtonSave(true)}} approval={false} shadow={true}>Salvar</Button>
                         <Button onClick={() => {setShow(true); setButtonSubmit(true)}} shadow={true}>Enviar</Button>
                     </div>
                 </div>
             );
         }else{
             return(
                 <form method='post' e className="flex flex-col justify-center items-center gap-10 mt-5 pl-10" enctype="multipart/form-data">
                     <div className='flex flex-col items-center justify-center text-red-900 font-black'>
                         <p>Atenção: O layout da planilha modelo não deve ser alterado.  </p>                      
                         <p>A linha '1' onde estão os eventos e coluna 'A' onde estão os funcionários devem permanecer na mesma ordem.</p>
                     </div>
                     <div className="flex gap-3 justify-center items-center flex-row font-black cursor-pointer ml-[-4rem]">
                         <p>1 -</p>
                         <div className='border border-green-800 hover:bg-white duration-300 hover:text-green-800 w-60 h-10 items-center justify-center text-white bg-[#1F6F45] rounded-md'>
                             <button className='flex flex-row gap-1 items-center justify-center m-auto mt-2' type='button' onClick={ ()=> getPlan()}>
                                 <FiDownload/> 
                                 Download planilha modelo
                             </button>
                         </div>
                     </div>
                     <div className="flex gap-3 justify-center items-center flex-row font-black cursor-pointer ml-[-4rem]">
                         <p>2 -</p>
                         <div className='border border-[#311962] hover:bg-white hover:text-[#311962] w-60 h-10 items-center justify-center text-white bg-[#311962] rounded-md'>
                             <label className='flex flex-row gap-1 justify-center items-center mt-2 cursor-pointer duration-300 font-black' for='linkFile'><FiUpload/> Anexar planilha preenchida</label>
                             <input
                                 className='hidden cursor-pointer'                            
                                 id='linkFile' 
                                 type='file' 
                                 accept='.xls,.xlsx' 
                                 onChange={(element)=>{
                                     setDocumentToImport(element.target)
                                     document.getElementById('showArchivePlan').innerHTML = `<p>${element.target.files[0].name}</p>`
                                 }}
                             />
                         </div>
                     </div>
                     <div className="flex gap-3 justify-center items-center flex-row font-black cursor-pointer ml-[-4rem]">
                         <p>3 -</p>
                         <div className='border border-[#2f0585] hover:bg-white duration-300 hover:text-[#2f0585] w-60 h-10 items-center justify-center text-white bg-[#2f0585] rounded-md'>
                             <button className="flex flex-row gap-1 items-center justify-center m-auto mt-2" type='button' onClick={() => {setShow(true); setButtonSubmitImport(true)}}>
                                 <FiCheck/>
                                 Importar 
                             </button>
                         </div>
                     </div>          
                 </form>
             )
         }
 
     }
 
     return(
         <div>
            {                
                show && errorSubmit ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Verifique os colaboradores em vermelho. 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                        <p className='p-3 text-black'><i>Observação</i>: você lançou valores que são maiores ou menores que a quantidade de dias do mês corrente. Verifique os colaboradores e certifique-se que a soma dos dias lançados é igual a {daysOfMonth(periodName)} dias.</p>                     
                    </div>
                </Modal>
                : show && errorSave ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Não foi lançado nenhum evento. 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                </Modal>
                : show && buttonSubmit ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                <p className='mr-7'>Deseja realmente "Enviar em Definitivo"?</p> 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-800'} onClick={() => {setShow(false); setButtonSubmit(false)}}>Cancelar</Button>
                            <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleSubmitDefinitive()}}>Confirmar</Button>
                        </div>
                    </div>
                </Modal>
                : show && errorAttach ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Primeiro você precisa anexar um arquivo!<br/>Faça isso na 2ª etapa 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                </Modal>
                : show && errorFormat ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Planilha fora de formatação! <br/>Preencha somente os campos necessários.
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                </Modal>
                : show && buttonSubmitImport ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                <span className='mr-7'>Deseja realmente importar esse arquivo?</span> 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                        <span className='text-center mb-3 text-md text-red-600 font-black'>Se você importar irá sobrescrever os eventos lançados.</span>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-800'} onClick={() => {setShow(false); setButtonSubmitImport(false)}}>Cancelar</Button>
                            <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleImportPlan()}}>Confirmar</Button>
                        </div>
                    </div>
                </Modal>
                : show && buttonSave ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                <span className='mr-7 text-red-600'>Alerta!</span> 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                        <p className='text-center mb-5 text-[1rem] p-4'>
                            <b>Você não está enviando os rateios para a folha.</b><br/>
                            Se você deseja enviar para a folha clique em <b>"Enviar"</b>.<br/>
                            Caso queira salvar, clique em "Prosseguir"
                        </p>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-800'} onClick={() => {setShow(false); setButtonSubmitImport(false)}}>Cancelar</Button>
                            <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleSubmitForSave()}}>Prosseguir</Button>
                        </div>
                    </div>
                </Modal>
                :
                <></>
            }
             <div className='conteudo flex flex-col'>
                 <div className='flex justify-around w-[100%] items-center'>
                     <div className={`${menuStatus == 1 ? 'topHeaderSendEventRigth' : 'topHeaderSendEvent'} flex justify-around pl-10 w-full items-center`}>
                         <Link to='/historyAverage' className={`${menuStatus == 1 ? 'voltar_historicoRight':'voltar_historico'} hover:text-gray-400 flex gap-1 items-center`}><FiArrowLeft /> <p> Voltar </p> </Link>
                         <div className={menuStatus == 1 ? 'titleHeaderRight' : 'titleHeader'}>
                             <h1 className='text-3xl font-black flex flex-col items-center justify-center'>{periodName}</h1>                        
                             <p className='font-semibold text-red-700'>Os lançamentos precisam ter como base {daysOfMonth(periodName)} dias nesse mês.</p>
                         </div>
                         <div className={`${menuStatus == 1 ? 'selecionarFormaPagamentoRight' : 'selecionarFormaPagamento'} flex flex-col`}>
                             <h4>Lançar horas: </h4>
                             <div className='selecionarFormaPagamento_opcoes'> 
                                 <div className='flex gap-1'>
                                     <input className='cursor-pointer' type="checkbox" id="sistemaCheck" name="sistemaCheck" onClick={()=>{ 
                                         setSelectedOption(1);
                                         setSendPersonEvents([])
                                         setEvents([]);
                                         setPersons([]);
                                         document.getElementById('sistemaCheck').checked = true;
                                         document.getElementById('excelCheck').checked = false;
                                     }}/>
                                     <label for="sistemaCheck">Sistema</label>
                                 </div>
                                 <div className='flex gap-1'>
                                     <input className='cursor-pointer' type="checkbox" id="excelCheck" name="excelCheck" onClick={()=>{ 
                                         setSelectedOption(2);
                                         document.getElementById('sistemaCheck').checked = false;
                                         document.getElementById('excelCheck').checked = true;
                                     }}/>
                                     <label for="excelCheck">Excel</label>
                                 </div>
                             </div> 
                         </div>
                     </div>
                 </div>
                 { !loading ? handleSendHours() : (
                     <div className={menuStatus == 1 ? 'tableAreaRightLoaderSend' : 'tableAreaLoaderSend'}>
                         <Loader/>
                     </div>
                 )}
             </div>
         </div>
     ); 
 
 };
 