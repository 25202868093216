import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FiUserCheck, FiPlus, FiUserMinus, FiClock, FiAlertCircle, FiArrowDown, FiArrowUp, FiTrash2, FiX, FiPaperclip, FiDownload, FiArrowLeft, FiEye } from 'react-icons/fi';
import { RiArrowUpDownLine } from 'react-icons/ri';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import Loader from '../../components/Loader/loader';
import { permittedFiles } from '../../utils/filesValidadtor/permittedFiles'
import { cnpjMask } from '../../utils/formatText/cnpjMask';
import { formatDate } from '../../utils/formatDate';

import { useAuth } from '../../hooks/useAuth';
import { handleOrdenation } from '../../utils/tableOrdenation';
import { TableDefault } from '../../components/table/table.default';
import { Button } from '../../components/buttons/button.default';
import Modal from '../../components/Modal/modal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaEye, FaHistory } from 'react-icons/fa';
import { formattTimeToShow } from '../../utils/formatTimeToShow';
import moment from 'moment';

export default function detailEmployeeDocs() {

    const token = localStorage.getItem('@auth:token');
    const { company, branch } = useCompanyBranch();
    const navigate = useNavigate()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const [loading, setLoading] = useState(false);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Documentos')
    const { user } = useAuth()
    const { setShow, show } = useModal()

    const [employeeData, setEmployeeData] = useState('')
    const [employeeFiles, setEmployeeFiles] = useState([])
    const [newScheduleAttacments, setNewScheduleAttachments] = useState([])
    const [attachmentToRemove, setAttachmentToRemove] = useState([])
    const [allHistoricToPerson, setAllHistoricToPerson] = useState([])
    const [fileHistoric, setFileHistoric] = useState('')
    const [showModalHistoricFile, setShowModalHistoricFile] = useState(false)

    useEffect(() => {
        setEmployeeData(JSON.parse(localStorage.getItem('employeeData')))
        if (([1, 2].includes(user.type) || ([3, 4].includes(user.type) && user.canAttach))){
            getAllHistoric(JSON.parse(localStorage.getItem('employeeData')))
        }
        getAllFilesAPI(JSON.parse(localStorage.getItem('employeeData')))
        localStorage.removeItem('employeeData')
    }, [])

    useEffect(() => {
        if (!show) {
            setShowModalHistoricFile(false)
        }
    }, [show])

    async function getAllHistoric(item){
        
        setLoading(true)

        try {

            const allFiles = await api.get(`api/v1/employeesDocs/actionsLog/${item?.employeeId}/${company}/${branch}`, {
                headers: {
                    authorization: token
                }
            })

            setAllHistoricToPerson(allFiles?.data?.data.map(historic => {
                return {
                    ...historic,
                    created_at: moment(historic?.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm'),
                    fullname: `${historic?.users_id} - ${historic?.fullname}`
                }
            }))
            setLoading(false)

        } catch(error){
            console.log(error)
            return alert('Erro ao buscar histórico do colaborador')
        }

    }

    async function getAllFilesAPI(item) {
        setLoading(true)

        const filesEmployee = await api.get(`/api/v1/employeesDocs/${item?.employeeId}/${company}`, {
            headers: {
                authorization: token
            }
        })
    
        setEmployeeFiles(filesEmployee.data.data)
        setLoading(false)
        
    }

    async function downloadFile(person, company, filename, originalFilename, branch){
        
        const employeeFile = await api.get(`/api/v1/employeesDocs/${person}/${company}/${branch}/${filename}`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: token
            }
        })
        
        let fileObj = URL.createObjectURL(employeeFile?.data)
        
        const myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute('download', originalFilename)
        myElement.click()

    }

    function addNewAttachment() {
        const inputFile = document.getElementById('fileInput')
        inputFile.click()
    }

    const handleNewPhoto = (photoFile) => {
        const inputFile = document.getElementById('fileInput')

        for (let i = 0; i < photoFile?.target?.files.length; i++) {
            const checkFile = permittedFiles(photoFile.target.files[i])
            if (checkFile.error) {
                return alert('Erro ao adicionar novo arquivo.')
            }
        }

        let newFiles = []
      
        Array.from(photoFile.target.files).map(file => {
            newFiles.push(file)
        })
        
        const oldArrayFiles = newScheduleAttacments

        setNewScheduleAttachments(newScheduleAttacments.length > 0 ? oldArrayFiles.concat(newFiles) : newFiles)

    }

    async function removeThisAttachmentHistory(attachment) {
        setAttachmentToRemove([...attachmentToRemove, attachment])
        setEmployeeFiles(employeeFiles.filter(attchToRemove => attchToRemove !== attachment))
    }

    async function removeThisAttachment(attachment, indexAttchament) {
        setNewScheduleAttachments(newScheduleAttacments.filter(attchToRemove => attchToRemove !== attachment))
    }

    async function handleSaveAttachments(){

        setLoading(true)

        try {

            if (newScheduleAttacments?.length > 0) {
                await handleAttchamentFile(employeeData?.employeeId)
            }

            if (attachmentToRemove?.length > 0) {
                
                let filesRemove = []
                
                attachmentToRemove.map(file => {
                    filesRemove.push(file?.filename)
                })    

                await handleDeleteAttachmentFiles(filesRemove, employeeData?.employeeId)
            }

            setLoading(false)
            setShowNotificationModal(true)
            setNewScheduleAttachments([]) 
            setAttachmentToRemove([])
            setNotificationModalText('Arquivos salvos com sucesso')
            return navigate('/employeeConsult')

        } catch (error) {
            setLoading(false)
            return alert(error?.response?.data[0]?.message)
        } 
    }

    async function handleAttchamentFile(person) {

        try {

            const formData = new FormData();

            newScheduleAttacments.map((attachment) => {
                formData.append('files', attachment);
            })

            const config = {
                headers: {
                    authorization: token,
                    'content-type': 'multipart/form-data',
                },
            };

            await api.post(`/api/v1/employeesDocs/${person}/${company}/${branch}`, formData, config)
            return
        } catch (error) {
            setLoading(false)
            return alert(error?.response?.data[0]?.message)
        }

    }

    async function handleDeleteAttachmentFiles(attach, person) {
        
        try {
            
            await api.put(`/api/v1/employeesDocs/${person}/${company}/${branch}`, {
                files: attach
            }, {
                headers: {
                    authorization: token,
                },
            })
            return

        } catch (error) {
            setLoading(false)
            return alert(error?.response?.data[0]?.message)
        }
    }

    async function getHistoricFile(file){

        const historicFileAPI = await api.get(`api/v1/employeesDocs/actionsLog/${employeeData?.employeeId}/${company}/${branch}/${file}`, {
            headers: {
                authorization: token
            }
        })

        setFileHistoric(historicFileAPI?.data?.data.map(historic => {
            return {
                ...historic,
                created_at: formattTimeToShow(historic?.created_at),
                fullname: `${historic?.users_id} - ${historic?.fullname}`
            }
        }))

        setShow(true)
        setShowModalHistoricFile(true)
    }

    const tableThead = [
        {
            "name": "Usuário",
            "original_name": "fullname",
        },
        {
            "name": "Arquivo",
            "original_name": "originalFilename",
        },
        {
            "name": "Descrição",
            "original_name": "description",
        },
        {
            "name": "Criado em",
            "original_name": "created_at",
        }
    ]

    return (
        <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
            {
                loading ?
                    <div className=''>
                        <Loader />
                    </div>
                    :
                    <>
                        {
                            show && showModalHistoricFile &&
                            <Modal>
                                <div className={`h-fit max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[50rem] p-4`}>
                                    <div className='flex justify-between pl-3 py-5 items-center mb-5'>
                                        <p className='flex flex-row font-extrabold items-center text-lg'>
                                            <p className='mr-7 flex justify-center items-center'><FaHistory className='flex mr-2'/>Histórico do arquivo:</p>
                                        </p>
                                        <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                                    </div>
                                    <TableDefault
                                        title={tableThead}
                                        data={fileHistoric}
                                        collumns={["fullname", "originalFilename", "description", "created_at"]}
                                    />
                                </div>
                            </Modal>
                        }
                        <div className="flex ml-[5rem] mt-12">
                            <span onClick={e => navigate('/employeeConsult')} className='hover:text-gray-500 cursor-pointer flex text-base justify-center align-center gap-1'><FiArrowLeft className='mt-1'/>Voltar</span>
                        </div>
                        <h1 className={'flex w-full justify-center items-center text-xl text-primaryDefaultLight'}>Colaborador:<i>{ employeeData?.employeeId} - {employeeData?.employeeName}</i></h1>
                        <div className={`transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow mt-2`}>
                            <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                                <div className={`flex flex-col items-center justify-start h-48 ${[1, 2].includes(user.type) ? 'w-[50rem]' : 'w-[50rem] mt-14'} p-1`}>
                                    <div className={`w-full flex lg:flex-row flex-col gap-2 items-start ${[1, 2].includes(user.type) ? 'justify-start' : 'justify-center'} lg:h-48 rounded-sm`}>
                                        {
                                            ([1, 2].includes(user.type) || ([3, 4].includes(user.type) && user.canAttach)) &&
                                            <Tippy
                                                content={<a>Anexar arquivos para esse colaborador</a>}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={80}>
                                                <div onClick={() => addNewAttachment()} className="p-2 ml-3 lg:mt-0 hover:brightness-125 rounded-lg shadow-lg hover:scale-150 duration-200 cursor-pointer h-10 flex flex-col items-center justify-center w-10">
                                                    <FiPaperclip/>
                                                </div>
                                            </Tippy>
                                        }
                                        <input className="hidden" multiple="multiple" id='fileInput' type={'file'} onChange={(e) => handleNewPhoto(e)}></input>
                                        <div className="bg-blue border border-gray-300 p-4 w-[42rem] h-full rounded-lg overflow-auto flex flex-row gap-2 flex-wrap">                                            
                                            {
                                                employeeFiles?.length == 0 && newScheduleAttacments?.length == 0 ?
                                                    <p className='text-orange-800 flex justify-center items-center text-center m-auto text-2xl'>Colaborador sem arquivos anexados.</p>
                                                :
                                                employeeFiles?.length > 0 &&
                                                    employeeFiles.map(attachment => {
                                                        return <div className="cursor-pointer p-2 h-12 flex w-48 rounded bg-gray-200 shadow-xl hover:scale-105 duration-200 items-center justify-center relative">
                                                            {
                                                                [1, 2].includes(user.type) &&
                                                                <span onClick={() => removeThisAttachmentHistory(attachment)} className="w-6 h-6 rounded-full hover:brightness-75 bg-gray-500 absolute left-0 top-0 -translate-x-2 -translate-y-2 text-titleGrayTextDark text-xs flex flex-col items-center justify-center cursor-pointer">x</span>
                                                            }
                                                            <Tippy
                                                                content={<a>Download do arquivo: {attachment?.originalFilename}</a>}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                delay={80}>
                                                                <div onClick={() => downloadFile(attachment.person, attachment.company, attachment.filename, attachment.originalFilename, branch)}>
                                                                    <p className='flex items-center gap-3 font-semibold'>{attachment?.originalFilename.slice(0, 10).concat('...').concat(attachment?.originalFilename.split('.')[1])}<span><FiDownload/></span></p>
                                                                </div>
                                                            </Tippy>
                                                            {
                                                                ([1, 2].includes(user.type) || ([3, 4].includes(user.type) && user.canAttach)) &&
                                                                <Tippy
                                                                    content={<a>Histórico do arquivo</a>}
                                                                    arrow={true}
                                                                    animation='shift-away'
                                                                    placement='top'
                                                                    delay={80}>
                                                                    <div className='flex ml-3'>
                                                                        <FaEye onClick={() => { getHistoricFile(attachment.filename) }}/>
                                                                    </div>
                                                                </Tippy>
                                                            }
                                                        </div>
                                                    })
                                            }
                                            {
                                                newScheduleAttacments?.length > 0 &&
                                                    newScheduleAttacments.map(attachment => {
                                                        return <div className="cursor-pointer p-2 h-12 flex w-48 rounded bg-primaryDefaultLight shadow-xl hover:scale-105 duration-200 flex-col items-center justify-center relative">
                                                            <span onClick={() => removeThisAttachment(attachment)} className="w-6 h-6 rounded-full hover:brightness-75 bg-gray-500 absolute left-0 top-0 -translate-x-2 -translate-y-2 text-titleGrayTextDark text-xs flex flex-col items-center justify-center cursor-pointer">x</span>
                                                            <Tippy
                                                                content={<a>Download do arquivo: {attachment?.name}</a>}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                delay={80}>
                                                                <div>
                                                                    <a className='flex items-center gap-3 text-white font-semibold' href={URL.createObjectURL(attachment)} download={attachment?.name}>{attachment?.name?.slice(0, 6).concat('...').concat(attachment.name.slice(attachment.name.length - 3, attachment.name.length))}<span><FiDownload/></span></a>
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                    })
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    ([1, 2].includes(user.type) || ([3,4].includes(user.type) && user.canAttach)) &&
                                    <div className='pb-4 flex flex-row justify-center items-center gap-4 mt-2'>                                        
                                        <Button type={'button'} onClick={() => handleSaveAttachments()}>Salvar</Button>
                                    </div>
                                }
                            </div>
                            {
                                ([1, 2].includes(user.type) || ([3,4].includes(user.type) && user.canAttach)) &&
                                <div className='conteudo'>
                                    <div className="flex flex-col justify-center align-center ml-20 w-[100%] mr-8">
                                        <div className='relative bg-white flex flex-col h-96 lg:max-h-[40rem] items-start justify-start w-full borderShadow overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500'>
                                            <TableDefault
                                                title={tableThead}
                                                data={allHistoricToPerson}
                                                quantity={allHistoricToPerson.length}
                                                collumns={["fullname", "originalFilename", "description", "created_at"]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
            }
        </div>
    )
}