/**
 * Desenvolvedor: Tiago Murilo Ochôa da Luz
 * Descrição: Pagina para consultar períodos fechados de folha.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
// import {StickyTable, Row, Cell} from 'react-sticky-table';
import api from '../../services/api';
import tableToExcel from '../../utils//tableToExcel';
import Loader from '../../components/Loader/loader'

import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import './consultPeriod.css'
import { useMenu } from '../../hooks/useMenu';
import { Button } from '../../components/buttons/button.default';


export default function ConsultPeriod() {

    const [persons, setPersons] = useState([]);
    const [events, setEvents] = useState([]);
    const [totalValues, setTotalValues] = useState(null)
    const [selectedOption, setSelectedOption] = useState(1);

    const { menuStatus } = useMenu();
    const { company, branch, branchCnpj } = useCompanyBranch();
    const periodId = sessionStorage.getItem('periodId');
    const period = sessionStorage.getItem('period');
    const token = localStorage.getItem('@auth:token');
    const { setMenuTitle } = useMenu()
    setMenuTitle('Lançamentos Realizados')
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function consultAPI(){
            setLoading(true)

            if(selectedOption === 1){

                const responseValueForPaper = await api.get(`/api/v1/sendEvents/company/${company}/branch/${branch}/period/${periodId}`, { //consulta eventos lançados no período
                    headers: {
                        Authorization: token
                    }
                })
                
                setPersons(responseValueForPaper.data.sends)
                setEvents(responseValueForPaper.data.events)

                const totalValuesObject = {}
                responseValueForPaper.data.events.map(event => {
                    totalValuesObject[event.codeve] = 0
                })

                responseValueForPaper.data.sends.map(send => {
                    send.sends.map(data => {
                        totalValuesObject[data.event] += data.value
                    })
                })

                setTotalValues(totalValuesObject)

            }else{

                const responseValueForPaper = await api.get(`/api/v1/sendEvents/company/${company}/branch/${branch}/period/${periodId}/sends`, { 
                    headers: {
                        Authorization: token
                    }
                })
                setPersons(responseValueForPaper.data.sends)
                setEvents(responseValueForPaper.data.events)                
                const totalValuesObject = {}
                responseValueForPaper.data.events.map(event => {
                    totalValuesObject[event.codeve] = 0
                })

                
                responseValueForPaper.data.sends.map(send => {
                    send.sends.map(data => {
                        totalValuesObject[data.event] += data.value
                    })
                })

                setTotalValues(totalValuesObject)

            }

            setLoading(false)
        }
        consultAPI()
    }, [company, periodId, period, token, selectedOption]);

    function showReport(){

        if(document.getElementById('valoreFolhaCheck')!== null && selectedOption === 1){
            document.getElementById('valoreFolhaCheck').checked = true
        }

        return(
            <>
                <div className='flex flex-col gap-5 justify-center items-center'>
                { events.length === 0 || persons.length === 0 ? 
                    <div className='flex mt-10 font-black text-primaryDefaultLight text-lg'>
                        <h2 className='flex justify-center items-center'>Folha não processada neste período</h2>
                    </div>
                :
                    <form method="#" id="formulario" className='border border-primaryDefaultLight flex flex-col h-auto max-h-[64vh] w-[85%] mt-5 overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                       
                            <table className='border-collapse' id='TableToExport'>
                                <thead className='m-0 bg-primaryDefaultLight text-white font-bold relative'>
                                    <tr className='sticky top-0 z-[300]'>
                                        <th className='sticky left-0 top-0 bg-primaryDefaultLight'>
                                            <div className='min-w-[20rem] z-5 py-3 px-2 border-0 border-r-2 border-r-gray-300 w-full'>
                                                Colaborador
                                            </div>
                                        </th>
                                        <th className='left-0 top-0 bg-primaryDefaultLight'>
                                            <div className='min-w-[20rem] z-5 py-3 px-2 border-0 border-r-2 border-r-gray-300 w-full'>
                                                Centro de Custo
                                            </div>
                                        </th>
                                        {                                            
                                            events.map(event => {
                                                return (
                                                    <th className='bg-primaryDefaultLight'> {event.codeve} - {event.deseve} </th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody className='pb-2 m-0'>
                                    {
                                        persons.map((person, index) => {
                                            return (
                                                <tr className={`${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'}`}>
                                                    <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'} z-3`}> 
                                                        <div className='min-w-[20rem] py-2 px-2 border-0 border-r-2 border-r-gray-300 flex items-center w-full'> {person.person} - {person.personName} </div> 
                                                    </td>
                                                    <td className={`${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'}`}> 
                                                        <div id={'person'+ person.codccu} className='min-w-[20rem] py-2 px-2 border-0 border-r-2 border-r-gray-300 w-full'>{person.codccuConcat ? person.codccuConcat : ' - '} </div> 
                                                    </td>
                                                    {
                                                        events.map(event => {
                                                            const filteredEvent = person.sends.filter(send => {
                                                                return event.codeve === send.event
                                                            })
                                                            if(filteredEvent.length > 0){
                                                                return (
                                                                    <td>
                                                                        <div className='mt-1 text-black px-1 py-1 h-5 w-60 items-center justify-center flex'>
                                                                            {selectedOption == 1 ? 'R$' : ''} {filteredEvent[0].value}
                                                                        </div>
                                                                    </td>
                                                                )
                                                            }else{
                                                                return( 
                                                                    <td>
                                                                        <div className='mt-1 text-black px-1 py-1 h-5 w-60 items-center justify-center flex'> 
                                                                            - 
                                                                        </div>
                                                                    </td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr className={`bg-primaryDefaultLight sticky bottom-0 z-[300]`}>
                                        <th className={'flex justify-center items-center sticky z-[40] left-0 top-0 bg-primaryDefaultLight'}> 
                                            <div className='min-w-[20rem] text-white py-2 pb-4 px-2 border-0 border-r-2 border-r-gray-300 h-10 flex justify-center items-center w-full'>Totais</div> 
                                        </th>
                                        <td> 
                                            <div className="flex justify-center items-center text-white pb-2"> - </div> 
                                        </td>
                                        {events.map(event => {
                                            return (
                                                <td> 
                                                    <div className="flex justify-center items-center text-white pb-2"> {selectedOption === 1 ? totalValues[event.codeve].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) :  totalValues[event.codeve].toLocaleString('pt-BR')} </div> 
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                    </form>
      
                } 
                </div>
            </>
        )
    }

    return (
        <div>
            <div className='conteudo flex flex-col p-10'>                
                <div className={`flex justify-around w-full items-center`}>
                    <Link to='/history' className='hover:text-gray-400 gap-1 flex justify-center flex-row items-center'><FiArrowLeft /> <p>Voltar</p> </Link>                      
                    <h1 className='text-3xl font-black flex items-center justify-center ml-[5rem]'>{period}</h1>
                    <div>
                        <Button onClick={() => tableToExcel(`${branchCnpj}_Lançamentos_na_folha_${period}.xls`)} 
                            className="flex flex-col justify-center">
                            Exportar Excel
                        </Button>
                        <a id="link-to-download" href="!#">{}</a> 
                    </div>
                </div>
                <div className='flex flex-row mt-4 justify-center items-center'>
                        <div className='flex flex-row justify-center items-center gap-4'> 
                            <div className='flex flex-row gap-1'>
                                <input className='cursor-pointer' type="checkbox" id="valoreFolhaCheck" name="valoreFolhaCheck" onClick={()=>{ 
                                    setSelectedOption(1);
                                    document.getElementById('valoreFolhaCheck').checked = true;
                                    document.getElementById('horasLancadasCheck').checked = false;
                                }}/>
                                <label for="valoreFolhaCheck"> Valores na Folha</label>
                            </div>
                            <div className='flex flex-row gap-1'>
                                <input className='cursor-pointer' type="checkbox" id="horasLancadasCheck" name="horasLancadasCheck" onClick={()=>{ 
                                    setSelectedOption(2);
                                    document.getElementById('horasLancadasCheck').checked = true;
                                    document.getElementById('valoreFolhaCheck').checked = false;
                                }}/>
                            <label for="horasLancadasCheck"> Lançamentos </label>
                            </div>
                        </div> 
                    </div>
                { !loading ? showReport() : (
                    <div className={menuStatus == 1 ? 'tableAreaRightLoader' : 'tableAreaLoader'}>
                        <Loader/>
                    </div>
                )}
            </div>
        </div>
    )
}