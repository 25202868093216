import React from 'react';
import { Route, HashRouter, Routes } from 'react-router-dom';
//pages
import Logon from '../pages/logon/logon';
import ConfirmedReadFile from '../pages/employee/confirmedReadFilePerEmail';

export default function NotAuthRoute(){
    return ( 
        <HashRouter> 
            <Routes>  
                <Route path="/confirmedRead/:uuid/:company/:branch/:companyName/:branchCnpj" element={<Logon/>}/>
                <Route path="/" element={<Logon/>} />
                {/* <Route path="/*" element={<Logon/>}/> */}
            </Routes>
        </HashRouter>
    )
}
