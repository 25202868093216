import React from 'react'
import './loader.css'

export default function Loader() {
    return (
        <>
        <div className='conteudo'>
            <div className="ldsDualRing"></div>
        </div>
        </>
    )
}