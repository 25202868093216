import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
// Hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
// Components
import Loader from '../../components/Loader/loader';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';
import fieldsList from '../../utils/fieldsReportList'
import { removerAcentos } from '../../utils/removeAccents'
import Multiselect from '../../components/selects/mult.select'

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaArrowRight, FaArrowLeft, FaPrint, FaFileExcel, FaEraser, FaCheckSquare, FaArrowDown, FaArrowUp, FaTrash } from 'react-icons/fa';
import Modal from '../../components/Modal/modal';
import { FiX } from 'react-icons/fi';
import { Button } from '../../components/buttons/button.default';
import { Input } from '../../components/input/input';

export function ReportEmployessData(){

    const navigate = useNavigate()
    const { setShow, show } = useModal()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { company, branch } = useCompanyBranch();
    const { user } = useAuth()
    const [loading, setLoading] = useState(false);
    const [loadingBars, setLoadingBars] = useState(false);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Relatórios')
    const token = localStorage.getItem('@auth:token');
    const [employeesList, setEmployessList] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState({employeeId: 0, employeeName: 'TODOS'})
    let selectedPDF = false   
    let selectedXLSX = false
    const [statusAll, setStatusAll] = useState(false)
    const [selectedOption, setSelectedOption] = useState('')
    const [branchList, setBranchList] = useState([])
    const [selectedBranchs, setSelectedBranchs] = useState([])

    const [listFields, setListFields] = useState(
        fieldsList.map(field => {
            field.selected = false
            field.checked = false
            field.show = true
            return field
        })
    )
    const [listForFiltersParams, setListForFiltersParams] = useState(
        fieldsList.map(field => {
            field.selected = false
            field.checked = false
            field.show = true
            return field
        })
    )
    const [historicFields, setHistoricFields] = useState(structuredClone(listFields))
    const [filtersParamsList, setFiltersParamsList] = useState([])
    const [selectedFilter, setSelectedFilter] = useState('')
    const [selectedSave, setSelectedSave] = useState(false)
    const [selectedTitle, setSelectedTitle] = useState('')

    useEffect(() => {
        setLoading(true)

        async function getEmployess(){
            if(company === 0 || branch === 0){
                alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
                return navigate('/')
            }
            try {
                const getEmployeesAPI = await api.get(`api/v1/employees/company/${company}/branch/${branch}?status=1`, {
                    headers: {
                        Authorization: token
                    }
                })

                const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
                    headers: {
                        authorization: token
                    }
                })
                setBranchList(resultBranches?.data?.data?.filter(emp => emp?.value != branch))
                setEmployessList(getEmployeesAPI?.data?.data)
                getFilters()
                setLoading(false)

            } catch (error){
                setLoading(false)
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }

        }

        getEmployess()

    }, [])

    async function getFilters(){
        try {
            const getFiltersApi = await api.get(`api/v1/filterForReport/company/${company}`, {
                headers: {
                    authorization: token
                }
            })
            setFiltersParamsList(getFiltersApi?.data?.data?.map(item => {
                return {
                    ...item,
                    filters: JSON.parse(item?.filters)
                }
            }))
        } catch(error){
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    async function downloadFile(){
        if (selectedSave){
            if (!selectedTitle){
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText('Preencha o campo título')
            }
        }
        // console.log(listForFiltersParams)
        // console.log(listFields)
        // return
        setLoadingBars(true)
        
        try {
            const filteredList = listFields.filter(item => item?.checked)
            let array = []
            const type = selectedPDF ? 'pdf' : 'excel'
            
            if (selectedEmployee){
                if (selectedEmployee?.employeeId == 'SOMENTE'){
                    if (selectedEmployee?.employeeName == 'ATIVOS'){
                        array.push(`allActives=true`)
                    }
                    if (selectedEmployee?.employeeName == 'DEMITIDOS'){
                        array.push(`notActive=true`)
                    }
                } else {
                    if (selectedEmployee?.employeeId != 0){
                        array.push(`numcad=${selectedEmployee?.employeeId}`)
                    }
                }

            }

            filteredList.map(item => {
                array.push(`${item?.value}=true`)
            })
            array = array.join('&')
            const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''

            const file = await api.get(`api/v1/reports/employeeData/numemp/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}/type/${type}/orderBy/${selectedOption?.id}?` + array, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download',  selectedEmployee && selectedEmployee?.employeeId != 0 ? `RelacaoColaborador_${Number(new Date())}` : `RelacaoColaboradores_${Number(new Date())}`)            
            myElement.click()

            if (selectedSave){
                await api.post(`api/v1/filterForReport`, {
                    company,
                    title: selectedTitle,
                    filters: JSON.stringify(filteredList)
                }, {
                    headers: {
                        authorization: token
                    }
                })
                setSelectedSave(!selectedSave)
            }

            getFilters()
            setSelectedFilter('')
            setSelectedTitle('')

            setLoadingBars(false)
        } catch (error){
            setLoadingBars(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }
    }

    async function handleSelected(item){
        const status = !item?.selected
        const newList = listFields.map(field => {
            if (field?.value == item?.value){
                field.selected = status
                return field
            } else {
                return field
            }
        })
        setListFields(structuredClone(newList))
        setHistoricFields(structuredClone(newList))
    }

    async function handleFieldsSelecteds(){
        
        const filterList = listFields.map(field => {
            if (field?.selected){
                field.selected = false
                field.checked = true
                return field
            } else {
                return field
            }
        })

        setListFields(filterList)
        setHistoricFields(filterList)
    }

    async function handleRemoveFieldsSelecteds(){
        
        const filterList = listFields.map(field => {
            if (field?.selected){
                field.selected = false
                field.checked = false
                return field
            } else {
                return field
            }
        })

        setListFields(filterList)
        setHistoricFields(filterList)
    }

    async function clearList(){
        const filterList = listFields.map(field => {
            field.selected = false
            field.checked = false
            return field
        })

        setSelectedFilter('')
        setListFields(filterList)
        setHistoricFields(filterList)
    }

    async function handleSelectedAll(){
        const status = !statusAll

        const filterList = listFields.map(field => {
            if (field?.checked){
                return field
            } else {
                if (field?.show){
                    field.selected = status
                    return field
                } else {
                    return field
                }
            }
        })
        setListFields(structuredClone(filterList))
        setHistoricFields(structuredClone(filterList))
    }

    async function filterListFields(filter){
        const fieldsList = selectedFilter?.title ? structuredClone(listFields) : structuredClone(historicFields)
        if (!filter){
            setListFields(fieldsList.map(field => {
                if (selectedFilter?.title){
                    const verifyParam = selectedFilter?.filters?.find(fil => fil?.value == field?.value)
                    if (verifyParam){
                        return verifyParam
                    } else {
                        return {
                            ...field,
                            selected: false,
                            show: true
                        }
                    }
                } else {
                    return {
                        ...field,
                        show: true
                    }
                }
            }))
        } else {
            const arrayItems = []
            fieldsList.map(field => {
                if (removerAcentos(field?.name.toLowerCase()).includes(removerAcentos(filter?.toLowerCase()))){
                    if (arrayItems.includes(field)){
                        return
                    } else {
                        field.show = true
                        arrayItems.push(field)
                    }
                } else {
                    if (arrayItems.includes(field)){
                        return
                    } else {
                        field.show = false
                        arrayItems.push(field)
                    }
                }
            })
            setListFields(arrayItems)
        }
    }

    const options = [
        {
            name: 'Nome',
            id: 'nomfun'
        },
        {
            name: 'Matrícula',
            id: 'numcad'
        }
    ]

    async function handleFilter(filter){
        setListFields(listForFiltersParams?.map(item => {
            const verifyParam = filter?.filters?.find(fil => fil?.value == item?.value)
            if (verifyParam){
                return verifyParam
            } else {
                return {
                    ...item,
                    selected: false
                }
            }
        }))
    }

    async function handleRemove(){
        setLoading(true)
        try {
            await api.delete(`api/v1/filterForReport/company/${company}/title/${selectedFilter?.title}`, {
                headers: {
                    Authorization: token
                }
            })
            setListFields(listForFiltersParams)
            getFilters()
            setSelectedFilter('')
            setSelectedTitle('')
            setSelectedSave(false)
            setLoading(false)
            setShowAprovalModal(false)
            setShowNotificationModal(true)
            return setNotificationModalText('Filtro removido com sucesso')
        } catch(error){
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao excluir filtro')
        }
    }

    return (
        <>
            <Modal>
                <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full`}>
                    <div className='flex justify-between pl-3 py-5 items-center'>
                        <p className='flex flex-row font-semibold items-center text-sm'>
                            <p className='mr-7'>Deseja realmente excluir esse filtro?</p>
                        </p>
                        <FiX className='text-gray-500 text-lg mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'} />
                    </div>
                    <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                        <Button height={6} width={24} background={'bg-green-800'} type={'button'} onClick={() => { setShow(false); handleRemove() }}>Confirmar</Button>
                        <Button height={6} width={24} background={'bg-red-800'} onClick={() => setShow(false)}>Cancelar</Button>
                    </div>
                </div>
            </Modal>
            {
                loading ?
                <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[85vh] items-center justify-center">
                    <Loader />
                </div>
                :
                <div className={`${loadingBars ? 'cursor-wait' : ''} flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-10 pb-20 md:pb-20 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500`}>
                    <p className='text-lg md:text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-5'>Relação de colaboradores</p>
                    <div className='mt-5 flex flex-col md:flex-row gap-3'>
                        <LabelInput>
                            <InputAutoComplete
                                data={[{employeeId: 0, employeeName: 'TODOS'},{employeeId: 'SOMENTE', employeeName: 'ATIVOS'},{employeeId: 'SOMENTE', employeeName: 'DEMITIDOS'}, ...employeesList]}
                                id={'employee'}
                                preSelectedValue={selectedEmployee ? selectedEmployee?.employeeName : ''}
                                value={selectedEmployee ? selectedEmployee?.employeeName : ''}
                                optionList={["employeeId", "employeeName"]}
                                selectedLabel={'employeeName'}
                                onChange={(e) => {
                                    setSelectedEmployee(e)
                                }}
                                width={80}
                                placeHolder={'Colaborador'}
                            />
                        </LabelInput>
                        <LabelInput>
                            <InputAutoComplete
                                data={options}
                                id={'options'}
                                preSelectedValue={selectedOption ? selectedOption?.name : ''}
                                value={selectedOption ? selectedOption?.name : ''}
                                optionList={["name"]}
                                selectedLabel={'name'}
                                onChange={(e) => {
                                    setSelectedOption(e)
                                }}
                                width={60}
                                placeHolder={'Ordenar por'}
                            />
                        </LabelInput>
                        <LabelInput>
                            <Multiselect
                            items={[{label: "0 - Todas", value: 'Todas'}, ...branchList]}
                                mult                                                 
                                onChange={e => {
                                    if (e[0]?.value == "Todas"){
                                        setSelectedBranchs(branchList)
                                    } else {
                                        setSelectedBranchs(e)
                                    }
                                }}
                                selectedLabel={'label'}
                                value={selectedBranchs ? selectedBranchs : []}
                                id={`selectedBranchs`}
                                height={8}
                                placeholder={'Filiais'}
                            />                                
                        </LabelInput>
                    </div>
                    <div className='flex flex-col md:flex-row gap-3 mt-5'>
                        <LabelInput>
                            <InputAutoComplete
                                data={filtersParamsList}
                                id={'filterParams'}
                                preSelectedValue={selectedFilter ? selectedFilter?.title : ''}
                                value={selectedFilter ? selectedFilter?.title : ''}
                                optionList={["title"]}
                                selectedLabel={'title'}
                                onChange={(e) => {
                                    setSelectedFilter(e)
                                    if (typeof(e) == 'object'){
                                        handleFilter(e)
                                    }
                                }}
                                width={80}
                                placeHolder={'Filtro'}
                            />
                        </LabelInput>
                        {
                            selectedFilter?.title ?
                            <div className='flex items-center justify-center'>
                                <Tippy
                                    content={'Excluir Filtro'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={80}>
                                    <div>
                                        <FaTrash 
                                            className='text-red-700 cursor-pointer'
                                            onClick={() => {
                                                setShow(true)
                                            }}
                                        />
                                    </div>
                                </Tippy>
                            </div>
                            : 
                            <></>
                        }
                        <div className='flex flex-row items-center justify-center gap-1'>
                            <input className='cursor-pointer' type='checkbox'onChange={(e) => setSelectedSave(!selectedSave)} checked={selectedSave}/>
                            <p>Salvar filtro</p>              
                        </div>
                        {
                            selectedSave &&
                            <LabelInput>
                                <Input
                                    id={'title'}
                                    value={selectedTitle ? selectedTitle : ''}
                                    onChange={(e) => setSelectedTitle(e?.target?.value)}
                                    width={80}
                                    placeholder={'Título'}
                                />
                            </LabelInput>
                        }
                    </div>
                    <div className='flex flex-col md:flex-row justify-center items-center md:items-start h-fit gap-10'>
                        <div className='flex flex-col mt-12 sm:mt-12'>
                            <p className='flex justify-center items-center text-lg text-primaryDefaultLight mb-2'>
                                Selecione os campos
                                <Tippy
                                    content={'Selecionar todos'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    className='ml-5'
                                    delay={80}>
                                    <div>
                                        <FaCheckSquare onClick={() => {setStatusAll(!statusAll); handleSelectedAll()}} className='ml-5 items-center text-base cursor-pointer'/>
                                    </div>
                                </Tippy>
                            </p>
                            <div className='flex flex-col w-80 h-44 lg:h-80 xl:h-96 2xl:h-[25rem] shadow-inner rounded-lg'>
                                <div className='w-full h-full overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3'>
                                    <input 
                                        className={`flex flex-col w-full h-7 border mt-3 focus:border-primaryDefaultLight duration-150 text-center items-center justify-center text-primaryDefaultLight rounded-md`}
                                        placeholder='Buscar campo'
                                        onChange={e => {
                                            filterListFields(e.target.value)
                                        }}
                                    />    
                                    {
                                        listFields.map((field, index) => {
                                            if (!field?.checked && field.show){
                                                return (
                                                    <div 
                                                        onClick={() => {handleSelected(field)}} 
                                                        className={`flex flex-col w-full h-7 border mt-3 ${field.selected ? 'border border-primaryDefaultLight' : ''} hover:border-primaryDefaultLight duration-150 hover:cursor-pointer text-center items-center justify-center text-primaryDefaultLight rounded-md`}>
                                                        <p>{field?.name}</p>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='hidden sm:flex flex-col justify-center items-center gap-7 md:gap-14 h-full'>
                            <Tippy
                                content={'Selecionar'}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={80}>
                                <div>
                                    <FaArrowRight onClick={() => handleFieldsSelecteds()} className='text-xl cursor-pointer'/>                                                 
                                </div>
                            </Tippy>
                            <Tippy
                                content={'Remover'}
                                arrow={true}
                                animation='shift-away'
                                placement='top'
                                delay={80}>
                                <div>
                                    <FaArrowLeft onClick={() => handleRemoveFieldsSelecteds()} className='text-xl cursor-pointer'/>                                                 
                                </div>
                            </Tippy>
                        </div>

                        <div className='flex sm:hidden flex-row justify-center items-center gap-7 md:gap-14 h-full'>                            
                            <FaArrowDown onClick={() => handleFieldsSelecteds()} className='text-xl cursor-pointer'/> 
                            <FaArrowUp onClick={() => handleRemoveFieldsSelecteds()} className='text-xl cursor-pointer'/>
                        </div>

                        <div className='flex flex-col mt-0 sm:mt-12'>
                            <p className='flex justify-center items-center text-lg text-primaryDefaultLight mb-2'>
                                Campos selecionados 
                                <Tippy
                                    content={'Limpar campos'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    className='ml-5'
                                    delay={80}>
                                    <div>
                                        <FaEraser onClick={() => clearList()} className='ml-5 items-center text-base cursor-pointer'/>
                                    </div>
                                </Tippy>
                            </p>
                            <div className='flex flex-col w-80 h-44 lg:h-80 xl:h-96 2xl:h-[25rem] shadow-inner rounded-lg'>
                                <div className='w-full h-full overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 p-3'>
                                    {
                                        listFields.map((field, index) => {
                                            if (field?.checked){
                                                return (
                                                    <div 
                                                        onClick={() => {handleSelected(field)}}
                                                        className={`flex flex-col w-full h-7 border mt-3 ${field.selected ? 'border border-primaryDefaultLight' : ''} hover:border-primaryDefaultLight duration-150 hover:cursor-pointer text-center items-center justify-center text-primaryDefaultLight rounded-md`}>
                                                        <p>{field?.name}</p>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row mt-16 md:mt-6 2xl:mt-10 justify-center gap-7'>
                        <div className='bg-primaryDefaultLight text-white w-60 cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                            onClick={() => {
                                selectedXLSX = false
                                selectedPDF = true
                                downloadFile()
                            }}
                        >
                            <FaPrint className='text-sm'/> PDF
                        </div>
                        <div className='bg-primaryDefaultLight text-white w-60 cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                            onClick={() => {
                                selectedPDF = false
                                selectedXLSX = true
                                downloadFile()}}
                            >
                            <FaFileExcel className='text-sm'/> XLSX
                        </div>
                    </div>
                </div>
            }
        </>
    )
}