export function capitalize(text){

    const splittedText = text.toLowerCase().split(' ')

    const formatedText = splittedText.map((splitted) => {
        return splitted[0]?.toUpperCase() + splitted?.slice(1);
    })

    return formatedText.join(' ')

}