/**
 * Desenvolvedor: Tiago Murilo Ochôa da Luz
 * Descrição: Area do sistema onde se é feito o logon..
 */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth'
import { useCompanyBranch } from '../../hooks/useCompanyBranch';

import logoImg from '../../images/logo_connect.png';
import logoPrime from '../../images/logo_prime.png';
import logoSoftjoin from '../../images/MailouDevLogo.png';
import './logon.css'
import { LabelInput } from '../../components/label/label.input';
import { Input } from '../../components/input/input'
import { Button } from '../../components/buttons/button.default';
import Loader from '../../components/Loader/loader';

export default function Logon() {

    const { setLogged, signIn } = useAuth();
    const {
        setCompany,
        company,
        setBranch,
        setCompanyName,
        setBranchCnpj
    } = useCompanyBranch()
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [statusLogon, setStatusLogon] = useState(false);
    const params = useParams()

    useEffect(() => {
        if (params.uuid) {
            setCompany(params.company)
            setBranch(params.branch)
            setCompanyName(params.companyName)
            setBranchCnpj(params.branchCnpj)
        } else {
            setCompany(0)
            setBranch(0)
            setCompanyName('')
            setBranchCnpj('')
        }
    }, [])

    async function handleLogin() {
        setLoading(true)
        return await signIn(username, password)
    }

    async function startLogin(e) {
        e.preventDefault()
        setLoading(true);
        setStatusLogon(true)

        setTimeout(async () => {
            setLoading(false)

            const status = handleLogin();
            if (await status !== true) {
                setStatusLogon(false);
            }
        }, 1000)
    }


    return (
        <>
            <div className='hidden lg:flex'>
                <div id="containerLogon" >
                    {statusLogon && !loading ?
                        (
                            <div className='loginLoaderShow'>
                                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        ) :
                        (
                            <div className={loading ? 'inLoginEvent' : statusLogon && !loading ? 'inProcessLoading' : 'logoContainer'}>
                                <img src={logoImg} alt="" width='200' />
                                <a>Portal RH - We4u</a>
                            </div>
                        )
                    }

                    <div className='loginSeparator'></div>
                    <div className='inputContainer'>
                        <form action="post" onSubmit={startLogin}>
                            <input
                                type="login"
                                placeholder="Login"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder="Senha"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button type='submit'> Acessar </button>
                        </form>
                    </div>
                    <div className='absolute bottom-0 w-full items-center justify-center flex flex-row gap-8 h-12'>
                        <a style={{ display: 'flex', }}>Desenvolvido por
                            <a href={'https://dev.mailou.com.br'} target="_blank"><img className='ml-2 cursor-pointer' width={100} src={logoSoftjoin}></img></a>
                        </a>
                    </div>
                </div>
            </div>
            <div className='flex h-full lg:hidden flex-col gap-3 w-full p-2 items-center justify-center'>
                {
                    loading ?
                        <Loader />
                        :
                        <div className='overflow-hidden w-full flex-col flex items-center justify-center gap-6'>
                            <img className='animate-[wiggle_1s_ease-in-out]' src={logoImg} width={200}></img>
                            <form className='animate-[wiggleLeft_1s_ease-in-out] w-full items-center justify-center flex flex-col gap-4'>
                                <LabelInput text={'Usuário'}>
                                    <Input onChange={(e) => setUsername(e.target.value)} value={username} />
                                </LabelInput>
                                <LabelInput text={'Senha'}>
                                    <Input type='password' onChange={(e) => setPassword(e.target.value)} value={password} />
                                </LabelInput>
                                <Button shadow={true} onClick={() => handleLogin()}>Login</Button>
                            </form>
                            <div className='w-full absolute bottom-0 bg-zinc-100 h-10 flex flex-row items-center justify-center gap-2'>
                                <a className='text-xt'>Desenvolvido por</a>
                                <a href={'https://dev.mailou.com.br'} target="_blank"><img src={logoSoftjoin} width={80}/></a>
                            </div>
                        </div>
                }
            </div>
        </>
    );

}