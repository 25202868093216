import React from 'react'

export function Input({ children, height, name, width, placeholder, onChange, value, autoFocus, id, type, inactive, disabled, charLimit, onBlur, style, onKeyUp }) {

    const widthPixel = width > 0 ? `w-[${width}px]` : 'w-[350px] sm:w-96'
    const widthDefault = width > 0 ? `w-${width}` : 'w-[350px] sm:w-96'

    const heightPixel = height > 0 ? `h-[${height}px]` : 'h-10 sm:h-8'
    const heightDefault = height > 0 ? `h-${height}` : 'h-10 sm:h-8'

    return (
        <input
            style={style && style}
            min={0}
            type={type}
            max={type === "date" ? "9999-12-31" : "false"}
            disabled={inactive || disabled ? true : false}
            id={id}
            name={name}
            onBlur={(e)=> onBlur !== undefined && onBlur()}
            value={value !== undefined && value !== false && value !== null ? value : ''}
            autoFocus={autoFocus ? true : false}
            onChange={(e) => e.target.value.length > charLimit ? '' : onChange(e)}
            placeholder={placeholder}
            onKeyUp={onKeyUp}
            className={`${width > 96 ? widthPixel : widthDefault} ${height > 96 ? heightPixel : heightDefault} 
        pl-2 border border-gray-300 border-opacity-75 
        outline-none
        rounded-lg
        
        text-sm sm:text-sm
        focus:bg-zinc-100
        transition-all duration-200
        text-inputPlaceholderLight
        dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
        ${inactive || disabled? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}
        dark:focus:shadow-borderShadow'`}></input>
    )
}