import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import api from "../../services/api";
import Loader from "../../components/Loader/loader";
import { LabelInput } from "../../components/label/label.input";
import { Input } from "../../components/input/input";
import { useMenu } from "../../hooks/useMenu";
import Logo from '../../images/logo_connect.png'
import { FaArrowLeft } from "react-icons/fa";
import { Button } from "../../components/buttons/button.default";
import { useModal } from "../../hooks/useModal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { Quillmodules } from "../../components/richText/quill.modules";

export function ReleaseCreate(){

    const { id } = useParams()
    const navigate = useNavigate()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const token = localStorage.getItem("@auth:token");
    const { setMenuTitle } = useMenu();
    setMenuTitle("Atualizações");
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState('')
    const [details, setDetails] = useState('')

    async function handleSubmit(){
        setLoading(true)
        try {
            const formData = new FormData()
            if (data.file){
                formData.append('file', data?.file)
            }
            formData.append('version', data?.version)
            formData.append('details', details)
            formData.append('observation', data?.observation)
            formData.append('has_seemore', false)

            await api.post('api/v1/release', formData, {
                headers: {
                    authorization: token,
                    'content-type': 'multipart/form-data',
                }
            })
            setShowNotificationModal(true)
            setNotificationModalText('Cadastrado realizado com sucesso')
            return navigate('/release')

        } catch(error){
            if (error?.response?.data?.message[0]){
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText(error?.response?.data?.message[0])
            } else {
                setLoading(false)
                setShowAprovalModal(true)
                setShowNotificationModal(true)
                return setNotificationModalText('Erro ao cadastrar')
            }
        }
    }

    function handleChangeValueText(content, delta, source, editor) {
        setDetails(content)
    }
    
    return (
        <div className={loading ? 'flex h-[70%] items-center justify-center' : 'bg-gray-200 h-full'}>
            {
                loading ?
                <Loader/>
                :
                <div className="relative animate-[wiggleLeft_1s_ease-in-out] h-full flex flex-col justify-center items-center">
                    <div className="absolute top-12 left-20 cursor-pointer duration-100" onClick={() => navigate(-1)}>
                        <p className="flex flex-row gap-1 items-center justify-center">
                            <FaArrowLeft className="text-xs text-primaryDefaultLight"/> 
                            Voltar
                        </p>
                    </div>
                    <div className="hidden sm:flex flex-col justify-start items-start p-10 w-[80rem] h-full gap-5 bg-white my-12 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400">
                        <div className="flex flex-row items-center justify-start">
                            <LabelInput>
                                <Input
                                    type={'text'}
                                    placeholder={'Versão'}
                                    onChange={(e) => setData(prev => ({...prev, version: e?.target?.value}))}
                                    value={data?.version ?? ''}                         
                                />
                            </LabelInput>
                            <div className='w-96 pl-2 border border-gray-300 border-opacity-75 text-sm sm:text-sm rounded-lg'>
                                <input 
                                    type="file" 
                                    className="text-black"
                                    onChange={(e) => setData(prev => ({...prev, file: e?.target?.files[0]}))}
                                />
                            </div>
                        </div>
                        <div className="relative flex flex-col h-full w-full items-center justify-between gap-6">
                            <div className="h-full w-full ">
                                <ReactQuill 
                                    modules={Quillmodules} 
                                    className='max-h-[35rem] h-full w-full'                                 
                                    readOnly={false} 
                                    theme={"snow"} 
                                    value={details ?? ''}      
                                    onChange={handleChangeValueText}
                                />
                            </div>
                            <textarea 
                                className="border border-gray-300 w-full rounded-lg h-28 p-2 resize-none"
                                placeholder="Observação"
                                value={data?.observation ?? ''}
                                onChange={(e) => setData(prev => ({...prev, observation: e?.target?.value}))}
                            />
                            <div className="flex items-center justify-center w-full">
                                <Button onClick={() => handleSubmit()}>Cadastrar</Button>
                            </div>
                        </div>
                    </div>
                </div>
            }   
        </div>
    )

}