import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useMenu } from '../../hooks/useMenu';
import { useCompanyBranch } from "../../hooks/useCompanyBranch";
import { SplineFinance } from '../../components/dashboard/dashFinance';
import api from "../../services/api";
import { useModal } from "../../hooks/useModal";
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';

export function DashboardFinancial({ selectedBranchs, startFilter, changeStartFilter }) {

    const navigate = useNavigate()
    const { company, branch } = useCompanyBranch();
    const { setShow, show, showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const [loadingValuesGrossAndNet, setLoadingValuesGrossAndNet] = useState(false)
    const { setMenuTitle } = useMenu()
    setMenuTitle('Dashboard')
    const token = localStorage.getItem('@auth:token')
    const [dataValuesOnSheet, setDataValuesOnSheet] = useState('')

    useEffect(() => {
        if (startFilter){
            getData()
            changeStartFilter(false)
        }        
    }, [startFilter])

    useEffect(() => {
        if(company === 0 || branch === 0){
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }

        getData()

    }, [])

    async function getData() {
        setLoadingValuesGrossAndNet(true)
        
        // const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''

        try {
            const getGrossValue = await api.get(`api/v1/dashboard/grossAndNetValue/company/${company}`, {
                headers: {
                    authorization: token
                }
            })
            setDataValuesOnSheet(getGrossValue?.data?.data)
            setLoadingValuesGrossAndNet(false)

        } catch (error) {
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    return (
        <div className="w-[96%] mt-4 ml-14 h-full gap-3 hidden lg:flex lg:flex-col xl:flex-col 2xl:flex-col lg:overflow-x-hidden xl:overflow-auto 2xl:overflow-x-hidden overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
            <div className="flex flex-col w-full gap-3">
                <div className="h-full gap-3 w-full flex">
                    {
                        loadingValuesGrossAndNet ?
                            <div className="py-6 w-full xl:h-[28rem] shadow-borderShadow rounded-lg bg-white flex justify-center items-center">
                                <div className="h-full w-[95%] flex bg-slate-200 animate-pulse rounded-md justify-center items-center"></div>
                            </div>
                            :
                            <div className="py-6 w-full h-full shadow-borderShadow rounded-lg bg-white">
                                <div className="h-9 w-full">
                                    <p className="text-center text-base sm:text-sm xl:text-lg font-semibold text-titleBlackTextLight pb-2">Valores da Folha</p>
                                    <p></p>
                                </div>
                                <SplineFinance
                                    id={'dashboardDesktop2'}
                                    data={dataValuesOnSheet}
                                />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}