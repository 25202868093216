import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { PictureEdit } from './customer.pictureEdit'
import DefaultLoader from '../../components/Loader/loader'
import { RiProfileLine } from "react-icons/ri";
import { FaCamera, FaInfoCircle } from "react-icons/fa";
import { permittedFiles } from "../../utils/filesValidadtor/permittedFiles";
import {LabelInput} from '../../components/label/label.input'
import {InputAutoComplete} from '../../components/input/input.autocomplete'
import { Button } from "../../components/buttons/button.default";

export function CompanyConfig(){

    const token = localStorage.getItem('@auth:token');
    const { company, branch, setCompanyParams } = useCompanyBranch();
    const { setShowNotificationModal, setNotificationModalText, setShowAprovalModal } = useModal()
    const { user } = useAuth()
    const [loading, setLoading] = useState(true);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Perfil da Empresa')
    const navigate = useNavigate()

    const [companyPicture, setCompanyPicture] = useState('')
    const [imageSrc, setImageSrc] = useState(null)
    const [picPreview, setPicturePreview] = useState('')
    const [companyData, setCompanyData] = useState('')

    const [selectedNatureExpense, setSelectedNatureExpense] = useState('')
    const [selectedAddHistory, setSelectedAddHistory] = useState('')
    const [selectedSumEvents, setSelectedSumEvents] = useState('')
    const [selectedAnotations, setSelectedAnotations] = useState('')
    const [selectedUniqueStructure, setSelectedUniqueStructure] = useState('')
    const [selectedNumberTag, setSelectedNumberTag] = useState('')

    async function getData(){
        try {
            try {
                const getPhotoAPI = await api.get(`api/v1/companies/photo/company/${company}/branch/${branch}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `${token}`
                    }
                })
                if (getPhotoAPI?.data){
                    console.log('achou nessa porra?')
                    const fileObj = URL.createObjectURL(getPhotoAPI?.data)
                    if (fileObj) {
                        console.log('faz o objeto ai')
                        setCompanyPicture(fileObj)
                    }
                }
            } catch (error) {   
                console.log('> Foto de perfil não encontrada.')
            }

            const getCompanyAPI = await api.get(`api/v1/companies/companyData/company/${company}?branch=${branch}`, {
                headers: {
                    Authorization: token
                }
            })
            setCompanyData(getCompanyAPI.data.data[0])

            const getConfigCompanyAPI = await api.get(`api/v1/companies/getCompanyConfig/company/${company}?branch=${branch}`, {
                headers: {
                    Authorization: token
                }
            })
            
            setLoading(false)
            setSelectedNatureExpense(getConfigCompanyAPI?.data?.data[0]?.useNatureExpenses)
            setSelectedAddHistory(getConfigCompanyAPI?.data?.data[0]?.addHistory ? getConfigCompanyAPI?.data?.data[0]?.addHistory : 'N')
            setSelectedSumEvents(getConfigCompanyAPI?.data?.data[0]?.sumEvents ? getConfigCompanyAPI?.data?.data[0]?.sumEvents : 'N')
            setSelectedAnotations(getConfigCompanyAPI?.data?.data[0]?.useAnotations ? getConfigCompanyAPI?.data?.data[0]?.useAnotations : 'N')
            setSelectedUniqueStructure(getConfigCompanyAPI?.data?.data[0]?.uniqueStructure ? getConfigCompanyAPI?.data?.data[0]?.uniqueStructure : 'N')
            setSelectedNumberTag(getConfigCompanyAPI?.data?.data[0]?.numberTag ? getConfigCompanyAPI?.data?.data[0]?.numberTag : 'N')

        } catch (error){
            setLoading(false)
            if (error?.response?.data?.message != undefined) {
                return alert(error?.response?.data?.message)
            }
            return alert('Erro ao realizar busca de dados.')
        }
    }

    useEffect(() => {
        if (company === 0) {
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }

        getData()

    }, [imageSrc])

    function handlePicture() {
        const input = document.getElementById('companyPicture')
        input.click()
    }

    async function showPreview(e) {
        const validImage = permittedFiles(e?.target?.files[0], 'image')
        if (validImage?.error) {
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro ao renderizar imagem')
        }
        try{
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file)
            setImageSrc(imageDataUrl);
            setPicturePreview(URL.createObjectURL(file))
        }catch(error){
            setLoading(false)
            console.log('Foto da empresa não encontrada.')
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const options = [
        {
            name: "Sim",
            value: 'S'
        },
        {
            name: "Não",
            value: 'N'
        }
    ]

    async function handleCreateParam(){
        setLoading(true)
        try {
            await api.post(`api/v1/companies/createParam/company/${company}/branch/${branch}`, {
                useNatureExpenses: selectedNatureExpense ? selectedNatureExpense : "N",
                addHistory: selectedAddHistory ? selectedAddHistory : "N",
                sumEvents: selectedSumEvents ? selectedSumEvents : "N",
                useAnotations: selectedAnotations ? selectedAnotations : "N",
                uniqueStructure: selectedUniqueStructure ? selectedUniqueStructure : "N",
                numberTag: selectedNumberTag ? selectedNumberTag : "N",
            }, {
                headers: {
                    Authorization: token
                }
            })
            setLoading(false)
            // Criado para quando editar para Sim ou Não as anotações, atualizar a empresa que está selecionada
            setCompanyParams(prev => ({...prev, useAnotations: selectedAnotations}))
            setShowNotificationModal(true)
            setNotificationModalText('Sucesso ao criar parâmetro')
            return getData()
        } catch(error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro na criação de parâmetro')
        }
    }

    return (
        <div className="conteudo px-2">
            {
                loading ? 
                <div className="w-full flex flex-row justify-center"> 
                    <DefaultLoader/>
                </div>
                :
                imageSrc ?
                    <PictureEdit onChange={(e) => setImageSrc(e)} imgSrc={imageSrc} picPreview={picPreview} />
                :
                <div className='flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14 2xl:pt-24'>
                    {
                        [1,2].includes(user?.type) && 
                        <div className="absolute right-4 top-16">
                            <Button width={60} height={6} onClick={() => {navigate('/eventsConfig')}}>Parametrização de Eventos</Button>
                        </div>
                    }
                    <div onClick={() => handlePicture()} className='cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark flex flex-col items-center justify-center border dark:border-primaryBorderDark border-zinc-300 w-52 2xl:w-60 h-24 2xl:h-40'>
                        {
                            companyPicture?.length > 0 ?
                                <div id={`customerPhoto`} className='p-4 cursor-pointer sm:h-[96%] sm:w-[96%] overflow-hidden text-titleBlackTextLight dark:text-titleGrayTextDark text-7xl flex flex-col items-center justify-center w-full h-hull '>
                                    <img src={`${companyPicture}`}></img>
                                </div>
                                :
                                <Tippy content={'Alterar logotipo'}
                                    animation="shift-away"
                                    arrow={true}
                                    placement="bottom"
                                    delay={100}>
                                    <div className='cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark text-2xl flex flex-col items-center justify-center w-full h-full '>
                                        <RiProfileLine />
                                    </div>
                                </Tippy>
                        }
                    </div>
                    <div className='flex flex-col items-start justify-start'>
                        <div onClick={() => handlePicture()} className='flex flex-row mt-3 w-full cursor-pointer gap-2 hover:brightness-150 transition-all duration-300 hover:underline'>
                            <FaCamera className='text-lg dark:text-titleGrayTextDark text-titleBlackTextLight' />
                            <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark '>Editar foto da empresa</a>
                            <input accept="image/png,image/jpeg,image/jpg,image/gif,image/jpeg" id='companyPicture' type='file' onChange={(e) => showPreview(e)} hidden={true}></input>
                        </div>
                    </div>
                    <div className="p-2 pb-8 md:mt-10 xl:mt-10 2xl:mt-16 flex flex-wrap sm:flex-row items-center justify-center gap-2 w-[90%] h-96 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                        <div className="flex flex-col">
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Nome da Empresa</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.company ? companyData?.company : ' - '}</div>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Telefone</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.phone ? companyData?.phone : ' - '}</div>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Estado</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.desest ? companyData?.desest : ' - '}</div>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Bairro</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.nombai ? companyData?.nombai : ' - '}</div>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Número</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.endnum ? companyData?.endnum : ' - '}</div>
                        </div>
                        <div className="flex flex-col">
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Filial</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.branch ? companyData?.branch : ' - '}</div>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">CEP</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.codcep ? companyData?.codcep : ' - '}</div>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Cidade</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.nomcid ? companyData?.nomcid : ' - '}</div>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Endereço</label>
                            <div className="shadow-md w-60 sm:w-96 p-1">{companyData?.endfil ? companyData?.endfil : ' - '}</div>                            
                        </div>
                        <div className="flex flex-col">
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Natureza Despesa</label>
                            <LabelInput>
                                <InputAutoComplete
                                    data={options}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    preSelectedValue={selectedNatureExpense ? options.filter(item => item?.value == selectedNatureExpense)[0]?.name : 'Não'}
                                    value={selectedNatureExpense ? options.filter(item => item?.value == selectedNatureExpense)[0]?.name : 'Não'}
                                    onChange={(e) => {
                                        if (typeof(e) == 'object'){
                                            setSelectedNatureExpense(e?.value)
                                        }
                                    }}
                                />
                            </LabelInput>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Histórico Adicional</label>
                            <LabelInput>
                                <InputAutoComplete
                                    data={options}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    preSelectedValue={selectedAddHistory ? options.filter(item => item?.value == selectedAddHistory)[0]?.name : 'Não'}
                                    value={selectedAddHistory ? options.filter(item => item?.value == selectedAddHistory)[0]?.name : 'Não'}
                                    onChange={(e) => {
                                        if (typeof(e) == 'object'){
                                            setSelectedAddHistory(e?.value)
                                        }
                                    }}
                                />
                            </LabelInput>
                            <label className="p-2 flex items-center gap-3 font-semibold mt-1 text-primaryDefaultLight">
                                Somar Eventos 
                                {
                                    <Tippy content={'Caso selecione "SIM", os lançamentos serão adicionados com os eventos do Senior, sendo sua responsabilidade caso duplique.'}
                                    animation="shift-away"
                                    arrow={true}
                                    placement="top"
                                    delay={100}>
                                    <div className="cursor-help">
                                        <FaInfoCircle />
                                    </div>
                                    </Tippy>
                                }
                            </label>                            
                            <LabelInput>
                                <InputAutoComplete
                                    data={options}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    preSelectedValue={selectedSumEvents ? options.filter(item => item?.value == selectedSumEvents)[0]?.name : 'Não'}
                                    value={selectedSumEvents ? options.filter(item => item?.value == selectedSumEvents)[0]?.name : 'Não'}
                                    onChange={(e) => {
                                        if (typeof(e) == 'object'){
                                            setSelectedSumEvents(e?.value)
                                        }
                                    }}
                                />
                            </LabelInput>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Anotações</label>
                            <LabelInput>
                                <InputAutoComplete
                                    data={options}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    preSelectedValue={selectedAnotations ? options.filter(item => item?.value == selectedAnotations)[0]?.name : 'Não'}
                                    value={selectedAnotations ? options.filter(item => item?.value == selectedAnotations)[0]?.name : 'Não'}
                                    onChange={(e) => {
                                        if (typeof(e) == 'object'){
                                            setSelectedAnotations(e?.value)
                                        }
                                    }}
                                    height={72}
                                />
                            </LabelInput>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Estrutura própria para escalas</label>
                            <LabelInput>
                                <InputAutoComplete
                                    data={options}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    preSelectedValue={selectedUniqueStructure ? options.filter(item => item?.value == selectedUniqueStructure)[0]?.name : 'Não'}
                                    value={selectedUniqueStructure ? options.filter(item => item?.value == selectedUniqueStructure)[0]?.name : 'Não'}
                                    onChange={(e) => {
                                        if (typeof(e) == 'object'){
                                            setSelectedUniqueStructure(e?.value)
                                        }
                                    }}
                                    height={72}
                                />
                            </LabelInput>
                            <label className="p-2 font-semibold mt-1 text-primaryDefaultLight">Número Crachá</label>
                            <LabelInput>
                                <InputAutoComplete
                                    data={options}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    preSelectedValue={selectedNumberTag ? options.filter(item => item?.value == selectedNumberTag)[0]?.name : 'Não'}
                                    value={selectedNumberTag ? options.filter(item => item?.value == selectedNumberTag)[0]?.name : 'Não'}
                                    onChange={(e) => {
                                        if (typeof(e) == 'object'){
                                            setSelectedNumberTag(e?.value)
                                        }
                                    }}
                                    height={72}
                                />
                            </LabelInput>
                            <div className="w-full mt-5 flex items-center justify-end lg:pb-10 xl:pb-0">
                                <Button height={6} width={20} onClick={() => handleCreateParam()}>Editar</Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}