import { createContext, useContext, useState } from 'react'

const LoadingContext = createContext({})

export function LoadingProvider({ children }){
    const [ loading, setLoading ] = useState(true)

    return(
        <LoadingContext.Provider value={{ setLoading }}>
            {
                loading ? 
                    <div className='LoadingAfterEnterBgFullPage'>
                        <div className='LoadingAfterEnter'>
                            <div className="lds-dual-ring"></div>
                        </div> 
                    </div> : <></>
            }          
            {children}   
        </LoadingContext.Provider>
    )

}

export function useLoading(){
    return useContext(LoadingContext)
}