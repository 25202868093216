/**
 * Desenvolvedor: Tiago Murilo Ochôa da Luz
 * Descrição: Homepage de boas vindas ao usuário.
 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Folpag from '../../images/logo_connect.png'

import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useAuth } from '../../hooks/useAuth';
import { useMenu } from '../../hooks/useMenu'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { LabelInput } from '../../components/label/label.input'
import Loader from '../../components/Loader/loader';
import { Button } from '../../components/buttons/button.default';

const Homepage = () => {

    const params = useParams()
    const { setCompany, setBranch, setCompanyName, setBranchCnpj } = useCompanyBranch();
    const { user } = useAuth()
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedBranch, setSelectedBranch] = useState(0);
    const [selectedBranchCnpj, setSelectedBranchCnpj] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingBranches, setLoadingBranches] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [branches, setBranches] = useState([]);
    const navigate = useNavigate();
    const { setMenuTitle } = useMenu()
    setMenuTitle('Home')

    useEffect(() => {
        async function getCompanies() {
            try {
                const response = await api.get(`api/v1/companiesConnect`, {
                    headers: {
                        "Authorization": user.token
                    }
                })

                setCompanies(response.data.data?.map(item => {
                    const setCompanyWithBaseAtOrigin = item?.companyOrigin == 'questor' ? item?.id_questor : item?.id_senior
                    return {
                        ...item,
                        label: `${setCompanyWithBaseAtOrigin} - ${item?.name}`,
                        nomemp: item?.name,
                        value: setCompanyWithBaseAtOrigin
                    }
                }));
                setLoading(false)
            } catch (error) {
                alert('Erro ao realizar busca de empresas.')
            }
        }
        getCompanies()

    }, []);

    async function handleBranches(value) {
        if (value?.value !== null) {
            setLoadingBranches(true);
            setSelectedBranchCnpj('')
            setSelectedBranch('')

            if (value?.companyOrigin == 'senior'){
                const resultBranches = await api.get(
                    `api/v1/companies/branch/${value?.value}`, {
                    headers: {
                        authorization: user.token
                    }
                })
                setBranches(resultBranches.data.data)
                setLoadingBranches(false)
                return
            } else {
                const resultBranches = await api.get(
                    `api/v1/companies/branchQuestor/${value?.value}`, {
                    headers: {
                        authorization: user.token
                    }
                })
                setBranches(resultBranches.data.data)
                setLoadingBranches(false)
            }

        }
    }

    async function handleSelectCompanyAndBranch(e) {
        
        if (selectedCompany?.value === 0 || selectedCompany?.value === null) {
            return alert('Selecione uma empresa.');
        } else if (selectedBranch === 0 || selectedBranch === null) {
            return alert('Selecione uma filial.');
        }

        setCompany(selectedCompany?.value);
        setBranch(selectedBranch);
        setCompanyName(selectedCompanyName);
        setBranchCnpj(selectedBranchCnpj);
        
        try {
            await api.patch(`api/v1/companiesConnect/setSession/${selectedCompany?.id}`, {}, {
                headers: {
                    Authorization: user.token
                }
            })
        } catch(error){
            console.log(error)
        }
        
        if (user.type == 4) {
            navigate('/employeeAdmission')
        } else {
            if (![1,2,4].includes(user?.type) && !user?.hasManagerAccess){
                navigate('/history')
            } else {
                navigate('/dashboard');
            }
        }
    }

    return (
        <div>
            <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                <div className='flex flex-col gap-2 justify-center items-center w-full lg:ml-6'>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-4xl flex flex-col items-center">
                        <img src={Folpag} className="w-56 lg:w-96" alt="Logo Folpag" />
                    </div>
                        {
                            loading ?                             
                            <Loader />
                            : 
                            <div className='flex flex-row w-full items-center justify-center mt-14 gap-2'>
                                {
                                    !loading &&
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={companies}
                                            selectedLabel={'label'}
                                            optionList={['label']}
                                            onChange={(e) => {
                                                setSelectedCompany(e)
                                                setSelectedCompanyName(e.label)
                                                if (companies.indexOf(e) > -1) {
                                                    handleBranches(e)
                                                } else {
                                                    setBranches([])
                                                }
                                            }}
                                            value={selectedCompanyName}
                                            onBlur={(e) => handleBranches(e)}
                                            placeHolder='Empresa'
                                        />
                                    </LabelInput>
                                }
                                {
                                    !loadingBranches &&
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={branches}
                                            selectedLabel={'label'}
                                            optionList={['label']}
                                            value={selectedBranchCnpj}
                                            onChange={(e) => {
                                                setSelectedBranch(e.value);
                                                setSelectedBranchCnpj(e.label)
                                            }}
                                            placeHolder='Filial'
                                            width={60}
                                        />
                                    </LabelInput>
                                }
                                <Button onClick={() => handleSelectCompanyAndBranch()}>Confirmar</Button>
                            </div>
                        }

                </div>
            </div>
        </div>
    )

};

export default Homepage;
