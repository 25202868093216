import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export const ProtectedRoute = ({ children, permissions }) => {

    const { user } = useAuth()              

    if(!permissions.includes(user.type)){
        return <Navigate to='/'/>
    }

    return children

}