import React, { useEffect, useState } from 'react'
// import moment from 'moment/locale/pt-br'
import { FaArrowDown, FaArrowUp, FaArrowsAltV, FaQuestion, FaSearch } from 'react-icons/fa'
import { RiArrowUpDownLine } from 'react-icons/ri'
import { Button } from '../buttons/button.default'
//components
import { Tooltip } from '../toolTip/toolTip'
export function TableMobile({ title, data, collumns, onClick, width, functions, buttonFunction, buttonFunctionParam, buttonDescription, icon, cursorPointer,customFunctions }) {

    const [sortBy, setSortBy] = useState('')
    const [sortTo, setSortTo] = useState(true)
    const [tableData, setTableData] = useState(data)
    const [startFilter, setStartFilter] = useState(false)
    const [filteredValue, setFilteredValue] = useState(null)

    useEffect(() => {

        setTableData([...data])
        filterTable()

    }, [data])

    function filterTable() {

        let filterHasSpace = filteredValue?.indexOf(' ')
        let filterToSearch = filteredValue
        let filteToSearArray = []

        if (filterHasSpace > - 1) {
            while (filterHasSpace !== -1) {
                filteToSearArray.push(filterToSearch.slice(0, filterHasSpace + 1))
                filterToSearch = filterToSearch.replace(filterToSearch.slice(0, filterHasSpace + 1), '')
                filterHasSpace = filterToSearch?.indexOf(' ')
                if (filterHasSpace === - 1 && filterToSearch?.length > 0) {
                    filteToSearArray.push(filterToSearch)
                }
            }
        } else if (filterHasSpace === - 1) {
            filteToSearArray.push(filterToSearch)
        }
        if (tableData?.length === 0 || filteToSearArray?.length > 0) {

            const filter = tableData?.filter((dataOfTable, index) => {
                let collumNames = Object.keys(dataOfTable)

                const filterWords = filteToSearArray.filter((wordToCheck) => {

                    let wordFinded = false

                    const collumnFilter = collumNames.filter((wordInCollumn) => {
                        let wordToSearch = wordToCheck.toString()?.toLowerCase().replace(' ', '')
                        if (!wordFinded && tableData[index][wordInCollumn]?.toString()?.toLowerCase().indexOf(wordToSearch) > -1) {
                            wordFinded = true
                            return wordInCollumn
                        }
                    })

                    if (collumnFilter.length > 0) {
                        return wordToCheck
                    }
                })
                if (filterWords?.length > 0 && (filterWords?.length === filteToSearArray?.length)) {
                    return dataOfTable
                }
            })
            if (filter?.length > 0) {
                return filter
            } else {
                return tableData
            }
        }
    }

    useEffect(() => {
        if (startFilter) {
            filterTable()
        }

    }, [startFilter])

    return (
        <div className='flex flex-col w-full gap-2 h-full'>
            {
                data.length > 0 ?
                    <>
                        {
                            data.map((apiData, index) => {
                                return <div key={index} className='w-full flex flex-col h-full pb-4'>
                                    <div className='flex flex-col w-full mt-2 rounded-lg'>
                                        <div className='flex flex-col gap-2 w-full'>
                                            {
                                                collumns.map((titleColumn, indexMobile) => {  
                                                    if(titleColumn === 'attachments'){
                                                        return
                                                    }                                                                    
                                                    return <div key={`${index}-${indexMobile}`} className='bg-zinc-100 flex flex-row w-full'>
                                                        <div className='h-12 flex flex-col w-32 dark:bg-thirdDefaultDark text-bgPrimaryLight font-extrabold justify-center items-start pl-2'>
                                                            <a className='text-sm font-extrabold text-primaryDefaultLight'>{title[indexMobile].name}</a>
                                                        </div>
                                                        <div className='h-12 w-full flex flex-col justify-center overflow-hidden items-start dark:bg-thirdDefaultDark'>
                                                            <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark pl-2'>{apiData[titleColumn]?.length > 70 ? apiData[titleColumn]?.slice(0,70) + '...' : apiData[titleColumn]}</a>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='w-full bg-primaryDefaultLight dark:bg-thirdDefaultDark h-12 flex flex-row items-center justify-start pl-2 gap-10 border-t border-primebg-primaryDefaultLight'>
                                            <div>
                                                <a className='text-sm text-bgPrimaryLight font-extrabold'>Opções</a>
                                            </div>
                                            {
                                                apiData.functions?.length > 0 &&
                                                apiData.functions?.map((func, indexFuncIcon) => {
                                                    return <div key={`${index}-${indexFuncIcon}-`} className='flex flex-col items-center justify-center cursor-pointer h-full w-12'>
                                                        <div className='text-2xl text-bgPrimaryLight'>{func?.func}</div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                      
                    </>

                    :
                    <p>Sem dados</p>
            }
        </div>
    )
}