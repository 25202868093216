import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useMenu } from '../../hooks/useMenu';
import { useCompanyBranch } from "../../hooks/useCompanyBranch";
import { LabelInput } from '../../components/label/label.input';
import api from "../../services/api";
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import Multiselect from '../../components/selects/mult.select';
import { Button } from "../../components/buttons/button.default";
import { DashboardRH } from "./dashboardRH";
import { DashboardFinancial } from "./dashboardFinancial"

export function Dashboard() {

    const navigate = useNavigate()
    const { company, branch } = useCompanyBranch()
    const { user } = useAuth()
    const [loadingMulti, setLoadingMulti] = useState(true)
    const { setMenuTitle } = useMenu()
    setMenuTitle('Dashboard')
    const token = localStorage.getItem('@auth:token')
    const [selectedModel, setSelectedModel] = useState(1)
    const [branchList, setBranchList] = useState([])
    const [selectedBranchs, setSelectedBranchs] = useState([])
    const [startFilter, setStartFilter] = useState(false)

    useEffect(() => {
        if(company === 0 || branch === 0){
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }
        
        async function getBranchs() {
            setLoadingMulti(true)
            try {

                const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
                    headers: {
                        authorization: token
                    }
                })
                setBranchList(resultBranches?.data?.data?.filter(emp => emp?.value != branch))
                setLoadingMulti(false)
                
            } catch (error){
                setLoading(false)
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        getBranchs()

    }, [selectedModel])

    return (
        <>
            <div className={`duration-500 w-full h-full flex flex-col justify-start py-12 bg-gray-200 gap-4`}>
                <div className="flex flex-col overflow-scroll h-full w-full gap-4">
                    <div className="flex flex-col sm:flex-row gap-3 w-[95%] sm:w-[96%] ml-0 sm:ml-14 items-center h-8 bg-transparent justify-between">
                        <div className="flex flex-row gap-3 items-center justify-center w-fit">
                            <div className="w-32 shadow-borderShadow rounded-lg bg-white flex">
                                <p
                                    onClick={() => setSelectedModel(1)}
                                    className={`${selectedModel == 1 ? 'bg-primaryDefaultLight text-secondaryDefaultLight' : 'bg-[#ececec]'} 
                                font-extrabold hover:bg-gray-300 hover:text-primaryDefaultLight shadow-md 
                                shadow-gray-400 text-primaryDefaultLight h-7 cursor-pointer rounded-md w-full flex flex-col 
                                items-center duration-300 justify-center`}>RH</p>
                            </div>
                            <div className="w-32 shadow-borderShadow rounded-lg bg-white flex">
                                <p
                                    onClick={() => setSelectedModel(2)}
                                    className={`${selectedModel == 2 ? 'bg-primaryDefaultLight text-secondaryDefaultLight' : 'bg-[#ececec]'} 
                                font-extrabold hover:bg-gray-300 hover:text-primaryDefaultLight shadow-md 
                                shadow-gray-400 text-primaryDefaultLight h-7 cursor-pointer w-full rounded-md flex flex-col items-center duration-300 
                                justify-center`}>Financeiro</p>
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row">
                            {
                                loadingMulti ?
                                <></>
                                :
                                <div className="flex flex-col sm:flex-row h-full items-center justify-center sm:gap-2 ml-5">                                    
                                    <LabelInput>
                                        <Multiselect
                                            items={[{label: "0 - Todas", value: 'Todas'}, ...branchList]}
                                            mult                                                 
                                            onChange={e => {
                                                setSelectedBranchs(e)
                                            }}
                                            selectedLabel={'label'}
                                            value={selectedBranchs ? selectedBranchs : []}
                                            id={`selectedBranchs`}
                                            height={8}
                                            placeholder={'Filiais'}
                                        />
                                    </LabelInput>
                                    <div className="mt-1 mr-4">
                                        <Button height={7} width={20} onClick={() => setStartFilter(true)}>Filtrar</Button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        selectedModel == 1 ?
                        <DashboardRH startFilter={startFilter} selectedBranchs={selectedBranchs} changeStartFilter={e => setStartFilter(e)}/>
                        : selectedModel == 2 ?
                        <DashboardFinancial startFilter={startFilter} selectedBranchs={selectedBranchs} changeStartFilter={e => setStartFilter(e)}/>
                        : <></>
                    }
                </div>
            </div >
        </>
    )

}