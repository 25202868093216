import readXlsxFile from 'read-excel-file'

export default{
    async readPlanToImport(file, eventsSenior, personsSenior){

        const sendsArray = []
        const eventsSeniorArray = []
        const personsSeniorArray = []
        const f = file.files[0];
        
        try {
            const rows = await readXlsxFile(f)
            // eslint-disable-next-line
            eventsSenior.map(event => { 
                eventsSeniorArray.push(event.average) 
            })
            // eslint-disable-next-line
            personsSenior.map(person => {
                personsSeniorArray.push(person.numcad)// eslint-disable-next-line
            })

            const events = rows[0].map(row => {
                return row.split(' - ')[0]
            })
            
            events.splice(0,1)
            rows.splice(0, 1)
            // eslint-disable-next-line
            rows.map((row, index) => {
                const person = row[0].split(' - ')[0]
                row.splice(0, 1)
                
                // eslint-disable-next-line
                row.map((r, i) => {
                    if(r !== undefined && r !== null && r !== "" && r !== "0"){
                        r.toString().replace(',', '.')
                        if(isNaN(r) === false){
                            if(personsSeniorArray.indexOf(parseInt(person)) > -1 && eventsSeniorArray.indexOf(parseInt(events[i])) > -1){
                                sendsArray.push({
                                    person: person,
                                    average: events[i],
                                    value: parseFloat(r)
                                })
                            }
                        }
                    }
                })         
            })

            return sendsArray                
        
        } catch (error) {
            return "ERROR"
        }
        
    }
}